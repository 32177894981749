import PropTypes from 'prop-types';
import React, { Component } from "react";

export default class IconRestr extends Component {
    render() {
        var truckColor = null;
        if (this.props.color === "white") {
            truckColor = "#ffffff";
        } else {
            truckColor = "#000000";
        }
        if (this.props.tipo === "pedagio") {
            return (
                // toll icon

                <svg version="1.1" width="26" viewBox="0 0 36 36" height="32">
                    <path
                        style={{ fill: this.props.color }}
                        d="M 25.559051,18.66426 35.350671,2.636804 32.707108,1.0220887 22.594591,17.575392 l -3.106323,0 0,13.903262 -1.188445,0 0,-26.7544853 -15.8586517,0 0,26.7544853 -1.1921953,0 0,3.64513 34.101695,0 0,-3.64513 -9.79162,0 0,-12.814394 z M 11.361493,7.4645809 l 4.955999,0 0,12.7683571 -4.955999,0 0,-12.7683571 z m -6.9379902,0 4.9559993,0 0,12.7683571 -4.9559993,0 0,-12.7683571 z"
                    />
                </svg>
            );
        } else {
            return (
                // stop icon

                <svg version="1.1" width="26" viewBox="0 0 36 36" height="36">
                    {/* Restrições */}
                    <path
                        d="m 26.673405,14.516114 -3.164862,0 0,-1.959084 c 0,-0.744029 -0.540232,-2.260731 -2.384072,-2.260731 l -12.3852835,0 c -1.1604498,0 -2.1099083,0.949458 -2.1099083,2.109907 l 0,11.604494 2.1099083,0 c 0,1.751219 1.4136385,3.164861 3.1648625,3.164861 1.751218,0 3.164861,-1.413642 3.164861,-3.164861 l 6.329724,0 c 0,1.751219 1.413642,3.164861 3.164862,3.164861 1.751219,0 3.164861,-1.413642 3.164861,-3.164861 l 2.109908,0 0,-5.274771 z M 11.90405,25.59313 c -0.875611,0 -1.582432,-0.70682 -1.582432,-1.58243 0,-0.87561 0.706821,-1.582431 1.582432,-1.582431 0.875608,0 1.58243,0.706821 1.58243,1.582431 0,0.87561 -0.706822,1.58243 -1.58243,1.58243 z m 14.241878,-9.494585 2.067705,2.637384 -4.70509,0 0,-2.637384 z m -1.582431,9.494585 c -0.87561,0 -1.582432,-0.70682 -1.582432,-1.58243 0,-0.87561 0.706822,-1.582431 1.582432,-1.582431 0.875609,0 1.582431,0.706821 1.582431,1.582431 0,0.87561 -0.706822,1.58243 -1.582431,1.58243 z"
                        style={{ fill: truckColor }}
                    />
                    <circle r="16.552822" cy="18" cx="18" style={{ stroke: this.props.color, strokeWidth: 3, fill: "none" }} />
                    <path
                        id="path4143"
                        d="M 5.4537125,5.4537124 30.546288,30.546288"
                        style={{ stroke: this.props.color, strokeWidth: 3, fill: "none" }}
                    />
                </svg>
            );
        }
    }
}

IconRestr.propTypes = {
    doc: PropTypes.object,
    tipo: PropTypes.string,
    color: PropTypes.string,
};
