import L from 'leaflet';
import { Marker } from 'react-leaflet';
import { useStoreState } from 'easy-peasy';
import ReactDOMServer from 'react-dom/server';
import React, { useEffect, useState } from 'react';
import MarkerClusterGroup from "react-leaflet-markercluster";

import PopupTolls from './PopupTolls.js';
import IconRestr from '../../../../../../icons/Restricoes.js';

export default function CompareTollsMap() {
    const tolls_map = useStoreState((state) => state.TollsConferenceStore.tolls_map);
    const [pedagios, setPedagios] = useState([])

    useEffect(() => {
        async function gerarPedagios() {
            var pedagios = tolls_map.map((data, index) => (
                <div key={index}>
                    {(() => {
                        var my_icon = L.divIcon({
                            className: "custom-icon",
                            html: ReactDOMServer.renderToString(<IconRestr color={"green"} tipo={"pedagio"} />),
                        });

                        return (
                            <>
                                <Marker key={index} icon={my_icon} position={[data.coordinates.lat, data.coordinates.lng]}>
                                    <PopupTolls data={data} fonte={"Calculado Optcargo"} />
                                </Marker>
                            </>
                        );
                    })()}
                </div>
            ));
            setPedagios(pedagios)
        }
        gerarPedagios()
    }, [tolls_map])

    return (
        <MarkerClusterGroup className={"markercluster-maprestr"} id={0}>
            {pedagios}
        </MarkerClusterGroup>
    );
}
