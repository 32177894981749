import L from 'leaflet';
import 'react-leaflet-fullscreen-control';
import ReactDOMServer from 'react-dom/server';
import React, { useState, useEffect } from 'react';
import ReactLeafletSearch from 'react-leaflet-search';
import { Map, TileLayer, Marker } from 'react-leaflet';

import '../../../../styles.css';
import Documento from './Documento.js';
import IconCD from '../../../../../../../../icons/CentroDistribuicao.js';


export default function MyMap(props) {
    const [center, setCenter] = useState([-22.977213, -47.101165]);
    const [my_markers, setMyMarkers] = useState([])

    useEffect(() => {
        function fetchData() {
            if (props.values.lat !== "" && props.values.lat) {
                setCenter([props.values.lat, props.values.lng])

                var my_markers = (
                    <div key={1}>
                        {(() => {
                            var my_icon = L.divIcon({
                                className: "custom-icon",
                                html: ReactDOMServer.renderToString(<IconCD id={1} />),
                            });

                            return (
                                < Marker icon={my_icon} key={1} position={[props.values.lat, props.values.lng]} >
                                    <Documento values={props.values} />
                                </Marker>
                            );
                        })()
                        }
                    </div >
                );
                setMyMarkers(my_markers);

            } else {
                setMyMarkers([]);
            }
        }
        fetchData();
    }, [props]);

    return (
        <div style={{ width: "48%", marginTop: "1.3%", marginLeft: "1%" }}>
            <Map fullscreenControl center={center} className="company_map" zoom={10}  >
                <TileLayer url="http://{s}.tile.osm.org/{z}/{x}/{y}.png" />
                <ReactLeafletSearch position="topright" providerOptions={{ region: "br" }} />
                {my_markers}
            </Map>
        </div>
    );
}
