import { useSnackbar } from 'notistack';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import React, { useEffect, useRef, useState } from 'react';
import AssessmentIcon from '@material-ui/icons/Assessment';
import { useStoreActions, useStoreState } from 'easy-peasy';
import LocalShipping from '@material-ui/icons/LocalShipping';
import SlowMotionVideoIcon from '@material-ui/icons/SlowMotionVideo';

import '../../styles.css';
import api from '../../../../services/api.js';
// import PieChartSimple from './Components/PieChartSimple.js';
// import PieAnimation from "./Components/PieAnimation.js";

export default function Dash() {
    //ativa e desativa o loading abaixo da appbar
    const setLoading = useRef()
    setLoading.current = useStoreActions(actions => {
        return actions.globalStore.setLoading;
    });

    const reload = useStoreState((state) => state.globalStore.reload); // filial e data da progr reload

    const snack = useRef()
    const { enqueueSnackbar } = useSnackbar();
    snack.current = enqueueSnackbar

    // const [vazio, setVazio] = useState(true)
    const [values, setValues] = useState({
        'ajuste': 0,
        'analisando': 0,
        'roteirizando': 0,
        'roteirizada': 0,
        'programada': 0,
        'total': 0,
        'entregas': 0,
        'coletas': 0,
        'reentregas': 0,
        'novos': 0,
    })


    useEffect(() => {
        async function fecthData() {
            // setVazio(true)
            setLoading.current(true)
            var docs = await api.get(`/dash/docs`)
                .catch((error) => {
                    console.log(error);
                    snack.current("Falha ao carregar os documentos", { variant: 'error' })
                });
            if (docs) {
                let values = {
                    'ajuste': 0,
                    'analisando': 0,
                    'roteirizando': 0,
                    'roteirizada': 0,
                    'programada': 0,
                    'total': 0,
                    'entregas': 0,
                    'coletas': 0,
                    'reentregas': 0,
                    'novos': 0,
                }
                values.total = docs.data.length
                for (let doc of docs.data) {
                    if (doc.ajuste) values.ajuste += 1
                    if (doc.analisando) values.analisando += 1
                    if (doc.roteirizando) values.roteirizando += 1
                    if (doc.roteirizada) values.roteirizada += 1
                    if (doc.tipo === 'ENTREGA') values.entregas += 1
                    if (doc.tipo === 'COLETA') values.coletas += 1
                    if (doc.situacao === 'novo') values.novos += 1
                    if (doc.situacao === 'reentrega') values.reentregas += 1
                }
                setValues(values)
                // if (values.total > 0) setVazio(false)
            }
            setLoading.current(false)
        }
        fecthData();
    }, [reload]);

    return (
        < >
            <>
                <div className="rectangle1">
                    <div className="tittle">
                        Aguardando Roteirização
                    </div>
                    <hr className="lineTitle1" />
                    <div className="row_dahatabs">
                        <div className="iconDash1">
                            <SlowMotionVideoIcon style={{ fontSize: '100px' }} />
                        </div>
                        <div className="value"> {values.ajuste}/{values.total} </div>
                    </div>
                </div>
                <div className="rectangle2">
                    <div className="tittle">
                        Em Roteirização
                    </div>
                    <hr className="lineTitle2" />
                    <div className="row_dahatabs">
                        <div className="iconDash2">
                            <PlayArrowIcon style={{ fontSize: '100px' }} />
                        </div>
                        <div className="value"> {values.roteirizando}/{values.total} </div>
                    </div>
                </div>
                <div className="rectangle3">
                    <div className="tittle">
                        Em Análise
                    </div>
                    <hr className="lineTitle3" />
                    <div className="row_dahatabs">
                        <div className="iconDash3">
                            <AssessmentIcon style={{ fontSize: '100px' }} />
                        </div>
                        <div className="value"> {values.analisando}/{values.total} </div>
                    </div>
                </div>
                <div className="rectangle4">
                    <div className="tittle">
                        Roteirizados
                    </div>
                    <hr className="lineTitle4" />
                    <div className="row_dahatabs">
                        <div className="iconDash4">
                            <LocalShipping style={{ fontSize: '100px' }} />
                        </div>
                        <div className="value"> {values.roteirizada}/{values.total}</div>
                    </div>
                </div>
            </>
            <div className="rowgrafs">
                {/* {vazio ? null :
                    <PieChartSimple values={values} />
                } */}

                {/* <PieChart /> */}
                {/* <PieAnimation /> */}
            </div>

        </>
    );
}
