import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useRef, useEffect } from 'react';
import { Button, DialogActions, DialogContent } from '@material-ui/core';

import Charges from './Charges.js'
import CreatedBy from './CreatedBy';
import BasicInfo from './BasicInfo.js'
import Permissions from './Permissions.js'
import api from '../../../../../../../services/api';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
}));

export default function FormStore(props) {
    const classes = useStyles();
    const snack = useRef()
    const { enqueueSnackbar } = useSnackbar();
    snack.current = enqueueSnackbar

    const [values, setValues] = useState({
        '_id': "",
        'faixas': [],
        'status': "",
        'acesso': "",
        'nome_plano': "",
        'funcoes_desabilitadas': [
            { "id": 0, "name": "Geocoder - banco" },
            { "id": 1, "name": "Geocoder - OSM" },
            { "id": 2, "name": "Geocoder - Google" },
            { "id": 3, "name": "Alocar - considerando Número Máximo de Documentos" },
            { "id": 4, "name": "Alocar - considerando Restrição de Porte" },
            { "id": 5, "name": "Alocar - considerando Restrição de Rodízio" },
            { "id": 6, "name": "Alocar - considerando Priorização da Frota Própria" },
            { "id": 7, "name": "Alocar - considerando Gestão de Risco Patrimonial (GRP)" },
            { "id": 8, "name": "Ordenar - considerando Pedágios" },
            { "id": 9, "name": "Ordenar - considerando Restrição de Horário" },
            { "id": 10, "name": "Ordenar - considerando Tempo de Jornada" },
            { "id": 11, "name": "Ordenar - considerando Prazo de Entrega" },
            { "id": 12, "name": "Ordenar - considerando Ocupação do Veículo" },
            { "id": 13, "name": "Ordenar - considerando Perfil de Veículo de Médio Porte" },
            { "id": 14, "name": "Ordenar - considerando Perfil de Veículo de Grande Porte" },
            { "id": 15, "name": "Relatório - Viagem" },
            { "id": 16, "name": "Relatório - Por Usuário" },
            { "id": 17, "name": "Relatório - Empresa" },
            { "id": 18, "name": "Relatório - Planejado x Realizado" },
        ],
        'funcoes_habilitadas': [],
        'assinatura_mensal_basica': "", // number
    });

    const [update, setUpdate] = useState(false) // true in edit mode
    const my_props = useRef(props)
    useEffect(() => {
        if (my_props.current.data) {
            setValues(my_props.current.data)
            setUpdate(true)
        }
    }, [])

    async function AddNew() {
        var new_plan = {
            'faixas': values.faixas,
            'status': values.status,
            'acesso': values.acesso,
            'nome_plano': values.nome_plano,
            'funcoes_habilitadas': values.funcoes_habilitadas,
            'funcoes_desabilitadas': values.funcoes_desabilitadas,
            'assinatura_mensal_basica': Number(values.assinatura_mensal_basica),
        }
        api.post(`/admin/plan`, { new_plan })
            .then(() => {
                props.setReload(currentIsOpen => !currentIsOpen)
                props.setOpen(false)
                snack.current("Plano adicionado!", { variant: 'success' })
            })
            .catch((error) => {
                if (error.response.data.message) {
                    snack.current(`${error.response.data.message}`, { variant: 'error' })
                } else {
                    snack.current("Falha ao adicionar Plano!", { variant: 'error' })
                }
            });
    }

    async function Update() {
        var update_plan = {
            '_id': values._id,
            'faixas': values.faixas,
            'status': values.status,
            'acesso': values.acesso,
            'nome_plano': values.nome_plano,
            'funcoes_habilitadas': values.funcoes_habilitadas,
            'funcoes_desabilitadas': values.funcoes_desabilitadas,
            'assinatura_mensal_basica': Number(values.assinatura_mensal_basica),
        }

        api.put(`/admin/plan`, { update_plan })
            .then(() => {
                props.setReload(currentIsOpen => !currentIsOpen)
                props.setOpen(false)
                snack.current("Plano editado!", { variant: 'success' })
            })
            .catch((error) => {
                if (error.response.data.message) {
                    snack.current(`${error.response.data.message}`, { variant: 'error' })
                } else {
                    snack.current("Falha ao adicionar o Plano!", { variant: 'error' })
                }
            });
    }

    return (
        <>
            <DialogContent className={classes.root}>
                <BasicInfo values={values} setValues={setValues} />
                <CreatedBy values={values} setValues={setValues} />
                <Charges values={values} setValues={setValues} />
                <Permissions values={values} setValues={setValues} />
            </DialogContent >
            <DialogActions>
                <Button
                    disabled={(
                        values.faixas.length > 0 &&
                        values.status &&
                        values.acesso &&
                        values.nome_plano &&
                        values.assinatura_mensal_basica
                    ) ? false : true}
                    onClick={() => { update === true ? Update() : AddNew() }}
                    color="primary"
                >
                    Salvar
                </Button>
                <Button onClick={() => { props.setOpen(false) }} color="primary"> Cancelar </Button>
            </DialogActions>
        </>
    )
}

FormStore.propTypes = {
    setOpen: PropTypes.func,
    setReload: PropTypes.func
};
