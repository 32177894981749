import { createStore } from 'easy-peasy';

import toTripStore from './toTripStore';
import toOrderStore from './toOrderStore';
import mapStore from './mapStore/mapStore';
import VeicsDocsStore from './VeicsDocsStore';
import toAllocateStore from './toAllocateStore';
import sizeStore from './adminAreaStore/sizeStore';
import reloadStore from './reloadStore/reloadStore';
import ResultStore from './ResultStore/ResultStore';
import toolsStore from './adminAreaStore/toolsStore';
import globalStore from './globalStore/globalStore.js';
import ImportVehiclesStore from './ImportVehiclesStore';
import rationingStore from './adminAreaStore/rationingStore';

import DocumentStore from '../pages/DashTabs/Components/Documents/store.js';
import InvoicesStore from '../pages/InvoiceTabs/Components/Invoices/store.js';
import PlanedTripsStore from '../pages/Progr/Components/PlannedTrips/store.js';
import VehicleStore from '../pages/RegistrationsTabs/Components/Vehicles/store.js';
import VehicleTrackingStore from '../pages/Perform/Components/VehicleTracking/store.js';
import TollsConferenceStore from '../pages/Perform/Components/TollsConference/store.js';

const store = createStore({
    mapStore: mapStore,
    sizeStore: sizeStore,
    toolsStore: toolsStore,
    ResultStore: ResultStore,
    toTripStore: toTripStore,
    reloadStore: reloadStore,
    globalStore: globalStore,
    VehicleStore: VehicleStore,
    toOrderStore: toOrderStore,
    VeicsDocsStore: VeicsDocsStore,
    rationingStore: rationingStore,
    toAllocateStore: toAllocateStore,
    ImportVehiclesStore: ImportVehiclesStore,

    InvoicesStore: InvoicesStore,
    DocumentStore: DocumentStore,
    PlanedTripsStore: PlanedTripsStore,
    VehicleTrackingStore: VehicleTrackingStore,
    TollsConferenceStore: TollsConferenceStore,
})

export default store;
