export default function VehicleValidation(obj) {
    var valid_vehicles = []
    for (var veic of obj.my_vehicles) {
        if (veic.n_docs > 0 && veic.valido) {
            veic["tableData"].checked = false
            valid_vehicles.push(veic)
        }
    }

    return valid_vehicles
}
