import React, { useEffect, useState } from "react";
import { Button, Tooltip } from "@material-ui/core";
import { useStoreActions, useStoreState } from "easy-peasy";
import ViewCompactIcon from "@material-ui/icons/ViewCompact";

export default function Alocattion() {
    // cria todas as variaveis necessárias para a alocação
    const toAllocate = useStoreActions(actions => {
        return actions.toAllocateStore.setAllocate;
    });

    const my_vehicles = useStoreState((state) => state.VeicsDocsStore.my_vehicles);
    const documents = useStoreState((state) => state.VeicsDocsStore.documents);

    const [disabled, setDisabled] = useState(false)
    useEffect(() => {
        if (!my_vehicles || !documents) {
            setDisabled(true)
        } else if (my_vehicles.length === 0 || documents.length === 0) {
            setDisabled(true)
        } else {
            setDisabled(false)
        }
    }, [my_vehicles, documents])

    return (
        <Tooltip title={
            <span style={{ fontSize: 15 }}>
                Aloca os Documentos (entregas e coletas) nos Veículos selecionados.
            </span>} >
            <Button
                disabled={disabled}
                onClick={() => {
                    toAllocate({
                        documents,
                        my_vehicles,
                        open_allocate: true,
                        type: "alocacao_aprimorada",
                    })
                }}
            >
                <ViewCompactIcon
                    fontSize={'large'}
                    color={disabled ? 'disabled' : 'primary'}
                />
            </Button>
        </Tooltip>
    );
}
