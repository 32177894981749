import React from 'react';
import PropTypes from 'prop-types';
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { Button, Box, Popover } from '@material-ui/core';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';

function Popup(props) {
    return (
        <Table style={{ backgroundColor: '#fff' }}>
            <TableBody>
                <TableRow>
                    <TableCell>Placa</TableCell>
                    <TableCell>{props.trip.placa}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ whiteSpace: "nowrap" }}>Identificador da Viagem</TableCell>
                    <TableCell>{props.trip._id}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ whiteSpace: "nowrap" }}>Capacidade do Veículo</TableCell>
                    <TableCell>{props.trip.capacidade_kg}</TableCell>
                </TableRow>

                <TableRow>
                    <TableCell>Ocupação Inicial</TableCell>
                    <TableCell>{props.trip.ocup_inicial_kg}kg - {props.trip.ocup_inicial_porc}% - R${props.trip.ocup_inicial_reais} </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell>Ocupação Final</TableCell>
                    <TableCell>{props.trip.ocup_final_kg}kg - {props.trip.ocup_final_porc}% - R${props.trip.ocup_final_reais} </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell>Ocupação Máxima</TableCell>
                    <TableCell>{props.trip.maior_peso_carga_kg}kg - {props.trip.maior_taxa_ocup}% - R${props.trip.maior_peso_carga_reais} </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell>Categoria</TableCell>
                    <TableCell>{props.trip.categoria}</TableCell>
                </TableRow>

                <TableRow>
                    <TableCell>Número de eixos</TableCell>
                    <TableCell>{props.trip.n_eixos}</TableCell>
                </TableRow>

                <TableRow>
                    <TableCell>Valor da diária</TableCell>
                    <TableCell>{props.trip.valor_diaria}</TableCell>
                </TableRow>

                <TableRow>
                    <TableCell>Valor do km</TableCell>
                    <TableCell>{props.trip.valor_km}</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    )
}

export default function IconVeic(props) {
    return (
        <PopupState variant="popover" popupId="demo-popup-popover">
            {(popupState) => (
                <>
                    <Button
                        size="large"
                        color="primary"
                        {...bindTrigger(popupState)}
                        style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px' }}
                    >
                        <LocalShippingIcon fontSize="small" />
                    </Button>
                    <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <Box p={2}>
                            <Popup trip={props.trip} />
                        </Box>
                    </Popover>
                </>
            )
            }
        </PopupState >
    )
}

IconVeic.propTypes = {
    trip: PropTypes.array,
};
