import PropTypes from 'prop-types';
import React, { useRef } from "react";
import MaterialTable from "material-table";
import { useStoreState } from 'easy-peasy';

import ButtonMap from "./ButtonMap"

export default function DocumentTable(props) {
    const state = {
        columns: [
            { title: "Placa", field: "placa" },
            { title: "Ordem", field: "ordem" },
            { title: "Documento", field: "documento" },
            { title: "Prazo", field: "prazo" },
            { title: "Peso (kg)", field: "peso_kg", type: "numeric" },
            { title: "Valor (Reais)", field: "valor_reais", type: "numeric" },
            { title: "Tipo", field: "tipo" },
            { title: "Latitude", field: "lat_doc", type: "numeric" },
            { title: "Longitude", field: "lon_doc", type: "numeric" },
            { title: "Logradouro", field: "logradouro" },
            { title: "Número", field: "numero" },
            { title: "Cidade", field: "cidade" },
            { title: "Estado", field: "estado" },
            { title: "CEP", field: "cep" },
            { title: "Razão Social", field: "razao_social" },
        ],
    };

    const avaliable_docs = useStoreState((state) => state.toAllocateStore.avaliable_docs);

    const tableRef = useRef();
    return (
        <MaterialTable
            tableRef={tableRef}
            title="Documentos Válidos"
            columns={state.columns}
            data={avaliable_docs}
            options={{
                add: true,
                search: true,
                pageSize: 10,
                grouping: true,
                selection: true,
                exportButton: true,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [1, 5, 10, 20, avaliable_docs.length],
                padding: "dense",
                headerStyle: {
                    backgroundColor: "#01579b",
                    textAlign: 'center',
                    color: "#FFF",
                    fontSize: 12 * window.innerWidth / 1280,
                },
                cellStyle: {
                    textAlign: "center",
                    fontSize: 12 * window.innerWidth / 1280,
                },
            }}
            onSelectionChange={(rows) => {
                rows.length > 0 ? props.setAlocattionDocuments(rows) : props.setAlocattionDocuments([])
            }}
            localization={{
                body: {
                    addTooltip: "Adicionar novo",
                    deleteTooltip: "Excluir",
                    editTooltip: "Editar",
                    editRow: {
                        deleteText: "Deseja excluir o documento?",
                        cancelTooltip: "Cancelar",
                        saveTooltip: "Salvar",
                    },
                    emptyDataSourceMessage: "",
                    bulkEditTooltip: "Editar todos",
                    bulkEditCancel: "Descartar edições",
                    bulkEditApprove: "Salvar todas as edições"
                },

                grouping: {
                    placeholder: "Arraste os cabeçalhos aqui para agrupar por",
                    groupedBy: "Agrupado por:",
                },

                header: {
                    actions: "Opções",
                },

                toolbar: {
                    searchTooltip: "Pesquisar",
                    searchPlaceholder: "Pesquisar",
                    exportTitle: "Exportar",
                    nRowsSelected: "{0} documento(s) selecionados",
                },
                pagination: {
                    labelRowsSelect: "",
                    labelDisplayedRows: "{count} de {from}-{to}",
                    firstTooltip: "Primeira página",
                    previousTooltip: "Página anterior",
                    nextTooltip: "Próxima página",
                    lastTooltip: "Última página",
                },
            }}
            components={{
                Actions: () => {
                    return (
                        <ButtonMap />
                    );
                }
            }}
        />
    );
}

DocumentTable.propTypes = {
    setAlocattionDocuments: PropTypes.func,
};
