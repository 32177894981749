import React from "react";
import PropTypes from 'prop-types';
import { TextField } from "@material-ui/core";

import "../../../styles.css"

const currencies = [
    { value: '', label: '' },
    { value: 'user', label: 'user' },
    { value: 'master', label: 'master' },
    { value: 'admin', label: 'admin' },
];

const status = [
    { value: '', label: '' },
    { value: 'ativo', label: 'ativo' },
    { value: 'inativo', label: 'inativo' },
];

export default function BasicInfo(props) {
    const handleChange = (event) => {
        props.setValues({
            ...props.values,
            [event.target.id]: event.target.value,
        });
    };

    return (
        <>
            <div className="title_info1">Informações Gerais</div>
            <div className="fantasia1">
                <div className="rowC">
                    <div className="name1">
                        <TextField
                            margin="dense"
                            id="nome_completo"
                            label="Nome Completo"
                            value={props.values.nome_completo}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="email1">
                        <TextField
                            id="email"
                            type="email"
                            margin="dense"
                            label="E-mail"
                            value={props.values.email}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="rowC">
                    <div className="companyUser">
                        <TextField
                            select
                            id="empresa"
                            size="small"
                            label="Empresa"
                            variant="outlined"
                            onChange={handleChange}
                            value={props.values.empresa}
                            SelectProps={{ native: true }}
                        >
                            {props.companies.map((option) => (
                                <option key={option.value} >
                                    {option.label}
                                </option>
                            ))}
                        </TextField>

                        <TextField
                            select
                            id="tipo"
                            size="small"
                            variant="outlined"
                            label="Tipo do usuário"
                            onChange={handleChange}
                            value={props.values.tipo}
                            SelectProps={{ native: true }}
                        >
                            {currencies.map((option) => (
                                <option key={option.value} value={props.values.tipo.value}>
                                    {option.label}
                                </option>
                            ))}
                        </TextField>

                        <TextField
                            select
                            id="status"
                            size="small"
                            label="Status"
                            variant="outlined"
                            onChange={handleChange}
                            value={props.values.status}
                            SelectProps={{ native: true }}
                        >
                            {status.map((option) => (
                                <option key={option.value} value={props.values.status.value}>
                                    {option.label}
                                </option>
                            ))}
                        </TextField>
                    </div>
                </div>
            </div>
        </>
    )
}

BasicInfo.propTypes = {
    values: PropTypes.object,
    setValues: PropTypes.func
};
