import PropTypes from 'prop-types';
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, List, Card, CardHeader, ListItem, ListItemText, ListItemIcon, Checkbox, Button, Divider, Typography } from "@material-ui/core";

import "../../../styles.css"

const useStyles = makeStyles((theme) => ({
    cardHeader: {
        padding: theme.spacing(1, 1),
        backgroundColor: "#5AB4E6",
        color: "#fff",
    },
    list: {
        width: 535,
        height: 300,
        backgroundColor: "#f2f2f2",
        overflow: "auto"
    },
    button: {
        margin: theme.spacing(1, 0),
        backgroundColor: "#fff"
    },
    textSecondary: {
        color: "#fff",
    }
}));

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
    return [...a, ...not(b, a)];
}

export default function Permissions(props) {
    const classes = useStyles();
    const [checked, setChecked] = useState([]);

    const leftChecked = intersection(checked, props.values.funcoes_desabilitadas);
    const rightChecked = intersection(checked, props.values.funcoes_habilitadas);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (items) => intersection(checked, items).length;

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    const handleCheckedRight = () => {
        props.values.funcoes_habilitadas = props.values.funcoes_habilitadas.concat(leftChecked)
        props.setValues(props.values);

        props.values.funcoes_desabilitadas = not(props.values.funcoes_desabilitadas, leftChecked)
        props.setValues(props.values);

        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        props.values.funcoes_desabilitadas = props.values.funcoes_desabilitadas.concat(rightChecked)
        props.setValues(props.values);

        props.values.funcoes_habilitadas = not(props.values.funcoes_habilitadas, rightChecked)
        props.setValues(props.values);

        setChecked(not(checked, rightChecked));
    };

    const customList = (title, items) => (
        <Card>
            <CardHeader
                className={classes.cardHeader}
                avatar={
                    <Checkbox
                        color="#ff9900"
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
                        disabled={items.length === 0}
                    />
                }
                title={title}
                subheader={
                    <Typography className={classes.textSecondary}>
                        {`${numberOfChecked(items)}/${items.length} selecionados`}
                    </Typography>
                }
            />
            <Divider />
            <List className={classes.list} dense component="div" role="list">
                {items.map((value) => {
                    const labelId = `transfer-list-all-item-${value}-label`;

                    return (
                        <ListItem
                            key={value}
                            role="listitem"
                            button
                            onClick={handleToggle(value)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    color="#ff9900"
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ "aria-labelledby": labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={`${value.name}`} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Card>
    );

    return (
        <Grid
            container
            spacing={2}
            justify="center"
            alignItems="center"
            className={classes.root}
        >
            <Grid item>{customList("Desabilitadas", props.values.funcoes_desabilitadas)}</Grid>
            <Grid item>
                <Grid container direction="column" alignItems="center">
                    <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={handleCheckedRight}
                        disabled={leftChecked.length === 0}
                        aria-label="move selected right"
                    >
                        &gt;
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0}
                        aria-label="move selected left"
                    >
                        &lt;
                    </Button>
                </Grid>
            </Grid>
            <Grid item>{customList("Habilitadas", props.values.funcoes_habilitadas)}</Grid>
        </Grid>
    );
}

Permissions.propTypes = {
    values: PropTypes.object,
    setValues: PropTypes.func
};
