import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import MaterialTable from 'material-table';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState, useRef } from 'react';
import { Fab, Tooltip, Container, Button } from '@material-ui/core';

import api from '../../../../../../services/api';

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'absolute',
        bottom: theme.spacing(4),
        right: theme.spacing(4),
    },
}));

export default function Table(props) {
    const classes = useStyles();
    const snack = useRef()
    const { enqueueSnackbar } = useSnackbar();
    snack.current = enqueueSnackbar

    const [plans, setPlans] = useState([]);

    const state = {
        columns: [
            { title: 'Nome do Plano', field: 'nome_plano' },
            { title: 'Acesso', field: 'acesso' },
            { title: 'Status', field: 'status' },
            { title: 'identificador', field: '_id' },
        ],
    };

    useEffect(() => {
        function fetchData() {
            api
                .get(`/admin/plan`)
                .then((res) => {
                    setPlans(res.data);
                    console.log("Planos carregadas com sucesso!")
                })
                .catch((error) => {
                    console.log(error);
                    snack.current("Falha ao buscar os planos", { variant: 'error' })
                });
        }
        fetchData();
    }, [props.reload]);

    const tableRef = useRef();
    return (
        <Container maxWidth={false} spacing={2}>
            <Tooltip title={<span>Inserir Novo Plano</span>}>
                <Fab color="primary" aria-label="add" className={classes.fab} onClick={() => { props.setOpen(true); props.setData(null); }}>
                    <AddIcon />
                </Fab>
            </Tooltip>
            <MaterialTable
                tableRef={tableRef}
                title="Planos"
                columns={state.columns}
                data={plans}
                options={{
                    add: true,
                    search: true,
                    pageSize: 10,
                    exportButton: false,
                    emptyRowsWhenPaging: false,
                    pageSizeOptions: [1, 5, 10, 20, plans.length],
                    padding: "dense",
                    headerStyle: {
                        backgroundColor: "#01579b",
                        textAlign: "center",
                        color: "#FFF",
                    },
                    cellStyle: {
                        textAlign: "center",
                    },
                    rowStyle: rowData => ({
                        backgroundColor: (rowData.tableData.id % 2 === 0 ? '#fff' : '#f5f5f5')
                    }),
                }}
                localization={{
                    body: {
                        emptyDataSourceMessage: "",
                        addTooltip: "adicionar novo usuário",
                        deleteTooltip: "excluir",
                        editRow: { deleteText: "Deseja excluir o usuário?" },
                    },

                    header: {
                        actions: "Opções",
                    },

                    toolbar: {
                        searchTooltip: "Pesquisar",
                        searchPlaceholder: "Pesquisar",
                        exportTitle: "exportar",
                        nRowsSelected: "{0} Usuários(s) selecionados",
                    },
                    pagination: {
                        labelRowsSelect: "",
                        labelDisplayedRows: "{count} de {from}-{to}",
                        firstTooltip: "Primeira página",
                        previousTooltip: "Página anterior",
                        nextTooltip: "Próxima página",
                        lastTooltip: "Última página",
                    },
                }}
                components={{
                    Action: props_ => (
                        <Tooltip title={<span> Editar </span>} >
                            <Button
                                onClick={() => {
                                    props.setData(props_.data)
                                    props.setOpen(true)
                                }}
                                color="primary"
                                size="small"
                            >
                                <EditIcon />
                            </Button>
                        </Tooltip>
                    ),
                }}
                editable={{
                    onRowDelete: (oldData) =>
                        new Promise((resolve) => {
                            setTimeout(() => {
                                resolve();
                            }, 1000);
                        }),
                }}
            />
        </Container>
    );
}

Table.propTypes = {
    reload: PropTypes.bool,
    setData: PropTypes.func,
    setOpen: PropTypes.func,
    setReload: PropTypes.func,
};
