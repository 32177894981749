import { action } from "easy-peasy";

const TollsConferenceStore = {
    // informações do veiculo
    tolls_opt_calc: [],
    setTollsCalc: action((state, payload) => {
        state.tolls_opt_calc = payload
    }),

    open_map: false,
    setOpenMap: action((state, _) => {
        state.open_map = !state.open_map
    }),

    tolls_map: [],
    center: [-22.977213, -47.101165], // centro do mapa
    setTollsMap: action((state, payload) => {
        // abre o mapa se estiver fechado
        if (state.tolls_map.length === 0 && !state.open_map && payload.length > 0) {
            state.open_map = !state.open_map
            state.center = [payload[0].coordinates.lat, payload[0].coordinates.lng]
        }
        // fecha o mapa se estiver aberto
        if (payload.length === 0 && state.open_map) {
            state.open_map = !state.open_map
        }
        state.tolls_map = payload
    }),

};
export default TollsConferenceStore;
