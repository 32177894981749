import { action } from "easy-peasy"

const VeicsDocsStore = {
    // ################## Store ##################
    // estados disponiveis para filtragem no dashboard de estudos
    check_states: {
        valido: true,
        invalido: true,
        processando: true,
    },
    setCheckStates: action((state, payload) => {
        state.check_states = payload
    }),

    // documents displayed in the document table "src/pages/Study/Components/DocumentTable.js"
    documents: [],
    setDocuments: action((state, payload) => {
        state.documents = payload;
    }),

    del_docs: [],
    setDelDocs: action((state, payload) => {
        state.del_docs = payload;
    }),

    // vehicle table values
    my_vehicles: [],
    setVeiculos: action((state, payload) => {
        state.my_vehicles = payload;
    }),

    reload: false,
    setReload: action((state, _) => {
        state.reload = !state.reload
    }),
};

export default VeicsDocsStore;
