import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import {
    AntTab,
    AntTabs,
    TabPanel,
    tabStyles,
} from '../../../../../components/MyTabs.js'

import {
    AppBar,
    Container,
    LinearProgress,
    SwipeableDrawer,
} from '@material-ui/core';

import '../../../styles.css';
import Sumary from './Components/Sumary.js';
import DuplicateTable from './Components/DuplicateTable.js';
import IncorrectCategory from './Components/IncorrectCategory.js';
import VehicleInconsistency from './Components/VehicleInconsistency.js';

export default function InvoiceDrawer() {
    const classes = tabStyles({ fontSize: 11 * window.innerWidth / 1280 });

    const setTab = useStoreActions(actions => { return actions.InvoicesStore.setTab });
    const setOpenInvoice = useStoreActions(actions => { return actions.InvoicesStore.setOpenInvoice });

    const tab = useStoreState((state) => state.InvoicesStore.tab);
    const fatura = useStoreState((state) => state.InvoicesStore.fatura);
    const loading = useStoreState((state) => state.globalStore.loading);
    const open = useStoreState((state) => state.InvoicesStore.open_invoice);

    return (
        <SwipeableDrawer
            anchor={"bottom"}
            onOpen={() => console.log("open")}
            onClose={() => {
                setOpenInvoice(false)
            }}
            open={open}
            classes={{ paper: classes.paper }}
        >
            < Container maxWidth={false} spacing={2}>
                <AppBar position='fixed' color='default' style={{ marginTop: 50 }}>
                    <AntTabs
                        value={tab}
                        variant='fullWidth'
                        textColor='primary'
                        scrollButtons='auto'
                        indicatorColor='primary'
                        onChange={(_, newValue) => { setTab(newValue) }}
                        classes={{ indicator: classes.customStyleOnActiveTab }}
                    >
                        <AntTab
                            label={<span className={tab === 0 ? classes.activeTab : classes.customStyleOnTab}>RESUMO</span>}
                        />
                        <AntTab
                            disabled={fatura.n_total_duplicate > 0 ? false : true}
                            label={<span className={tab === 1 ? classes.activeTab : classes.customStyleOnTab}>COBRANÇA DUPLICADA</span>}
                        />
                        <AntTab
                            disabled={fatura.n_total_inc_category > 0 ? false : true}
                            label={<span className={tab === 2 ? classes.activeTab : classes.customStyleOnTab}>CATEGORIA INCORRETA</span>}
                        />
                        <AntTab
                            disabled={fatura.n_total_vehicle_inconsistency > 0 ? false : true}
                            label={<span className={tab === 3 ? classes.activeTab : classes.customStyleOnTab}>VEÍCULO NÃO CADASTRADO/INATIVO</span>}
                        />
                    </AntTabs>
                    {loading && <LinearProgress />}
                </AppBar>
                <div >
                    <TabPanel value={tab} index={0} style={{ marginTop: 50, fontSize: 13 }}>
                        <Sumary />
                    </TabPanel>
                    <TabPanel value={tab} index={1} style={{ marginTop: 50 }}>
                        <DuplicateTable />
                    </TabPanel>
                    <TabPanel value={tab} index={2} style={{ marginTop: 50 }}>
                        < IncorrectCategory />
                    </TabPanel>
                    <TabPanel value={tab} index={3} style={{ marginTop: 50 }}>
                        <VehicleInconsistency />
                    </TabPanel>
                </div>
            </Container>
        </SwipeableDrawer>
    );
}
