import React, { useRef } from 'react';
import { useSnackbar } from "notistack";
import { Button } from "@material-ui/core";
import GetAppIcon from '@material-ui/icons/GetApp';
import { useStoreState, useStoreActions } from "easy-peasy";

import api from "../../../../../services/api.js";

export default function ImportVehicles() {
    const snack = useRef()
    const { enqueueSnackbar } = useSnackbar();
    snack.current = enqueueSnackbar

    // abre e fecha o modal de importação de veículos
    const setOpen = useStoreActions(actions => {
        return actions.ImportVehiclesStore.setOpen;
    });

    //ativa e desativa o loading abaixo da appbar
    const setLoading = useStoreActions(actions => {
        return actions.globalStore.setLoading;
    });

    // limpa os veiculos plotados no mapa
    const plotVehicles = useStoreActions(actions => {
        return actions.mapStore.plotVehicles;
    });

    // limpa os documentos plotados no mapa
    const plotDocuments = useStoreActions(actions => {
        return actions.mapStore.plotDocuments;
    });

    // atualiza a tabela de documentos
    const setReload = useStoreActions(actions => {
        return actions.VeicsDocsStore.setReload;
    });

    const import_vehicles = useStoreState((state) => state.ImportVehiclesStore.import_vehicles);
    const setVehicles = useStoreActions(actions => {
        return actions.ImportVehiclesStore.setVehicles;
    });

    async function Import() {
        setOpen(false)
        setLoading(true);
        snack.current("Importando veículos, aguarde.", { variant: 'info' })
        api.put(`/import/veics`, {
            veics: import_vehicles,
        })
            .then(() => {
                snack.current("Importação concluída!", { variant: 'success' })
                setReload()
                setLoading(false);
                plotVehicles([])
                plotDocuments([])
                setVehicles([])
            })
            .catch((error) => {
                console.log(error);
                try {
                    snack.current(`${error.response.data.message}`, { variant: 'error' });
                } catch (e) {
                    console.error(e);
                    snack.current("Não foi possível importar importar os veículos", { variant: 'error' })
                }
                plotVehicles([])
                plotDocuments([])
                setLoading(false);
                setVehicles([])
            });
    }

    return (
        <Button
            color="primary"
            component="span"
            disabled={import_vehicles.length === 0 ? true : false}
            startIcon={<GetAppIcon />}
            onClick={() => Import()}
        >
            Importar Veículos
        </Button>
    );
}
