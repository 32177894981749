import React from "react";
import { useStoreState } from 'easy-peasy';
import BlockIcon from '@material-ui/icons/Block';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';

import "../styles.css"

export default function GeneralResults() {
    const general = useStoreState((state) => state.PlanedTripsStore.general);

    return (
        <>
            <div className="fantasia">
                <div className='title'>Viagens Planejadas da Filial</div>
            </div>

            <div className="rowData">
                <BlockIcon />
                <div className="item"> Houve Restrição:</div>
                <div className="dado">{general.restricao} </div>
            </div>

            <div className="rowData">
                <LocalShippingIcon />
                <div className="item"> Ocupação média:</div>
                <div className="dado">{general.ocupacao_media}%</div>
                <div className="item"> Distância Total:</div>
                <div className="dado">{general.tam_viagem_km} km </div>
            </div>

            <div className="rowData">
                <LocalAtmIcon />
                <div className="item"> Custo Pedágio:</div>
                <div className="dado">R$ {general.custo_pedagio} </div>
                <div className="item"> Custo Diárias:</div>
                <div className="dado">R$ {general.valor_diaria}</div>
                <div className="item"> Custo km:</div>
                <div className="dado">R$ {general.custo_km} </div>
                <div className="item"> Custo Total:</div>
                <div className="dado">R$ {general.custo_total_reais} </div>
            </div>
        </>
    );
}
