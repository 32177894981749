import MaterialTable from 'material-table';
import React, { useEffect, useRef, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';

import Add from './Components/Add.js';
import Upload from './Components/Upload.js';
import Delete from './Components/Delete.js';
import IconDoc from './Components/IconDoc.js';
import IconRot from './Components/IconRot.js';
import IconGeo from './Components/IconGeo.js';
import Geocoder from './Components/Geocoder.js';
import DellMany from './Components/DellMany.js';
import IconOrder from './Components/IconOrder.js';
import api from '../../../../../../services/api.js';
import EditDocument from './Components/EditDocument.js';

export default function DocumentTable(props) {
    const plotDocuments = useRef()
    plotDocuments.current = useStoreActions(actions => {
        return actions.mapStore.plotDocuments;
    });

    //ativa e desativa o loading abaixo da appbar
    const setLoading = useRef()
    setLoading.current = useStoreActions(actions => {
        return actions.globalStore.setLoading;
    });

    // adiciona os documentos ao store
    const setDocumentsStore = useRef()
    setDocumentsStore.current = useStoreActions(actions => {
        return actions.VeicsDocsStore.setDocuments;
    });

    // Seleção dos documentos a serem excluídos
    const setDelDocs = useRef()
    setDelDocs.current = useStoreActions(actions => {
        return actions.VeicsDocsStore.setDelDocs;
    });

    const check_states = useStoreState((state) => state.VeicsDocsStore.check_states);
    const reload = useStoreState((state) => state.VeicsDocsStore.reload);
    const reload_ = useStoreState((state) => state.globalStore.reload);

    const [documents, setDocuments] = useState([])
    useEffect(() => {
        async function fetchData() {
            setLoading.current(true)
            api.get(`/progr/docs`)
                .then((res) => {
                    setDocuments(res.data)
                    // utilizado nas funções de alocação, ordenação e geração de viagem
                    setDocumentsStore.current(res.data)
                    setLoading.current(false)
                })
                .catch((error) => {
                    setLoading.current(false)
                    console.log(error);
                });
        }
        fetchData()
    }, [reload, reload_])

    const [visible_docs, setVisibleDocs] = useState([])
    useEffect(() => {
        var visible_docs_ = []
        for (let doc of documents) {
            if (check_states.valido && doc.valido) visible_docs_.push(doc)
            if (check_states.invalido && doc.invalido) visible_docs_.push(doc)
            if (check_states.processando && doc.processando) visible_docs_.push(doc)
        }
        setVisibleDocs(visible_docs_)
    }, [documents, check_states]);

    const state = {
        columns: [
            {
                title: "Opções",
                field: "opcoes",
                editable: false,
                render: (rowData) => {
                    return (
                        <>
                            <EditDocument doc={rowData} setData={props.setData} setOpen={props.setOpen} />
                            <Delete doc={rowData} docs={visible_docs} />
                        </>
                    )
                }
            },
            {
                title: "info",
                field: "info",
                editable: false,
                render: (rowData) => {
                    return (
                        <>
                            <IconDoc doc={rowData} />
                            <IconRot doc={rowData} />
                            <IconGeo doc={rowData} />
                            <IconOrder doc={rowData} />
                        </>
                    )
                }
            },
            { title: "Status Error", field: "status_error" },
            { title: "Placa", field: "placa" },
            { title: "Ordem", field: "ordem" },
            { title: "Documento", field: "documento" },
            { title: "Peso (kg)", field: "peso_kg", type: "numeric" },
            { title: "Valor (reais)", field: "valor_reais", type: "numeric" },
            { title: "Tipo", field: "tipo" },
            { title: "Situação", field: "situacao" },
            { title: "Tentativas de Entrega", field: "cont_situacao" },
            { title: "Prazo", field: "prazo" },
        ],
    };

    const tableRef = useRef();
    return (
        <MaterialTable
            tableRef={tableRef}
            title="Documentos em Roteirização"
            columns={state.columns}
            data={visible_docs}
            options={{
                add: true,
                search: true,
                grouping: true,
                selection: true,
                exportButton: false,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [1, 5, 10, 20, visible_docs.length],
                padding: "dense",
                headerStyle: {
                    backgroundColor: "#01579b",
                    textAlign: 'center',
                    color: "#FFF",
                    fontSize: 11 * window.innerWidth / 1280,
                },
                cellStyle: {
                    textAlign: "center",
                    fontSize: 11 * window.innerWidth / 1280,
                },
                rowStyle: rowData => ({
                    backgroundColor:
                        rowData.invalido ? '#ffcccc' :
                            rowData.valido ? '#ebfaeb' : "#add8e6"
                }),
            }}
            onSelectionChange={(rows) => {
                plotDocuments.current(rows)
                setDelDocs.current(rows)
            }}
            localization={{
                body: {
                    addTooltip: "Adicionar novo documento",
                    deleteTooltip: "Excluir",
                    editTooltip: "Editar",
                    editRow: {
                        deleteText: "Deseja excluir o documento?",
                        cancelTooltip: "Cancelar",
                        saveTooltip: "Salvar",
                    },
                    emptyDataSourceMessage: (
                        ""
                    ),
                    bulkEditTooltip: "Editar todos",
                    bulkEditCancel: "Descartar edições",
                    bulkEditApprove: "Salvar todas as edições"
                },

                grouping: {
                    placeholder: "Arraste os cabeçalhos aqui para agrupar por",
                    groupedBy: "Agrupado por:",
                },

                header: {
                    actions: "Opções",
                },

                toolbar: {
                    searchTooltip: "Pesquisar",
                    searchPlaceholder: "Pesquisar",
                    exportTitle: "Exportar",
                    nRowsSelected: "{0} documento(s) selecionados",
                },
                pagination: {
                    labelRowsSelect: "",
                    labelDisplayedRows: "{count} de {from}-{to}",
                    firstTooltip: "Primeira página",
                    previousTooltip: "Página anterior",
                    nextTooltip: "Próxima página",
                    lastTooltip: "Última página",
                },
            }}
            components={{
                Actions: () => {
                    return (
                        <div className="row">
                            <Upload />
                            <Geocoder
                                documents={documents}
                            />
                            <Add
                                setData={props.setData}
                                setOpen={props.setOpen}
                            />
                            <DellMany />
                        </div>
                    );
                }
            }}
        />
    );
}
