import React from "react";
import PropTypes from 'prop-types';
import { Popup } from "react-leaflet";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

export default function PopupRestricaoPorte(props) {
    var altura_min = ""
    var pesomin = ""
    var eixomin = ""
    var comprimentomin = ""


    var alturamax = ""
    var pesomax = ""
    var eixomax = ""
    var comprimentomax = ""

    if (props.data.alturamin | props.data.alturamin === 0) {
        altura_min = (
            <TableRow>
                <TableCell>Proibida circulação de veículos com altura superior a: </TableCell>
                <TableCell>{props.data.alturamin}(m)</TableCell>
            </TableRow>
        )
    }
    if (props.data.pesomin | props.data.pesomin === 0) {
        pesomin = (
            <TableRow>
                <TableCell>Proibida circulação de veículos com peso superior a: </TableCell>
                <TableCell>{props.data.pesomin} (kg)</TableCell>
            </TableRow>
        )
    }
    if (props.data.eixomin | props.data.eixomin === 0) {
        eixomin = (
            <TableRow>
                <TableCell>Proibida circulação de veículos com mais de: </TableCell>
                <TableCell>{props.data.eixomin} eixos</TableCell>
            </TableRow>
        )
    }
    if (props.data.comprimentomin | props.data.comprimentomin === 0) {
        comprimentomin = (
            <TableRow>
                <TableCell>Proibida circulação de veículos com comprimento superior a: </TableCell>
                <TableCell>{props.data.comprimentomin} (m)</TableCell>
            </TableRow>
        )
    }

    if (props.data.alturamax | props.data.alturamax === 0) {
        alturamax = (
            <TableRow>
                <TableCell>Proibida circulação de veículos com altura inferior a: </TableCell>
                <TableCell>{props.data.alturamax} (m)</TableCell>
            </TableRow>
        )
    }
    if (props.data.pesomax | props.data.pesomax === 0) {
        pesomax = (
            <TableRow>
                <TableCell>Proibida circulação de veículos com peso inferior a: </TableCell>
                <TableCell>{props.data.pesomax} (kg)</TableCell>
            </TableRow>
        )
    }
    if (props.data.eixomax | props.data.eixomax === 0) {
        eixomax = (
            <TableRow>
                <TableCell>Proibida circulação de veículos com menos de: </TableCell>
                <TableCell>{props.data.eixomax} eixos </TableCell>
            </TableRow>
        )
    }
    if (props.data.comprimentomax | props.data.comprimentomax === 0) {
        comprimentomax = (
            <TableRow>
                <TableCell>Proibida circulação de veículos de comprimento inferior a:</TableCell>
                <TableCell>{props.data.comprimentomax} (m) </TableCell>
            </TableRow>
        )
    }

    var horario_seg_sex = ""
    if (props.data.horario_seg_sex.length === 15) {
        horario_seg_sex = (
            <TableRow>
                <TableCell>segunda - sexta</TableCell>
                <TableCell width="40%" >{props.data.horario_seg_sex.substr(0, 11)}</TableCell>
            </TableRow>
        )
    } else if (props.data.horario_seg_sex.length === 25) {
        horario_seg_sex = (
            <TableRow>
                <TableCell>segunda - sexta</TableCell>
                <TableCell width="40%" >{props.data.horario_seg_sex.substr(0, 23)}</TableCell>
            </TableRow>
        )
    } else if (props.data.horario_seg_sex.length === 35) {
        horario_seg_sex = (
            <TableRow>
                <TableCell>segunda - sexta</TableCell>
                <TableCell width="40%" >{props.data.horario_seg_sex}</TableCell>
            </TableRow>
        )
    }

    var horario_sabados = ""
    if (props.data.horario_sabados.length === 15) {
        horario_sabados = (
            <TableRow>
                <TableCell>sábados</TableCell>
                <TableCell width="40%" >{props.data.horario_sabados.substr(0, 11)}</TableCell>
            </TableRow>
        )
    } else if (props.data.horario_sabados.length === 25) {
        horario_sabados = (
            <TableRow>
                <TableCell>sábados</TableCell>
                <TableCell width="40%" >{props.data.horario_sabados.substr(0, 23)}</TableCell>
            </TableRow>
        )
    } else if (props.data.horario_sabados.length === 35) {
        horario_sabados = (
            <TableRow>
                <TableCell>sábados</TableCell>
                <TableCell width="40%" >{props.data.horario_sabados.substr(0, 23)}</TableCell>
            </TableRow>
        )
    }

    var horario_dom_fer = ""
    if (props.data.horario_dom_fer.length === 15) {
        horario_dom_fer = (
            <TableRow>
                <TableCell>domingos e feriados</TableCell>
                <TableCell width="40%" >{props.data.horario_dom_fer.substr(0, 11)}</TableCell>
            </TableRow>
        )
    } else if (props.data.horario_dom_fer.length === 25) {
        horario_dom_fer = (
            <TableRow>
                <TableCell>domingos e feriados</TableCell>
                <TableCell width="40%" >{props.data.horario_dom_fer.substr(0, 23)}</TableCell>
            </TableRow>
        )
    } else if (props.data.horario_dom_fer.length === 35) {
        horario_dom_fer = (
            <TableRow>
                <TableCell>domingos e feriados</TableCell>
                <TableCell width="40%">{props.data.horario_dom_fer}</TableCell>
            </TableRow>
        )
    }


    return (
        <Popup>
            <h2>Decreto {props.data.decreto}</h2>
            <Table size="small">
                <TableBody>
                    {altura_min}
                    {alturamax}

                    {pesomin}
                    {pesomax}

                    {eixomin}
                    {eixomax}

                    {comprimentomin}
                    {comprimentomax}

                    {horario_seg_sex}
                    {horario_sabados}
                    {horario_dom_fer}
                    <TableRow>
                        <TableCell>Cidade</TableCell>
                        <TableCell>{props.data.cidade}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Estado</TableCell>
                        <TableCell>{props.data.estado}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Popup>
    );
}

PopupRestricaoPorte.propTypes = {
    data: PropTypes.object,
};
