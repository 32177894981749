import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useStoreActions } from 'easy-peasy';
import DeleteIcon from '@material-ui/icons/Delete';
import { Tooltip, Button } from '@material-ui/core';
import React, { useState, useRef, Fragment } from 'react';

import api from '../services/api.js'

export default function Delete(props) {
    const {
        _id,
        name,
        route,
        setReload,
    } = props

    const snack = useRef()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    snack.current = enqueueSnackbar
    const closeSnackbarRef = useRef()
    closeSnackbarRef.current = closeSnackbar

    //ativa e desativa o loading abaixo da appbar
    const setLoading = useRef()
    setLoading.current = useStoreActions(actions => { return actions.globalStore.setLoading });

    const [id, setId] = useState(null);
    const Id = useRef()
    Id.current = id

    async function DeleteOne(key) {
        setLoading.current(true)
        closeSnackbarRef.current(key)
        snack.current("Excluindo, aguarde.", { variant: 'info' })
        let aux = route + Id.current
        api.delete(aux)
            .then(() => {
                snack.current("Excluído com sucesso", { variant: 'success' })
                setReload()
                setLoading.current(false)
            })
            .catch((error) => {
                console.log(error);
                snack.current("Falha ao excluir", { variant: 'error' })
                setReload()
                setLoading.current(false)
            })
    }

    const action = key => (
        <Fragment>
            <Button onClick={() => { DeleteOne(key) }}>
                Sim
            </Button>
            <Button onClick={() => { closeSnackbarRef.current(key) }}>
                Não
            </Button>
        </Fragment>
    );

    function confirmationWindow(name) {
        snack.current(`Deseja excluir ${name}?`, {
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
            },
            variant: 'info',
            persist: true,
            preventDuplicate: true,
            action,
        })
    }

    return (
        <Tooltip title={<span style={{ fontSize: 15 }}> Excluir</span>} >
            <Button
                color="primary"
                onClick={() => {
                    setId(_id)
                    confirmationWindow(name)
                }}
                style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px' }}
            >
                <DeleteIcon fontSize="small" />
            </Button>
        </Tooltip>
    );
}

Delete.propTypes = {
    _id: PropTypes.string,
    name: PropTypes.string,
    route: PropTypes.string,
    setReload: PropTypes.func,
};
