import L from "leaflet"
import "../../styles.css";
import { useStoreState } from "easy-peasy"
import ReactDOMServer from "react-dom/server";
import { Polyline, Marker } from "react-leaflet";
import React, { useEffect, useState } from "react";
import MarkerClusterGroup from "react-leaflet-markercluster";

import IconRestr from "../../../../../../icons/Restricoes";
import PopupPedagio from "../../../../../../popups/Pedagio";

export default function MapTools() {
    const [pedagios, setPedagios] = useState([]);

    const tools = useStoreState((state) => state.toolsStore.tools);

    useEffect(() => {
        function fetchData() {
            var pedagios = tools.map((data, index) => (
                <div key={index}>
                    {(() => {
                        var paths_latlon = data.area;
                        var my_icon = L.divIcon({
                            className: "custom-icon",
                            html: ReactDOMServer.renderToString(<IconRestr color={"black"} tipo={"pedagio"} />),
                        });

                        return (
                            <Marker key={index} icon={my_icon} position={[data.center[0].lat, data.center[0].lng]}>
                                <Polyline color={"black"} positions={paths_latlon} />
                                <PopupPedagio data={data} />
                            </Marker>
                        );
                    })()}
                </div>
            ));
            setPedagios(pedagios);
        }
        fetchData();
    }, [tools]);

    return (
        <>
            <MarkerClusterGroup className={"markercluster-maprestr"} id={0}>
                {pedagios}
            </MarkerClusterGroup>
        </>
    );
}
