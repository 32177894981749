import React from "react";
import PropTypes from 'prop-types';
import { Popup } from "react-leaflet";
import Table from "@material-ui/core/Table";
import {
    TableBody,
    TableCell,
    TableRow,
} from "@material-ui/core";

export default function LatLon(props) {
    return (
        <Popup>
            <Table size="small">
                <TableBody>
                    <TableRow>
                        <TableCell>Latitude</TableCell>
                        <TableCell>{props.lat.toFixed(6)} &emsp;</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Longitude</TableCell>
                        <TableCell>{props.lon.toFixed(6)}   </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Popup>
    );
}

LatLon.propTypes = {
    lat: PropTypes.number,
    lon: PropTypes.number
};
