import PropTypes from 'prop-types';
import { useSnackbar } from "notistack";
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useRef, useEffect } from "react";
import { Button, DialogActions, DialogContent } from "@material-ui/core";

import MyMap from "./MyMap"
import Adress from "./Adress.js"
import Contacts from "./Contacts.js"
import BasicInfo from "./BasicInfo.js"
import CreatedBy from "./CreatedBy"
import api from "../../../../../../../services/api";

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
}));

export default function FormStore(props) {
  const classes = useStyles();
  const snack = useRef()
  const { enqueueSnackbar } = useSnackbar();
  snack.current = enqueueSnackbar

  const [values, setValues] = useState({
    _id: "",
    lat: "", // number
    lng: "", // number
    cep: "",
    cnpj: "",
    pais: "",
    plano: "",
    status: "",
    bairro: "",
    cidade: "",
    estado: "",
    numero: "",
    apelido: "",
    contatos: [], // array
    criado_em: "",
    criado_por: "",
    editado_em: "",
    logradouro: "",
    editado_por: "",
    complemento: "",
    razao_social: "",
    nome_fantasia: "",
    tempo_contrato: "", // number
    webhook_url: "",
  });

  const [update, setUpdate] = useState(false) // true in edit mode
  const my_props = useRef(props)
  useEffect(() => {
    if (my_props.current.data) {
      setValues(my_props.current.data)
      setUpdate(true)
    }
  }, [])

  async function AddNew() {
    let my_plan = props.plans.filter(x => x.label === values.plano)
    var new_company = {
      'cep': values.cep,
      'lat': values.lat,
      'lng': values.lng,
      'pais': values.pais,
      'cnpj': values.cnpj,
      'planId': my_plan[0].value,
      'status': values.status,
      'bairro': values.bairro,
      'cidade': values.cidade,
      'estado': values.estado,
      'numero': values.numero,
      'apelido': values.apelido,
      'contatos': values.contatos,
      'logradouro': values.logradouro,
      'complemento': values.complemento,
      'razao_social': values.razao_social,
      'nome_fantasia': values.nome_fantasia,
      'tempo_contrato': Number(values.tempo_contrato),
      'webhook_url': values.webhook_url,
    }
    api.post(`/admin/company`, { new_company })
      .then(() => {
        props.setReload(currentIsOpen => !currentIsOpen)
        props.setOpen(false)
        snack.current("Empresa adicionada!", { variant: 'success' })
      })
      .catch((error) => {
        if (error.response.data.message) {
          snack.current(`${error.response.data.message}`, { variant: 'error' })
        } else {
          snack.current("Falha ao adicionar a Empresa!", { variant: 'error' })
        }
      });
  }

  async function Update() {
    let my_plan = props.plans.filter(x => x.label === values.plano)
    var update_company = {
      '_id': values._id,
      'cep': values.cep,
      'lat': values.lat,
      'lng': values.lng,
      'pais': values.pais,
      'cnpj': values.cnpj,
      'planId': my_plan[0].value,
      'status': values.status,
      'bairro': values.bairro,
      'cidade': values.cidade,
      'estado': values.estado,
      'numero': values.numero,
      'apelido': values.apelido,
      'contatos': values.contatos,
      'logradouro': values.logradouro,
      'complemento': values.complemento,
      'razao_social': values.razao_social,
      'nome_fantasia': values.nome_fantasia,
      'tempo_contrato': Number(values.tempo_contrato),
      'webhook_url': values.webhook_url,
    }
    api.put(`/admin/company`, { update_company })
      .then(() => {
        props.setReload(currentIsOpen => !currentIsOpen)
        props.setOpen(false)
        snack.current("Empresa editada!", { variant: 'success' })
      })
      .catch((error) => {
        if (error.response.data.message) {
          snack.current(`${error.response.data.message}`, { variant: 'error' })
        } else {
          snack.current("Falha ao editar a Empresa!", { variant: 'error' })
        }
      });
  }

  return (
    <>
      <DialogContent className={classes.root}>
        <BasicInfo values={values} setValues={setValues} plans={props.plans} />
        <Adress values={values} setValues={setValues} snack={snack.current} />
        <CreatedBy values={values} setValues={setValues} />
        <div className="row">
          <MyMap values={values} />
          <Contacts values={values} setValues={setValues} snack={snack.current} />
        </div>
      </DialogContent >
      <DialogActions>
        <Button
          disabled={(
            values.lat &&
              values.lng &&
              values.cep &&
              values.cnpj &&
              values.pais &&
              values.status &&
              values.bairro &&
              values.cidade &&
              values.estado &&
              values.apelido &&
              values.logradouro &&
              values.razao_social &&
              values.nome_fantasia &&
              values.contatos.length > 0)
                    ? false : true}
          onClick={() => { update === true ? Update() : AddNew() }}
          color="primary"
        >
          Salvar
        </Button>
        <Button onClick={() => { props.setOpen(false) }} color="primary"> Cancelar </Button>
      </DialogActions>
    </>
  )
}

FormStore.propTypes = {
    plans: PropTypes.object,
    setOpen: PropTypes.func,
    setReload: PropTypes.func
};
