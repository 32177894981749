import { action } from "easy-peasy"

const InvoicesStore = {
    open_up_drawer: false,
    setOpenUploadDrawer: action((state, payload) => {
        state.open_up_drawer = payload
        state.activeStep = 0
        state.date = new Date()
        state.files = []
    }),

    activeStep: 0,
    setActiveStep: action((state, payload) => {
        if (payload === "next") {
            state.activeStep += 1
        } else {
            state.activeStep = state.activeStep - 1
        }
    }),

    date: new Date(),
    setDate: action((state, payload) => {
        state.date = payload
    }),

    files: [],
    setFiles: action((state, payload) => {
        state.files = payload
    }),

    reload: false,
    setReload: action((state, _) => {
        state.reload = !state.reload
    }),

    collumns: {
        nome: {
            label: "Fatura",
            field: "nome",
            value: true
        },
        n_passagem_ped: {
            label: "Nº Passagens Pedágios",
            field: "n_passagem_ped",
            value: true
        },
        valor_passagem_ped: {
            label: "Passagens Pedágios (R$)",
            field: "valor_passagem_ped",
            value: true
        },
        n_vale_ped: {
            label: "Nº Vale Pedágio",
            field: "n_vale_ped",
            value: true
        },
        valor_vale_ped: {
            label: "Vale Pedágios (R$)",
            field: "valor_vale_ped",
            value: true
        },
        mes: {
            label: "Mês",
            field: "mes",
            value: true
        },
        ano: {
            label: "Ano",
            field: "ano",
            value: true
        },
        duplicate_tolls: {
            label: "Cobrança Duplicada",
            field: "duplicate_tolls",
            value: true
        },
        incorrect_category: {
            label: "Categoria Incorreta",
            field: "incorrect_category",
            value: true
        },
        vehicle_inconsistency: {
            label: "Veículo Não Cadastrado/Inativo",
            field: "vehicle_inconsistency",
            value: true
        },
        criado_por: {
            label: "Criado",
            field: "criado_por",
            value: true
        },
        criado_em: {
            label: "Criado em",
            field: "criado_em",
            value: true
        },
        editado_por: {
            label: "Editado",
            field: "editado_por",
            value: true
        },
        editado_em: {
            label: "Editado em",
            field: "editado_em",
            value: true
        },
    },

    setCollumns: action((state, payload) => {
        state.collumns = payload
    }),

    // open or close invoice Drawer
    open_invoice: false,
    setOpenInvoice: action((state, payload) => {
        state.open_invoice = payload
        state.tab = 0
    }),

    // invoice content information
    fatura: {},
    setFatura: action((state, payload) => {
        state.fatura = payload
    }),

    // change tabs inside of the invoice 
    tab: 0,
    setTab: action((state, payload) => {
        state.tab = payload
    }),
};
export default InvoicesStore;
