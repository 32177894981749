import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import MaterialTable from 'material-table';
import { useStoreActions } from 'easy-peasy';
import React, { useEffect, useState, useRef } from 'react';

import AddUser from './Components/AddUser.js'
import EditUser from './Components/EditUser.js'
import api from '../../../../../../services/api.js';

export default function Table(props) {
    const tableRef = useRef();
    const snack = useRef()
    const { enqueueSnackbar } = useSnackbar();
    snack.current = enqueueSnackbar

    const [users, setUsers] = useState([]);

    //ativa e desativa o loading abaixo da appbar
    const setLoading = useRef()
    setLoading.current = useStoreActions(actions => {
        return actions.globalStore.setLoading;
    });

    const state = {
        columns: [
            {
                title: "Opções",
                field: "opcoes",
                width: "1%",
                editable: false,
                render: (rowData) => {
                    return (
                        <EditUser setData={props.setData} setOpen={props.setOpen} veic={rowData} />
                    )
                }
            },
            { title: "Nome Completo", field: "nome_completo" },
            { title: "Email", field: "email" },
            { title: "Status", field: "status" },
            { title: "Tipo", field: "tipo" },
            { title: "identificador", field: "_id" },
        ],
    };

    useEffect(() => {
        setLoading.current(true);
        function fetchData() {
            api.get(`/utils/users/company`)
                .then((res) => {
                    let users = []
                    for (let user of res.data) {
                        let aux = {
                            "_id": user._id,
                            "tipo": user.tipo,
                            "email": user.email,
                            "status": user.status,
                            "nome_completo": user.nome_completo,

                            "criado_em": user.criado_em,
                            "criado_por": user.criado_por,
                            "editado_em": user.editado_em,
                            "editado_por": user.editado_por,
                        }
                        users.push(aux)
                    }
                    setUsers(users);
                    setLoading.current(false);
                })
                .catch((error) => {
                    console.log(error);
                    setLoading.current(false);
                    snack.current("Falha ao buscar os usuários", { variant: 'error' })
                });
        }
        fetchData();
    }, [props.reload]);

    return (
        <MaterialTable
            tableRef={tableRef}
            title="Usuários Optcargo"
            columns={state.columns}
            data={users}
            components={{
                Actions: () => {
                    return (
                        <AddUser setData={props.setData} setOpen={props.setOpen} />
                    );
                }
            }}
            options={{
                add: true,
                search: true,
                pageSize: 10,
                exportButton: false,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [1, 5, 10, 20, users.length],
                padding: "dense",
                headerStyle: {
                    backgroundColor: "#01579b",
                    textAlign: 'center',
                    color: "#FFF",
                    fontSize: 11 * window.innerWidth / 1280,
                },
                cellStyle: {
                    textAlign: "center",
                    fontSize: 11 * window.innerWidth / 1280,
                },
                rowStyle: rowData => ({
                    backgroundColor: (rowData.tableData.id % 2 === 0 ? '#fff' : '#f5f5f5')
                }),
            }}
            localization={{
                body: {
                    emptyDataSourceMessage: "",
                    addTooltip: "adicionar novo usuário",
                    deleteTooltip: "excluir",
                    editRow: { deleteText: "Deseja excluir o usuário?" },
                },

                header: {
                    actions: "Opções",
                },

                toolbar: {
                    searchTooltip: "Pesquisar",
                    searchPlaceholder: "Pesquisar",
                    exportTitle: "exportar",
                    nRowsSelected: "{0} Usuários(s) selecionados",
                },
                pagination: {
                    labelRowsSelect: "",
                    labelDisplayedRows: "{count} de {from}-{to}",
                    firstTooltip: "Primeira página",
                    previousTooltip: "Página anterior",
                    nextTooltip: "Próxima página",
                    lastTooltip: "Última página",
                },
            }}
        />
    );
}

Table.propTypes = {
    reload: PropTypes.bool,
    setOpen: PropTypes.func,
    setReload: PropTypes.func,
};
