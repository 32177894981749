import React from "react";

export default function IconCD(props) {

	var color = "#0055a7"
	if (props.veic.possui_restr === "sim") {
		color = "red"
	}

	return (
		<svg version="1.1" width="36" viewBox="0 0 36 36" height="36">
			<path
				style={{ fill: color }}
				d="m 9.6312219,21.567307 0,-7.106334 4.7375561,0 0,7.106334 5.921946,0 0,-9.475112 3.553167,0 L 12,1.4326929 0.15610927,12.092195 l 3.55316723,0 0,9.475112 z"
			/>
		</svg>
	);
}
