import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { Card, CardContent } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import React, { useState, useEffect, Fragment } from "react";
import { TextField, Typography, Button, Grid, InputAdornment, IconButton } from "@material-ui/core";

import api from "../../../services/api";
import logo from "../../../assets/locus_invert.png";

export default function MinhaConta() {
    const history = useHistory();
    const [verSenhaNova, setVerSenhaNova] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [redefinirSenhaNova, setRedefinirSenhaNova] = useState("");
    const [redefinirSenhaNova2, setRedefinirSenhaNova2] = useState("");
    const [verSenhaConfirmacao, setVerSenhaConfirmacao] = useState(false);
    const [btnAtualizarSenha, setBtnAtualizarSenha] = useState(false);

    useEffect(() => {
        verificarCamposRedefinir();
    });

    // Função que valida os dados da alteração de senha
    function verificarCamposRedefinir() {
        if (redefinirSenhaNova !== "" && redefinirSenhaNova.length < 8) {
            setBtnAtualizarSenha(false);
            return;
        } else if (redefinirSenhaNova !== "" && redefinirSenhaNova2 !== "" && redefinirSenhaNova !== redefinirSenhaNova2) {
            setBtnAtualizarSenha(false);
            return;
        } else {
            setBtnAtualizarSenha(redefinirSenhaNova !== "" && redefinirSenhaNova2 !== "");
        }
    }

    function gotoLogin(key) {
        closeSnackbar(key)
        history.push("/");
    }

    const action = key => (
        <Fragment>
            <Button onClick={() => { gotoLogin(key) }}>
                Login
            </Button>
        </Fragment>
    );

    // Função que realiza a alteração de senha
    async function handleAtualizarSenha() {
        enqueueSnackbar("Solicitando alteração de senha", { variant: 'info' })
        const token = document.URL.toString().split("/")[4]
        console.log(token)
        api.put(`/password/reset/${token}`,
            { new_password: redefinirSenhaNova, url: document.URL.toString().split("alterar-a-senha")[0] })
            .then(() => {
                localStorage.setItem("senha", redefinirSenhaNova);
                enqueueSnackbar("Senha alterada com sucesso", {
                    variant: 'success',
                    persist: true,
                    action
                })
            })
            .catch((error) => {
                console.log(error);
                enqueueSnackbar(error.response.data.message, { variant: 'error' })
            });
    }

    return (
        <div className="background_login">
            <Grid container spacing={0} alignItems="center" justify="center" direction="column" className="full-grid">
                <Card raised className="login-card">
                    <CardContent>
                        <Typography variant="h5" gutterBottom>
                            Redefinir senha
							</Typography>
                        <Typography variant="body2" gutterBottom>
                            Escolha uma senha segura e busque não reutilizá-la <br />em outras contas. Utilize pelo menos 8 caracteres.
							</Typography>
                        <FormControl margin="dense" fullWidth={true}>
                            <TextField
                                type={verSenhaNova ? "text" : "password"}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                tabIndex="-1"
                                                aria-label="toggle password visibility"
                                                onClick={() => setVerSenhaNova(!verSenhaNova)}
                                                onMouseDown={(event) => event.preventDefault()}
                                            >
                                                {verSenhaNova ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                size="small"
                                fullWidth={true}
                                label="Informe a nova senha"
                                variant="outlined"
                                onChange={(event) => setRedefinirSenhaNova(event.target.value)}
                            />
                        </FormControl>
                        <FormControl margin="dense" fullWidth={true}>
                            <TextField
                                type={verSenhaConfirmacao ? "text" : "password"}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                tabIndex="-1"
                                                aria-label="toggle password visibility"
                                                onClick={() => setVerSenhaConfirmacao(!verSenhaConfirmacao)}
                                                onMouseDown={(event) => event.preventDefault()}
                                            >
                                                {verSenhaConfirmacao ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                size="small"
                                fullWidth={true}
                                label="Confirme a nova senha"
                                variant="outlined"
                                onChange={(event) => setRedefinirSenhaNova2(event.target.value)}
                            />
                        </FormControl>
                        <br />
                        <br />
                        <Button
                            disabled={!btnAtualizarSenha}
                            onClick={handleAtualizarSenha}
                            variant="contained"
                            color="primary"
                            fullWidth={true}
                            size="large"
                            className="btn-login">
                            Atualizar
						</Button>
                    </CardContent>
                </Card>
                <footer>
                    <a href="/">
                        <img src={logo} alt="Locus" title="desenvolvido por Locus tecnologia" />
                    </a>
                </footer>
            </Grid>
        </div>
    );
}
