import PropTypes from 'prop-types';
import "react-leaflet-fullscreen-control";
import { Map, TileLayer } from "react-leaflet";
import React, { useState, useEffect } from "react";
import ReactLeafletSearch from "react-leaflet-search";

import "../../../../../styles.css"
import Buttons from "./Buttons.js"
import Documents from "./Documents.js"
import AllocationEditControl from "./AllocationEditControl.js";

export default function StudyMap(props) {
    const [center, setCenter] = useState([-22.977213, -47.101165]);

    useEffect(() => {
        const map = mapRef.current.leafletElement;
        map.setView(center);
    }, [center]);

    const mapRef = React.useRef();
    return (
        <Map fullscreenControl style={{ width: props.width, height: props.height }} ref={mapRef} center={center} zoom={8} >
            <TileLayer url="http://{s}.tile.osm.org/{z}/{x}/{y}.png" />
            <ReactLeafletSearch position="topright" providerOptions={{ region: "br" }} />
            <Documents setCenter={setCenter} />
            <Buttons />
            <AllocationEditControl setAlocattionDocuments={props.setAlocattionDocuments} />
        </Map>
    );
}

StudyMap.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    setAlocattionDocuments: PropTypes.func,
};
