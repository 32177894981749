import PropTypes from 'prop-types';
import LensIcon from '@material-ui/icons/Lens';
import AlarmIcon from '@material-ui/icons/Alarm';
import React, { useEffect, useState } from 'react';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import NotInterestedIcon from '@material-ui/icons/NotInterested';

import '../../../styles.css'

export default function DocsInfo(props) {
    const [content, setContent] = useState("");

    useEffect(() => {
        let docs = props.docs.filter(x => JSON.stringify(x.TripId) === JSON.stringify(props.veic._id))
        docs.sort(function (a, b) {
            return parseFloat(a.ordem) - parseFloat(b.ordem);
        });
        let content = docs.map((data, index) => (
            <div key={index}>
                {(() => {

                    return (
                        <div >
                            <div className="fantasia">
                                <div className="title_info4">Ordem: {data.ordem} </div>
                                <div className="rowDado">
                                    <div className="rowDado" >
                                        <div className="itemDocs">{data.tipo}:</div>
                                        <div className="dadoDocs">{data.documento}</div>
                                    </div>

                                    <div className="rowDado" >
                                        <AvTimerIcon />
                                        <div className="itemDocs">Peso:</div>
                                        <div className="dadoDocs">{data.peso_kg}Kg</div>
                                    </div>

                                    <div className="rowDado" >
                                        <LensIcon fontSize='small' />
                                        <div className="itemDocs">Ocupação:</div>
                                        <div className="dadoDocs">{data.taxa_ocup_kg}kg - {data.taxa_ocup}% - R${data.taxa_ocup_reais}</div>
                                    </div>

                                    <div className="rowDado" >
                                        <NotInterestedIcon />
                                        <div className="itemDocs">Restrição Placa/Porte:</div>
                                        <div className="dadoDocs">{data.restri_placa} - {data.restri_porte}</div>
                                    </div>
                                    <div className="rowDado" >
                                        <AlarmIcon />
                                        <div className="itemDocs">Chegada ao Cliente: </div>
                                        <div className="dadoDocs">{data.horario_chegada_cli_format}</div>
                                    </div>

                                    <div className="rowDado" >
                                        <AlarmIcon />
                                        <div className="itemDocs">Carga/Descarga: </div>
                                        <div className="dadoDocs">{data.tempo_carg_desc_format}</div>
                                    </div>
                                </div>

                                <div className="rowDado">
                                    <div className="itemDocsEnd">Endereço:</div>
                                    <div className="dadoDocsEnd">{data.logradouro}, {data.numero}, {data.cidade}, {data.estado}, {data.cep} - {data.razao_social}</div>
                                    <div className="dadoDocsEnd">{
                                        data.parada_almoco ?
                                            <FastfoodIcon /> :
                                            ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })()}
            </div>
        ));
        setContent(content);
    }, [])

    return (
        <>
            <div className="title_info4">Informações da Carga (coletas/entregas)</div>
            {content}
        </>
    )
}

DocsInfo.propTypes = {
    docs: PropTypes.array,
    veic: PropTypes.object,
};


// { title: "", field: "" },
// { title: "", field: "" },
