import { useSnackbar } from 'notistack';
import MaterialTable from 'material-table';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useRef, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';

import api from '../../../../services/api.js';
import IconVeic from './Components/IconVeic.js';
import ClearTrip from './Components/ClearTrip.js';
import DeleteVeic from './Components/DeleteVeic.js';
import ClearOrders from './Components/ClearOrders.js';
import IconWarning from './Components/IconWarning.js';
import ImportVehiclesButton from './Components/ImportVehiclesButton.js'

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1, 0),
        backgroundColor: "#fff"
    },
}));

export default function VehicleTable() {
    const classes = useStyles();
    const snack = useRef()
    const { enqueueSnackbar } = useSnackbar();
    snack.current = enqueueSnackbar

    const setImportVehicles = useStoreActions(actions => {
        return actions.ImportVehiclesStore.setImportVehicles;
    });

    const state = {
        columns: [
            {
                title: "Opções",
                field: "opcoes",
                editable: false,
                render: (rowData) => {
                    return (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <DeleteVeic veic={rowData} />
                            <ClearTrip veic={rowData} />
                            <ClearOrders veic={rowData} />
                        </div>
                    )
                }
            },
            {
                title: "info",
                field: "info",
                editable: false,
                render: (rowData) => {
                    return (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <IconVeic trip={rowData} />
                            <IconWarning trip={rowData} />
                        </ div>
                    )
                }
            },
            { title: "Status Error", field: "status_error" },
            { title: "Placa", field: "placa" },
            { title: "Número de documentos", field: "n_docs" },
            { title: "Entregas + Coletas (kg)", field: "ocupacao" },
            { title: "Entregas + Coletas (reais)", field: "ocupacao_reais" },
        ],
    };

    // limpa os veiculos plotados no mapa
    const plotVehicles = useStoreActions(actions => {
        return actions.mapStore.plotVehicles;
    });

    // adiciona o veiculos ao store
    const setVeiculosStore = useRef()
    setVeiculosStore.current = useStoreActions(actions => {
        return actions.VeicsDocsStore.setVeiculos;
    });

    // const my_vehicles = useStoreState((state) => state.VeicsDocsStore.my_vehicles);
    const check_states = useStoreState((state) => state.VeicsDocsStore.check_states);
    const reload = useStoreState((state) => state.VeicsDocsStore.reload);
    const reload_ = useStoreState((state) => state.globalStore.reload);

    const [my_vehicles, setVehicles] = useState([])
    useEffect(() => {
        async function fetchData() {
            api.get(`/trips/rot`)
                .then((res) => {
                    setVehicles(res.data)
                    setVeiculosStore.current(res.data)
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        fetchData()
    }, [reload, reload_])

    // filter data
    const [visible_veics, setVisibleVehicles] = useState([])
    useEffect(() => {
        var visible_veics = []
        for (let veic of my_vehicles) {
            if (check_states.valido && veic.valido) visible_veics.push(veic)
            if (check_states.invalido && veic.invalido) visible_veics.push(veic)
            if (check_states.processando && veic.processando) visible_veics.push(veic)
        }
        setVisibleVehicles(visible_veics)
    }, [my_vehicles, check_states]);

    function checkRow(veics) {
        if (veics.length > 0) {
            var plot_vehicles = []
            for (var veic of veics) {
                if (veic.n_docs > 0) plot_vehicles.push(veic)
            }
            if (plot_vehicles.length > 0) plotVehicles(plot_vehicles);
        }
        else {
            plotVehicles([])
        }
    }

    const tableRef = React.useRef();
    return (
        <MaterialTable
            tableRef={tableRef}
            title="Viagens em Roteirização"
            columns={state.columns}
            data={visible_veics.map(rowData => {
                rowData.tableData = { ...rowData.tableData, disabled: rowData.invalido };
                return rowData;
            })}
            options={{
                search: true,
                grouping: true,
                selection: true,
                exportButton: false,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [1, 5, 10, 20],
                padding: "dense",
                headerStyle: {
                    backgroundColor: "#01579b",
                    textAlign: 'center',
                    color: "#FFF",
                    fontSize: 11 * window.innerWidth / 1280,
                },
                cellStyle: {
                    textAlign: "center",
                    fontSize: 11 * window.innerWidth / 1280,
                },
                selectionProps: rowData => ({ disabled: rowData.tableData.disabled }),
                rowStyle: rowData => ({
                    backgroundColor:
                        rowData.invalido ? '#ffcccc' :
                            rowData.valido ? '#ebfaeb' : "#add8e6"
                }),
            }}
            onSelectionChange={(rows) => checkRow(rows)}
            localization={{
                body: {
                    addTooltip: "Adicionar novo",
                    deleteTooltip: "Excluir",
                    editTooltip: "Editar",
                    editRow: {
                        deleteText: "Deseja excluir o veículo?",
                        cancelTooltip: "Cancelar",
                        saveTooltip: "Salvar",
                    },
                    emptyDataSourceMessage: (
                        <Button
                            color="primary"
                            className={classes.button}
                            onClick={() => {
                                setImportVehicles({ open: true })
                            }}
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-center',
                                maxWidth: window.innerWidth - 150,
                                minWidth: window.innerWidth - 150,
                                maxHeight: 85,
                                minHeight: 85,
                            }}
                        >
                            <EmojiTransportationIcon fontSize={'large'} />
                            Importar Veículos
                        </Button>
                    ),
                    bulkEditTooltip: "Editar todos",
                    bulkEditCancel: "Descartar edições",
                    bulkEditApprove: "Salvar todas as edições"
                },

                grouping: {
                    placeholder: "Arraste os cabeçalhos aqui para agrupar por",
                    groupedBy: "Agrupado por:",
                },

                header: {
                    actions: "Opções",
                },

                toolbar: {
                    searchTooltip: "Pesquisar",
                    searchPlaceholder: "Pesquisar",
                    exportTitle: "Exportar",
                    nRowsSelected: "{0} veículos(s) selecionados",
                },
                pagination: {
                    labelRowsSelect: "",
                    labelDisplayedRows: "{count} de {from}-{to}",
                    firstTooltip: "Primeira página",
                    previousTooltip: "Página anterior",
                    nextTooltip: "Próxima página",
                    lastTooltip: "Última página",
                },
            }}
            components={{
                Actions: () => {
                    return (
                        <div className="row">
                            <ImportVehiclesButton />
                        </div>
                    );
                }
            }}
        />
    );
}
