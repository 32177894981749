import { useSnackbar } from 'notistack';
import TextField from '@material-ui/core/TextField';
import React, { useRef, useState, useEffect } from 'react';

import Boxes from './Components/Boxes.js';
import api from '../../../../services/api.js';
import InvoicesHistogram from './Components/InvoicesHistogram.js';

const tipos = [
    { value: 'Número de Cobranças', label: 'Número de Cobranças' },
    { value: 'Cobranças em Reais', label: 'Valor das Cobranças (Reais)' },
];

export default function Dash() {
    const snack = useRef()
    const { enqueueSnackbar } = useSnackbar();
    snack.current = enqueueSnackbar

    const [type, setType] = useState("Número de Cobranças")

    const [results, setResults] = useState([])
    const [invoices, setInvoices] = useState([])
    const [histogram_count, setHistogramCount] = useState([])
    const [histogram_values, setHistogramValues] = useState([])

    useEffect(() => {
        async function fecthData() {
            api.get(`/invoices`)
                .then((resp) => {
                    let { invoices } = resp.data

                    // format invoice data
                    var flag = false
                    for (let invoice of invoices) {
                        let date = new Date(invoice.dateRef)
                        invoice["ano"] = date.getFullYear()
                        invoice["mes"] = date.getMonth() + 1
                        invoice["duplicate_tolls"] = invoice["n_total_duplicate"] > 0 ? "sim" : "não"
                        invoice["incorrect_category"] = invoice["n_total_inc_category"] > 0 ? "sim" : "não"
                        invoice["vehicle_inconsistency"] = invoice["n_total_vehicle_inconsistency"] > 0 ? "sim" : "não"
                        invoice.editado_em = new Date(invoice.editado_em).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })
                        invoice.criado_em = new Date(invoice.criado_em).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })
                        if (invoice.processing) flag = true
                    }
                    setInvoices(invoices)

                    var results = {
                        n_vale_ped: 0,
                        n_passagem_ped: 0,
                        valor_vale_ped: 0,
                        valor_passagem_ped: 0,
                        n_passagem_ped_vale_ped: 0,
                        valor_passagem_ped_vale_ped: 0,
                    }
                    var histogram_data = []
                    var histogram_values = []
                    for (let invoice of invoices) {
                        let aux_hist = {
                            "Fatura": invoice.mes + "/" + invoice.ano,
                            "Pedágios": invoice.n_passagem_ped,
                            "Vale_Pedágios": invoice.n_vale_ped,
                        }
                        histogram_data.push(aux_hist)

                        let aux_hist_values = {
                            "Fatura": invoice.mes + "/" + invoice.ano,
                            "Pedágios": invoice.valor_passagem_ped,
                            "Vale_Pedágios": invoice.valor_vale_ped,
                        }
                        histogram_values.push(aux_hist_values)

                        results['n_passagem_ped'] += invoice.n_passagem_ped
                        results['n_vale_ped'] += invoice.n_vale_ped
                        results['n_passagem_ped_vale_ped'] += invoice.n_passagem_ped + invoice.n_vale_ped

                        results['valor_passagem_ped'] += invoice.valor_passagem_ped
                        results['valor_vale_ped'] += invoice.valor_vale_ped
                        results['valor_passagem_ped_vale_ped'] += invoice.valor_passagem_ped + invoice.valor_vale_ped
                    }

                    // format data
                    results['n_vale_ped'] = results['n_vale_ped'].toLocaleString(undefined, { minimumFractionDigits: 0 });
                    results['n_passagem_ped'] = results['n_passagem_ped'].toLocaleString(undefined, { minimumFractionDigits: 0 });
                    results['valor_vale_ped'] = results['valor_vale_ped'].toLocaleString(undefined, { minimumFractionDigits: 2 });
                    results['valor_passagem_ped'] = results['valor_passagem_ped'].toLocaleString(undefined, { minimumFractionDigits: 2 });
                    results['n_passagem_ped_vale_ped'] = results['n_passagem_ped_vale_ped'].toLocaleString(undefined, { minimumFractionDigits: 0 });
                    results['valor_passagem_ped_vale_ped'] = results['valor_passagem_ped_vale_ped'].toLocaleString(undefined, { minimumFractionDigits: 2 });
                    setResults(results)
                    setHistogramCount(histogram_data.reverse())
                    setHistogramValues(histogram_values.reverse())
                })
                .catch((error) => {
                    console.log(error);
                    snack.current("Falha ao carregar as faturas", { variant: 'error' })
                });
        }
        fecthData();
    }, []);

    return (
        < >

            <Boxes results={results} />

            <div className="invoice_dash_tittle_graph">  Histórico de Cobranças </div>

            <div className="rowC">
                <InvoicesHistogram
                    y_label={type}
                    histogram_data={type === "Número de Cobranças" ? histogram_count : histogram_values}
                />

                <TextField
                    style={{ margin: "10%" }}
                    required
                    select
                    id="tipo"
                    size="small"
                    label="Tipo"
                    variant="outlined"
                    onChange={e => {
                        setType(e.target.value)
                    }}
                    value={type}
                    SelectProps={{ native: true }}
                >
                    {tipos.map((option) => (
                        <option key={option.value} >
                            {option.label}
                        </option>
                    ))}
                </TextField>

            </div>

        </>
    );
}
