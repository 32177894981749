import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import { Card, CardContent, TextField, Typography, Button, Grid } from "@material-ui/core";

import api from "../../../services/api";
import optlogo from "../../../assets/optlogo.png";
import logo from "../../../assets/locus_invert.png";

export default function RecuperarSenha() {
    const [email, setEmail] = useState("");
    const { enqueueSnackbar } = useSnackbar();

    async function emailSubmit(event) {
        event.preventDefault();
        enqueueSnackbar("Verificando o email", { variant: 'info' })
        api.post(`/password/recover/${email}`, {
            url: document.URL.toString().split("recuperar-a-senha")[0]
        })
            .then((resp) => {
                enqueueSnackbar(resp.data.message, { variant: 'success' })
            })
            .catch((error) => {
                console.log(error)
                enqueueSnackbar("Email inválido!", { variant: 'error' })
            });
    }

    return (
        <div className="background_login">
            <Grid container spacing={0} alignItems="center" justify="center" direction="column" className="full-grid">
                <Card raised className="login-card">
                    <CardContent>
                        <form onSubmit={emailSubmit}>
                            <Typography gutterBottom>
                                <img className="login-logo" src={optlogo} alt="Optcargo" width="180" />
                            </Typography>
                            <Typography gutterBottom>
                                Confirme o seu endereço de e-mail para<br />
								enviarmos o link de criação da nova senha.
								</Typography>
                            <FormGroup>
                                <FormControl margin="dense" fullWidth={true}>
                                    <TextField
                                        id="email"
                                        type="email"
                                        placeholder="Informe seu email cadastrado"
                                        value={email}
                                        variant="outlined"
                                        onChange={(event) => setEmail(event.target.value)}
                                    />
                                </FormControl>
                            </FormGroup>
                            <br />
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                fullWidth={true}
                                size="large"
                                className="btn-login"
                            >Solicitar nova senha</Button>
                            <br />
                            <br />
                            <Typography color="textPrimary">
                                Lembrou sua senha? <Link to="/">Faça login.</Link>
                            </Typography>
                        </form>
                    </CardContent>
                </Card>
                <footer>
                    <a href="/">
                        <img src={logo} alt="Locus" title="desenvolvido por Locus tecnologia" />
                    </a>
                </footer>
            </Grid>
        </div>
    );
}
