import React from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import { Fab, Tooltip } from '@material-ui/core';

export default function AddOne(props) {
    const {
        setOpen,
        setData,
    } = props

    return (
        <Tooltip title={<span style={{ fontSize: 15 }}> Inserir </span>}>
            <Fab
                color="primary"
                aria-label="add"
                style={{
                    margin: 10,
                    maxWidth: 45,
                    minWidth: 45,
                    maxHeight: 45,
                    minHeight: 45,
                }}
                onClick={() => {
                    setOpen(true);
                    setData(null);
                }}
            >
                <AddIcon />
            </Fab>
        </Tooltip>
    );
}

AddOne.propTypes = {
    setData: PropTypes.func,
    setOpen: PropTypes.func,
};
