import React from "react";
import PropTypes from 'prop-types';
import { TextField } from "@material-ui/core";

import "../../../styles.css";

const currencies = [
    { value: '', label: '' },
    { value: 'ativo', label: 'ativo' },
    { value: 'inativo', label: 'inativo' },
];

const acesso = [
    { value: '', label: '' },
    { value: 'api', label: 'api' },
    { value: 'site', label: 'site' },
];

export default function BasicInfo(props) {
    const handleChange = (event) => {
        props.setValues({
            ...props.values,
            [event.target.id]: event.target.value,
        });
    };

    return (
        <div className="fantasia_plan">
            <div className="createdPlan">
                <TextField
                    autoFocus
                    type="text"
                    margin="dense"
                    id="nome_plano"
                    variant="outlined"
                    label="Nome Plano"
                    value={props.values.nome_plano}
                    onChange={handleChange}
                />
                <TextField
                    autoFocus
                    type="number"
                    margin="dense"
                    variant="outlined"
                    id="assinatura_mensal_basica"
                    label="Assinatura Mensal Básica (R$)"
                    value={props.values.assinatura_mensal_basica}
                    onChange={handleChange}
                />
                <TextField
                    select
                    id="acesso"
                    size="small"
                    label="Acesso"
                    variant="outlined"
                    onChange={handleChange}
                    value={props.values.acesso}
                    SelectProps={{ native: true }}
                >
                    {acesso.map((option) => (
                        <option key={option.value} value={props.values.acesso.value}>
                            {option.label}
                        </option>
                    ))}
                </TextField>
                <TextField
                    select
                    id="status"
                    size="small"
                    label="Status"
                    variant="outlined"
                    onChange={handleChange}
                    value={props.values.status}
                    SelectProps={{ native: true }}
                >
                    {currencies.map((option) => (
                        <option key={option.value} value={props.values.status.value}>
                            {option.label}
                        </option>
                    ))}
                </TextField>
            </div>
        </div>
    )
}

BasicInfo.propTypes = {
    values: PropTypes.object,
    setValues: PropTypes.func
};
