import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { useSnackbar } from "notistack";
import { Button, Tooltip } from "@material-ui/core";
import SwapCalls from '@material-ui/icons/SwapCalls';
import { useStoreState, useStoreActions } from "easy-peasy";

import api from "../../../../../services/api.js";

export default function OrderGen(props) {
    const snack = useRef()
    const { enqueueSnackbar } = useSnackbar();
    snack.current = enqueueSnackbar

    const setOpenOrder = useStoreActions(actions => {
        return actions.toOrderStore.setOpenOrder;
    });

    // atualiza a tabela de documentos
    const setReload = useStoreActions(actions => {
        return actions.VeicsDocsStore.setReload;
    });

    //ativa e desativa o loading abaixo da appbar
    const setLoading = useStoreActions(actions => {
        return actions.globalStore.setLoading;
    });

    // limpa os veiculos plotados no mapa
    const plotVehicles = useStoreActions(actions => {
        return actions.mapStore.plotVehicles;
    });

    // limpa os documentos plotados no mapa
    const plotDocuments = useStoreActions(actions => {
        return actions.mapStore.plotDocuments;
    });

    // const cd = useStoreState((state) => state.distributionCentersStore.cd);
    const avaliable_docs = useStoreState((state) => state.toOrderStore.avaliable_docs);
    const order_type = useStoreState((state) => state.toOrderStore.order_type);

    async function Ordenar() {
        setOpenOrder(false)
        setLoading(true);
        api.post(`/order`, {
            avaliable_docs: avaliable_docs,
            veics: props.order_vehicles,
            tipo: order_type,
        })
            .then((res) => {
                snack.current(res.data.message, { variant: res.data.status })
                setReload()
                setLoading(false);
                plotVehicles([])
                plotDocuments([])
                props.setOrderVehicles([])
            })
            .catch((error) => {
                console.log(error);
                try {
                    snack.current(`${error.response.data.message}`, { variant: 'error' });
                } catch (e) {
                    console.error(e);
                    snack.current("Não foi possível ordenar os documentos", { variant: 'error' })
                }
                setReload()
                setLoading(false);
                plotVehicles([])
                plotDocuments([])
                props.setOrderVehicles([])
            });
    }

    return (
        <Tooltip title={<span> Gerar Viagem </span>} >
            <Button
                color="primary"
                component="span"
                disabled={props.order_vehicles.length === 0 ? true : false}
                startIcon={<SwapCalls />}
                onClick={() => Ordenar()}
            >
                Ordenar Documentos
            </Button>
        </Tooltip>
    );
}

OrderGen.propTypes = {
    order_vehicles: PropTypes.array,
    setOrderVehicles: PropTypes.func,
};