import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import Language from "@material-ui/icons/Language";
import { TextField, Button } from "@material-ui/core";
import React, { useEffect, useState, useRef } from "react";

import api from "../../../../../../../services/api";

function TextMaskCustom(props) {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/
            ]}
            placeholderChar={"\u2000"}
            showMask
        />
    );
}

export default function Adress(props) {

    const [disable, setDisable] = useState(true)
    const [cep_flag_, setCepFlag] = useState(true)
    const cep_flag = useRef()
    cep_flag.current = cep_flag_
    const [geo_flag, setGeoFlag] = useState(true)
    const [error, setError] = useState(false)

    useEffect(() => {
        async function findCep(cep_string) {
            let cep = cep_string.match(/\d/g);
            if (cep) {
                if (cep_flag.current) {
                    cep = cep.join("")
                    // Find a CEP
                    if (cep.length === 8) {
                        setCepFlag(false)
                        api
                            .get(`utils/cep/find/${cep}`)
                            .then((resp) => {
                                if (resp.data.erro) {
                                    setError(true)
                                } else {
                                    setError(false)
                                    props.setValues({
                                        ...props.values,
                                        'logradouro': resp.data.logradouro,
                                        'bairro': resp.data.bairro,
                                        'cidade': resp.data.localidade,
                                        'estado': resp.data.uf,
                                        'pais': "BR",
                                    });
                                    setGeoFlag(false)
                                    if (resp.data.logradouro === '') {
                                        setDisable(false)
                                    } else {
                                        setDisable(true)
                                    }
                                }
                            })
                            .catch((error) => {
                                console.log(error)
                            });
                    }
                } else if (cep.length < 8) {
                    setCepFlag(true)
                    setGeoFlag(true)
                    props.setValues({
                        ...props.values,
                        'logradouro': "",
                        'bairro': "",
                        'cidade': "",
                        'estado': "",
                        'pais': "",
                        'lat': "",
                        'lng': ""
                    });
                }
            }
        }
        findCep(props.values.cep)
    }, [props]);

    function Geocoder() {
        let cep = props.values.cep.match(/\d/g);
        cep = cep.join("")
        props.snack("Geolocalizando o endereço", { variant: 'info' })
        api
            .put(`/utils/latlng/find`, {
                cep,
                logradouro: props.values.logradouro,
                numero: props.values.numero,
                bairro: props.values.bairro,
                cidade: props.values.cidade,
                estado: props.values.estado
            })
            .then((resp) => {
                props.setValues({
                    ...props.values,
                    'lat': resp.data.lat,
                    'lng': resp.data.lng
                });
                setGeoFlag(true)
                props.snack("Endereço geolocalizado", { variant: 'success' })
            })
            .catch((error) => {
                console.log(error)
                props.snack("Não foi possível geolocalizar o endereço", { variant: 'error' })
            });
    }

    const handleChange = (event) => {
        props.setValues({
            ...props.values,
            [event.target.id]: event.target.value,
        });
    };

    return (
        <>
            <div className="title_info3">Endereço</div>
            <div className="fantasia">
                <div className="space4">
                    <TextField
                        id="cep"
                        error={error}
                        label="CEP"
                        variant="outlined"
                        onChange={handleChange}
                        value={props.values.cep}
                        helperText={error ? "Cep Inválido" : ""}
                        InputProps={{ inputComponent: TextMaskCustom }}
                        size="small"
                    />
                    <TextField
                        autoFocus
                        disabled={props.values.lat !== "" ? true : false}
                        type="number"
                        id="numero"
                        label="Número"
                        margin="dense"
                        variant="outlined"
                        onChange={handleChange}
                        value={props.values.numero}
                    />
                    <TextField
                        autoFocus
                        type="text"
                        margin="dense"
                        id="complemento"
                        variant="outlined"
                        label="Complemento"
                        onChange={handleChange}
                        value={props.values.complemento}
                    />
                    <TextField
                        fullWidth
                        disabled={disable}
                        type="text"
                        margin="dense"
                        id="logradouro"
                        variant="outlined"
                        label="Logradouro"
                        value={props.values.logradouro}
                    />
                </div>

                <div className="space4">
                    <TextField
                        disabled
                        autoFocus
                        id="bairro"
                        type="text"
                        label="Bairro"
                        margin="dense"
                        variant="outlined"
                        onChange={handleChange}
                        value={props.values.bairro}
                    />
                    <TextField
                        disabled
                        autoFocus
                        id="cidade"
                        type="text"
                        label="Cidade"
                        margin="dense"
                        variant="outlined"
                        onChange={handleChange}
                        value={props.values.cidade}
                    />
                    <TextField
                        disabled
                        autoFocus
                        id="estado"
                        type="text"
                        label="Estado"
                        margin="dense"
                        variant="outlined"
                        onChange={handleChange}
                        value={props.values.estado}
                    />
                    <TextField
                        disabled
                        autoFocus
                        id="pais"
                        type="text"
                        label="País"
                        margin="dense"
                        variant="outlined"
                        onChange={handleChange}
                        value={props.values.pais}
                    />
                </div>
                <div className="space2button">
                    <Button
                        variant="contained"
                        style={{ marginTop: "0.9%" }}
                        size="medium"
                        component="span"
                        disabled={geo_flag}
                        startIcon={<Language />}
                        onClick={() => Geocoder()}
                    >
                        Geolocalizar
                    </Button>
                    <TextField
                        id="lat"
                        disabled
                        autoFocus
                        type="number"
                        margin="dense"
                        label="Latitude"
                        variant="outlined"
                        onChange={handleChange}
                        value={props.values.lat}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                    <TextField
                        id="lng"
                        disabled
                        autoFocus
                        type="number"
                        margin="dense"
                        label="Longitude"
                        variant="outlined"
                        onChange={handleChange}
                        value={props.values.lng}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />


                </div>
            </div>
        </>
    )
}

Adress.propTypes = {
    snack: PropTypes.func,
    values: PropTypes.object,
    setValues: PropTypes.func
};
