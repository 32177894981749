import { action } from "easy-peasy"

const reloadStore = {
    // reload tools table
    reload_tools: 0,
    setReloadTools: action((state) => {
        state.reload_tools += 1;
        console.log("Atualizando Pedágios: ", state.reload_tools)
    }),

    // reload size table
    reload_size: 0,
    setReloadSize: action((state) => {
        state.reload_size += 1;
        console.log("Atualizando Restrições de Porte: ", state.reload_size)
    }),

    // reload rationing table
    reload_rationing: 0,
    setReloadRationing: action((state) => {
        state.reload_rationing += 1;
        console.log("Atualizando Restrições de Rodízio: ", state.reload_rationing)
    }),
};

export default reloadStore;
