import React, { useRef } from 'react';
import { useSnackbar } from 'notistack';
import { AttachFile } from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import { useStoreActions, useStoreState } from 'easy-peasy';
import AccordionDetails from '@material-ui/core/AccordionDetails';

const useStyles = makeStyles((theme) => ({
    expandDetails: {
        display: "block",
        padding: "0px",
    },
    text: {
        textAlign: "justify",
        fontSize: "0.85rem",
        marginBottom: "18px",
    },
}));

export default function MyDropZoneArea() {
    const classes = useStyles();

    const snack = useRef()
    const { enqueueSnackbar } = useSnackbar();
    snack.current = enqueueSnackbar

    const setFiles = useStoreActions(actions => {
        return actions.InvoicesStore.setFiles;
    });

    const files = useStoreState((state) => state.InvoicesStore.files);

    //ativa e desativa o loading abaixo da appbar
    const setLoading = useRef()
    setLoading.current = useStoreActions(actions => {
        return actions.globalStore.setLoading;
    });

    const previewIcon = (fileObject, classes) => {
        const iconProps = {
            className: classes.image,
        };
        return <AttachFile {...iconProps} />;
    };

    return (
        <>
            <AccordionDetails className={classes.expandDetails}>
                <Typography
                    variant="body2"
                    color="textSecondary"
                    gutterBottom
                    className={classes.text}
                >
                    Utilize a caixa abaixo para enviar uma fatura de seu
                    computador para processamento. A fatura deve estar no formato .xlsx, .xls ou .ods
                </Typography>
                <DropzoneAreaBase
                    getPreviewIcon={previewIcon}
                    fileObjects={files}
                    filesLimit={1}
                    maxFileSize={100000000}
                    acceptedFiles={[".xlsx", ".ods", "xls"]}
                    showPreviewsInDropzone={true}
                    showFileNames={true}
                    // showFileNames
                    showFileNamesInPreview
                    previewGridProps={{
                        container: {
                            spacing: 1,
                            direction: "row",
                            justify: "flex-start",
                            alignItems: "center",
                        },
                    }}
                    showAlerts={["error"]}
                    previewText="Arquivos selecionados"
                    dropzoneText="Arraste a fatura para cá ou clique aqui para inserí-la"
                    getFileLimitExceedMessage={(filesLimit) =>
                        `Número máximo de arquivos excedido. Somente ${filesLimit} permitidos`
                    }
                    getFileRemovedMessage={(fileName) =>
                        snack.current(`O arquivo ${fileName} foi removido.`, { variant: 'success' })
                    }
                    getDropRejectMessage={(rejectedFile) =>
                        `O arquivo ${rejectedFile.name} foi rejeitado.`
                    }

                    onAdd={(newFiles) => {
                        setFiles(newFiles);
                        snack.current(`Foi adicionado um arquivo.`, { variant: 'success' })
                    }}
                    onDelete={(deletedFileObject, index) =>
                        setFiles([])
                    }
                />
            </AccordionDetails>
        </>
    );
}
