import { action } from "easy-peasy"


const ResultStore = {

    // atualiza a tabela de resultados
    reload: false,
    setReload: action((state, _) => {
        state.reload = !state.reload
    }),

    docs: [],
    veics: [],
    plotVehiclesResults: action((state, payload) => {
        state.veics = payload.veics;
        state.docs = payload.docs;
    }),
};

export default ResultStore;
