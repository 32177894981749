import React from "react";

export default function IconBox() {
    return (
        <svg version="1.1" width="36" viewBox="0 0 36 36" height="36">
            <path
            // style={{ fill: 'black' }}
            //transform="translate(-177,59.914999)"
            // d="M 18.078125 1.8886719 L 12.130859 4.5917969 L 26.064453 11.890625 L 32.4375 8.4140625 L 18.078125 1.8886719 z M 10.455078 5.3535156 L 3.7207031 8.4140625 L 5.8222656 9.5605469 L 18.078125 16.246094 L 24.529297 12.728516 L 10.476562 5.3671875 C 10.469562 5.3632875 10.462378 5.3576256 10.455078 5.3535156 z M 2.8222656 9.5800781 L 2.8222656 26.087891 L 17.351562 34.013672 L 17.351562 17.505859 L 12.671875 14.951172 L 2.8222656 9.5800781 z M 33.335938 9.5800781 L 20.419922 16.625 L 18.804688 17.505859 L 18.804688 34.013672 L 33.335938 26.087891 L 33.335938 9.5800781 z M 7.1484375 16.347656 C 7.2702212 16.34218 7.3932444 16.367558 7.5058594 16.423828 L 13.318359 19.330078 C 13.564509 19.453151 13.71875 19.705261 13.71875 19.980469 L 13.71875 26.519531 C 13.71875 26.771345 13.58925 27.004347 13.375 27.136719 C 13.25832 27.208789 13.125078 27.246094 12.992188 27.246094 C 12.881178 27.246094 12.770559 27.219259 12.667969 27.167969 L 6.8554688 24.261719 C 6.6093987 24.138645 6.4550781 23.888488 6.4550781 23.613281 L 6.4550781 17.074219 C 6.4550781 16.822405 6.5845781 16.587378 6.7988281 16.455078 C 6.9059181 16.388892 7.0266538 16.353132 7.1484375 16.347656 z M 7.9082031 18.25 L 7.9082031 23.164062 L 12.265625 25.34375 L 12.265625 20.429688 L 7.9082031 18.25 z "
            />
        </svg>
    );
}
