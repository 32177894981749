import { useSnackbar } from "notistack";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useStoreActions, useStoreState } from "easy-peasy"
import React, { useState, useRef, useEffect } from "react";
import { Button, TextField, DialogActions, DialogContent } from "@material-ui/core";

import Ufs from "../../../../../../utils/Ufs"
import api from "../../../../../../services/api";

export default function FormTools() {
    const snack = useRef()
    const { enqueueSnackbar } = useSnackbar();
    snack.current = enqueueSnackbar

    const setReloadToolsRef = useRef()
    setReloadToolsRef.current = useStoreActions(actions => {
        return actions.reloadStore.setReloadTools;
    });

    const setUpdateFormData = useRef()
    setUpdateFormData.current = useStoreActions(actions => {
        return actions.toolsStore.setUpdateFormData;
    });

    const data = useStoreState((state) => state.toolsStore.update_form_data);

    const [nome, setNome] = useState(data.nome);
    const [auto, setAuto] = useState(data.auto);
    const [eixo, setEixo] = useState(data.eixo);
    const [estado, setEstado] = useState(data.estado);
    const [rodovia, setRodovia] = useState(data.rodovia);
    const [btn_atualizar_salvar, setBtnHabilitarSalvar] = useState(false);
    const [concessionaria, setConcessionaria] = useState(data.concessionaria);

    useEffect(() => { checkFields() });

    function checkFields() {
        if (auto < 0) {
            setBtnHabilitarSalvar(false);
            return;
        } else if (auto < 0) {
            setBtnHabilitarSalvar(false);
            return;
        } else {
            setBtnHabilitarSalvar(nome !== "" && concessionaria !== "" && rodovia !== "" && estado !== "" && auto !== "" && eixo !== "");
        }
    }

    async function setToll() {
        data.nome = nome
        data.auto = Number(auto)
        data.eixo = Number(eixo)
        data.estado = estado
        data.rodovia = rodovia
        data.concessionaria = concessionaria
        api.put(`/admin/tolls`, {
            up: data,
        })
            .then(() => {
                snack.current(`Editado com sucesso!`, { variant: 'success' })
                setUpdateFormData.current(false);
                setReloadToolsRef.current();
            })
            .catch((error) => {
                console.log(error);
                snack.current(`Falha ao editar!`, { variant: 'error' })
                setUpdateFormData.current(false);
            });
    }

    return (
        <>
            <DialogContent>
                <TextField
                    id="nome"
                    autoFocus
                    fullWidth
                    type="text"
                    value={nome}
                    margin="dense"
                    label="Nome da Praça"
                    onChange={(event) => setNome(event.target.value)}
                />

                <TextField
                    fullWidth
                    autoFocus
                    type="text"
                    margin="dense"
                    id="concessionaria"
                    value={concessionaria}
                    label="Concessionária"
                    onChange={(event) => setConcessionaria(event.target.value)}
                />

                <TextField
                    fullWidth
                    autoFocus
                    id="rodovia"
                    margin="dense"
                    type="rodovia"
                    value={rodovia}
                    label="Nome da Rodovia"
                    onChange={(event) => setRodovia(event.target.value)}
                />

                <TextField
                    id="auto"
                    autoFocus
                    fullWidth
                    value={auto}
                    type="number"
                    margin="dense"
                    label="Automóvel (R$)"
                    onChange={(event) => setAuto(event.target.value)}
                />

                <TextField
                    id="eixo"
                    fullWidth
                    autoFocus
                    value={eixo}
                    type="number"
                    margin="dense"
                    label="Eixo (R$)"
                    onChange={(event) => setEixo(event.target.value)}
                />

                <Autocomplete
                    fullWidth
                    id="estado_"
                    disableClearable
                    options={Ufs}
                    value={{ title: estado, value: estado }}
                    getOptionLabel={(option) => option.title}
                    onChange={(_, data) => setEstado(data.value)}
                    renderInput={(params) => <TextField {...params} label="Estado" margin="dense" />}
                />

            </DialogContent>
            <DialogActions>
                <Button disabled={!btn_atualizar_salvar} onClick={() => setToll()} color="primary">
                    Salvar
                </Button>
                <Button onClick={() => setUpdateFormData.current(false)} color="primary">
                    Cancelar
                </Button>
            </DialogActions>
        </>
    )
}