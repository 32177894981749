import React from "react";
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import { TextField } from "@material-ui/core";

import "../../../styles.css"

const currencies = [
    { value: '', label: '' },
    { value: 'piloto', label: 'piloto' },
    { value: 'ativo', label: 'ativo' },
    { value: 'inativo', label: 'inativo' },
];

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/
            ]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}

export default function BasicInfo(props) {
    const handleChange = (event) => {
        props.setValues({
            ...props.values,
            [event.target.id]: event.target.value,
        });
    };

    return (
        <>
          <div className="title_info3">Informações Gerais</div>
          <div className="fantasia">
            <div className="space3">
              <TextField
                autoFocus
                type="text"
                margin="dense"
                id="nome_fantasia"
                variant="outlined"
                label="Nome Fantasia"
                value={props.values.nome_fantasia}
                onChange={handleChange}
              />
              <TextField
                autoFocus
                type="text"
                margin="dense"
                id="razao_social"
                variant="outlined"
                label="Razão Social"
                onChange={handleChange}
                value={props.values.razao_social}
              />
              <TextField
                autoFocus
                type="text"
                id="apelido"
                margin="dense"
                label="Apelido"
                variant="outlined"
                value={props.values.apelido}
                onChange={handleChange}
              />
            </div>

            <div className="space3">
              <TextField
                id="cnpj"
                label="CNPJ"
                size="small"
                variant="outlined"
                onChange={handleChange}
                value={props.values.cnpj}
                InputProps={{ inputComponent: TextMaskCustom }}
              />
              <TextField
                select
                id="status"
                size="small"
                label="Status"
                variant="outlined"
                onChange={handleChange}
                value={props.values.status}
                SelectProps={{ native: true }}
              >
                {currencies.map((option) => (
                  <option key={option.value} value={props.values.status.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
              <TextField
                select
                id="plano"
                size="small"
                label="Plano"
                variant="outlined"
                onChange={handleChange}
                value={props.values.plano}
                SelectProps={{ native: true }}
              >
                {props.plans.map((option) => (
                  <option key={option.value} >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </div>
            <div className="space2">
              <TextField
                autoFocus
                type="number"
                id="tempo_contrato"
                margin="dense"
                label="Tempo de contrato:"
                variant="outlined"
                value={props.values.tempo_contrato}
                onChange={handleChange}
              />
              <TextField
                autoFocus
                type="text"
                id="webhook_url"
                margin="dense"
                label="URL do Webhook:"
                variant="outlined"
                value={props.values.webhook_url}
                onChange={handleChange}
              />
            </div>
          </div>
        </>
    )
}

BasicInfo.propTypes = {
    values: PropTypes.object,
    setValues: PropTypes.func,
    plans: PropTypes.object,
};
