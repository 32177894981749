import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Button } from '@material-ui/core';
import SwapCalls from '@material-ui/icons/SwapCalls';

export default function IconOrder(props) {
    return (
        <>
            {props.doc.flag_travamento_ordem === "usuario" ? (
                <Tooltip title={<span style={{ fontSize: 15 }}> Ordem travada pelo usuário </span>} size='small'>
                    <Button
                        style={{ color: 'grey', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px' }}
                    >
                        <SwapCalls fontSize="small" />
                    </Button>
                </Tooltip>
            ) : props.doc.flag_travamento_ordem === "ordenar" ? (
                <Tooltip title={<span style={{ fontSize: 15 }}> Ordem gerada pelo Optcargo </span>} size='small'>
                    <Button
                        style={{ color: 'green', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px' }}
                    >
                        <SwapCalls fontSize="small" />
                    </Button>
                </Tooltip>
            ) : null
            }
        </>
    )
}

IconOrder.propTypes = {
    doc: PropTypes.array,
};
