import { action, thunk } from "easy-peasy"
import api from "../../services/api"

const ImportVehiclesStore = {

    // input information in order module
    setImportVehicles: thunk(async (actions, payload) => {
        actions.setOpen(payload.open)
        // retorna os veículos ativos na filial
        const affiliate_veics = await api.get(`/import/veics`)
            .catch((error) => {
                console.log(error);
                console.log("Erro ao buscar os veículos")
            });
        actions.setAvaliableVehicles(affiliate_veics.data)

    }),

    // ----- store ------

    // lista de documentos validos disponiveis para gerar viagem
    veics_available: [],
    setAvaliableVehicles: action((state, payload) => {
        state.veics_available = payload
        // state.veics_available = VehicleValidation({ my_vehicles: payload.my_vehicles, documents: payload.documents })
    }),

    // abre e fecha o modal gerar viagem
    open: false,
    setOpen: action((state, payload) => {
        state.open = payload;
    }),

    import_vehicles: [],
    setVehicles: action((state, payload) => {
        state.import_vehicles = payload;
    }),
};

export default ImportVehiclesStore;
