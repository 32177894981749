import React, { useEffect, useState } from "react";
import { Button, Tooltip } from "@material-ui/core";
import { useStoreActions, useStoreState } from "easy-peasy";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";

export default function Trip() {
    // cria todas as variaveis necessárias para a alocação
    const toTrip = useStoreActions(actions => {
        return actions.toTripStore.setTrip;
    });

    const my_vehicles = useStoreState((state) => state.VeicsDocsStore.my_vehicles);
    const documents = useStoreState((state) => state.VeicsDocsStore.documents);

    const [disabled, setDisabled] = useState(true)
    useEffect(() => {
        var flag = false
        if (!my_vehicles || !documents) {
            setDisabled(true)
        } else if (my_vehicles.length === 0 || documents.length === 0) {
            setDisabled(true)
        }
        else {
            for (let trip of my_vehicles) {
                // se tiver documento associado e for valido
                if (trip.n_docs > 0 && trip.valido) {
                    setDisabled(false)
                    flag = true
                }
            }
        }
        if (flag === false) {
            setDisabled(true)
        }
    }, [my_vehicles, documents])

    return (
        <Tooltip
            title={
                <span style={{ fontSize: 15 }}>
                    Gera a viagem, neste processo são calculadas as rotas reais, todos os custos (km, pedágio, diárias), % de
                    alocação em cada ponto da viagem, verificação se existe alguma restrição, cálculo de itinerário, horário de
                    saída e retorno do Centro de Distribuição, horários de entrega/coleta, carga/ descarga em cada documento e
                    tempo para refeições.
                </span>
            }
        >
            <Button
                component="span"
                disabled={disabled}
                onClick={() => {
                    toTrip({
                        documents,
                        my_vehicles,
                        open_trip: true,
                        type: "gerar_viagem",
                    })
                }}
            >
                <LocalShippingIcon
                    fontSize={'large'}
                    color={disabled ? 'disabled' : 'primary'}
                />
            </Button>
        </Tooltip>
    );
}
