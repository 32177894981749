import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Button } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';

export default function IconRestr(props) {
    return (
        <>
            {props.trip.possui_restr === 'sim' ? (
                < Tooltip title={<span style={{ fontSize: 15 }}> veículo possui restrição </span>} size='small'>
                    <Button
                        style={{ color: 'red', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px' }}
                    >
                        <WarningIcon fontSize="small" />
                    </Button>
                </Tooltip>
            ) : null
            }
        </>
    )
}

IconRestr.propTypes = {
    trip: PropTypes.array,
};
