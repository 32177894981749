import socketio from 'socket.io-client';
import { useSnackbar } from 'notistack';
import { useStoreActions } from 'easy-peasy';
import React, { useEffect, useRef, useState } from 'react';

export default function WebSocket() {
    // atualiza a tabela de documentos e veiculos
    const setReload = useRef()
    setReload.current = useStoreActions(actions => {
        return actions.VeicsDocsStore.setReload;
    });

    // atualiza aba viagens
    const setReloadResults = useRef()
    setReloadResults.current = useStoreActions(actions => {
        return actions.ResultStore.setReload;
    });

    // declarando a snackbar
    const snack = useRef()
    const { enqueueSnackbar } = useSnackbar();
    snack.current = enqueueSnackbar

    const [reconnect, tryReconnect] = useState(false)
    // se o usuario estiver com a janela de importação de documentos aberta

    function startWebsocket() {
        let socket = socketio(process.env.REACT_APP_API_URL || "http://localhost:3030")
        return socket
    }

    useEffect(() => {
        let socket = startWebsocket()
        socket.emit('study', {
            userId: localStorage.getItem("user"),
            email: localStorage.getItem("email"),
            nome_filial: localStorage.getItem("nome_filial"),
            affiliateId: localStorage.getItem("affiliateId"),
        })

        socket.on('simple_msg', (data) => {
            let d1 = new Date(data.dataProgr)
            let d2 = new Date(localStorage.getItem("dataProgr"))
            if (
                (data.email !== localStorage.getItem("email")) &&
                (d1.getDate() === d2.getDate())
            ) {
                snack.current(data.message, { variant: data.status })
            }
        })

        socket.on('update_msg', (data) => {
            let d1 = new Date(data.dataProgr)
            let d2 = new Date(localStorage.getItem("dataProgr"))
            if (
                (data.email !== localStorage.getItem("email")) &&
                (d1.getDate() === d2.getDate())
            ) {
                snack.current(data.message, { variant: data.status })
                setReload.current() // atualiza a tabela de documentos
                if (data.type === "return_trip_rot") {
                    setReloadResults.current()
                }
            }
        })

        socket.on('all_update_msg', (data) => {
            let d1 = new Date(data.dataProgr)
            let d2 = new Date(localStorage.getItem("dataProgr"))
            if (
                (d1.getDate() === d2.getDate())
            ) {
                snack.current(data.message, { variant: data.status })
                setReload.current() // atualiza a tabela de documentos
                if (data.type === "trip_done") {
                    setReloadResults.current()
                }
            }
        })

        socket.on('validation', (data) => {
            let d1 = new Date(data.dataProgr)
            let d2 = new Date(localStorage.getItem("dataProgr"))
            if (
                (data.email !== localStorage.getItem("email")) &&
                (d1.getDate() === d2.getDate())
            ) {
                snack.current(data.message, { variant: data.status })
                setReloadResults.current()
            }
        })

        socket.on('connect_error', () => {
            setTimeout(() => {
                socket.close()
                tryReconnect(!reconnect)
            }, 4000);
        })

        return () => {
            console.log('close websocket connection');
            socket.close()
        }

    }, [reconnect])

    return <></>
}
