import React from "react";
import PropTypes from 'prop-types';
import { Popup } from "react-leaflet";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";

export default function PopupDocumento(props) {
    return (
        <Popup>
            <h2> {props.values.documento} </h2>
            <Table size="small">
                <TableBody>
                    {
                        props.values.placa ? (
                            <TableRow>
                                <TableCell>Placa</TableCell>
                                <TableCell>{props.values.placa}</TableCell>
                            </TableRow>
                        ) : null
                    }
                    <TableRow>
                        <TableCell>Tipo</TableCell>
                        <TableCell>{props.values.tipo}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Peso Mercadoria</TableCell>
                        <TableCell>{props.values.peso_kg}&nbsp;kg</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Valor Mercadoria</TableCell>
                        <TableCell>{props.values.valor_reais}&nbsp;R$</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Endereço</TableCell>
                        <TableCell style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
                            {props.values.logradouro}, {props.values.numero}, {props.values.cidade}, {props.values.estado}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>CEP</TableCell>
                        <TableCell>{props.values.cep}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Razão Social</TableCell>
                        <TableCell
                            style={{ whiteSpace: "normal", wordWrap: "break-word" }}
                        >
                            {props.values.razao_social}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Lat, Lon</TableCell>
                        <TableCell
                            style={{ whiteSpace: "normal", wordWrap: "break-word" }}
                        >
                            {props.values.lat_doc}, {props.values.lon_doc}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Popup>
    );
}

PopupDocumento.propTypes = {
    doc: PropTypes.object,
};
