import { action } from 'easy-peasy';

const PlanedTripsStore = {
    open_drawer: false,
    setOpenDrawer: action((state, payload) => {
        state.open_drawer = payload
    }),

    veic_info: false,
    setVeicInfo: action((state, payload) => {
        state.veic_info = payload
    }),

    end: new Date(),
    setEnd: action((state, payload) => {
        state.end = payload
    }),

    start: new Date(),
    setStart: action((state, payload) => {
        state.start = payload
    }),

    docs: [],
    setDocs: action((state, payload) => {
        state.docs = payload
    }),

    veics: [],
    setVeics: action((state, payload) => {
        state.veics = payload
    }),

    general: [{
        restricao: "não",
        custo_km: 0,
        valor_diaria: 0,
        custo_pedagio: 0,
        tam_viagem_km: 0,
        ocupacao_media: 0,
        custo_total_reais: 0,
        custo_diaria_mais_km: 0,
    }],
    setGeneral: action((state, payload) => {
        state.general = payload
    }),

    reload: false,
    setReload: action((state, _) => {
        state.reload = !state.reload
    }),

    collumns: {
        placa: {
            label: "Placa",
            field: "placa",
            value: true
        },
        _id: {
            label: "Identificador da Viagem",
            field: "_id",
            value: true
        },
        ocup_inicial_kg: {
            label: "Ocup. Inicial(kg)",
            field: "ocup_inicial_kg",
            value: false
        },
        ocup_inicial_porc: {
            label: "Ocup. Inicial(%)",
            field: "ocup_inicial_porc",
            value: false
        },
        ocup_inicial_reais: {
            label: "Ocup. Inicial(reais)",
            field: "ocup_inicial_reais",
            value: false
        },
        ocup_final_kg: {
            label: "Ocup. Final(kg)",
            field: "ocup_final_kg",
            value: false
        },
        ocup_final_porc: {
            label: "Ocup. Final(%)",
            field: "ocup_final_porc",
            value: false
        },
        ocup_final_reais: {
            label: "Ocup. Final(reais)",
            field: "ocup_final_reais",
            value: false
        },
        maior_peso_carga_kg: {
            label: "Ocup. Máx(kg)",
            field: "maior_peso_carga_kg",
            value: false
        },
        maior_taxa_ocup: {
            label: "Ocup. Máx(%)",
            field: "maior_taxa_ocup",
            value: false
        },
        maior_peso_carga_reais: {
            label: "Ocup. Máx(reais)",
            field: "maior_peso_carga_reais",
            value: false
        },
        n_docs: {
            label: "Nº Docs",
            field: "n_docs",
            value: true
        },
        possui_restr: {
            label: "Houve restrição",
            field: "possui_restr",
            value: true
        },
        horario_saida_cd_format: {
            label: "Previsão saída",
            field: "horario_saida_cd_format",
            value: false
        },
        horario_retorno_cd_format: {
            label: "Previsão chegada",
            field: "horario_retorno_cd_format",
            value: false
        },
        tempo_viagem_format: {
            label: "Tempo de viagem",
            field: "tempo_viagem_format",
            value: false
        },
        n_eixos: {
            label: "Nº Eixos",
            field: "n_eixos",
            value: false
        },
        custo_pedagio: {
            label: "Custo Pedágio",
            field: "custo_pedagio",
            value: false
        },
        valor_diaria: {
            label: "Valor da Diária",
            field: "valor_diaria",
            value: false
        },
        tam_viagem_km: {
            label: "Km Total",
            field: "tam_viagem_km",
            value: false
        },
        valor_km: {
            label: "Valor Km",
            field: "valor_km",
            value: false
        },
        custo_diaria_mais_km: {
            label: "Custo da diária + km",
            field: "custo_diaria_mais_km",
            value: false
        },
        custo_total_reais: {
            label: "Custo Total",
            field: "custo_total_reais",
            value: true
        },
        criado_por: {
            label: "Criado",
            field: "criado_por",
            value: true
        },
        criado_em: {
            label: "Criado em",
            field: "criado_em",
            value: false
        },
        editado_por: {
            label: "Editado",
            field: "editado_por",
            value: false
        },
        editado_em: {
            label: "Editado em",
            field: "editado_em",
            value: false
        },
    },

    setCollumns: action((state, payload) => {
        state.collumns = payload
    }),

};
export default PlanedTripsStore;
