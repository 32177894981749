import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import MaterialTable from 'material-table';
import Button from '@material-ui/core/Button';
import React, { useState, useRef } from 'react';

import api from '../../../../../services/api.js';

export default function Table(props) {
    const { start, end } = props

    const tableRef = useRef();
    const snack = useRef()
    const { enqueueSnackbar } = useSnackbar();
    snack.current = enqueueSnackbar

    const state = {
        columns: [
            { title: "Email", field: "email" },
            { title: "Empresa", field: "empresa" },
            { title: "Geocoder", field: "geocoder", type: "numeric" },
            { title: "Find Cep", field: "find_cep", type: "numeric" },
            { title: "P2P", field: "pointtopoint", type: "numeric" },
            { title: "Distance Matrix", field: "matrix", type: "numeric" },
            { title: "Tolls", field: "tolls", type: "numeric" },
            { title: "Tolls Plus", field: "tools_plus", type: "numeric" },
            { title: "Traffic Rotation", field: "traffic_rotation", type: "numeric" },
            { title: "Size Restrictions", field: "size_restrictions", type: "numeric" },
            { title: "Simple Allocation", field: "one_veic_allocation", type: "numeric" },
            { title: "Allocation", field: "allocation", type: "numeric" },
            { title: "Planner", field: "planner", type: "numeric" },
            { title: "Sequencer", field: "sequencer", type: "numeric" },
            { title: "Sequencer Plus", field: "sequencer_plus", type: "numeric" },
            { title: "Trip", field: "trip", type: "numeric" },
            { title: "Router", field: "router", type: "numeric" },
        ],
    };

    const [counters, setCounters] = useState([])

    async function loadCounters() {
        snack.current("Carregando contadores, aguarde...", { variant: 'info' })
        api.get(`/dash/statistics/${start}/${end}`)
            .then((res) => {
                // criando a lista com todos os usuários
                var aux_users = []
                var users = []
                for (let reg of res.data) {
                    if (aux_users.indexOf(reg.userId) === -1) {
                        aux_users.push(reg.userId)
                        users.push(reg)
                    }
                }
                // obtendo os contadores do usuário
                var counters = []
                for (let user of users) {
                    let filter_conts = res.data.filter(x => x.userId === user.userId)
                    let user_counters = {
                        empresa: user.companyId.apelido,
                        email: user.criado_por,

                        geo_db: 0,
                        geo_osm: 0,
                        geocoder: 0,
                        geo_google: 0,

                        matrix: 0,
                        find_cep: 0,
                        tools_plus: 0,
                        pointtopoint: 0,
                        traffic_rotation: 0,
                        size_restrictions: 0,
                        one_veic_allocation: 0,

                        trip: 0,
                        tolls: 0,
                        router: 0,
                        planner: 0,
                        sequencer: 0,
                        allocation: 0,
                        sequencer_plus: 0,
                    }
                    for (let cont of filter_conts) {
                        user_counters[cont.type] += cont.cont
                    }
                    user_counters["geocoder"] = user_counters["geo_db"] + user_counters["geo_osm"] + user_counters["geo_google"]
                    counters.push(user_counters)
                }
                setCounters(counters)
                snack.current("Contadores carregados", { variant: 'success' })
            })
            .catch((error) => {
                console.log(error);
                snack.current("Falha ao buscar os contadores", { variant: 'error' })
            });
    }

    return (
        <>
            <Button
                onClick={() => { loadCounters() }}
                variant="contained"
                color="secundary"
                style={{ marginTop: "1%" }}
            >
                Consultar
            </Button>
            <MaterialTable
                tableRef={tableRef}
                title="Estatíticas dos Usuários"
                columns={state.columns}
                data={counters}
                options={{
                    add: true,
                    pageSize: 10,
                    search: true,
                    grouping: true,
                    exportButton: true,
                    emptyRowsWhenPaging: false,
                    pageSizeOptions: [1, 5, 10, 20, counters.length],
                    padding: "dense",
                    headerStyle: {
                        backgroundColor: "#01579b",
                        textAlign: 'center',
                        color: "#FFF",
                        fontSize: 11 * window.innerWidth / 1280,
                    },
                    cellStyle: {
                        textAlign: "center",
                        fontSize: 11 * window.innerWidth / 1280,
                    },
                    rowStyle: rowData => ({
                        backgroundColor: (rowData.tableData.id % 2 === 0 ? '#fff' : '#f5f5f5')
                    }),
                }}
                localization={{
                    body: {
                        emptyDataSourceMessage: "",
                        addTooltip: "adicionar novo usuário",
                        deleteTooltip: "excluir",
                        editRow: { deleteText: "Deseja excluir o usuário?" },
                    },

                    header: {
                        actions: "Opções",
                    },

                    grouping: {
                        placeholder: "Arraste os cabeçalhos aqui para agrupar por",
                        groupedBy: "Agrupado por:",
                    },

                    toolbar: {
                        searchTooltip: "Pesquisar",
                        searchPlaceholder: "Pesquisar",
                        exportTitle: "exportar",
                        nRowsSelected: "{0} Usuários(s) selecionados",
                    },
                    pagination: {
                        labelRowsSelect: "",
                        labelDisplayedRows: "{count} de {from}-{to}",
                        firstTooltip: "Primeira página",
                        previousTooltip: "Página anterior",
                        nextTooltip: "Próxima página",
                        lastTooltip: "Última página",
                    },
                }}
            />
        </>
    );
}

Table.propTypes = {
    end: PropTypes.string,
    start: PropTypes.string,
};
