import React from "react";
import PropTypes from 'prop-types';
import { Popup } from "react-leaflet";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

export default function PopupPedagio(props) {
    return (
        <Popup>
            <h2> Pedágio {props.data.nome}</h2>
            <Table size="small">
                <TableBody>
                    <TableRow>
                        <TableCell>Concessionária</TableCell>
                        <TableCell>{props.data.concessionaria}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Rodovia</TableCell>
                        <TableCell>{props.data.rodovia}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Automóvel</TableCell>
                        <TableCell>{props.data.auto}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Eixo R$</TableCell>
                        <TableCell>{props.data.eixo}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Estado </TableCell>
                        <TableCell>{props.data.estado}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Popup>
    );
}

PopupPedagio.propTypes = {
    data: PropTypes.object,
};
