import { useSnackbar } from "notistack";
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useState, useRef, useEffect } from "react";
import { useStoreActions, useStoreState } from "easy-peasy"
import { Button, TextField, DialogActions, DialogContent } from "@material-ui/core";

import FieldTime from "./FieldTime"
import Ufs from "../../../../../../utils/Ufs"
import api from "../../../../../../services/api";
import cities from "../../../../../../utils/Cities"
import finals from "../../../../../../utils/Finals"

export default function FormRationing() {
    const [decreto, setDecreto] = useState("");
    const [estado, setEstado] = useState("");
    const [disabled, setDisabled] = useState(true);
    const [cities_uf, setCitiesUf] = useState(true);
    const [cidade, setCidade] = useState([""]);
    const [hora1, setHora1] = useState("");
    const [hora2, setHora2] = useState("");
    const [hora3, setHora3] = useState("");
    const [hora4, setHora4] = useState("");
    const [hora5, setHora5] = useState("");
    const [hora6, setHora6] = useState("");
    const [hora7, setHora7] = useState("");
    const [hora8, setHora8] = useState("");
    const [hora9, setHora9] = useState("");
    const [hora10, setHora10] = useState("");
    const [hora11, setHora11] = useState("");
    const [hora12, setHora12] = useState("");
    const [hora13, setHora13] = useState("");
    const [hora14, setHora14] = useState("");
    const [hora15, setHora15] = useState("");
    const [hora16, setHora16] = useState("");
    const [hora17, setHora17] = useState("");
    const [hora18, setHora18] = useState("");
    const [final_0, setFinal_0] = useState("");
    const [final_1, setFinal_1] = useState("");
    const [final_2, setFinal_2] = useState("");
    const [final_3, setFinal_3] = useState("");
    const [final_4, setFinal_4] = useState("");
    const [final_5, setFinal_5] = useState("");
    const [final_6, setFinal_6] = useState("");
    const [final_7, setFinal_7] = useState("");
    const [final_8, setFinal_8] = useState("");
    const [final_9, setFinal_9] = useState("");
    const [btn_atualizar_salvar, setBtnHabilitarSalvar] = useState(false);

    const snack = useRef()
    const { enqueueSnackbar } = useSnackbar();
    snack.current = enqueueSnackbar

    const setReloadRationingRef = useRef()
    setReloadRationingRef.current = useStoreActions(actions => {
        return actions.reloadStore.setReloadRationing;
    });

    const setOpenStoreFormRef = useRef()
    setOpenStoreFormRef.current = useStoreActions(actions => {
        return actions.rationingStore.setOpenStoreForm;
    });
    const area = useStoreState((state) => state.rationingStore.area);

    useEffect(() => { checkFields() });

    useEffect(() => {
        if (estado === "") {
            setDisabled(true)
        }
        else {
            setDisabled(false)
            setCitiesUf(cities[estado])
        }
    }, [estado]);

    function checkFields() {
        setBtnHabilitarSalvar(
            final_0 !== "" && final_1 !== "" && final_2 !== "" && final_3 !== "" && final_4 !== "" && final_5 !== "" &&
            final_6 !== "" && final_7 !== "" && final_8 !== "" && final_9 !== "" && decreto !== "" && cidade !== "" && estado !== "");
    }

    async function setRationing() {
        var horario_seg_sex = hora1 + "-" + hora2 + "\n" + hora3 + "-" + hora4 + "\n" + hora5 + "-" + hora6;
        var horario_sabados = hora7 + "-" + hora8 + "\n" + hora9 + "-" + hora10 + "\n" + hora11 + "-" + hora12;
        var horario_dom_fer = hora13 + "-" + hora14 + "\n" + hora15 + "-" + hora16 + "\n" + hora17 + "-" + hora18;

        api.post(`/admin/trafficrotation`,
            {
                decreto,
                horario_seg_sex,
                horario_sabados,
                horario_dom_fer,
                final_0,
                final_1,
                final_2,
                final_3,
                final_4,
                final_5,
                final_6,
                final_7,
                final_8,
                final_9,
                cidade,
                estado,
                area: area,
                center: area[0],
            },
        ).then(() => {
            snack.current(`Restrição de rodízio adicionada com sucesso!`, { variant: 'success' })
            setReloadRationingRef.current()
            setOpenStoreFormRef.current(false);
        }).catch((error) => {
            console.log(error)
            snack.current(`Falha ao adicionar a restrição de rodízio!`, { variant: 'error' })
        })
    }

    return (
        <>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="decreto"
                    label="Descrição/Decreto"
                    onChange={(event) => setDecreto(event.target.value)}
                    type="text"
                    fullWidth
                />

                < FieldTime
                    setHora1={setHora1}
                    setHora2={setHora2}
                    setHora3={setHora3}
                    setHora4={setHora4}
                    setHora5={setHora5}
                    setHora6={setHora6}
                    setHora7={setHora7}
                    setHora8={setHora8}
                    setHora9={setHora9}
                    setHora10={setHora10}
                    setHora11={setHora11}
                    setHora12={setHora12}
                    setHora13={setHora13}
                    setHora14={setHora14}
                    setHora15={setHora15}
                    setHora16={setHora16}
                    setHora17={setHora17}
                    setHora18={setHora18}
                />

                <Autocomplete
                    id="final_0"
                    options={finals}
                    getOptionLabel={(option) => option.title}
                    renderInput={(params) => <TextField {...params} label="Final da Placa 0" margin="dense" />}
                    onChange={(_, data) => setFinal_0(data.value)}
                    fullWidth
                />

                <Autocomplete
                    id="final_1"
                    options={finals}
                    getOptionLabel={(option) => option.title}
                    renderInput={(params) => <TextField {...params} label="Final da Placa 1" margin="dense" />}
                    onChange={(_, data) => setFinal_1(data.value)}
                    fullWidth
                />

                <Autocomplete
                    id="final_2"
                    options={finals}
                    getOptionLabel={(option) => option.title}
                    renderInput={(params) => <TextField {...params} label="Final da Placa 2" margin="dense" />}
                    onChange={(_, data) => setFinal_2(data.value)}
                    fullWidth
                />

                <Autocomplete
                    id="final_3"
                    options={finals}
                    getOptionLabel={(option) => option.title}
                    renderInput={(params) => <TextField {...params} label="Final da Placa 3" margin="dense" />}
                    onChange={(_, data) => setFinal_3(data.value)}
                    fullWidth
                />

                <Autocomplete
                    id="final_4"
                    options={finals}
                    getOptionLabel={(option) => option.title}
                    renderInput={(params) => <TextField {...params} label="Final da Placa 4" margin="dense" />}
                    onChange={(_, data) => setFinal_4(data.value)}
                    fullWidth
                />

                <Autocomplete
                    id="final_5"
                    options={finals}
                    getOptionLabel={(option) => option.title}
                    renderInput={(params) => <TextField {...params} label="Final da Placa 5" margin="dense" />}
                    onChange={(_, data) => setFinal_5(data.value)}
                    fullWidth
                />

                <Autocomplete
                    id="final_6"
                    options={finals}
                    getOptionLabel={(option) => option.title}
                    renderInput={(params) => <TextField {...params} label="Final da Placa 6" margin="dense" />}
                    onChange={(_, data) => setFinal_6(data.value)}
                    fullWidth
                />

                <Autocomplete
                    id="final_7"
                    options={finals}
                    getOptionLabel={(option) => option.title}
                    renderInput={(params) => <TextField {...params} label="Final da Placa 7" margin="dense" />}
                    onChange={(_, data) => setFinal_7(data.value)}
                    fullWidth
                />

                <Autocomplete
                    id="final_8"
                    options={finals}
                    getOptionLabel={(option) => option.title}
                    renderInput={(params) => <TextField {...params} label="Final da Placa 8" margin="dense" />}
                    onChange={(_, data) => setFinal_8(data.value)}
                    fullWidth
                />

                <Autocomplete
                    id="final_9"
                    options={finals}
                    getOptionLabel={(option) => option.title}
                    renderInput={(params) => <TextField {...params} label="Final da Placa 9" margin="dense" />}
                    onChange={(_, data) => setFinal_9(data.value)}
                    fullWidth
                />

                <Autocomplete
                    id="estado_"
                    options={Ufs}
                    getOptionLabel={(option) => option.title}
                    renderInput={(params) => <TextField {...params} label="Estado" margin="dense" />}
                    onChange={(_, data) => setEstado(data.value)}
                    fullWidth
                />

                <Autocomplete
                    id="cities"
                    options={cities_uf}
                    disabled={disabled}
                    getOptionLabel={(option) => option.city}
                    renderInput={(params) => <TextField {...params} label="Cidade" margin="dense" />}
                    onChange={(_, data) => setCidade(data.city)}
                    fullWidth
                />


                <TextField
                    autoFocus
                    margin="dense"
                    label="latitudes e longitudes"
                    value={JSON.stringify(area)}
                    InputProps={{
                        readOnly: true,
                    }}
                    variant="filled"
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button disabled={!btn_atualizar_salvar} onClick={() => setRationing()} color="primary">
                    Salvar
                </Button>
                <Button onClick={() => setOpenStoreFormRef.current(false)} color="primary">
                    Cancelar
                </Button>
            </DialogActions>
        </>
    )
}
