import { useSnackbar } from 'notistack';
import MaterialTable from 'material-table';
import React, { useRef, useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';

import api from '../../../../../services/api.js';
import ButtonMap from './Components/ButtonMap.js';

export default function VehicleTable() {
    const snack = useRef();
    const { enqueueSnackbar } = useSnackbar();
    snack.current = enqueueSnackbar

    //ativa e desativa o loading abaixo da appbar
    const setLoading = useRef()
    setLoading.current = useStoreActions(actions => {
        return actions.globalStore.setLoading;
    });

    const setPositions = useStoreActions(actions => {
        return actions.VehicleTrackingStore.setPositions;
    });
    const setPositionsMap = useStoreActions(actions => {
        return actions.VehicleTrackingStore.setPositionsMap;
    });
    const setUpdatePositions = useStoreActions(actions => {
        return actions.VehicleTrackingStore.setUpdatePositions;
    });
    const positions = useRef();
    positions.current = useStoreState((state) => state.VehicleTrackingStore.positions);

    const state = {
        columns: [
            { title: "Placa", field: "placa" },
            { title: "Horário", field: "data" },
            { title: "Latitude", field: "lat" },
            { title: "Longitude", field: "lon" },
            { title: "Identificador", field: "_id" },
        ],
    };

    async function loadVeicPos() {
        setLoading.current(true)
        api.get(`/track/vehicles`)
            .then((resp) => {
                setUpdatePositions({
                    new_pos: resp.data,
                    old_pos: positions.current
                })
                setLoading.current(false)
            })
            .catch((error) => {
                console.log(error);
                setLoading.current(false)
                snack.current("Falha ao carregar.", { variant: 'error' })
            });
    }

    useEffect(() => {
        api.get(`/track/vehicles`)
            .then((resp) => {
                if (resp.data.length === 0) {
                    // snack.current("Filial não possui veículos com rastreador ativo.", { variant: 'info' })
                    console.log("Filial não possui veículos com rastreador ativo.")
                } else {
                    console.log(resp.data)
                    setPositions(resp.data)
                    const interval = setInterval(() => {
                        loadVeicPos()
                    }, 300000);
                    return () => clearInterval(interval);
                }
            })
            .catch((error) => {
                console.log(error);
                snack.current("Falha ao carregar.", { variant: 'error' })
            });
    }, [])

    const tableRef = useRef();
    return (
        <MaterialTable
            tableRef={tableRef}
            title="Rastreamento de Veículos"
            columns={state.columns}
            data={positions.current}
            options={{
                search: true,
                pageSize: 10,
                grouping: true,
                selection: true,
                exportButton: false,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [1, 5, 10, 20],
                padding: "dense",
                headerStyle: {
                    backgroundColor: "#01579b",
                    textAlign: 'center',
                    color: "#FFF",
                    fontSize: 11 * window.innerWidth / 1280,
                },
                cellStyle: {
                    textAlign: "center",
                    fontSize: 11 * window.innerWidth / 1280,
                },
            }}
            onSelectionChange={(rows) => setPositionsMap(rows)}
            localization={{
                body: {
                    addTooltip: "Adicionar novo",
                    deleteTooltip: "Excluir",
                    editTooltip: "Editar",
                    editRow: {
                        deleteText: "Deseja excluir o veículo?",
                        cancelTooltip: "Cancelar",
                        saveTooltip: "Salvar",
                    },
                    emptyDataSourceMessage: (
                        ""
                    ),
                    bulkEditTooltip: "Editar todos",
                    bulkEditCancel: "Descartar edições",
                    bulkEditApprove: "Salvar todas as edições"
                },

                grouping: {
                    placeholder: "Arraste os cabeçalhos aqui para agrupar por",
                    groupedBy: "Agrupado por:",
                },

                header: {
                    actions: "Opções",
                },

                toolbar: {
                    searchTooltip: "Pesquisar",
                    searchPlaceholder: "Pesquisar",
                    exportTitle: "Exportar",
                    nRowsSelected: "{0} veículos(s) selecionados",
                },
                pagination: {
                    labelRowsSelect: "",
                    labelDisplayedRows: "{count} de {from}-{to}",
                    firstTooltip: "Primeira página",
                    previousTooltip: "Página anterior",
                    nextTooltip: "Próxima página",
                    lastTooltip: "Última página",
                },
            }}
            components={{
                Actions: () => {
                    return (
                        <div className="row">
                            <ButtonMap />
                        </div>
                    );
                }
            }}
        />
    );
}
