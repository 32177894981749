import L from 'leaflet';
import React, { useState } from 'react';
import Control from 'react-leaflet-control';
import { useStoreActions } from 'easy-peasy'
import ReactDOMServer from 'react-dom/server';
import { Marker, Polyline } from 'react-leaflet';
import { Tooltip, Button } from '@material-ui/core';
import GroupWork from '@material-ui/icons/GroupWork';

import api from '../../../../../../services/api.js';
import IconRestr from '../../../../../../icons/Restricoes.js';
import PopupPedagio from '../../../../../../popups/Pedagio.js';

export default function MyButtons() {
    const [pedagios, setPedagios] = useState([]);
    const [flag_pedagios, setFlagPedagios] = useState(false);
    async function gerarPedagios() {
        if (flag_pedagios === false) {
            api.get(`/admin/tolls`)
                .then((res) => {
                    var pedagios = res.data.map((data, index) => (
                        <div key={index}>
                            {(() => {
                                var paths_latlon = data.area;
                                var my_icon = L.divIcon({
                                    className: "custom-icon",
                                    html: ReactDOMServer.renderToString(<IconRestr color={"black"} tipo={"pedagio"} />),
                                });
                                return (
                                    <Marker key={index} icon={my_icon} position={[data.center[0].lat, data.center[0].lng]}>
                                        <Polyline color={"black"} positions={paths_latlon} />
                                        <PopupPedagio data={data} />
                                    </Marker>
                                );
                            })()}
                        </div>
                    ));
                    setPedagios(pedagios);
                    setFlagPedagios(true);
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            setPedagios([]);
            setFlagPedagios(false);
        }
    }

    const setCluster = useStoreActions(actions => {
        return actions.mapStore.setCluster;
    });

    return (
        <div>
            <Control position="topleft">
                <div className="leaflet-bar custom-control">
                    <Tooltip title={<span>Plota os pedágios no mapa</span>}>
                        <Button
                            color="inherit"
                            component="span"
                            startIcon={<IconRestr size="small" color={"black"} tipo={"pedagio"} />}
                            onClick={() => gerarPedagios()}
                        >
                        </Button>
                    </Tooltip>
                </div>

                <div className="leaflet-bar custom-control">
                    <Tooltip title={<span>Ativar ou desativar o agrupamento nos documentos</span>}>
                        <Button
                            color="inherit"
                            component="span"
                            startIcon={<GroupWork />}
                            onClick={() => setCluster()}
                        >

                        </Button>
                    </Tooltip>
                </div>

            </Control>
            {pedagios}
        </div>
    )
}
