import { action } from "easy-peasy"

const sizeStore = {
    // plot size restrictions in map
    size: [],
    center: [-22.977213, -47.101165],
    plotSizeRestrictions: action((state, obj) => {
        state.size = obj;
        if (obj[0]) {
            state.center = [obj[obj.length - 1].area[0].lat, obj[obj.length - 1].area[0].lng]
        }
    }),

    // opens the dialog box where links of restrictions are shown
    open_link_dialog: false,
    setOpenLinkDialog: action((state, payload) => {
        state.open_link_dialog = payload;
    }),
    decree_links: [],
    setLinkDialogData: action((state, payload) => {
        state.decree_links = payload.split(",");
    }),

    // open store tools form
    area: [],
    open_store_form: false,
    setOpenStoreForm: action((state, payload) => {
        state.area = payload;
        state.open_store_form = !state.open_store_form;
    }),

    // opens and closes a size restriction update form
    open_update_form: false,
    update_form_data: {},
    setUpdateFormData: action((state, payload) => {
        state.open_update_form = !state.open_update_form

        if (payload.horario_seg_sex) {
            var horario_seg_sex = payload.horario_seg_sex.split("\n")
            var aux_0 = horario_seg_sex[0].split("-")
            var aux_1 = horario_seg_sex[1].split("-")
            var aux_2 = horario_seg_sex[2].split("-")
            payload["hora1"] = aux_0[0]
            payload["hora2"] = aux_0[1]
            payload["hora3"] = aux_1[0]
            payload["hora4"] = aux_1[1]
            payload["hora5"] = aux_2[0]
            payload["hora6"] = aux_2[1]

            var horario_sabados = payload.horario_sabados.split("\n")
            aux_0 = horario_sabados[0].split("-")
            aux_1 = horario_sabados[1].split("-")
            aux_2 = horario_sabados[2].split("-")
            payload["hora7"] = aux_0[0]
            payload["hora8"] = aux_0[1]
            payload["hora9"] = aux_1[0]
            payload["hora10"] = aux_1[1]
            payload["hora11"] = aux_2[0]
            payload["hora12"] = aux_2[1]

            var horario_dom_fer = payload.horario_dom_fer.split("\n")
            aux_0 = horario_dom_fer[0].split("-")
            aux_1 = horario_dom_fer[1].split("-")
            aux_2 = horario_dom_fer[2].split("-")
            payload["hora13"] = aux_0[0]
            payload["hora14"] = aux_0[1]
            payload["hora15"] = aux_1[0]
            payload["hora16"] = aux_1[1]
            payload["hora17"] = aux_2[0]
            payload["hora18"] = aux_2[1]
        }
        state.update_form_data = payload;
    }),

};

export default sizeStore;
