import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Button } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

export default function Upload(props) {
    const {
        tolltip_info,
        setOpen,
    } = props

    return (
        <Tooltip
            title={
                <span style={{ fontSize: 15 }}>
                    {tolltip_info}
                </span>
            }
        >
            <Button
                size="large"
                color="inherit"
                component="span"
                onClick={() => { setOpen(true) }}
                startIcon={<CloudUploadIcon
                    style={{
                        maxWidth: 50,
                        maxHeight: 50,
                        minWidth: 50,
                        minHeight: 50,
                        marginRight: -10,
                        marginLeft: 10,
                        fontSize: 62,
                        color: "#2093d3"
                    }}
                />}>
            </Button>
        </Tooltip>
    );
}

Upload.propTypes = {
    tolltip_info: PropTypes.string,
    setOpen: PropTypes.func,
};

