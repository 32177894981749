import Cookies from "js-cookie";
import api from "../../../services/api";
import { useSnackbar } from "notistack";
import { useDropzone } from "react-dropzone";
import { useHistory } from "react-router-dom";
import userAvatar from "../../../assets/user.png";

import CloseIcon from "@material-ui/icons/Close";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import VpnLockIcon from "@material-ui/icons/VpnLock";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import React, { useState, useEffect, useRef } from "react";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import { Visibility, VisibilityOff } from "@material-ui/icons";

import { makeStyles } from "@material-ui/core/styles";
import {
    Divider,
    TextField,
    Typography,
    Button,
    Grid,
    InputAdornment,
    IconButton,
    Tooltip,
    FormGroup,
    AppBar,
    Toolbar,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl
} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiTextField-root, & .MuiFormControl-root": {
            margin: theme.spacing(1, 0),
        },
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(1),
    },
    title: {
        flexGrow: 1,
        color: "#fff",
    },
    meuDadosDialog: {
        maxWidth: "lg",
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

export default function MinhaConta() {
    const classes = useStyles();
    const history = useHistory();
    const enqueueSnackbarRef = useRef()
    const { enqueueSnackbar } = useSnackbar();
    enqueueSnackbarRef.current = enqueueSnackbar

    const auth = Cookies.get("is_authenticated");
    const auth_provisoria = localStorage.getItem("auth_provisoria");
    if (auth !== "true" && auth_provisoria !== "true") {
        history.push("/");
    }

    const acess_token = Cookies.get("x-access-token");
    if (!acess_token) history.push("/");

    const [dadosNome, setDadosNome] = useState("");
    const [openDados, setOpenDados] = useState(false);
    const [dadosAvatar, setDadosAvatar] = useState("");

    const handleClickOpenDados = () => {
        setOpenDados(true);
    };

    const handleCloseDados = () => {
        setOpenDados(false);
    };

    const onDrop = React.useCallback(async (acceptedFiles, rejectedFiles) => {
        console.log(acceptedFiles);
        console.log(rejectedFiles);

        if (rejectedFiles.length) {
            enqueueSnackbarRef.current("Erro ao carregar arquivo: . Verifique se o arquivo não é maior que 5MB", { variant: 'error' })
            return;
        }

        if (acceptedFiles.length) {
            let imgConvertida = await convertBase64(acceptedFiles[0]);
            imgConvertida = imgConvertida.encoded;
            setDadosAvatar(imgConvertida);
        }
    }, []);
    const { getRootProps, getInputProps } = useDropzone({
        accept: ["image/jpeg", "image/png", "image/jpg"],
        maxSize: 1024 * 1024 * 5, // Maximum file size (in bytes)
        multiple: false,
        noClick: false,
        noDrag: true,
        onDrop,
    });

    function convertBase64(file) {
        return new Promise((res, rej) => {
            var reader = new FileReader();
            let base = {
                name: file.name,
                size: file.size,
            };
            reader.addEventListener("abort", (e) => rej(`File upload aborted:${e}`));
            reader.addEventListener("error", (e) => rej(`File upload error: ${e}`));
            reader.addEventListener(
                "load",
                () =>
                    res({
                        ...base,
                        encoded: String(reader.result)
                            .replace("data:image/jpeg;base64,", "")
                            .replace("data:image/png;base64,", "")
                            .replace("data:image/jpg;base64,", ""),
                    }),
                false
            );
            reader.readAsDataURL(file);
        });
    }

    async function AtualizarAvatar() {
        api
            .put(`/avatar/${localStorage.getItem("user")}`, { nome_completo: dadosNome, avatar: dadosAvatar })
            .then(() => {
                localStorage.setItem("avatar", dadosAvatar);
                localStorage.setItem("nome_completo", dadosNome);
                enqueueSnackbarRef.current("Avatar e nome completo salvos com sucesso!", { variant: 'success' })
                setOpenDados(false);
            })
            .catch((error) => {
                console.log(error)
                enqueueSnackbarRef.current("Erro ao atualizar avatar e nome completo", { variant: 'error' })
                setOpenDados(false);
            });
    }

    const [openSenha, setOpenSenha] = useState(false);
    const [verSenhaNova, setVerSenhaNova] = useState(false);
    const [verSenhaAtual, setVerSenhaAtual] = useState(false);
    const [btnAtualizarSenha, setBtnAtualizarSenha] = useState(false);
    const [redefinirSenhaNova, setRedefinirSenhaNova] = useState("");
    const [redefinirSenhaAtual, setRedefinirSenhaAtual] = useState("");
    const [redefinirSenhaNova2, setRedefinirSenhaNova2] = useState("");
    const [verSenhaConfirmacao, setVerSenhaConfirmacao] = useState(false);

    useEffect(() => {
        verificarCamposRedefinir();
    });

    const handleClickOpenSenha = () => {
        setOpenSenha(true);
    };

    const handleCloseSenha = () => {
        setOpenSenha(false);
    };

    // Função que valida os dados da alteração de senha
    function verificarCamposRedefinir() {
        if (redefinirSenhaNova !== "" && redefinirSenhaNova.length < 8) {
            setBtnAtualizarSenha(false);
            return;
        } else if (redefinirSenhaNova !== "" && redefinirSenhaNova2 !== "" && redefinirSenhaNova !== redefinirSenhaNova2) {
            setBtnAtualizarSenha(false);
            return;
        } else {
            setBtnAtualizarSenha(redefinirSenhaAtual !== "" && redefinirSenhaNova !== "" && redefinirSenhaNova2 !== "");
        }
    }

    // Função que realiza a alteração de senha
    async function handleAtualizarSenha() {
        api
            .put(`/password/change/${localStorage.getItem("user")}`, { nova_senha: redefinirSenhaNova, senha_atual: redefinirSenhaAtual })
            .then(() => {
                localStorage.setItem("senha", redefinirSenhaNova);
                enqueueSnackbarRef.current("Senha alterada com sucesso!", { variant: 'success' })
                setOpenSenha(false);
            })
            .catch((error) => {
                console.log(error)
                enqueueSnackbarRef.current("Erro ao alterar a senha", { variant: 'error' })
            });
    }

    const [senha_tms, setSenhaTms] = useState("");
    const [openTMS, setOpenTMS] = useState(false);
    const [empresa_tms, setEmpresaTms] = useState("");
    const [usuario_tms, setUsuarioTms] = useState("");

    const handleClickOpenTMS = () => {
        setOpenTMS(true);
    };
    const handleCloseTMS = () => {
        setOpenTMS(false);
    };

    async function CadastroTms(event) {
        event.preventDefault();
        if (empresa_tms === "") {
            enqueueSnackbarRef.current("Preencha o campo Empresa!", { variant: 'error' })
        } else if (usuario_tms === "") {
            enqueueSnackbarRef.current("Preencha o campo Usuário!", { variant: 'error' })
        } else if (senha_tms === "") {
            enqueueSnackbarRef.current("Preencha o campo Senha!", { variant: 'error' })
        } else {
            api
                .put(`/tms/register/${localStorage.getItem("user")}`,
                    { empresa_tms, usuario_tms, senha_tms },
                )
                .then(() => {
                    setOpenTMS(false);
                    localStorage.setItem("empresa", empresa_tms);
                    enqueueSnackbarRef.current("Salvo com sucesso!", { variant: 'success' })
                })
                .catch((error) => {
                    console.log(error);
                    enqueueSnackbarRef.current("Erro ao atualizar dados TMS!", { variant: 'error' })
                });
        }
    }

    return (
        <>
            <div className={classes.root}>
                <AppBar position="fixed">
                    <Toolbar variant="dense">
                        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                            <ArrowBackIcon onClick={() => history.push("/progr")} />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Programações
                        </Typography>
                    </Toolbar>
                </AppBar>
            </div>
            <Grid container justify="center">
                <Grid item md={10}>
                    <header>
                        <h1> Minha conta</h1>
                    </header>
                    <Divider />
                    <br />
                    <br />
                    <Grid container direction="row" spacing={6}>
                        <Grid item sm={4}>
                            <Typography variant="h5" gutterBottom>
                                Informações pessoais
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                Atualize as suas informações pessoais, como o seu nome e a sua foto.
                            </Typography>
                            <FormControl required margin="dense" variant="outlined" fullWidth>
                                <Button
                                    className={classes.buttons}
                                    size="large"
                                    variant="contained"
                                    color="primary"
                                    onClick={handleClickOpenDados}
                                    startIcon={<ContactMailIcon />}
                                >
                                    Meus Dados
                                </Button>
                            </FormControl>
                            <Dialog
                                classes={{ paper: classes.meuDadosDialog }}
                                open={openDados}
                                onClose={handleCloseDados}
                                aria-labelledby="form-dialog-title"
                            >
                                <DialogTitle id="form-dialog-title">
                                    Meus Dados
                                    <IconButton aria-label="fechar" className={classes.closeButton} onClick={handleCloseDados}>
                                        <CloseIcon />
                                    </IconButton>
                                </DialogTitle>
                                <DialogContent dividers>
                                    <DialogContentText>Personalize as informações da sua conta, como o nome e foto.</DialogContentText>
                                    <Grid container spacing={1}>
                                        <Grid item xs={3} className="user-avatar-col">
                                            <a {...getRootProps({ className: "dropzone" })} className="user-upload-avatar">
                                                <img
                                                    src={dadosAvatar !== "" ? `data:image/jpeg;base64,${dadosAvatar}` : userAvatar}
                                                    width="100"
                                                    height="100"
                                                    alt="Usuário"
                                                />
                                                <span>
                                                    <CameraAltIcon />
                                                </span>
                                                <input {...getInputProps()} />
                                            </a>
                                            {dadosAvatar !== "" ? (
                                                <Tooltip title="Remover foto">
                                                    <IconButton size="small" aria-label="remover" onClick={() => setDadosAvatar("")}>
                                                        <CloseIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            ) : null}
                                        </Grid>
                                        <Grid item xs={9}>
                                            <FormControl margin="dense" fullWidth>
                                                <TextField
                                                    size="small"
                                                    fullWidth
                                                    label="Nome completo"
                                                    variant="outlined"
                                                    value={dadosNome}
                                                    onChange={(event) => setDadosNome(event.target.value)}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleCloseDados} color="secondary">
                                        Cancelar
                                    </Button>
                                    <Button onClick={AtualizarAvatar} color="primary">
                                        Atualizar
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </Grid>
                        <Grid item sm={4}>
                            <Typography variant="h5" gutterBottom>
                                Alteração de senha
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                Escolha uma senha segura e busque não reutilizá-la em outras contas.
                            </Typography>
                            <FormControl required margin="dense" variant="outlined" fullWidth>
                                <Button
                                    size="large"
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleClickOpenSenha}
                                    startIcon={<VpnKeyIcon />}
                                >
                                    Redefinir Senha
                                </Button>
                            </FormControl>
                            <Dialog open={openSenha} onClose={handleCloseSenha} aria-labelledby="form-dialog-title">
                                <DialogTitle>
                                    Redefinir Senha
                                    <IconButton aria-label="fechar" className={classes.closeButton} onClick={handleCloseSenha}>
                                        <CloseIcon />
                                    </IconButton>
                                </DialogTitle>
                                <DialogContent className="no-scroll" dividers>
                                    <DialogContentText>Escolha uma senha segura. Utilize pelo menos 8 caracteres.</DialogContentText>
                                    <FormControl margin="dense" fullWidth>
                                        <TextField
                                            id="senha"
                                            type={verSenhaAtual ? "text" : "password"}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            tabIndex="-1"
                                                            aria-label="toggle password visibility"
                                                            onClick={() => setVerSenhaAtual(!verSenhaAtual)}
                                                            onMouseDown={(event) => event.preventDefault()}
                                                        >
                                                            {verSenhaAtual ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            size="small"
                                            fullWidth
                                            label="Informe a senha atual"
                                            variant="outlined"
                                            onChange={(event) => setRedefinirSenhaAtual(event.target.value)}
                                        />
                                    </FormControl>
                                    <FormControl margin="dense" fullWidth={true}>
                                        <TextField
                                            type={verSenhaNova ? "text" : "password"}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            tabIndex="-1"
                                                            aria-label="toggle password visibility"
                                                            onClick={() => setVerSenhaNova(!verSenhaNova)}
                                                            onMouseDown={(event) => event.preventDefault()}
                                                        >
                                                            {verSenhaNova ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            size="small"
                                            fullWidth={true}
                                            label="Informe a nova senha"
                                            variant="outlined"
                                            onChange={(event) => setRedefinirSenhaNova(event.target.value)}
                                        />
                                    </FormControl>
                                    <FormControl margin="dense" fullWidth={true}>
                                        <TextField
                                            type={verSenhaConfirmacao ? "text" : "password"}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            tabIndex="-1"
                                                            aria-label="toggle password visibility"
                                                            onClick={() => setVerSenhaConfirmacao(!verSenhaConfirmacao)}
                                                            onMouseDown={(event) => event.preventDefault()}
                                                        >
                                                            {verSenhaConfirmacao ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            size="small"
                                            fullWidth={true}
                                            label="Confirme a nova senha"
                                            variant="outlined"
                                            onChange={(event) => setRedefinirSenhaNova2(event.target.value)}
                                        />
                                    </FormControl>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleCloseSenha} color="secondary">
                                        Cancelar
                                    </Button>
                                    <Button disabled={!btnAtualizarSenha} onClick={handleAtualizarSenha} color="primary">
                                        Atualizar
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </Grid>
                        <Grid item sm={4}>
                            <Typography variant="h5" gutterBottom>
                                API TMS Sascar
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                Gerencie aqui seus dados de acesso da API.{" "}
                                <a href="https://tms.sascar.com.br/" target="_blank" rel="noopener noreferrer">
                                    https://tms.sascar.com.br/
                                </a>
                            </Typography>
                            <FormControl required margin="dense" variant="outlined" fullWidth>
                                <Button
                                    size="large"
                                    variant="contained"
                                    color="default"
                                    onClick={handleClickOpenTMS}
                                    startIcon={<VpnLockIcon />}
                                >
                                    Gerenciar
                                </Button>
                            </FormControl>
                            <Dialog open={openTMS} onClose={handleCloseTMS} aria-labelledby="form-dialog-title">
                                <form onSubmit={CadastroTms}>
                                    <DialogTitle>
                                        Cadastro API TMS Sascar
                                        <IconButton aria-label="fechar" className={classes.closeButton} onClick={handleCloseTMS}>
                                            <CloseIcon />
                                        </IconButton>
                                    </DialogTitle>
                                    <DialogContent className="no-scroll" dividers>
                                        <DialogContentText>Digite aqui os dados de acesso à API do TMS SASCAR:</DialogContentText>
                                        <FormGroup>
                                            <FormControl margin="dense" fullWidth={true}>
                                                <TextField
                                                    id="empresa_tms"
                                                    type="text"
                                                    placeholder="Nome da empresa"
                                                    value={empresa_tms}
                                                    variant="outlined"
                                                    onChange={(event) => setEmpresaTms(event.target.value)}
                                                />
                                            </FormControl>
                                            <FormControl margin="dense" fullWidth={true}>
                                                <TextField
                                                    id="usuario_tms"
                                                    type="text"
                                                    placeholder="Usuário do TMS"
                                                    value={usuario_tms}
                                                    variant="outlined"
                                                    onChange={(event) => setUsuarioTms(event.target.value)}
                                                />
                                            </FormControl>
                                            <FormControl margin="dense" fullWidth={true}>
                                                <TextField
                                                    id="password"
                                                    type="text"
                                                    placeholder="Digite a senha TMS"
                                                    value={senha_tms}
                                                    variant="outlined"
                                                    onChange={(event) => setSenhaTms(event.target.value)}
                                                />
                                            </FormControl>
                                        </FormGroup>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleCloseTMS} color="secondary">
                                            Cancelar
                                        </Button>
                                        <Button color="primary" type="submit">
                                            Salvar
                                        </Button>
                                    </DialogActions>
                                </form>
                            </Dialog>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}
