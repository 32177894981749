import React from 'react';
import Cancel from '@material-ui/icons/Cancel';
import { makeStyles } from '@material-ui/core/styles';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { SwipeableDrawer, Container, IconButton } from '@material-ui/core';

import VehicleTable from './Components/VehicleTable.js';

const useStyles = makeStyles((theme) => ({
    paper: {
        top: 0,
    },
    menuButton: {
        marginRight: theme.spacing(1),
    },
}));

export default function ImportVehicles() {
    const classes = useStyles();

    // abre e fecha o modal de importação de veículos
    const setOpen = useStoreActions(actions => {
        return actions.ImportVehiclesStore.setOpen;
    });

    const setVehicles = useStoreActions(actions => {
        return actions.ImportVehiclesStore.setVehicles;
    });

    const open = useStoreState((state) => state.ImportVehiclesStore.open);

    function cancelarViagem() {
        setVehicles([])
        setOpen(false)
    }

    return (
        <SwipeableDrawer
            anchor={"bottom"}
            onOpen={() => console.log("open")}
            onClose={() => {
                cancelarViagem()
            }}
            open={open}
            classes={{ paper: classes.paper }}
        >
            < Container maxWidth={false} spacing={2}>

                <IconButton
                    edge="start"
                    className={classes.menuButton}
                    aria-label="menu"
                    onClick={() => cancelarViagem()} >
                    <Cancel />
                </IconButton>

                < VehicleTable />

            </Container>
        </SwipeableDrawer>
    );
}
