import React from "react";
import Control from "react-leaflet-control";
import { useStoreActions } from 'easy-peasy'
import { Tooltip, Button } from "@material-ui/core";
import GroupWork from "@material-ui/icons/GroupWork";

export default function MyButtons() {
    const setCluster = useStoreActions(actions => {
        return actions.toAllocateStore.setCluster;
    });

    return (
        <div>
            <Control position="topleft">
                <div className="leaflet-bar custom-control">
                    <Tooltip title={<span>Ativar ou desativar o agrupamento nos documentos</span>}>
                        <Button
                            color="inherit"
                            component="span"
                            startIcon={<GroupWork />}
                            onClick={() => setCluster()}
                        >
                        </Button>
                    </Tooltip>
                </div>
            </Control>
        </div>
    )
}
