import { Resizable } from 're-resizable';
import 'react-leaflet-fullscreen-control';
import { useStoreState } from 'easy-peasy';
import { Map, TileLayer } from 'react-leaflet';
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ReactLeafletSearch from 'react-leaflet-search';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';

import '../../../styles.css';
import MapButtons from '../../../../../buttons/MapButtons.js';
import VehiclesPositionsMap from './Components/VehiclesPositionsMap.js';

const useStyles = makeStyles({
    paper: {
        width: props => props.width,
        top: props => props.top,
        backgroundColor: 'transparent',
    }
});

export default function MyMap() {
    const [width, setWidth] = useState(window.innerWidth / 2.5);
    const [height, setHeight] = useState(window.innerHeight / 2);
    const [top, setTop] = useState(-20 + window.innerHeight / 2);
    const props = { width: width, top: top };
    const classes = useStyles(props);

    const center = useStoreState((state) => state.VehicleTrackingStore.center);
    const open_map = useStoreState((state) => state.VehicleTrackingStore.open_map);

    useEffect(() => {
        const map = mapRef.current.leafletElement;
        map.setView(center);
    }, [center]);

    const mapRef = React.useRef();
    return (
        < SwipeableDrawer
            anchor={"right"}
            variant="persistent"
            open={open_map}
            onOpen={() => console.log("open")}
            onClose={() => console.log("close")}
            classes={{ paper: classes.paper }}
        >
            <div className="container1">
                <Resizable
                    size={{ height, width }}
                    maxHeight={window.innerHeight - 100}
                    maxWidth={window.innerWidth - 100}
                    onResizeStop={(e, direction, ref, d) => {
                        setHeight(height + d.height);
                        setWidth(width + d.width)
                        setTop(top - d.height);
                    }}
                >
                    <Map fullscreenControl style={{ width: width, height: height }} ref={mapRef} center={center} zoom={5} >
                        <TileLayer url="http://{s}.tile.osm.org/{z}/{x}/{y}.png" />
                        <ReactLeafletSearch position="topright" providerOptions={{ region: "br" }} />
                        <MapButtons />
                        <VehiclesPositionsMap />
                    </Map>
                </Resizable>
            </div>
        </SwipeableDrawer >
    );
}
