import XLSX from 'xlsx';
import { useSnackbar } from 'notistack';
import React, { useRef } from "react";
import { useStoreState } from 'easy-peasy';
import { Button, Tooltip } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';

export default function DownloadReport() {
    const snack = useRef()
    const { enqueueSnackbar } = useSnackbar();
    snack.current = enqueueSnackbar

    const docs = useStoreState((state) => state.PlanedTripsStore.docs);
    const veics = useStoreState((state) => state.PlanedTripsStore.veics);
    const general = useStoreState((state) => state.PlanedTripsStore.general);

    async function DownloadData() {
        let general_results = [{
            "Data Inícial da Consulta": general["data_inicio"],
            "Data Final da Consulta": general["data_fim"],
            "Houve Restrição": general["restricao"],
            "Ocupação Média (%)": general["ocupacao_media"],
            "Distância Total (km)": general["tam_viagem_km"],
            "Custo Pedágios (R$)": general["custo_pedagio"],
            "Custo Diárias (R$)": general["valor_diaria"],
            "Custo km (R$)": general["custo_km"],
            "Custo Total (R$)": general["custo_total_reais"],
        }]

        var trips = []
        for (let trip of veics) {
            let aux = {
                "Placa": trip.placa,
                "Identificador da Viagem": trip._id,
                "Ocup. Inicial(kg)": trip.ocup_inicial_kg,
                "Ocup. Inicial(%)": trip.ocup_inicial_porc,
                "Ocup. Inicial(reais)": trip.ocup_inicial_reais,
                "Ocup. Final(kg)": trip.ocup_final_kg,
                "Ocup. Final(%)": trip.ocup_final_porc,
                "Ocup. Final(reais)": trip.ocup_final_reais,
                "Ocup. Máx(kg)": trip.maior_peso_carga_kg,
                "Ocup. Máx(%)": trip.maior_taxa_ocup,
                "Ocup. Máx(reais)": trip.maior_peso_carga_reais,
                "Nº Docs": trip.n_docs,
                "Houve restrição": trip.possui_restr,
                "Previsão saída": trip.horario_saida_cd_format,
                "Previsão chegada": trip.horario_retorno_cd_format,
                "Tempo de viagem": trip.tempo_viagem_format,
                "Nº Eixos": trip.n_eixos,
                "Custo Pedágios (reais)": trip.custo_pedagio,
                "Valor da Diária (reais)": trip.valor_diaria,
                "Distância Total (km)": trip.tam_viagem_km,
                "Valor do Km (reais)": trip.valor_km,
                "Custo Total (reais)": trip.custo_total_reais,
                "Criado por": trip.criado_por,
                "Criado em": trip.criado_em,
                "Editado por": trip.editado_por,
                "Editado em": trip.editado_em,
            }
            trips.push(aux)
        }

        var docs_results = []
        for (let doc of docs) {
            let aux = {
                "Placa": doc.placa,
                "Identificador da Viagem": doc.TripId,
                "Documento": doc.documento,
                "Identificador do documento": doc._id,
                "Ordem": doc.ordem,
                "dataProgr": doc.dataProgr,
                "Prazo": doc.prazo,
                "CEP": doc.cep,
                "Tipo": doc.tipo,
                "País": doc.pais,
                "Cidade": doc.cidade,
                "Estado": doc.estado,
                "Bairro": doc.bairro,
                "Número": doc.numero,
                "Logradouro": doc.logradouro,
                "Complemento": doc.complemento,
                "Razao social": doc.razao_social,
                "Peso (kg)": doc.peso_kg,
                "Latitude": doc.lat_doc,
                "Longitude": doc.lon_doc,
                "Valor da mercadoria (reais)": doc.valor_reais,
                "Taxa de Ocupação (%)": doc.taxa_ocup,
                "Taxa de Ocupação (kg)": doc.taxa_ocup_kg,
                "Taxa de ocupação (reais)": doc.taxa_ocup_reais,
                "Parada para Almoço": doc.parada_almoco,
                "Restrição de Placa": doc.restri_placa,
                "Restrição de Porte": doc.restri_porte,
                "Tempo carga/descarga": doc.tempo_carg_desc_format,
                "Horário de chegada ao cliente": doc.horario_chegada_cli_format,
                "Criado por": doc.criado_por,
                "Criado em": doc.criado_em,
                "Editado por": doc.editado_por,
                "Editado em": doc.editado_em,
            }
            docs_results.push(aux)
        }

        docs_results.sort((a, b) => a["Ordem"] < b["Ordem"] ? 1 : -1);
        docs_results.sort((a, b) => a["Identificador da Viagem"] > b["Identificador da Viagem"] ? 1 : -1);

        var new_workbook = XLSX.utils.book_new();

        let general_info = XLSX.utils.json_to_sheet(general_results);
        XLSX.utils.book_append_sheet(new_workbook, general_info, "Informações Gerais");

        let veics_info = XLSX.utils.json_to_sheet(trips);
        XLSX.utils.book_append_sheet(new_workbook, veics_info, "Viagens");

        let docs_info = XLSX.utils.json_to_sheet(docs_results);
        XLSX.utils.book_append_sheet(new_workbook, docs_info, "Resultados por documento");

        XLSX.writeFile(new_workbook, 'Relatório de Viagens Planejadas OptCargo.xlsx');
    }


    return (
        <Tooltip style={{ whiteSpace: 'nowrap', overflow: 'hidden' }} title={<span style={{ fontSize: 15 }}>  Baixar Relatório </span>} >
            <Button
                color="primary"
                variant="contained"
                style={{ marginTop: "1.25%", marginLeft: "1.5%" }}
                onClick={() => { DownloadData() }}
                disabled={veics.length === 0 ? true : false}
            >
                <GetAppIcon />
            </Button>
        </Tooltip>
    );
}
