import { useSnackbar } from 'notistack';
import MaterialTable from 'material-table';
import React, { useRef, useState, useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';

import api from '../../../../../services/api.js';
import Open from '../../../../../buttons/Open.js';
import Delete from '../../../../../buttons/Delete.js';
import Hidden from '../../../../../buttons/Hidden.js';
import Upload from '../../../../../buttons/Upload.js';
import ProcessingStatus from './Components/ProcessingStatus.js';

export default function InvoicesTable() {
    const snack = useRef()
    const { enqueueSnackbar } = useSnackbar();
    snack.current = enqueueSnackbar

    const setReload = useRef()
    setReload.current = useStoreActions(actions => { return actions.InvoicesStore.setReload });

    const setFatura = useStoreActions(actions => { return actions.InvoicesStore.setFatura });
    const setOpen = useStoreActions(actions => { return actions.InvoicesStore.setOpenUploadDrawer });
    const setCollumns = useStoreActions(actions => { return actions.InvoicesStore.setCollumns });
    const setOpenInvoice = useStoreActions(actions => { return actions.InvoicesStore.setOpenInvoice });


    const reload = useStoreState((state) => state.InvoicesStore.reload);
    const collumns = useStoreState((state) => state.InvoicesStore.collumns);

    const [invoices, setInvoices] = useState([])
    useEffect(() => {
        async function fecthData() {
            api.get(`/utils/user/config/config_invoice`)
                .then((resp) => {
                    let { data } = resp
                    if (data) setCollumns(data)
                })
                .catch((error) => {
                    console.log(error);
                    snack.current("Falha ao buscar as configurações", { variant: 'error' })
                });
        }
        fecthData();
    }, []);

    useEffect(() => {
        async function fecthData() {
            api.get(`/invoices`)
                .then((resp) => {
                    let { invoices } = resp.data

                    // format invoice data
                    var flag = false
                    for (let invoice of invoices) {
                        let date = new Date(invoice.dateRef)
                        invoice["ano"] = date.getFullYear()
                        invoice["mes"] = date.getMonth() + 1
                        invoice["duplicate_tolls"] = invoice["n_total_duplicate"] > 0 ? "sim" : "não"
                        invoice["incorrect_category"] = invoice["n_total_inc_category"] > 0 ? "sim" : "não"
                        invoice["vehicle_inconsistency"] = invoice["n_total_vehicle_inconsistency"] > 0 ? "sim" : "não"
                        invoice.editado_em = new Date(invoice.editado_em).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })
                        invoice.criado_em = new Date(invoice.criado_em).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })
                        if (invoice.processing) flag = true
                    }
                    setInvoices(invoices)

                    // if any invoice is in process refresh the screen every 5 seconds
                    if (flag) {
                        const interval = setInterval(() => {
                            clearInterval(interval);
                            setReload.current()
                        }, 5000);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    snack.current("Falha ao carregar as faturas", { variant: 'error' })
                });
        }
        fecthData();
    }, [reload]);

    const tableRef = useRef();
    return (
        <MaterialTable
            tableRef={tableRef}
            title="Conferência de faturas de pedágios"
            columns={
                [
                    {
                        title: "Opções",
                        field: "opcoes",
                        editable: false,
                        render: (rowData) => {
                            return (
                                <div className="rowIcon">
                                    <ProcessingStatus fatura={rowData} />
                                    <Open
                                        open={true}
                                        data={rowData}
                                        setData={setFatura}
                                        setOpen={setOpenInvoice}
                                        disabled={rowData.completed === true ? false : true}
                                    />
                                    <Delete
                                        _id={rowData._id}
                                        name={rowData.nome}
                                        route={"/invoices/"}
                                        setReload={setReload.current}
                                    />
                                </div>
                            )
                        }
                    },
                    {
                        title: "Fatura",
                        field: "nome",
                        hidden: !collumns.nome.value,
                    },
                    {
                        title: "Nº Passagens Pedágios",
                        field: "n_passagem_ped",
                        hidden: !collumns.n_passagem_ped.value,
                    },
                    {
                        title: "Passagens Pedágios (R$)",
                        field: "valor_passagem_ped",
                        hidden: !collumns.valor_passagem_ped.value,
                    },
                    {
                        title: "Nº Vale Pedágio",
                        field: "n_vale_ped",
                        hidden: !collumns.n_vale_ped.value,
                    },
                    {
                        title: "Vale Pedágios (R$)",
                        field: "valor_vale_ped",
                        hidden: !collumns.valor_vale_ped.value,
                    },
                    {
                        title: "Mês",
                        field: "mes",
                        hidden: !collumns.mes.value,
                    },
                    {
                        title: "Ano",
                        field: "ano",
                        hidden: !collumns.ano.value,
                    },
                    {
                        title: "Cobrança Duplicada",
                        field: "duplicate_tolls",
                        hidden: !collumns.duplicate_tolls.value,
                    },
                    {
                        title: "Categoria Incorreta",
                        field: "incorrect_category",
                        hidden: !collumns.incorrect_category.value,
                    },
                    {
                        title: "Veículo Não Cadastrado/Inativo",
                        field: "vehicle_inconsistency",
                        hidden: !collumns.vehicle_inconsistency.value,
                    },
                    {
                        title: "Criado",
                        field: "criado_por",
                        hidden: !collumns.criado_por.value,
                    },
                    {
                        title: "Criado em",
                        field: "criado_em",
                        hidden: !collumns.criado_em.value,
                    },
                    {
                        title: "Editado",
                        field: "editado_por",
                        hidden: !collumns.editado_por.value,
                    },
                    {
                        title: "Editado em",
                        field: "editado_em",
                        hidden: !collumns.editado_em.value,
                    },
                ]
            }
            data={invoices}
            options={{
                search: true,
                pageSize: 5,
                grouping: true,
                selection: false,
                exportButton: false,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [1, 5, 10, 20],
                padding: "dense",
                headerStyle: {
                    backgroundColor: "#01579b",
                    textAlign: 'center',
                    color: "#FFF",
                    fontSize: 11 * window.innerWidth / 1280,
                },
                cellStyle: {
                    textAlign: "center",
                    fontSize: 11 * window.innerWidth / 1280,
                },
            }}
            localization={{
                body: {
                    addTooltip: "Adicionar novo",
                    deleteTooltip: "Excluir",
                    editTooltip: "Editar",
                    editRow: {
                        deleteText: "Deseja excluir o veículo?",
                        cancelTooltip: "Cancelar",
                        saveTooltip: "Salvar",
                    },
                    emptyDataSourceMessage: (
                        ""
                    ),
                    bulkEditTooltip: "Editar todos",
                    bulkEditCancel: "Descartar edições",
                    bulkEditApprove: "Salvar todas as edições"
                },

                grouping: {
                    placeholder: "Arraste os cabeçalhos aqui para agrupar por",
                    groupedBy: "Agrupado por:",
                },

                header: {
                    actions: "Opções",
                },

                toolbar: {
                    searchTooltip: "Pesquisar",
                    searchPlaceholder: "Pesquisar",
                    exportTitle: "Exportar",
                    nRowsSelected: "{0} veículos(s) selecionados",
                },
                pagination: {
                    labelRowsSelect: "",
                    labelDisplayedRows: "{count} de {from}-{to}",
                    firstTooltip: "Primeira página",
                    previousTooltip: "Página anterior",
                    nextTooltip: "Próxima página",
                    lastTooltip: "Última página",
                },
            }}
            components={{
                Actions: () => {
                    return (
                        <>
                            < Upload
                                setOpen={setOpen}
                                tolltip_info={"Importe uma fatura com seus pedágios. Formatos aceitos: .xls, .xlsx, .ods"}
                            />
                            < Hidden
                                type={"config_invoice"}
                                collumns={collumns}
                                setReload={setReload.current}
                            />
                        </>
                    );
                }
            }}
        />
    );
}
