import React from 'react';
import { useStoreState } from 'easy-peasy';
import { AppBar, Container, LinearProgress } from '@material-ui/core';

import MyAppBar from '../../utils/MyAppBar/MyAppBar.js';
import TollsConference from './Components/TollsConference/TollsConference.js';
import VehicleTracking from './Components/VehicleTracking/VehicleTracking.js';
import ButtonMap from './Components/TollsConference/FixedAppBar/ButtonMap.js';
import SelectDateRange from './Components/TollsConference/FixedAppBar/SelectDateRange.js';

import {
    AntTab,
    AntTabs,
    TabPanel,
    tabStyles,
} from '../../components/MyTabs.js'

export default function Perform() {
    const classes = tabStyles({ fontSize: 12 * window.innerWidth / 1280 });
    const [value, setValue] = React.useState(0);
    const loading = useStoreState((state) => state.globalStore.loading);
    return (
        <div className={classes.root}>
            < MyAppBar />
            < Container maxWidth={false} spacing={2}>
                <AppBar position='fixed' color='default' style={{ marginTop: 50 }}>
                    <AntTabs
                        value={value}
                        variant='fullWidth'
                        textColor='primary'
                        scrollButtons='auto'
                        aria-label="some text"
                        indicatorColor='primary'
                        onChange={(_, newValue) => { setValue(newValue) }}
                        classes={{ indicator: classes.customStyleOnActiveTab }}
                    >
                        <AntTab label={<span className={value === 0 ? classes.activeTab : classes.customStyleOnTab}>RASTREAR</span>} />
                        <AntTab label={<span className={value === 1 ? classes.activeTab : classes.customStyleOnTab}>PEDÁGIOS</span>} />
                    </AntTabs>
                    {loading && <LinearProgress />}
                </AppBar>
                <div >
                    <TabPanel value={value} index={0} style={{ marginTop: 50, fontSize: 13 }}>
                        <VehicleTracking />
                    </TabPanel>
                    <TabPanel value={value} index={1} style={{ marginTop: 50 }}>
                        <AppBar position='fixed' color="#fffff" style={{ marginTop: 100 }} elevation={0}>
                            <div className="rowAppTollsConference" >
                                <div className="rowAppTollsConferenceSelectDateRange">
                                    < SelectDateRange />
                                </div>
                                <div className="rowAppTollsConferencerowAppTool">
                                    < ButtonMap />
                                </div >
                            </div>
                        </AppBar>
                        <div style={{ marginTop: 100 }}>
                            <TollsConference />
                        </div>
                    </TabPanel>
                </div>
            </Container>
        </div >
    );
}
