import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Button } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';

export default function Open(props) {
    const {
        open,
        data,
        setData,
        setOpen,
        disabled,
    } = props

    return (
        <Tooltip title={<span style={{ fontSize: 15 }}> Abrir </span>} >
            <Button
                color="primary"
                disabled={disabled}
                onClick={() => {
                    setOpen(open)
                    setData(data)
                }}
                style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px' }}
            >
                <VisibilityIcon fontSize="small" />
            </Button>
        </Tooltip>
    );
}

Open.propTypes = {
    open: PropTypes.bool,
    data: PropTypes.object,
    setOpen: PropTypes.func,
    setData: PropTypes.func,
    disabled: PropTypes.bool,
};
