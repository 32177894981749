import React from 'react';
import { useStoreState } from 'easy-peasy';
import { AppBar, Container, LinearProgress } from '@material-ui/core';

import "./styles.css";
import Dash from './Components/Dash/Dash.js'
import MyAppBar from '../../utils/MyAppBar/MyAppBar.js';
import Documents from './Components/Documents/Documents.js';
import Statistics from './Components/Statistics/Statistics.js';
import Select from './Components/Documents/FixedAppBar/Select.js';

import {
    AntTab,
    AntTabs,
    TabPanel,
    tabStyles,
} from '../../components/MyTabs.js'

export default function DashTabs() {
    const classes = tabStyles({ fontSize: 12 * window.innerWidth / 1280 });
    const [value, setValue] = React.useState(0);
    const loading = useStoreState((state) => state.globalStore.loading);
    return (
        <div className={classes.root}>
            < MyAppBar />
            < Container maxWidth={false} spacing={2}>
                <AppBar position='fixed' color='default' style={{ marginTop: 50 }}>
                    <AntTabs
                        value={value}
                        variant='fullWidth'
                        textColor='primary'
                        scrollButtons='auto'
                        aria-label="some text"
                        indicatorColor='primary'
                        onChange={(_, newValue) => { setValue(newValue) }}
                        classes={{ indicator: classes.customStyleOnActiveTab }}
                    >
                        <AntTab label={<span className={value === 0 ? classes.activeTab : classes.customStyleOnTab}>DASH </span>} />
                        <AntTab label={<span className={value === 1 ? classes.activeTab : classes.customStyleOnTab}>DOCUMENTO</span>} />
                        <AntTab label={<span className={value === 2 ? classes.activeTab : classes.customStyleOnTab}>ESTATÍSTICAS</span>} />
                    </AntTabs>
                    {loading && <LinearProgress />}
                </AppBar>
                <div >
                    <TabPanel value={value} index={0} style={{ marginTop: 50, fontSize: 13 }}>
                        <Dash />
                    </TabPanel>
                    <TabPanel value={value} index={1} style={{ marginTop: 50 }}>
                        <AppBar position='fixed' color="#fffff" style={{ marginTop: 100 }} elevation={0}>
                            <div className="rowAppTollsConference" >
                                <div className="rowAppTollsConferenceSelectDateRange">
                                    < Select />
                                </div>
                            </div>
                        </AppBar>
                        <div style={{ marginTop: 100 }}>
                            <Documents />
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={2} style={{ marginTop: 50 }}>
                        <Statistics />
                    </TabPanel>
                </div>
            </Container>
        </div >
    );
}
