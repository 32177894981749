import PropTypes from 'prop-types';
import React, { useState } from "react";
import MaskedInput from 'react-text-mask';
import Language from "@material-ui/icons/Language";
import { TextField, Button } from "@material-ui/core";
import FindReplaceIcon from '@material-ui/icons/FindReplace';

import api from "../../../../../../../services/api.js";

function TextMaskCustom(props) {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/
            ]}
            placeholderChar={"\u2000"}
            showMask
        />
    );
}

export default function Adress(props) {
    const [disable, setDisable] = useState(true)
    const [geo_flag, setGeoFlag] = useState(true)
    const [error, setError] = useState(false)

    async function findCep() {
        let cep = props.values.cep.toString().match(/\d/g);
        cep = cep.join("")
        // Find a CEP
        props.snack("Buscando Cep", { variant: 'info' })
        api.get(`utils/cep/find/${cep}`)
            .then((resp) => {
                if (resp.data.erro) {
                    setError(true)
                } else {
                    setError(false)
                    if (resp.data.logradouro === '') {
                        setDisable(false)
                        props.setValues({
                            ...props.values,
                            'bairro': resp.data.bairro,
                            'cidade': resp.data.localidade,
                            'estado': resp.data.uf,
                            'pais': "BR",
                        });
                    } else {
                        setDisable(true)
                        props.setValues({
                            ...props.values,
                            'logradouro': resp.data.logradouro,
                            'bairro': resp.data.bairro,
                            'cidade': resp.data.localidade,
                            'estado': resp.data.uf,
                            'pais': "BR",
                        });
                    }

                    setGeoFlag(false)
                    if (resp.data.logradouro === '') {
                        setDisable(false)
                    } else {
                        setDisable(true)
                    }
                }
            })
            .catch((error) => {
                console.log(error)
                props.snack("Falha ao buscar o cep", { variant: 'error' })
            });
    }

    function Geocoder() {
        var cep
        if (typeof props.values.cep === "number") {
            cep = props.values.cep.toString()
        } else {
            cep = props.values.cep.match(/\d/g);
            cep = cep.join("")
        }
        props.snack("Geolocalizando o endereço", { variant: 'info' })
        api
            .put(`/utils/latlng/find`, {
                cep,
                logradouro: props.values.logradouro,
                numero: props.values.numero,
                bairro: props.values.bairro,
                cidade: props.values.cidade,
                estado: props.values.estado
            })
            .then((resp) => {
                props.setValues({
                    ...props.values,
                    'lat_doc': resp.data.lat,
                    'lon_doc': resp.data.lng,
                    'geocoder': true,
                });
                setGeoFlag(true)
                props.snack("Endereço geolocalizado", { variant: 'success' })
            })
            .catch((error) => {
                console.log(error)
                props.snack("Não foi possível geolocalizar o endereço", { variant: 'error' })
            });
    }

    const handleChange = (event) => {
        props.setValues({
            ...props.values,
            [event.target.id]: event.target.value,
        });
    };

    return (
        <>
            <div className="titleFlex">
                <div className="title_info4">Endereço</div>
                <div className="fantasiaRow">
                    <div className="space2row">
                        <TextField
                            id="cep"
                            error={error}
                            label="CEP"
                            variant="outlined"
                            onChange={handleChange}
                            value={props.values.cep}
                            helperText={error ? "Cep Inválido" : ""}
                            InputProps={{ inputComponent: TextMaskCustom }}
                            size="small"
                        />

                        <TextField
                            autoFocus
                            type="number"
                            id="numero"
                            label="Número"
                            margin="dense"
                            variant="outlined"
                            onChange={handleChange}
                            value={props.values.numero}
                        />
                    </div>
                    <div className="space2row">
                        <TextField
                            autoFocus
                            type="text"
                            margin="dense"
                            id="complemento"
                            variant="outlined"
                            label="Complemento"
                            onChange={handleChange}
                            value={props.values.complemento}
                        />

                        <TextField
                            fullWidth
                            disabled={disable}
                            type="text"
                            margin="dense"
                            id="logradouro"
                            variant="outlined"
                            label="Logradouro"
                            onChange={handleChange}
                            value={props.values.logradouro}
                        />
                    </div>

                    <div className="space2row">
                        <TextField
                            disabled={disable}
                            autoFocus
                            id="bairro"
                            type="text"
                            label="Bairro"
                            margin="dense"
                            variant="outlined"
                            onChange={handleChange}
                            value={props.values.bairro}
                        />

                        <TextField
                            disabled
                            autoFocus
                            id="cidade"
                            type="text"
                            label="Cidade"
                            margin="dense"
                            variant="outlined"
                            onChange={handleChange}
                            value={props.values.cidade}
                        />
                    </div>

                    <div className="space2row">
                        <TextField
                            disabled
                            autoFocus
                            id="estado"
                            type="text"
                            label="Estado"
                            margin="dense"
                            variant="outlined"
                            onChange={handleChange}
                            value={props.values.estado}
                        />

                        <TextField
                            disabled
                            autoFocus
                            id="pais"
                            type="text"
                            label="País"
                            margin="dense"
                            variant="outlined"
                            onChange={handleChange}
                            value={props.values.pais}
                        />
                    </div>

                    <div className="space2row">
                        <TextField
                            required
                            autoFocus
                            id="lat_doc"
                            type="number"
                            margin="dense"
                            label="Latitude"
                            variant="outlined"
                            onChange={handleChange}
                            value={props.values.lat_doc}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={props.update === true && (
                                props.values.lat_doc > 5.53 ||
                                props.values.lat_doc < -34.09) ? true :
                                props.values.lat_doc === "" ? false : (
                                    props.values.lat_doc > 5.53 ||
                                    props.values.lat_doc < -34.09) ? true : false
                            }
                            helperText={props.update === true && (
                                props.values.lat_doc > 5.53 ||
                                props.values.lat_doc < -34.09) ? "latitude inválida" :
                                props.values.lat_doc === "" ? "" : (
                                    props.values.lat_doc > 5.53 ||
                                    props.values.lat_doc < -34.09) ? "latitude inválida" : ""
                            }
                        />

                        <TextField
                            required
                            autoFocus
                            id="lon_doc"
                            type="number"
                            margin="dense"
                            label="Longitude"
                            variant="outlined"
                            onChange={handleChange}
                            value={props.values.lon_doc}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={props.update === true && (
                                props.values.lon_doc > -33.79 ||
                                props.values.lon_doc < -74.62) ? true :
                                props.values.lon_doc === "" ? false : (
                                    props.values.lon_doc > -33.79 ||
                                    props.values.lon_doc < -74.62) ? true : false
                            }
                            helperText={props.update === true && (
                                props.values.lon_doc > -33.79 ||
                                props.values.lon_doc < -74.62) ? "longitude inválida" :
                                props.values.lon_doc === "" ? "" : (
                                    props.values.lon_doc > -33.79 ||
                                    props.values.lon_doc < -74.62) ? "longitude inválida" : ""
                            }
                        />
                    </div>
                    <Button
                        variant="contained"
                        style={{ margin: "2%" }}
                        size="medium"
                        component="span"
                        disabled={
                            (props.values.cep === "" || !Array.isArray(props.values.cep.toString().match(/\d/g))) ? true :
                                props.values.cep.toString().match(/\d/g).length === 8 ? false
                                    : true
                        }
                        startIcon={<FindReplaceIcon />}
                        onClick={() => findCep()}
                    >
                        Buscar CEP
                    </Button>
                    <Button
                        variant="contained"
                        style={{ margin: "2%" }}
                        size="medium"
                        component="span"
                        disabled={geo_flag}
                        startIcon={<Language />}
                        onClick={() => Geocoder()}
                    >
                        Geolocalizar
                    </Button>
                </div>
            </div>
        </>
    )
}

Adress.propTypes = {
    snack: PropTypes.func,
    values: PropTypes.object,
    setValues: PropTypes.func
};
