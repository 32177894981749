import { useSnackbar } from "notistack";
import MaterialTable from "material-table";
import { TextField } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useStoreActions, useStoreState } from "easy-peasy"
import React, { useEffect, useState, useRef } from "react";

import "../styles.css";
import Ufs from "../../../../../utils/Ufs"
import api from "../../../../../services/api";
import finals from "../../../../../utils/Finals"

export default function Table() {
    const snack = useRef()
    const { enqueueSnackbar } = useSnackbar();
    snack.current = enqueueSnackbar

    const state = {
        columns: [
            { title: "decreto", field: "decreto" },
            {
                title: "final_0", field: "final_0",
                lookup: finals.value,
                filtering: false,
                editComponent: props => (
                    <Autocomplete
                        id="final_0"
                        value={{ title: props.value, value: props.value }}
                        disableClearable
                        options={finals}
                        autoComplete={true}
                        getOptionLabel={(option) => option.value}
                        onChange={(_, data) => {
                            props.onRowDataChange({
                                ...props.rowData,
                                final_0: data.value
                            })
                        }}
                        renderInput={(params) => <TextField {...params} label="" size="small" placeholder='' />}
                    />
                )
            },
            {
                title: "final_1", field: "final_1",
                lookup: finals.value,
                filtering: false,
                editComponent: props => (
                    <Autocomplete
                        id="final_1"
                        value={{ title: props.value, value: props.value }}
                        disableClearable
                        options={finals}
                        autoComplete={true}
                        getOptionLabel={(option) => option.value}
                        onChange={(_, data) => {
                            props.onRowDataChange({
                                ...props.rowData,
                                final_1: data.value
                            })
                        }}
                        renderInput={(params) => <TextField {...params} label="" size="small" placeholder='' />}
                    />
                )
            },
            {
                title: "final_2", field: "final_2",
                lookup: finals.value,
                filtering: false,
                editComponent: props => (
                    <Autocomplete
                        id="final_2"
                        value={{ title: props.value, value: props.value }}
                        disableClearable
                        options={finals}
                        autoComplete={true}
                        getOptionLabel={(option) => option.value}
                        onChange={(_, data) => {
                            props.onRowDataChange({
                                ...props.rowData,
                                final_2: data.value
                            })
                        }}
                        renderInput={(params) => <TextField {...params} label="" size="small" placeholder='' />}
                    />
                )
            },
            {
                title: "final_3", field: "final_3",
                lookup: finals.value,
                filtering: false,
                editComponent: props => (
                    <Autocomplete
                        id="final_3"
                        value={{ title: props.value, value: props.value }}
                        disableClearable
                        options={finals}
                        autoComplete={true}
                        getOptionLabel={(option) => option.value}
                        onChange={(_, data) => {
                            props.onRowDataChange({
                                ...props.rowData,
                                final_3: data.value
                            })
                        }}
                        renderInput={(params) => <TextField {...params} label="" size="small" placeholder='' />}
                    />
                )
            },
            {
                title: "final_4", field: "final_4",
                lookup: finals.value,
                filtering: false,
                editComponent: props => (
                    <Autocomplete
                        id="final_4"
                        value={{ title: props.value, value: props.value }}
                        disableClearable
                        options={finals}
                        autoComplete={true}
                        getOptionLabel={(option) => option.value}
                        onChange={(_, data) => {
                            props.onRowDataChange({
                                ...props.rowData,
                                final_4: data.value
                            })
                        }}
                        renderInput={(params) => <TextField {...params} label="" size="small" placeholder='' />}
                    />
                )
            },
            {
                title: "final_5", field: "final_5",
                lookup: finals.value,
                filtering: false,
                editComponent: props => (
                    <Autocomplete
                        id="final_5"
                        value={{ title: props.value, value: props.value }}
                        disableClearable
                        options={finals}
                        autoComplete={true}
                        getOptionLabel={(option) => option.value}
                        onChange={(_, data) => {
                            props.onRowDataChange({
                                ...props.rowData,
                                final_5: data.value
                            })
                        }}
                        renderInput={(params) => <TextField {...params} label="" size="small" placeholder='' />}
                    />
                )
            },
            {
                title: "final_6", field: "final_6",
                lookup: finals.value,
                filtering: false,
                editComponent: props => (
                    <Autocomplete
                        id="final_6"
                        value={{ title: props.value, value: props.value }}
                        disableClearable
                        options={finals}
                        autoComplete={true}
                        getOptionLabel={(option) => option.value}
                        onChange={(_, data) => {
                            props.onRowDataChange({
                                ...props.rowData,
                                final_6: data.value
                            })
                        }}
                        renderInput={(params) => <TextField {...params} label="" size="small" placeholder='' />}
                    />
                )
            },
            {
                title: "final_7", field: "final_7",
                lookup: finals.value,
                filtering: false,
                editComponent: props => (
                    <Autocomplete
                        id="final_7"
                        value={{ title: props.value, value: props.value }}
                        disableClearable
                        options={finals}
                        autoComplete={true}
                        getOptionLabel={(option) => option.value}
                        onChange={(_, data) => {
                            props.onRowDataChange({
                                ...props.rowData,
                                final_7: data.value
                            })
                        }}
                        renderInput={(params) => <TextField {...params} label="" size="small" placeholder='' />}
                    />
                )
            },
            {
                title: "final_8", field: "final_8",
                lookup: finals.value,
                filtering: false,
                editComponent: props => (
                    <Autocomplete
                        id="final_8"
                        value={{ title: props.value, value: props.value }}
                        disableClearable
                        options={finals}
                        autoComplete={true}
                        getOptionLabel={(option) => option.value}
                        onChange={(_, data) => {
                            props.onRowDataChange({
                                ...props.rowData,
                                final_8: data.value
                            })
                        }}
                        renderInput={(params) => <TextField {...params} label="" size="small" placeholder='' />}
                    />
                )
            },
            {
                title: "final_9", field: "final_9",
                lookup: finals.value,
                filtering: false,
                editComponent: props => (
                    <Autocomplete
                        id="final_9"
                        value={{ title: props.value, value: props.value }}
                        disableClearable
                        options={finals}
                        autoComplete={true}
                        getOptionLabel={(option) => option.value}
                        onChange={(_, data) => {
                            props.onRowDataChange({
                                ...props.rowData,
                                final_9: data.value
                            })
                        }}
                        renderInput={(params) => <TextField {...params} label="" size="small" placeholder='' />}
                    />
                )
            },
            { title: "SEGUNDA - SEXTA", field: "horario_seg_sex", editable: "never" },
            { title: "SÁBADOS", field: "horario_sabados", editable: "never" },
            { title: "DOMINGOS E FERIADOS", field: "horario_dom_fer", editable: "never" },
            { title: "cidade", field: "cidade" },
            {
                title: "estado", field: "estado",
                lookup: Ufs.value,
                filtering: false,
                editComponent: props => (
                    <Autocomplete
                        id="estado"
                        value={{ title: props.value, value: props.value }}
                        options={Ufs}
                        autoComplete={true}
                        disableClearable
                        getOptionLabel={(option) => option.value}
                        onChange={(_, data) => {
                            props.onRowDataChange({
                                ...props.rowData,
                                estado: data.value
                            })
                        }}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                label=""
                                size="small"
                                placeholder='' />}
                    />
                )
            },
            { title: "Atualizado por", field: "email", editable: "never" },
            { title: "Atualizado em:", field: "data_ultima_atualizacao", editable: "never" },
            { title: "identificador", field: "_id", editable: "never" },
        ],
    };

    const tableRef = useRef();
    const [restricoes_rodizio, setRestricoesRodizio] = useState([]);
    const plotRationingRef = useRef()
    plotRationingRef.current = useStoreActions(actions => {
        return actions.rationingStore.plotRationing;
    });
    const reload_rationing = useStoreState((state) => state.reloadStore.reload_rationing);

    useEffect(() => {
        function fetchData() {
            api.get(`/admin/trafficrotation`)
                .then((res) => {
                    setRestricoesRodizio(res.data.reverse());
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        fetchData();
    }, [reload_rationing]);

    return (
        <MaterialTable
            tableRef={tableRef}
            title="Restrições de Rodízio"
            columns={state.columns}
            data={restricoes_rodizio}
            options={{
                search: true,
                grouping: true,
                selection: true,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [1, 5, 10, restricoes_rodizio.length],

                padding: "dense",
                headerStyle: {
                    backgroundColor: "#01579b",
                    textAlign: "center",
                    color: "#FFF",
                },
                cellStyle: {
                    textAlign: "center",
                },
            }}
            onSelectionChange={(rows) => plotRationingRef.current(rows)}
            localization={{
                body: {
                    emptyDataSourceMessage: "",
                    deleteTooltip: "excluir",
                    editTooltip: "editar",
                    editRow: { deleteText: "Deseja excluir a restrição?" },
                },

                grouping: {
                    placeholder: "Arraste os cabeçalhos aqui para agrupar por",
                    groupedBy: "Agrupado por:",
                },

                header: {
                    actions: "Opções",
                },

                toolbar: {
                    searchTooltip: "Pesquisar",
                    searchPlaceholder: "Pesquisar",
                    exportTitle: "exportar",
                },
                pagination: {
                    labelRowsSelect: "",
                    labelDisplayedRows: "{count} de {from}-{to}",
                    firstTooltip: "Primeira página",
                    previousTooltip: "Página anterior",
                    nextTooltip: "Próxima página",
                    lastTooltip: "Última página",
                },
            }}
            editable={{
                onRowUpdate: (newData, oldData) =>
                    new Promise((resolve) => {
                        setTimeout(() => {
                            const dataUpdate = [...restricoes_rodizio];
                            const index = oldData.tableData.id;
                            dataUpdate[index] = newData;
                            api.put(`/admin/trafficrotation`, {
                                data: newData,
                            })
                                .then(() => {
                                    snack.current(`Restrição de rodízio atualizada com sucesso!`, { variant: 'success' })
                                }).
                                catch((error) => {
                                    console.log(error)
                                    snack.current(`Falha ao atualizar restrição de rodízio!`, { variant: 'error' })
                                })
                            setRestricoesRodizio([...dataUpdate]);
                            resolve();
                        }, 1000);
                    }),
                onRowDelete: (oldData) =>
                    new Promise((resolve) => {
                        setTimeout(() => {
                            const dataDelete = [...restricoes_rodizio];
                            const index = oldData.tableData.id;
                            const del = dataDelete.splice(index, 1);
                            const trafficId = del[0]._id
                            api.delete(`/admin/trafficrotation/${trafficId}`)
                                .then(() => {
                                    snack.current(`Restrição de rodízio excluida com sucesso!`, { variant: 'success' })
                                })
                                .catch((error) => {
                                    console.log(error)
                                    snack.current(`Falha ao excluir restrição de rodízio!`, { variant: 'error' })
                                })
                            setRestricoesRodizio([...dataDelete]);
                            resolve();
                        }, 1000);
                    }),
            }}
        />
    );
}
