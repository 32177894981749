import React from "react";

export default function IconToll() {
	return (
		<svg version="1.1" width="26" viewBox="0 0 36 36" height="32">
			<path
				style={{ fill: "black" }}
				d="M 25.559051,18.66426 35.350671,2.636804 32.707108,1.0220887 22.594591,17.575392 l -3.106323,0 0,13.903262 -1.188445,0 0,-26.7544853 -15.8586517,0 0,26.7544853 -1.1921953,0 0,3.64513 34.101695,0 0,-3.64513 -9.79162,0 0,-12.814394 z M 11.361493,7.4645809 l 4.955999,0 0,12.7683571 -4.955999,0 0,-12.7683571 z m -6.9379902,0 4.9559993,0 0,12.7683571 -4.9559993,0 0,-12.7683571 z"
			/>
		</svg>
	);
}
