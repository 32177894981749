import { action, thunk } from "easy-peasy"

import DocumentValidation from "./Components/DocumentValidation"
import VehicleValidation from "./Components/VehicleValidation"

const toOrderStore = {

    // input information in order module
    setOrder: thunk(async (actions, payload) => {
        actions.setOrderType(payload.type)
        let valid_docs = DocumentValidation(payload.documents)
        actions.setOrderDocuments(valid_docs)
        actions.setOrderVehicles(payload)
        actions.setOpenOrder(payload.open_order)
    }),

    // ----- store ------
    // tipo da ordenação
    order_type: "",
    setOrderType: action((state, payload) => {
        state.order_type = payload;
    }),

    // lista de documentos validos disponiveis para gerar viagem
    avaliable_docs: [],
    setOrderDocuments: action((state, payload) => {
        state.avaliable_docs = payload
    }),

    // lista de documentos validos disponiveis para gerar viagem
    veics_available: [],
    setOrderVehicles: action((state, payload) => {
        state.veics_available = VehicleValidation({ my_vehicles: payload.my_vehicles, documents: payload.documents })
    }),

    // abre e fecha o modal gerar viagem
    open_order: false,
    setOpenOrder: action((state, payload) => {
        state.open_order = payload;
    }),

};

export default toOrderStore;
