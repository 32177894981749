import React, { Fragment } from "react";
import { useStoreState } from "easy-peasy";
import { makeStyles } from "@material-ui/core/styles";
import { Dialog, DialogTitle, FormControl, DialogContent } from "@material-ui/core";

import SizeFormUpdate from "./SizeFormUpdate"

const useStyles = makeStyles((theme) => ({
    form: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        width: "fit-content",
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
}));

export default function FormUpdate() {
    const classes = useStyles();
    const open_update_form = useStoreState((state) => state.sizeStore.open_update_form);

    return (
        <Fragment>
            <Dialog
                fullWidth={true}
                maxWidth={"md"}
                open={open_update_form}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="max-width-dialog-title">Edição do Cadastro de Restrições de Porte</DialogTitle>
                <DialogContent>
                    <form className={classes.form} noValidate>
                        <FormControl className={classes.formControl}>
                            <SizeFormUpdate />
                        </FormControl>
                    </form>
                </DialogContent>
            </Dialog>
        </Fragment>
    );
}
