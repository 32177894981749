import React from 'react';
import Cancel from '@material-ui/icons/Cancel';
import { makeStyles } from '@material-ui/core/styles';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { SwipeableDrawer, Container, IconButton } from '@material-ui/core';

import '../../../styles.css'
import MyStepper from './MyStepper.js';
import MyDropZoneArea from './MyDropZoneArea.js';
import YearMonthPicker from './SelectDateRange.js';

const useStyles = makeStyles((theme) => ({
    paper: {
        top: 80,
    },
    menuButton: {
        marginRight: theme.spacing(1),
    },
}));

export default function UploadDrawer() {
    const classes = useStyles();

    const setOpen = useStoreActions(actions => {
        return actions.InvoicesStore.setOpenUploadDrawer;
    });

    const open = useStoreState((state) => state.InvoicesStore.open_up_drawer);
    const activeStep = useStoreState((state) => state.InvoicesStore.activeStep);

    return (
        <SwipeableDrawer
            anchor={"bottom"}
            onOpen={() => console.log("open")}
            onClose={() => {
                setOpen(false)
            }}
            open={open}
            classes={{ paper: classes.paper }}
        >
            < Container maxWidth={false} spacing={2}>

                <IconButton
                    edge="start"
                    className={classes.menuButton}
                    aria-label="menu"
                    onClick={() => setOpen(false)} >
                    <Cancel />
                </IconButton>

                <MyStepper
                />

                {activeStep === 0 ?
                    <div className="center_DataRef">
                        <YearMonthPicker />
                    </div>
                    : null
                }

                {activeStep === 1 ?
                    <div className="center_DataRef">
                        <MyDropZoneArea />
                    </div>
                    : null
                }

            </Container>
        </SwipeableDrawer>
    );
}
