import PropTypes from 'prop-types';
import { useSnackbar } from "notistack";
import MaterialTable from "material-table";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState, useRef } from "react";
import { Fab, Tooltip, Container, Button } from "@material-ui/core";

import api from "../../../../../../services/api";

const useStyles = makeStyles((theme) => ({
    fab: {
        position: "absolute",
        bottom: theme.spacing(4),
        right: theme.spacing(4),
    },
}));

export default function Table(props) {
    const tableRef = useRef();
    const classes = useStyles();
    const snack = useRef()
    const { enqueueSnackbar } = useSnackbar();
    snack.current = enqueueSnackbar

    const [users, setUsers] = useState([]);

    const state = {
        columns: [
            { title: "Nome Completo", field: "nome_completo" },
            { title: "Email", field: "email" },
            { title: "Empresa", field: "empresa" },
            { title: "Status", field: "status" },
            { title: "Tipo", field: "tipo" },
            { title: "identificador", field: "_id" },
        ],
    };

    const { reload } = props
    useEffect(() => {
        function fetchData() {
            api.get(`/utils/users/admin`)
                .then((res) => {
                    let users = []
                    for (let user of res.data) {
                        let aux = {
                            "_id": user._id,
                            "tipo": user.tipo,
                            "email": user.email,
                            "status": user.status,
                            "empresa": user.companyId.apelido,
                            "nome_completo": user.nome_completo,

                            "criado_em": user.criado_em,
                            "criado_por": user.criado_por,
                            "editado_em": user.editado_em,
                            "editado_por": user.editado_por,
                        }
                        users.push(aux)
                    }
                    setUsers(users);
                })
                .catch((error) => {
                    console.log(error);
                    snack.current("Falha ao buscar os usuários", { variant: 'error' })
                });

            api.get(`/admin/company`)
                .then((res) => {
                    var companies = [{ label: '', value: '' }]
                    for (let company of res.data) {
                        let aux = {
                            label: company.apelido,
                            value: company._id
                        }
                        companies.push(aux)
                    }
                    props.setCompanies(companies)
                })
                .catch((error) => {
                    console.log(error);
                    snack.current("Falha ao buscar os empresas", { variant: 'error' })
                });
        }
        fetchData();
    }, [reload]);

    return (
        <Container maxWidth="xl" spacing={2}>
            <Tooltip title={<span>Inserir Novo Usuário</span>}>
                <Fab color="primary" aria-label="add" className={classes.fab} onClick={() => { props.setOpen(true); props.setData(null); }}>
                    <AddIcon />
                </Fab>
            </Tooltip>
            <MaterialTable
                tableRef={tableRef}
                title="Usuários Optcargo"
                columns={state.columns}
                data={users}
                options={{
                    add: true,
                    search: true,
                    pageSize: 10,
                    exportButton: false,
                    emptyRowsWhenPaging: false,
                    pageSizeOptions: [1, 5, 10, 20, users.length],

                    padding: "dense",
                    headerStyle: {
                        backgroundColor: "#01579b",
                        textAlign: "center",
                        color: "#FFF",
                    },
                    cellStyle: {
                        textAlign: "center",
                    },
                    rowStyle: rowData => ({
                        backgroundColor: (rowData.tableData.id % 2 === 0 ? '#fff' : '#f5f5f5')
                    }),
                }}
                localization={{
                    body: {
                        emptyDataSourceMessage: "",
                        addTooltip: "adicionar novo usuário",
                        deleteTooltip: "excluir",
                        editRow: { deleteText: "Deseja excluir o usuário?" },
                    },

                    header: {
                        actions: "Opções",
                    },

                    toolbar: {
                        searchTooltip: "Pesquisar",
                        searchPlaceholder: "Pesquisar",
                        exportTitle: "exportar",
                        nRowsSelected: "{0} Usuários(s) selecionados",
                    },
                    pagination: {
                        labelRowsSelect: "",
                        labelDisplayedRows: "{count} de {from}-{to}",
                        firstTooltip: "Primeira página",
                        previousTooltip: "Página anterior",
                        nextTooltip: "Próxima página",
                        lastTooltip: "Última página",
                    },
                }}
                components={{
                    Action: props_ => (
                        <Tooltip title={<span> Editar </span>} >
                            <Button
                                onClick={() => {
                                    props.setData(props_.data)
                                    props.setOpen(true)
                                }}
                                color="primary"
                                size="small"
                            >
                                <EditIcon />
                            </Button>
                        </Tooltip>
                    ),
                }}
                editable={{
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve) => {
                            setTimeout(() => {
                                resolve();
                            }, 1000);
                        }),
                }}
            />
        </Container>
    );
}

Table.propTypes = {
    reload: PropTypes.bool,
    setOpen: PropTypes.func,
    setReload: PropTypes.func,
    setCompanies: PropTypes.func,
};
