import React from "react";
import PropTypes from 'prop-types';
import { Popup } from "react-leaflet";
import Icon from "@material-ui/core/Icon";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

export default function PopupCentroDistribuicao(props) {
    return (
        <Popup>
            <h2> Centro Distribuição </h2>
            {props.veic.possui_restr === "sim" ? (
                <p className="obs">
                    <Icon>block</Icon> &quot;Veículo possui restrição&quot;
                </p>)
                : null
            }
            <Table size="small">
                <TableBody>
                    <TableRow>
                        <TableCell>Placa</TableCell>
                        <TableCell>{props.veic.placa}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Horário saída CD</TableCell>
                        <TableCell>{props.veic.horario_saida_cd_format}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Horário retorno CD</TableCell>
                        <TableCell>{props.veic.horario_retorno_cd_format}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Tempo Total Viagem</TableCell>
                        <TableCell>{props.veic.tempo_viagem_format}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Maior Peso da Carga durante a Viagem</TableCell>
                        <TableCell>{props.veic.maior_peso_carga_kg}&nbsp;kg</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Maior Taxa de Ocupação durante a Viagem</TableCell>
                        <TableCell>{props.veic.maior_taxa_ocup}%</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Capacidade do Veículo</TableCell>
                        <TableCell>{props.veic.capacidade_kg}&nbsp;kg</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Custo Diária + Km</TableCell>
                        <TableCell>R$&nbsp;{props.veic.custo_diaria_mais_km} </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Custo Pedágios</TableCell>
                        <TableCell>R$&nbsp;{props.veic.custo_pedagio} </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Custo Total Rota</TableCell>
                        <TableCell>R$&nbsp;{props.veic.custo_total_reais} </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Tamanho da Rota</TableCell>
                        <TableCell>{props.veic.tam_viagem_km}&nbsp;km</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Popup>
    );
}

PopupCentroDistribuicao.propTypes = {
    veic: PropTypes.object,
};
