// https://recharts.org/en-US/guide
import React, { useEffect } from 'react';
import {
    Bar,
    XAxis,
    YAxis,
    Legend,
    Tooltip,
    BarChart,
    CartesianGrid,
} from 'recharts';

// const histogram_data = [
//     {
//         Fatura: 'Page A',
//         Pedágios: 2400,
//         Vale_Pedágios: 2400,
//     },
//     {
//         Fatura: 'Page B',
//         Pedágios: 1398,
//         Vale_Pedágios: 2210,
//     },
//     {
//         Fatura: 'Page C',
//         Pedágios: 9800,
//         Vale_Pedágios: 2290,
//     },
// ];

export default function InvoicesHistogram(props) {
    const { histogram_data, y_label } = props

    return (
        <BarChart
            width={750 * window.innerWidth / 1280}
            height={450 * window.innerHeight / 980}
            data={histogram_data}
            margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
            }}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="Fatura" />
            <YAxis
                type="number"
                width={180}
                label={{ value: y_label, angle: -90, position: 'center' }} />
            <Tooltip />
            <Legend />
            <Bar maxBarSize={50} dataKey="Pedágios" stackId="a" fill="#4AC5BB" />
            <Bar maxBarSize={50} dataKey="Vale_Pedágios" stackId="a" fill="#c4a22b" />
        </BarChart>
    );
}
