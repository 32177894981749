import { action } from 'easy-peasy';

const VehicleTrackingStore = {
    open_map: false,
    setOpenMap: action((state, _) => {
        state.open_map = !state.open_map
    }),

    open_cluster: true,
    setOpenCluster: action((state, _) => {
        state.open_cluster = !state.open_cluster
    }),

    reload: false,
    setReload: action((state, _) => {
        state.reload = !state.reload
    }),

    positions: [],
    setPositions: action((state, payload) => {
        for (let pos of payload) {
            pos["data"] = new Date(pos["dataPacote"]).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })
        }
        state.positions = payload
    }),

    setUpdatePositions: action((state, payload) => {
        for (let pos of payload.new_pos) {
            // format date
            pos["data"] = new Date(pos["dataPacote"]).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })
            // search position in table by index 
            let my_pos = payload.old_pos.find(item => item._id === pos._id);
            // update information
            my_pos["data"] = pos["data"]
            my_pos["lat"] = pos["lat"]
            my_pos["lon"] = pos["lon"]
            state.positions = payload.old_pos
            // update map information
            var map_positions = []
            for (let pos of payload.old_pos) {
                if (pos.tableData.checked === true) {
                    map_positions.push(pos)
                }
            }
            state.positions_map = map_positions
        }
    }),

    positions_map: [],
    center: [-22.977213, -47.101165], // centro do mapa
    setPositionsMap: action((state, payload) => {
        // abre o mapa se estiver fechado
        if (state.positions_map.length === 0 && !state.open_map && payload.length > 0) {
            state.open_map = !state.open_map
            state.center = [payload[0].lat, payload[0].lon]
        }
        // fecha o mapa se estiver aberto
        if (payload.length === 0 && state.open_map) {
            state.open_map = !state.open_map
        }
        state.positions_map = payload
    }),

};
export default VehicleTrackingStore;
