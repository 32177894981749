import PropTypes from 'prop-types';
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState, useRef, useEffect } from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import { Button, DialogActions, Dialog, DialogTitle, FormControl, DialogContent, Typography } from "@material-ui/core";

import Adress from "./Components/Adress.js";
import CreatedBy from "./Components/CreatedBy";
import MyMap from "./Components/MyMap/MyMap.js";
import BasicInfo from "./Components/BasicInfo.js";

import api from "../../../../../../services/api.js";

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    form: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        width: "fit-content",
    },
    formControl: {
        marginTop: theme.spacing(1),
        minWidth: 70,
    },
}));

export default function DocumentForm(props) {
    const classes = useStyles();

    const snack = useRef()
    const { enqueueSnackbar } = useSnackbar();
    snack.current = enqueueSnackbar

    const my_vehicles = useRef()
    my_vehicles.current = useStoreState((state) => state.VeicsDocsStore.my_vehicles);

    //ativa e desativa o loading abaixo da appbar
    const setLoading = useRef()
    setLoading.current = useStoreActions(actions => {
        return actions.globalStore.setLoading;
    });

    const setReload = useRef()
    setReload.current = useStoreActions(actions => {
        return actions.VeicsDocsStore.setReload;
    });

    const [values, setValues] = useState({
        "_id": "",
        "cep": "",
        "pais": "",
        "placa": "",
        "cidade": "",
        "estado": "",
        "bairro": "",
        "id_doc": "",
        "TripId": "",
        "lat_doc": '',
        "lon_doc": '',
        "ajuste": true,
        "numero": null,
        "documento": "",
        "peso_kg": null,
        "logradouro": "",
        "complemento": "",
        "geocoder": false,
        "tipo": "ENTREGA",
        "razao_social": "",
        "valor_reais": null,
        "prazo": new Date(),
    });

    const [update, setUpdate] = useState(false) // true in edit mode
    var { data } = props
    useEffect(() => {
        if (data) {
            setValues(data)
            setUpdate(true)
        }
    }, [data])

    async function AddNew() {
        setLoading.current(true)
        snack.current("Adicionado, aguarde...", { variant: 'info' })
        var new_doc = {
            "cep": values.cep,
            "tipo": values.tipo,
            "pais": values.pais,
            "prazo": values.prazo, // data e hora
            "numero": values.numero,
            "cidade": values.cidade,
            "estado": values.estado,
            "bairro": values.bairro,
            "dataProgr": localStorage.getItem("dataProgr"),
            "geocoder": values.geocoder,
            "documento": values.documento,
            "logradouro": values.logradouro,
            "complemento": values.complemento,
            "peso_kg": Number(values.peso_kg),
            "lat_doc": Number(values.lat_doc),
            "lon_doc": Number(values.lon_doc),
            "razao_social": values.razao_social,
            "valor_reais": Number(values.valor_reais),
            "companyId": localStorage.getItem("companyId"),
            "affiliateId": localStorage.getItem("affiliateId"),
        }
        api.post(`/progr/doc`, { new_doc })
            .then(() => {
                props.setOpen(false)
                snack.current("Documento adicionado!", { variant: 'success' })
                setReload.current()
                setLoading.current(false)
            })
            .catch((error) => {
                setLoading.current(false)
                setReload.current()
                try {
                    snack.current(error.response.data.message, { variant: 'error' })
                } catch {
                    snack.current("Falha ao adicionar o Documento!", { variant: 'error' })
                }
            });
    }

    async function Update() {
        let TripId = "vazio"
        var placa = ""
        if (values.TripId !== "" && values.TripId) {
            TripId = values.TripId
            let aux = my_vehicles.current.filter(x => JSON.stringify(x._id) === JSON.stringify(TripId))
            placa = aux[0].placa
        }

        var ordem = ""
        if (values.ordem === "" || !values.ordem) {
            ordem = ""
        } else {
            ordem = Number(values.ordem)
        }

        var edit_doc = {
            "placa": placa,
            "ordem": ordem,
            "TripId": TripId,
            "_id": values._id,
            "cep": values.cep,
            "pais": values.pais,
            "tipo": values.tipo,
            "prazo": values.prazo, // data e hora
            "numero": values.numero,
            "cidade": values.cidade,
            "estado": values.estado,
            "bairro": values.bairro,
            "documento": values.documento,
            "logradouro": values.logradouro,
            "complemento": values.complemento,
            "razao_social": values.razao_social,
            "geocoder": values.geocoder, // true ou false
            "peso_kg": Number(values.peso_kg),
            "lat_doc": Number(values.lat_doc),
            "lon_doc": Number(values.lon_doc),
            "valor_reais": Number(values.valor_reais),
        }
        api.put(`/progr/doc`, { edit_doc })
            .then(() => {
                props.setOpen(false)
                snack.current("Documento editado", { variant: 'success' })
                setReload.current()

            })
            .catch((error) => {
                console.log(error)
                snack.current("Falha ao editar o Documento", { variant: 'error' })
                setReload.current()
            });
    }
    return (
        <Dialog
            fullWidth={true}
            maxWidth={"lg"}
            open={props.open}
            onClose={() => props.setOpen(false)}
            aria-labelledby="max-width-dialog-title"
        >
            <DialogTitle id="max-width-dialog-title" >
                <Typography variant="h5" align="center"> Cadastro de Documentos </Typography>
            </DialogTitle>
            <DialogContent>
                <form className={classes.form} noValidate>
                    <FormControl className={classes.formControl}>
                        <DialogContent className={classes.root}>
                            <BasicInfo
                                values={values}
                                update={update}
                                setValues={setValues}
                            />
                            <div className="rowMap">
                                <MyMap values={values} />
                                <Adress values={values} setValues={setValues} update={update} snack={snack.current} />
                            </div>
                            <CreatedBy values={values} setValues={setValues} />
                        </DialogContent >
                        <DialogActions>
                            <Button
                                disabled={(
                                    values.peso_kg <= 0 ||
                                    !values.lat_doc ||
                                    !values.lon_doc ||
                                    values.lat_doc > 5.53 ||
                                    values.lat_doc < -34.09 ||
                                    values.lon_doc > -33.79 ||
                                    values.lon_doc < -74.62 ||
                                    values.valor_reais <= 0
                                ) ? true : false}
                                onClick={() => { update === true ? Update() : AddNew() }}
                                color="primary"
                            >
                                Salvar
                            </Button>
                            <Button onClick={() => { props.setOpen(false) }} color="primary"> Cancelar </Button>
                        </DialogActions>
                    </FormControl>
                </form>
            </DialogContent>
        </Dialog>
    );
}

DocumentForm.propTypes = {
    open: PropTypes.bool,
    data: PropTypes.object,
    setOpen: PropTypes.func,
};
