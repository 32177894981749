import PropTypes from 'prop-types';
import { useSnackbar } from "notistack";
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useEffect, useRef } from "react";
import { Button, DialogActions, DialogContent } from "@material-ui/core";

import BasicInfo from './BasicInfo.js'
import CreatedBy from './CreatedBy.js'
import api from "../../../../../../../services/api";

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
}));

export default function FormStore(props) {
    const classes = useStyles();
    const location = useLocation();

    const snack = useRef()
    const { enqueueSnackbar } = useSnackbar();
    snack.current = enqueueSnackbar

    const [values, setValues] = useState({
        email: '',
        tipo: '',
        status: '',
        empresa: '',
        criado_em: '',
        criado_por: '',
        editado_em: '',
        editado_por: '',
        nome_completo: '',
    });

    const [update, setUpdate] = useState(false) // true in edit mode
    const my_props = useRef(props)
    useEffect(() => {
        if (my_props.current.data) {
            setValues(my_props.current.data)
            setUpdate(true)
        }
    }, [])

    // useEffect(() => { checkFields() });

    // function checkFields() {
    //     let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //     let result = re.test(String(email).toLowerCase());
    //     setBtnHabilitarSalvar(nome_completo !== "" && email !== "" && result !== false);
    // }

    async function AddNew() {
        snack.current("adicionado, aguarde.", { variant: 'info' })
        let my_company = props.companies.filter(x => x.label === values.empresa)
        var new_user = {
            "tipo": values.tipo,
            "email": values.email,
            "status": values.status,
            "companyId": my_company[0].value,
            "nome_completo": values.nome_completo,
            "url": document.URL.toString().split(location.pathname)[0]
        }
        api.post(`/utils/users`, { new_user })
            .then(() => {
                props.setReload(currentIsOpen => !currentIsOpen)
                props.setOpen(false)
                snack.current(`usuário criado, foi enviado um email para ${values.email}!`, { variant: 'success' })
            })
            .catch((error) => {
                snack.current("Falha ao adicionar usuário.", { variant: 'error' })
            });
    }

    async function Update() {
        let my_company = props.companies.filter(x => x.label === values.empresa)
        var update_user = {
            '_id': values._id,
            "tipo": values.tipo,
            "email": values.email,
            "status": values.status,
            "companyId": my_company[0].value,
            "nome_completo": values.nome_completo,
        }
        api.put(`/utils/users`, { update_user })
            .then(() => {
                props.setReload(currentIsOpen => !currentIsOpen)
                props.setOpen(false)
                snack.current("Usuário editado!", { variant: 'success' })
            })
            .catch((error) => {
                snack.current("Falha ao atualizar usuário.", { variant: 'error' })
            });
    }

    return (
        <>
            <DialogContent className={classes.root}>
                <BasicInfo values={values} setValues={setValues} companies={props.companies} />
                <CreatedBy values={values} setValues={setValues} />
            </DialogContent >
            <DialogActions>
                <Button
                    // disabled={(
                    //     values.contatos.length > 0)
                    //     ? false : true}
                    onClick={() => { update === true ? Update() : AddNew() }}
                    color="primary">
                    Salvar
                </Button>
                <Button onClick={() => { props.setOpen(false) }} color="primary">
                    Cancelar
                </Button>
            </DialogActions>
        </>
    )
}

FormStore.propTypes = {
    companies: PropTypes.array,
    setOpen: PropTypes.func,
    setReload: PropTypes.func
};
