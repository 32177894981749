import PropTypes from 'prop-types';
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Dialog, DialogTitle, FormControl, DialogContent, Typography } from "@material-ui/core";

import FormStore from "./Components/FormStore.js"

const useStyles = makeStyles((theme) => ({
    form: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        width: "fit-content",
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 100,
    },
}));

export default function RegisterStore(props) {
    const classes = useStyles();

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"md"}
            open={props.open}
            onClose={() => props.setOpen(false)}
            aria-labelledby="max-width-dialog-title"
        >
            <DialogTitle id="max-width-dialog-title">
                <Typography variant="h5" align="center">Cadastro de Usuário</Typography>
            </DialogTitle>
            <DialogContent>
                <form className={classes.form} noValidate>
                    <FormControl className={classes.formControl}>
                        <FormStore setOpen={props.setOpen} setReload={props.setReload} data={props.data} companies={props.companies} />
                    </FormControl>
                </form>
            </DialogContent>
        </Dialog>
    );
}

RegisterStore.propTypes = {
    open: PropTypes.bool,
    companies: PropTypes.array,
    setOpen: PropTypes.func,
    setReload: PropTypes.func,
};
