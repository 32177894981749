import PropTypes from 'prop-types';
import { useSnackbar } from "notistack";
import { useStoreActions } from "easy-peasy";
import React, { useRef, Fragment } from "react";
import DeleteIcon from '@material-ui/icons/Delete';
import { Tooltip, Button } from "@material-ui/core";

import api from "../../../../../services/api.js";

export default function DeleteVeic(props) {
    const snack = useRef()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    snack.current = enqueueSnackbar
    const closeSnackbarRef = useRef()
    closeSnackbarRef.current = closeSnackbar

    // atualiza a tabela de documentos
    const setReload = useStoreActions(actions => {
        return actions.VeicsDocsStore.setReload;
    });

    // limpa os veiculos plotados no mapa
    const plotVehicles = useStoreActions(actions => {
        return actions.mapStore.plotVehicles;
    });

    // limpa os documentos plotados no mapa
    const plotDocuments = useStoreActions(actions => {
        return actions.mapStore.plotDocuments;
    });

    async function DeleteVeic_(key) {
        closeSnackbarRef.current(key)
        api.delete(`/import/veics/${props.veic._id}`)
            .then(() => {
                snack.current(`Viagem excluída`, { variant: 'success' })
                plotVehicles([])
                plotDocuments([])
                setReload()
            })
            .catch((error) => {
                console.log(error)
                snack.current(`Falha ao excluir viagem!`, { variant: 'error' })
                plotVehicles([])
                plotDocuments([])
            })
    }

    const action = key => (
        <Fragment>
            <Button onClick={() => { DeleteVeic_(key) }}>
                Sim
            </Button>
            <Button onClick={() => { closeSnackbarRef.current(key) }}>
                Não
            </Button>
        </Fragment>
    );

    function confirmationWindow(placa) {
        snack.current(`Deseja remover o veículo ${placa} da programação?`, {
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
            },
            variant: 'info',
            persist: true,
            preventDuplicate: true,
            action,
        })
    }

    function VerifyDel() {
        if (props.veic.n_docs > 0) {
            snack.current(`Veículo possui documento associado.`, { variant: 'error' })
        } else {
            confirmationWindow(props.veic.placa)
        }
    }

    return (
        <Tooltip title={<span style={{ fontSize: 15 }}> Excluir </span>} >
            <Button
                onClick={() => {
                    VerifyDel()
                }}
                color="primary"
                style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px' }}
            >
                <DeleteIcon fontSize="small" />
            </Button>
        </Tooltip>
    );
}

DeleteVeic.propTypes = {
    veic: PropTypes.object,
};
