import React from "react";
import PropTypes from 'prop-types';
import AddIcon from "@material-ui/icons/Add";
import { Fab, Tooltip } from "@material-ui/core";

export default function AddUser(props) {

    return (
        <Tooltip title={<span style={{ fontSize: 15 }}>Inserir Usuário</span>}>
            <Fab
                style={{
                    margin: 10,
                    maxWidth: 45,
                    minWidth: 45,
                    maxHeight: 45,
                    minHeight: 45,
                }}
                color="primary"
                aria-label="add"
                onClick={() => {
                    props.setOpen(true); props.setData(null);
                }}
            >
                <AddIcon />
            </Fab>
        </Tooltip>
    );
}

AddUser.propTypes = {
    setData: PropTypes.func,
    setOpen: PropTypes.func,
};
