import L from 'leaflet';
import PropTypes from 'prop-types';
import ReactDOMServer from 'react-dom/server';
import { Marker, Polyline } from 'react-leaflet';
import React, { useState, useEffect, useRef } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import MarkerClusterGroup from 'react-leaflet-markercluster';

import '../../../styles.css';
import IconCD from './IconCD.js';
import IconResults from '../../../../../icons/Results';
import PopupDocumentoResult from '../../../../../popups/DocumentoResult';
import PopupCentroDistribuicaoResult from '../../../../../popups/CentroDistribuicaoResult';

export default function Results(props) {
    const setCenterRef = useRef()
    setCenterRef.current = props.setCenter

    const open_cluster = useStoreState((state) => state.mapStore.open_cluster);
    const veics = useStoreState((state) => state.ResultStore.veics);
    const docs = useRef()
    docs.current = useStoreState((state) => state.ResultStore.docs);

    const setOpenMap = useStoreActions(actions => {
        return actions.mapStore.setOpenMap;
    });

    function getDarkColor() {
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += Math.floor(Math.random() * 10);
        }
        return color;
    }

    const [results, setResults] = useState([]);
    useEffect(() => {
        async function fetchData_() {
            if (typeof (veics) !== "undefined") {
                if (veics.length > 0) {
                    setCenterRef.current([veics[0].path[0][0], veics[0].path[0][1]]);
                    for (let veic of veics) {
                        veic["docs"] = docs.current.filter(x => JSON.stringify(x.TripId) === JSON.stringify(veic._id))
                        var aux_color = getDarkColor()
                        for (var doc of veic.docs) {
                            doc["color"] = aux_color;
                        }
                        veic["color"] = aux_color
                    }
                    var marker_g = [];
                    for (let veic of veics) {
                        var my_markers = veic.docs.map((doc, cont) => (
                            <div key={cont}>
                                {(() => {

                                    var my_icon = L.divIcon({
                                        className: "custom-icon",
                                        html: ReactDOMServer.renderToString(<IconResults ordem={doc.ordem} tipo={doc.tipo} doc={doc} veic={veic} />),
                                    });

                                    if (doc.tipo === "ENTREGA") {
                                        return (
                                            <Marker icon={my_icon} key={cont} position={[doc.lat_doc, doc.lon_doc]}>
                                                <PopupDocumentoResult doc={doc} placa={veic.placa} />
                                            </Marker>
                                        );
                                    } else if (doc.tipo === "COLETA") {
                                        return (
                                            <Marker icon={my_icon} key={cont} position={[doc.lat_doc, doc.lon_doc]}>
                                                <PopupDocumentoResult doc={doc} placa={veic.placa} />
                                            </Marker>
                                        );
                                    }
                                })()}
                            </div>
                        ));

                        if (veic.path.length > 0) {
                            var my_icon = L.divIcon({
                                className: "custom-icon",
                                html: ReactDOMServer.renderToString(<IconCD id={0} veic={veic} />),
                            });
                            var aux_path = (
                                <Marker icon={my_icon} key={0} position={[veic.path[0][0], veic.path[0][1]]}>
                                    <PopupCentroDistribuicaoResult veic={veic} />
                                    <Polyline key={0} color={veic["color"]} positions={veic.path} weight={2} />
                                </Marker>
                            )
                            my_markers.push(aux_path)
                        }
                        marker_g.push(my_markers);
                    }
                    setResults(marker_g);
                    setOpenMap("open")
                }
                else {
                    setResults([]);
                    setOpenMap("close")
                }
            }

        }
        fetchData_();
    }, [veics]);


    return (
        <>
            {!open_cluster && <>{results} </>}
            {open_cluster && <MarkerClusterGroup className={"markercluster-map"} id={0}> {results} </MarkerClusterGroup>}
        </>
    );
}

Results.propTypes = {
    setCenter: PropTypes.func,
};
