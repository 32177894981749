import Cookies from 'js-cookie';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import {
    Card,
    Grid,
    Button,
    Checkbox,
    TextField,
    FormGroup,
    Typography,
    IconButton,
    CardContent,
    FormControl,
    LinearProgress,
    InputAdornment,
    FormControlLabel,
} from '@material-ui/core';

import './styles.css';
import api from '../../services/api';
import optlogo from '../../assets/optlogo.png';
import logo from '../../assets/locus_invert.png';

export default function Login() {
    localStorage.setItem("auth_provisoria", "false");
    const auth = Cookies.get("is_authenticated");
    const history = useHistory();
    if (auth === "true") {
        if (localStorage.getItem("acesso") === 'api') history.push("/users");
        else history.push("/dash");
    }

    const [email, setEmail] = useState("");
    const [senha, setSenha] = useState("");
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [verSenhaAtual, setVerSenhaAtual] = useState(false);
    const [manter_conectado, setManterConectado] = useState(false);

    async function loginSubmit(event) {
        setLoading(true)
        event.preventDefault();
        await api.post("/utils/sessions", { email, senha })
            .then((response) => {
                localStorage.setItem("user", response.data._id);
                localStorage.setItem("email", email);
                localStorage.setItem("tipo", response.data.tipo);
                localStorage.setItem("avatar", response.data.avatar);
                localStorage.setItem("nome_completo", response.data.nome_completo);
                localStorage.setItem("empresa", response.data.empresa_tms);
                localStorage.setItem("companyId", response.data.companyId);
                localStorage.setItem("acesso", response.data.plano_acesso); // acesso pode ser api//site
                localStorage.setItem("affiliateId", response.data.affiliateId);
                localStorage.setItem("nome_filial", response.data.nome_filial);
                localStorage.setItem("dataProgr", response.data.dataProgr);
                localStorage.setItem("config", response.data.config);
                Cookies.set("x-access-token", response.data.AuthToken, { expires: 100 });

                if (manter_conectado === true) {
                    console.log("Usuário autenticado por 100 dias");
                    Cookies.set("is_authenticated", "true", { expires: 100 });
                } else {
                    console.log("autenticação provisória");
                    Cookies.set("is_authenticated", "true", { expires: 0 });
                    localStorage.setItem("auth_provisoria", "true");
                }

                if (response.data.plano_acesso === 'api') history.push("/users")
                else history.push("/dash")
            })
            .catch((error) => {
                if (!error.response) {
                    enqueueSnackbar("Servidor não conectado!", { variant: 'error' })
                }
                else if (error.response) {
                    enqueueSnackbar(error.response.data.message, { variant: 'info' })
                }
            })
        setLoading(false)
    }

    return (
        <div className="background_login">
            <Grid container spacing={0} alignItems="center" justify="center" direction="column" className="full-grid">
                <Card raised className="login-card">
                    <CardContent>
                        <form onSubmit={loginSubmit}>
                            <Typography gutterBottom>
                                <img className="login-logo" src={optlogo} alt="Optcargo" width="180" />
                            </Typography>
                            {loading && <LinearProgress />}
                            <FormGroup>
                                <FormControl margin="dense" fullWidth={true}>
                                    <TextField
                                        id="email"
                                        type="email"
                                        placeholder="Seu email"
                                        value={email}
                                        variant="outlined"
                                        onChange={(event) => setEmail(event.target.value)}
                                    />
                                </FormControl>
                                <FormControl margin="dense" fullWidth={true}>
                                    <TextField
                                        id="password"
                                        type={verSenhaAtual ? "text" : "password"}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        tabIndex="-1"
                                                        onClick={() => setVerSenhaAtual(!verSenhaAtual)}
                                                        onMouseDown={(event) => event.preventDefault()}
                                                    >
                                                        {verSenhaAtual ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        fullWidth
                                        label="Informe a senha atual"
                                        variant="outlined"
                                        onChange={(event) => setSenha(event.target.value)}
                                    />
                                </FormControl>
                            </FormGroup>
                            <FormGroup aria-label="position" row>
                                <FormControlLabel
                                    control={<Checkbox color="primary" onChange={(e) => setManterConectado(e.target.checked)} />}
                                    label="Manter-se conectado"
                                />
                                <Typography
                                    style={{
                                        display: "inline-flex",
                                        alignItems: "center",
                                        marginInlineStart: "auto",
                                    }}
                                    component="label"
                                    display="inline"
                                >
                                    <Link to="/recuperar-a-senha">
                                        <small>Esqueceu sua senha?</small>
                                    </Link>
                                </Typography>
                            </FormGroup>
                            <br />
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                fullWidth={true}
                                size="large"
                                className="btn-login"
                                disabled={(
                                    email === "" ||
                                    senha === "")
                                    ? true : false}
                            >Entrar</Button>
                        </form>
                        <br />
                    </CardContent>
                </Card>
                <footer>
                    <a href="/">
                        <img src={logo} alt="Locus" title="desenvolvido por Locus tecnologia" />
                    </a>
                </footer>
            </Grid>
        </div>
    );
}
