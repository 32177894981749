import React, { useState } from 'react';

import Table from './Table.js';
import VehicleForm from './Form/VehicleForm.js';

export default function Vehicles() {
    const [open, setOpen] = useState(false)
    const [data, setData] = useState(null)
    const [reload, setReload] = useState(false);
    const [affiliates, setAffiliates] = useState([]);

    return (
        <>
            < Table
                reload={reload}
                setOpen={setOpen}
                setData={setData}
                setReload={setReload}
                setAffiliates={setAffiliates}
            />

            {
                open ?
                    < VehicleForm
                        data={data}
                        open={open}
                        affiliates={affiliates}
                        setOpen={setOpen}
                        setReload={setReload}
                    />
                    : null
            }
        </>
    );
}
