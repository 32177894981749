import { useSnackbar } from "notistack";
import React, { useRef, Fragment } from "react";
import { Tooltip, Button, Fab } from "@material-ui/core";
import { useStoreActions, useStoreState } from 'easy-peasy';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import "../../../styles.css";
import api from "../../../../../../../services/api.js";

export default function DellMany() {
    const snack = useRef()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    snack.current = enqueueSnackbar
    const closeSnackbarRef = useRef()
    closeSnackbarRef.current = closeSnackbar

    //ativa e desativa o loading abaixo da appbar
    const setLoading = useRef()
    setLoading.current = useStoreActions(actions => {
        return actions.globalStore.setLoading;
    });
    // Seleção dos documentos a serem excluídos
    const setDelDocs = useRef()
    setDelDocs.current = useStoreActions(actions => {
        return actions.VeicsDocsStore.setDelDocs;
    });
    const del_docs = useStoreState((state) => state.VeicsDocsStore.del_docs);

    const setReload = useRef()
    setReload.current = useStoreActions(actions => {
        return actions.VeicsDocsStore.setReload;
    });

    async function Delete(key) {
        closeSnackbarRef.current(key)
        snack.current("Excluindo os documentos selecionados, aguarde.", { variant: 'info' })
        setLoading.current(true)
        var list_del_docs = []
        for (let doc of del_docs) {
            if (doc.ajuste === true) list_del_docs.push(doc._id)
        }

        api.put(`/progr/docs`, {
            del_docs: list_del_docs
        })
            .then((resp) => {
                setDelDocs.current([])
                setReload.current()
                snack.current(resp.data.message, { variant: 'success' })
            })
            .catch((error) => {
                try {
                    snack.current(error.response.data.message, { variant: 'error' })
                } catch (e) {
                    snack.current("Falha ao excluir.", { variant: 'error' })
                }
                console.log(error);
                setDelDocs.current([])
                setLoading.current(false)
                setReload.current()
            })
    }

    const action = key => (
        <Fragment>
            <Button onClick={() => { Delete(key) }}>
                Sim
            </Button>
            <Button onClick={() => { closeSnackbarRef.current(key) }}>
                Não
            </Button>
        </Fragment>
    );

    function confirmationWindow() {
        snack.current(`Deseja excluir os documentos selecionados?`, {
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
            },
            variant: 'info',
            persist: true,
            preventDuplicate: true,
            action,
        })
    }

    return (
        <Tooltip title={<span style={{ fontSize: 15 }}>Excluir os documentos</span>}>
            <Fab
                color="primary"
                aria-label="add"
                style={{
                    margin: 10,
                    maxWidth: 45,
                    minWidth: 45,
                    maxHeight: 45,
                    minHeight: 45,
                }}
                onClick={() => { confirmationWindow() }}
                disabled={del_docs.length === 0 ? true : false}
            >
                <DeleteForeverIcon />
            </Fab>
        </Tooltip>
    );
}
