import { action } from "easy-peasy"

const toolsStore = {
    // plot tools in map
    tools: [],
    center: [-22.977213, -47.101165],
    plotTools: action((state, obj) => {
        state.tools = obj;
        if (obj[0]) {
            state.center = [obj[obj.length - 1].area[0].lat, obj[obj.length - 1].area[0].lng]
        }
    }),

    // open store tools form
    area: [],
    open_store_form: false,
    setOpenStoreForm: action((state, payload) => {
        state.area = payload;
        state.open_store_form = !state.open_store_form;
    }),

    // opens and closes a toll update form
    open_update_form: false,
    update_form_data: {},
    setUpdateFormData: action((state, payload) => {
        state.update_form_data = payload;
        state.open_update_form = !state.open_update_form
    }),
};

export default toolsStore;
