import React from 'react';

import MyMap from './Map/MyMap.js';
import TollsOptcargoCalc from './TollsOptcargoCalc/TollsOptcargoCalc.js';

export default function TollsConference() {
    return (
        <>
            < TollsOptcargoCalc />
            < MyMap />
        </>
    );
}
