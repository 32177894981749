import { useSnackbar } from "notistack";
import MaterialTable from "material-table";
import EditIcon from '@material-ui/icons/Edit';
import Launch from '@material-ui/icons/Launch';
import { Button, Tooltip } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useEffect, useState, useRef, Fragment } from "react";

import "../styles.css";
import api from "../../../../../services/api";

export default function Table() {
    const snack = useRef()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    snack.current = enqueueSnackbar
    const closeSnackbarRef = useRef()
    closeSnackbarRef.current = closeSnackbar

    const [restricoes_porte, setRestricoesPorte] = useState([]);
    const [data_delete, setDataDelete] = useState(null);
    const data_deleteRef = useRef()
    data_deleteRef.current = data_delete

    const plotSizeRestrictionsRef = useRef()
    plotSizeRestrictionsRef.current = useStoreActions(actions => {
        return actions.sizeStore.plotSizeRestrictions;
    });

    // update form
    const setUpdateFormData = useRef()
    setUpdateFormData.current = useStoreActions(actions => {
        return actions.sizeStore.setUpdateFormData;
    });

    // link dialog
    const setOpenLinkDialogRef = useRef()
    setOpenLinkDialogRef.current = useStoreActions(actions => {
        return actions.sizeStore.setOpenLinkDialog;
    });
    const setLinkDialogRef = useRef()
    setLinkDialogRef.current = useStoreActions(actions => {
        return actions.sizeStore.setLinkDialogData;
    });

    const reload_size = useStoreState((state) => state.reloadStore.reload_size);

    const state = {
        columns: [
            {
                title: "Link dos decretos",
                field: "links_decretos",
                editable: false,
                render: (rowData) =>
                    rowData && (
                        <Button
                            style={{ color: rowData.links_decretos.length > 1 ? 'blue' : "red" }}
                            onClick={() => {
                                setLinkDialogRef.current(rowData.links_decretos)
                                setOpenLinkDialogRef.current(true)
                            }
                            }
                        >
                            <Launch />
                        </Button>
                    )
            },
            { title: "Decreto", field: "decreto" },
            { title: "Peso Mínimo (kg)", field: "pesomin" },
            { title: "Peso Máximo (kg)", field: "pesomax" },
            { title: "Altura Mínima (kg)", field: "alturamin" },
            { title: "Altura Máxima (kg)", field: "alturamax" },
            { title: "Comprim. Min (m)", field: "comprimentomin" },
            { title: "Comprim. Máx (m)", field: "comprimentomax" },
            { title: "Nº Mínimo de Eixos", field: "eixomin" },
            { title: "Nº Máximo de Eixos", field: "eixomax" },
            { title: "SEGUNDA - SEXTA", field: "horario_seg_sex" },
            { title: "SÁBADOS", field: "horario_sabados" },
            { title: "DOMINGOS E FERIADOS", field: "horario_dom_fer" },
            { title: "Cidade", field: "cidade" },
            { title: "Estado", field: "estado" },
            { title: "atualizado por", field: "email", editable: "never" },
            { title: "atualizado em:", field: "data_ultima_atualizacao", editable: "never" },
            { title: "Identificador", field: "_id", editable: "never" },
        ],
    };

    useEffect(() => {
        function fetchData() {
            api
                .get(`/admin/sizerestrictions`)
                .then((res) => {
                    setRestricoesPorte(res.data.reverse());
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        fetchData();
    }, [reload_size]);

    async function delete_size_restriction(key) {
        closeSnackbarRef.current(key)
        const dataDelete = [...restricoes_porte];
        const index = data_deleteRef.current.tableData.id;
        const del = dataDelete.splice(index, 1);
        const sizeId = del[0]._id
        api.delete(`/admin/sizerestrictions/${sizeId}`, {
            data: del,
        })
            .then(() => {
                setRestricoesPorte([...dataDelete]);
                snack.current(`Restrição de porte excluída com sucesso!`, { variant: 'success' })
            })
            .catch((error) => {
                console.log(error)
                console.log("Erro ao excluir uma restrição de porte")
                snack.current(`Falha ao excluir restrição de porte!`, { variant: 'error' })
            })
    }

    const action = key => (
        <Fragment>
            <Button onClick={() => { delete_size_restriction(key) }}>
                Sim
            </Button>
            <Button onClick={() => { closeSnackbarRef.current(key) }}>
                Não
            </Button>
        </Fragment>
    );

    function confirmationWindow(data) {
        setDataDelete(data)
        snack.current("Deseja excluir a restrição de porte?", {
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
            },
            variant: 'info',
            persist: true,
            preventDuplicate: true,
            action,
        })
    }

    const tableRef = useRef();
    return (
        <MaterialTable
            tableRef={tableRef}
            title="Restrições de Porte"
            columns={state.columns}
            data={restricoes_porte}
            options={{
                grouping: true,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [1, 5, 10, 20, 50, restricoes_porte.length],
                selection: true,
                search: true,
                add: true,
                padding: "dense",
                headerStyle: {
                    backgroundColor: "#01579b",
                    textAlign: "center",
                    color: "#FFF",
                },
                cellStyle: {
                    textAlign: "center",
                },
            }}
            onSelectionChange={(rows) => plotSizeRestrictionsRef.current(rows)}
            localization={{
                body: {
                    emptyDataSourceMessage: "",
                    deleteTooltip: "excluir",
                    editTooltip: "editar",
                    editRow: { deleteText: "Deseja excluir a restrição?" },
                },

                grouping: {
                    placeholder: "Arraste os cabeçalhos aqui para agrupar por",
                    groupedBy: "Agrupado por:",
                },

                header: {
                    actions: "Opções",
                },

                toolbar: {
                    searchTooltip: "Pesquisar",
                    searchPlaceholder: "Pesquisar",
                    exportTitle: "exportar",
                },
                pagination: {
                    labelRowsSelect: "",
                    labelDisplayedRows: "{count} de {from}-{to}",
                    firstTooltip: "Primeira página",
                    previousTooltip: "Página anterior",
                    nextTooltip: "Próxima página",
                    lastTooltip: "Última página",
                },
            }}
            components={{
                Action: props_ => (
                    <>
                        <Tooltip title={<span> Editar </span>} >
                            <Button
                                onClick={() => {
                                    setUpdateFormData.current(props_.data)
                                }}
                                color="primary"
                                size="small"
                            >
                                <EditIcon />
                            </Button>
                        </Tooltip>

                        <Tooltip title={<span> Excluir </span>} >
                            <Button
                                onClick={() => confirmationWindow(props_.data)}
                                color="primary"
                                size="small"
                            >
                                <DeleteIcon />
                            </Button>
                        </Tooltip>
                    </>
                ),
            }}
            editable={{
                onRowDelete: (_) =>
                    new Promise((resolve) => {
                        setTimeout(() => {
                            resolve();
                        }, 1000);
                    }),
            }}
        />
    );
}


