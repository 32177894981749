const finals = [
    { title: "par", value: "par" },
    { title: "impar", value: "impar" },
    { title: "seg", value: "seg" },
    { title: "ter", value: "ter" },
    { title: "qua", value: "qua" },
    { title: "qui", value: "qui" },
    { title: "sex", value: "sex" },
    { title: "sab", value: "sab" },
    { title: "dom", value: "dom" },
];
export default finals;
