import React, { useRef } from 'react';
import { useSnackbar } from 'notistack';
import { grey } from '@material-ui/core/colors';
import { DatePicker } from '@material-ui/pickers';
import { Button, Tooltip } from '@material-ui/core';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import api from "../../../../../services/api.js"

const materialTheme = createMuiTheme({
    palette: {
        primary: grey,
    },
});

export default function SelectDateRange() {
    const snack = useRef()
    const { enqueueSnackbar } = useSnackbar();
    snack.current = enqueueSnackbar

    //ativa e desativa o loading abaixo da appbar
    const setLoading = useRef()
    setLoading.current = useStoreActions(actions => { return actions.globalStore.setLoading });
    const setEnd = useStoreActions(actions => { return actions.PlanedTripsStore.setEnd });
    const setDocs = useStoreActions(actions => { return actions.PlanedTripsStore.setDocs });
    const setStart = useStoreActions(actions => { return actions.PlanedTripsStore.setStart });
    const setVeics = useStoreActions(actions => { return actions.PlanedTripsStore.setVeics });

    const end = useStoreState((state) => state.PlanedTripsStore.end);
    const start = useStoreState((state) => state.PlanedTripsStore.start);

    async function loadDocs() {
        setLoading.current(true)
        snack.current("Carregando, aguarde...", { variant: 'info' })
        let resp = await api.get(`/trips/plan/${start}/${end}`)
            .catch((error) => {
                console.log(error);
                snack.current("Falha ao carregar.", { variant: 'error' })
            });
        if (resp) {
            setVeics(resp.data.trips)
            setDocs(resp.data.docs)
        }
        setLoading.current(false)
    }

    return (
        <>
            {/* <h3>Selecione o intervalo: </h3> */}
            <ThemeProvider theme={materialTheme}>
                <DatePicker
                    style={{ margin: 10, marginTop: "1%" }}
                    size="small"
                    ampm={false}
                    label="Inicial"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    value={start}
                    onChange={date => {
                        setStart(date)
                    }}
                    onError={console.log}
                />

                <DatePicker
                    style={{ margin: 10, marginTop: "1%" }}
                    size="small"
                    ampm={false}
                    label="Final"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    value={end}
                    onChange={date => {
                        setEnd(date)
                    }}
                    onError={console.log}
                />
            </ThemeProvider>

            <Tooltip style={{ whiteSpace: 'nowrap', overflow: 'hidden' }} title={<span style={{ fontSize: 15 }}>  Consulte as Viagens em determinado período. </span>} >
                <Button
                    color="secundary"
                    variant="contained"
                    style={{ marginTop: "1%", marginLeft: "1%" }}
                    onClick={() => { loadDocs() }}
                >
                    Consultar
                </Button>
            </Tooltip>
        </>
    );
}
