import React from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'react-leaflet';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';

export default function PopupDocumento(props) {
    return (
        <Popup>
            <h2> {props.values.nome_filial} </h2>
            <Table size="small">
                <TableBody>
                    <TableRow>
                        <TableCell>Razão Social</TableCell>
                        <TableCell>{props.values.razao_social}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Status</TableCell>
                        <TableCell>{props.values.status}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Endereço</TableCell>
                        <TableCell>{props.values.logradouro}, {props.values.numero}, {props.values.cidade}, {props.values.estado}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>CEP</TableCell>
                        <TableCell>{props.values.cep}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell>Lat, Lon</TableCell>
                        <TableCell>
                            {props.values.lat}, {props.values.lng}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Popup>
    );
}

PopupDocumento.propTypes = {
    doc: PropTypes.object,
};
