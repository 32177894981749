import { useSnackbar } from "notistack";
import MaterialTable from "material-table";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useStoreActions, useStoreState } from "easy-peasy";
import { TextField, Button, Tooltip } from "@material-ui/core";
import React, { useEffect, useState, useRef, Fragment } from "react";

import "../styles.css";
import Ufs from "../../../../../utils/Ufs"
import api from "../../../../../services/api";

export default function Table() {
    const snack = useRef()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    snack.current = enqueueSnackbar
    const closeSnackbarRef = useRef()
    closeSnackbarRef.current = closeSnackbar

    const state = {
        columns: [
            { title: "nome", field: "nome" },
            { title: "concessionária", field: "concessionaria" },
            { title: "rodovia", field: "rodovia" },
            { title: "auto", field: "auto" },
            { title: "eixo", field: "eixo" },
            {
                title: "estado", field: "estado",
                lookup: Ufs.value,
                filtering: false,
                editComponent: props => (
                    <Autocomplete
                        id="estado"
                        value={{ title: props.value, value: props.value }}
                        options={Ufs}
                        autoComplete={true}
                        disableClearable
                        getOptionLabel={(option) => option.value}
                        onChange={(_, data) => {
                            props.onRowDataChange({
                                ...props.rowData,
                                estado: data.value
                            })
                        }}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                label=""
                                size="small"
                                placeholder='' />}
                    />
                )
            },
            { title: "atualizado por", field: "email", editable: "never" },
            { title: "atualizado em:", field: "data_ultima_atualizacao", editable: "never" },
            { title: "identificador", field: "_id", editable: "never" },
        ],
    };
    const [pedagios, setPedagios] = useState([]);
    const plotToolsRef = useRef()
    plotToolsRef.current = useStoreActions(actions => {
        return actions.toolsStore.plotTools;
    });
    const setUpdateFormData = useRef()
    setUpdateFormData.current = useStoreActions(actions => {
        return actions.toolsStore.setUpdateFormData;
    });

    const reload_tools = useStoreState((state) => state.reloadStore.reload_tools);
    const [data_delete, setDataDelete] = useState(null);
    const data_deleteRef = useRef()
    data_deleteRef.current = data_delete

    useEffect(() => {
        function fetchData() {
            api
                .get(`/admin/tolls`)
                .then((res) => {
                    setPedagios(res.data.reverse());
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        fetchData();
    }, [reload_tools]);

    async function delete_tools(key) {
        closeSnackbarRef.current(key)
        const dataDelete = [...pedagios];
        const index = data_deleteRef.current.tableData.id;
        const del = dataDelete.splice(index, 1);
        const tollId = del[0]._id
        api.delete(`/admin/tolls/${tollId}`)
            .then(() => {
                setPedagios([...dataDelete]);
                snack.current(`Praça de pedágio excluída com sucesso!`, { variant: 'success' })
            })
            .catch((error) => {
                console.log(error)
                console.log("Erro ao excluir o pedágio")
            })
    }

    const action = key => (
        <Fragment>
            <Button onClick={() => { delete_tools(key) }}>
                Sim
            </Button>
            <Button onClick={() => { closeSnackbarRef.current(key) }}>
                Não
            </Button>
        </Fragment>
    );

    function confirmationWindow(data) {
        setDataDelete(data)
        snack.current("Deseja excluir a praça de pedágio?", {
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
            },
            variant: 'info',
            persist: true,
            preventDuplicate: true,
            action,
        })
    }

    const tableRef = useRef();
    return (
        <MaterialTable
            tableRef={tableRef}
            title="Pedágios"
            columns={state.columns}
            data={pedagios}
            options={{
                add: true,
                search: true,
                pageSize: 10,
                grouping: true,
                selection: true,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [1, 5, 10, 20, 30, 40, 50, pedagios.length],

                padding: "dense",
                headerStyle: {
                    backgroundColor: "#01579b",
                    textAlign: "center",
                    color: "#FFF",
                },
                cellStyle: {
                    textAlign: "center",
                },
            }}
            onSelectionChange={(rows) => plotToolsRef.current(rows)}
            localization={{
                body: {
                    emptyDataSourceMessage: "",
                    deleteTooltip: "excluir",
                    editTooltip: "editar",
                    editRow: { deleteText: "Deseja excluir a praça de pedágio?" },
                },

                grouping: {
                    placeholder: "Arraste os cabeçalhos aqui para agrupar por",
                    groupedBy: "Agrupado por:",
                },

                header: {
                    actions: "Opções",
                },

                toolbar: {
                    searchTooltip: "Pesquisar",
                    searchPlaceholder: "Pesquisar",
                    exportTitle: "exportar",
                },
                pagination: {
                    labelRowsSelect: "",
                    labelDisplayedRows: "{count} de {from}-{to}",
                    firstTooltip: "Primeira página",
                    previousTooltip: "Página anterior",
                    nextTooltip: "Próxima página",
                    lastTooltip: "Última página",
                },
            }}
            components={{
                Action: props_ => (
                    <>
                        <Tooltip title={<span> Editar </span>} >
                            <Button
                                onClick={() => {
                                    setUpdateFormData.current(props_.data)
                                }}
                                color="primary"
                                size="small"
                            >
                                <EditIcon />
                            </Button>
                        </Tooltip>

                        <Tooltip title={<span> Excluir </span>} >
                            <Button
                                onClick={() => confirmationWindow(props_.data)}
                                color="primary"
                                size="small"
                            >
                                <DeleteIcon />
                            </Button>
                        </Tooltip>
                    </>
                ),
            }}
            editable={{
                onRowDelete: (_) =>
                    new Promise((resolve) => {
                        setTimeout(() => {
                            resolve();
                        }, 1000);
                    }),
            }}
        />
    );
}
