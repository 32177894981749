import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';

import '../../styles.css'
import { grey } from '@material-ui/core/colors';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const materialTheme = createMuiTheme({
    palette: {
        primary: grey,
    },
});

const tipos_veiculos = [
    { value: '', label: '' },
    { value: 'moto', label: 'Moto' },
    { value: 'onibus', label: 'Ônibus' },
    { value: 'passeio', label: 'Passeio' },
    { value: 'utilitario', label: 'Utilitário' },
    { value: '3/4', label: '3/4' },
    { value: 'vuc', label: 'VUC' },
    { value: 'toco', label: 'Toco' },
    { value: 'truck', label: 'Truck' },
    { value: 'cavalo', label: 'Cavalo' },
    { value: 'cavalo_trucado', label: 'Cavalo Trucado' },
];

const combustiveis = [
    { value: '', label: '' },
    { value: 'Diesel S10', label: 'Diesel S10' },
    { value: 'Diesel S500', label: 'Diesel S500' },
    { value: 'Gasolina', label: 'Gasolina' },
    { value: 'Álcool', label: 'Álcool' },
    { value: 'Flex', label: 'Flex' },
    { value: 'Eletricidade', label: 'Eletricidade' },
];

const categorias = [
    { value: '', label: '' },
    { value: 'Moto', label: 'Moto' },
    { value: 'Carro', label: 'Carro' },
    { value: 'Ônibus', label: 'Ônibus' },
    { value: 'Caminhão', label: 'Caminhão' },
];

const tipos_de_eixo = [
    { value: '', label: '' },
    { value: 'Rodagem Simples', label: 'Rodagem Simples' },
    { value: 'Rodagem Dupla', label: 'Rodagem Dupla' },
];


export default function VehicleType(props) {

    const handleChange = (event) => {
        props.setValues({
            ...props.values,
            [event.target.id]: event.target.value,
        });
    };

    return (
        <>
            <div className="title_info2">Informações do Veículo</div>
            <div className="fantasia">
                <div className="vehicle_type">
                    <TextField
                        select
                        size="small"
                        id="tipo_veiculo"
                        label="Tipo do Veículo"
                        variant="outlined"
                        onChange={handleChange}
                        SelectProps={{ native: true }}
                        value={props.values.tipo_veiculo}
                    >
                        {tipos_veiculos.map((option) => (
                            <option key={option.value} value={props.values.tipo_veiculo.value}>
                                {option.label}
                            </option>
                        ))}
                    </TextField>

                    <TextField
                        size="small"
                        id="marca"
                        variant="outlined"
                        label="Marca"
                        onChange={handleChange}
                        value={props.values.marca}
                    />
                    <ThemeProvider theme={materialTheme}>
                        <KeyboardDatePicker
                            id="ano_fabricacao"
                            size="small"
                            autoOk
                            variant="inline"
                            inputVariant="outlined"
                            label="Ano de Fabricação"
                            format="yyyy"
                            views={['year']}
                            value={props.values.ano_fabricacao}
                            InputAdornmentProps={{ position: "start" }}
                            onChange={date => {
                                props.setValues({
                                    ...props.values,
                                    ["ano_fabricacao"]: date,
                                });
                            }}
                            maxDateMessage="Data inválida"
                            minDateMessage="Data inválida"
                        />

                        <KeyboardDatePicker
                            id="ano_modelo"
                            size="small"
                            autoOk
                            variant="inline"
                            inputVariant="outlined"
                            label="Ano do Modelo"
                            format="yyyy"
                            views={['year']}
                            value={props.values.ano_modelo}
                            InputAdornmentProps={{ position: "start" }}
                            onChange={date => {
                                props.setValues({
                                    ...props.values,
                                    ["ano_modelo"]: date,
                                });
                            }}
                            maxDateMessage="Data inválida"
                            minDateMessage="Data inválida"
                        />
                    </ThemeProvider>
                    <TextField
                        select
                        size="small"
                        id="combustivel"
                        label="Combustível"
                        variant="outlined"
                        onChange={handleChange}
                        SelectProps={{ native: true }}
                        value={props.values.combustivel}
                    >
                        {combustiveis.map((option) => (
                            <option key={option.value} value={props.values.combustivel.value}>
                                {option.label}
                            </option>
                        ))}
                    </TextField>
                </div>
                <div className="basictt">
                    <TextField
                        select
                        required
                        size="small"
                        id="categoria"
                        label="Categoria do Veículo"
                        variant="outlined"
                        onChange={handleChange}
                        SelectProps={{ native: true }}
                        value={props.values.categoria}
                        error={(
                            props.values.categoria === "" &&
                            props.update === true)
                            ? true : false}
                        helperText={(
                            props.values.categoria === "" &&
                            props.update === true)
                            ? "selecione uma categoria" : ""}
                    >
                        {categorias.map((option) => (
                            <option key={option.value} value={props.values.categoria.value}>
                                {option.label}
                            </option>
                        ))}
                    </TextField>

                    <TextField
                        required
                        id="n_eixos"
                        type="number"
                        size="small"
                        variant="outlined"
                        label="Número de Eixos"
                        onChange={handleChange}
                        value={props.values.n_eixos}
                        error={props.values.n_eixos > 0 || props.values.n_eixos === '' ? false : true}
                        helperText={props.values.n_eixos > 0 || props.values.n_eixos === '' ? "" : "valor inválido"}
                    />

                    <TextField
                        select
                        required
                        size="small"
                        id="tipo_eixo"
                        label="Tipo de Eixo"
                        variant="outlined"
                        onChange={handleChange}
                        SelectProps={{ native: true }}
                        value={props.values.tipo_eixo}
                        error={(
                            props.values.tipo_eixo === "" &&
                            props.update === true)
                            ? true : false}
                        helperText={(
                            props.values.tipo_eixo === "" &&
                            props.update === true)
                            ? "selecione o tipo de eixo" : ""}
                    >
                        {tipos_de_eixo.map((option) => (
                            <option key={option.value} value={props.values.tipo_eixo.value}>
                                {option.label}
                            </option>
                        ))}
                    </TextField>

                    <TextField
                        required
                        type="number"
                        size="small"
                        id="altura"
                        variant="outlined"
                        label="Altura Total (m)"
                        onChange={handleChange}
                        value={props.values.altura}
                        error={props.values.altura > 0 || props.values.altura === '' ? false : true}
                        helperText={props.values.altura > 0 || props.values.altura === '' ? "" : "valor inválido"}
                    />

                    <TextField
                        required
                        type="number"
                        size="small"
                        id="largura"
                        variant="outlined"
                        label="Largura Total (m)"
                        onChange={handleChange}
                        value={props.values.largura}
                        error={props.values.largura > 0 || props.values.largura === '' ? false : true}
                        helperText={props.values.largura > 0 || props.values.largura === '' ? "" : "valor inválido"}
                    />

                    <TextField
                        required
                        type="number"
                        size="small"
                        id="comprimento"
                        variant="outlined"
                        onChange={handleChange}
                        label="Comprimento Total (m)"
                        value={props.values.comprimento}
                        error={props.values.comprimento > 0 || props.values.comprimento === '' ? false : true}
                        helperText={props.values.comprimento > 0 || props.values.comprimento === '' ? "" : "valor inválido"}
                    />
                </div>
            </div>
        </>
    )
}

VehicleType.propTypes = {
    values: PropTypes.object,
    setValues: PropTypes.func,
};
