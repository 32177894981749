import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import MaterialTable from 'material-table';
import React, { useEffect, useState, useRef } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';

import './styles.css';
import api from '../../../../services/api.js';
import AddOne from '../../../../buttons/AddOne.js';
import Delete from '../../../../buttons/Delete.js';
import Hidden from '../../../../buttons/Hidden.js';
import EditOne from '../../../../buttons/EditOne.js';
import EditMany from '../../../../buttons/EditMany.js';
import UploadSheet from '../../../../buttons/UploadSheet.js';
import DownloadSheet from '../../../../buttons/DownloadSheet.js';

export default function Table(props) {
    const {
        reload,
        setData,
        setOpen,
        setReload,
        setAffiliates,
    } = props

    const snack = useRef()
    const { enqueueSnackbar } = useSnackbar();
    snack.current = enqueueSnackbar

    const [vehicles, setVehicles] = useState([]);

    //ativa e desativa o loading abaixo da appbar
    const setLoading = useRef()
    setLoading.current = useStoreActions(actions => { return actions.globalStore.setLoading });
    const setSelectVeics = useRef()
    setSelectVeics.current = useStoreActions(actions => { return actions.VehicleStore.setSelectVeics });
    const setCollumns = useStoreActions(actions => { return actions.VehicleStore.setCollumns });

    const collumns = useStoreState((state) => state.VehicleStore.collumns);
    const select_veics = useStoreState((state) => state.VehicleStore.select_veics);

    const setReload_ = useRef()
    setReload_.current = setReload

    useEffect(() => {
        async function fecthData() {
            api.get(`/utils/user/config/config_vehicle_registration`)
                .then((resp) => {
                    let { data } = resp
                    if (data) setCollumns(data)
                })
                .catch((error) => {
                    console.log(error);
                    snack.current("Falha ao buscar as configurações", { variant: 'error' })
                });
        }
        fecthData();
    }, []);

    useEffect(() => {
        setLoading.current(true)
        async function fetchData() {
            api.get(`/register/affiliate`)
                .then((res) => {
                    var affiliates = [{
                        label: '',
                        value: ''
                    }]
                    for (let affiliate of res.data) {
                        let aux = {
                            label: affiliate.nome_filial,
                            value: affiliate._id
                        }
                        affiliates.push(aux)
                    }
                    setAffiliates(affiliates)
                })
                .catch((error) => {
                    console.log(error);
                    snack.current("Falha ao buscar os empresas", { variant: 'error' })
                });

            api.get(`/register/vehicles`)
                .then((res) => {
                    var vehicles = []
                    for (let vehicle of res.data) {
                        vehicle["filial"] = vehicle.affiliateId.nome_filial
                        vehicles.push(vehicle)
                    }
                    setVehicles(vehicles);
                    setLoading.current(false)
                })
                .catch((error) => {
                    setLoading.current(false)
                    console.log(error);
                    snack.current("Falha ao buscar os veículos", { variant: 'error' })
                });
        }
        fetchData();
    }, [reload]);

    const tableRef = useRef();
    return (
        <MaterialTable
            tableRef={tableRef}
            title="Veículos da Empresa e Terceiros"
            columns={[
                {
                    title: "Opções",
                    field: "opcoes",
                    editable: false,
                    render: (rowData) => {
                        return (
                            <>
                                < EditOne
                                    item={rowData}
                                    setData={setData}
                                    setOpen={setOpen}
                                />
                                <Delete
                                    _id={rowData._id}
                                    name={rowData.placa}
                                    route={"/register/vehicle/"}
                                    setReload={setReload_.current}
                                    setLoading={setLoading.current}
                                />
                            </>
                        )
                    }
                },
                {
                    title: "Status do Veículo",
                    field: "status",
                    hidden: !collumns.status.value,
                },
                {
                    title: "Placa",
                    field: "placa",
                    hidden: !collumns.placa.value,
                },
                {
                    title: "Filial",
                    field: "filial",
                    hidden: !collumns.filial.value,
                },
                {
                    title: "Status Error",
                    field: "status_error",
                    hidden: !collumns.status_error.value,
                },
                {
                    title: "Identificador",
                    field: "_id",
                    hidden: !collumns._id.value,
                },
                {
                    title: "Vínculo",
                    field: "vinculo",
                    hidden: !collumns.vinculo.value,
                },
                {
                    title: "Código da Frota",
                    field: "codigo_frota",
                    hidden: !collumns.codigo_frota.value,
                },
                {
                    title: "Valor da Diária",
                    field: "valor_diaria",
                    hidden: !collumns.valor_diaria.value,
                },
                {
                    title: "Valor do km (reais)",
                    field: "valor_km",
                    hidden: !collumns.valor_km.value,
                },
                {
                    title: "Tipo de Veículo",
                    field: "tipo_veiculo",
                    hidden: !collumns.tipo_veiculo.value,
                },
                {
                    title: "Marca",
                    field: "marca",
                    hidden: !collumns.marca.value,
                },
                {
                    title: "Ano do Modelo",
                    field: "ano_modelo",
                    hidden: !collumns.ano_modelo.value,
                },
                {
                    title: "Ano da fabricação",
                    field: "ano_fabricacao",
                    hidden: !collumns.ano_fabricacao.value,
                },
                {
                    title: "Combustível",
                    field: "combustivel",
                    hidden: !collumns.combustivel.value,
                },
                {
                    title: "Categoria",
                    field: "categoria",
                    hidden: !collumns.categoria.value,
                },
                {
                    title: "Número de Eixos",
                    field: "n_eixos",
                    hidden: !collumns.n_eixos.value,
                },
                {
                    title: "Tipo de Eixo",
                    field: "tipo_eixo",
                    hidden: !collumns.tipo_eixo.value,
                },
                {
                    title: "Altura Total (m)",
                    field: "altura",
                    hidden: !collumns.altura.value,
                },
                {
                    title: "Largura Total (m)",
                    field: "largura",
                    hidden: !collumns.largura.value,
                },
                {
                    title: "Comprimento Total (m)",
                    field: "comprimento",
                    hidden: !collumns.comprimento.value,
                },
                {
                    title: "Tipo de Carroceria",
                    field: "tipo_carroceria",
                    hidden: !collumns.tipo_carroceria.value,
                },
                {
                    title: "Capacidade (kg)",
                    field: "capacidade_kg",
                    hidden: !collumns.capacidade_kg.value,
                },
                {
                    title: "Capacidade (m3)",
                    field: "capacidade_m3",
                    hidden: !collumns.capacidade_m3.value,
                },
                {
                    title: "Altura carroceria (m)",
                    field: "altura_carroceria_m",
                    hidden: !collumns.altura_carroceria_m.value,
                },
                {
                    title: "Largura carroceria (m)",
                    field: "largura_carroceria_m",
                    hidden: !collumns.largura_carroceria_m.value,
                },
                {
                    title: "Comprimento carroceria (m)",
                    field: "comprimento_carroceria_m",
                    hidden: !collumns.comprimento_carroceria_m.value,
                },
                {
                    title: "Vencimento CRLV",
                    field: "vencimento_crlv",
                    hidden: !collumns.vencimento_crlv.value,
                },
                {
                    title: "Número ANTT",
                    field: "num_antt",
                    hidden: !collumns.num_antt.value,
                },
                {
                    title: "Vencimento ANTT",
                    field: "vencimento_antt",
                    hidden: !collumns.vencimento_antt.value,
                },
                {
                    title: "Tecnologia de rastreio",
                    field: "tec_rastreio",
                    hidden: !collumns.tec_rastreio.value,
                },
                {
                    title: "Identificador Tecnologia de Rastreio",
                    field: "id_tec_rastreio",
                    hidden: !collumns.id_tec_rastreio.value,
                },
                {
                    title: "Criado",
                    field: "criado_por",
                    hidden: !collumns.criado_por.value,
                },
                {
                    title: "Criado em",
                    field: "criado_em",
                    hidden: !collumns.criado_em.value,
                },
                {
                    title: "Editado",
                    field: "editado_por",
                    hidden: !collumns.editado_por.value,
                },
                {
                    title: "Editado em",
                    field: "editado_em",
                    hidden: !collumns.editado_em.value,
                },
            ]}
            data={vehicles}
            options={{
                add: true,
                search: true,
                pageSize: 10,
                grouping: true,
                selection: true,
                exportButton: false,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [1, 5, 10, 20, vehicles.length],
                padding: "dense",
                headerStyle: {
                    backgroundColor: "#01579b",
                    textAlign: 'center',
                    color: "#FFF",
                    fontSize: 11 * window.innerWidth / 1280,
                },
                cellStyle: {
                    textAlign: "center",
                    fontSize: 11 * window.innerWidth / 1280,
                },
                rowStyle: rowData => ({
                    backgroundColor: (rowData.status_error === "" ? '#ebfaeb' : "#ffcccc")
                }),
            }}
            onSelectionChange={(rows) => setSelectVeics.current(rows)}
            localization={{
                body: {
                    emptyDataSourceMessage: "",
                    addTooltip: "adicionar novo usuário",
                    deleteTooltip: "excluir",
                    editRow: { deleteText: "Deseja excluir o usuário?" },
                },

                header: {
                    actions: "Opções",
                },
                grouping: {
                    placeholder: "Arraste os cabeçalhos aqui para agrupar por",
                    groupedBy: "Agrupado por:",
                },

                toolbar: {
                    searchTooltip: "Pesquisar",
                    searchPlaceholder: "Pesquisar",
                    exportTitle: "exportar",
                    nRowsSelected: "{0} Itens(s) selecionados",
                },
                pagination: {
                    labelRowsSelect: "",
                    labelDisplayedRows: "{count} de {from}-{to}",
                    firstTooltip: "Primeira página",
                    previousTooltip: "Página anterior",
                    nextTooltip: "Próxima página",
                    lastTooltip: "Última página",
                },
            }}
            components={{
                Actions: () => {
                    return (
                        <div className="row">
                            < DownloadSheet />
                            <UploadSheet
                                route={"/register/vehicles"}
                                setReload={setReload_.current}
                            />
                            <AddOne
                                setData={setData}
                                setOpen={setOpen}
                            />
                            <EditMany
                                type={"EXCLUIR"}
                                select_itens={select_veics}
                                route={"/register/vehicles/"}
                                setReload={setReload_.current}
                                setSelect={setSelectVeics.current}
                            />
                            <EditMany
                                type={"ATIVAR"}
                                select_itens={select_veics}
                                route={"/register/vehicles/"}
                                setReload={setReload_.current}
                                setSelect={setSelectVeics.current}
                            />
                            <EditMany
                                type={"INATIVAR"}
                                select_itens={select_veics}
                                route={"/register/vehicles/"}
                                setReload={setReload_.current}
                                setSelect={setSelectVeics.current}
                            />
                            < Hidden
                                type={"config_vehicle_registration"}
                                collumns={collumns}
                                setReload={setReload_.current}
                            />
                        </div>
                    );
                }
            }}
        />
    );
}

Table.propTypes = {
    reload: PropTypes.bool,
    setData: PropTypes.func,
    setOpen: PropTypes.func,
    setReload: PropTypes.func,
    setAffiliates: PropTypes.func,
};
