import { useSnackbar } from "notistack";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useStoreActions, useStoreState } from "easy-peasy"
import React, { useState, useRef, useEffect } from "react";
import { Button, TextField, DialogActions, DialogContent } from "@material-ui/core";

import FieldTime from "../FieldTime.js"
import Ufs from "../../../../../../utils/Ufs"
import api from "../../../../../../services/api";
import cities from "../../../../../../utils/Cities"

export default function SizeFormUpdate() {
    const snack = useRef()
    const { enqueueSnackbar } = useSnackbar();
    snack.current = enqueueSnackbar

    const setReloadSizeRef = useRef()
    setReloadSizeRef.current = useStoreActions(actions => {
        return actions.reloadStore.setReloadSize;
    });

    // update form
    const setUpdateFormData = useRef()
    setUpdateFormData.current = useStoreActions(actions => {
        return actions.sizeStore.setUpdateFormData;
    });

    const data = useStoreState((state) => state.sizeStore.update_form_data);

    const [hora1, setHora1] = useState(data.hora1);
    const [hora2, setHora2] = useState(data.hora2);
    const [hora3, setHora3] = useState(data.hora3);
    const [hora4, setHora4] = useState(data.hora4);
    const [hora5, setHora5] = useState(data.hora5);
    const [hora6, setHora6] = useState(data.hora6);
    const [hora7, setHora7] = useState(data.hora7);
    const [hora8, setHora8] = useState(data.hora8);
    const [hora9, setHora9] = useState(data.hora9);
    const [hora10, setHora10] = useState(data.hora10);
    const [hora11, setHora11] = useState(data.hora11);
    const [hora12, setHora12] = useState(data.hora12);
    const [hora13, setHora13] = useState(data.hora13);
    const [hora14, setHora14] = useState(data.hora14);
    const [hora15, setHora15] = useState(data.hora15);
    const [hora16, setHora16] = useState(data.hora16);
    const [hora17, setHora17] = useState(data.hora17);
    const [hora18, setHora18] = useState(data.hora18);

    const [disabled, setDisabled] = useState(false);
    const [cidade, setCidade] = useState(data.cidade);
    const [estado, setEstado] = useState(data.estado);
    const [decreto, setDecreto] = useState(data.decreto);
    const [pesomin, setPesomin] = useState(data.pesomin);
    const [eixomax, setEixomax] = useState(data.eixomax);
    const [eixomin, setEixomin] = useState(data.eixomin);
    const [pesomax, setPesomax] = useState(data.pesomax);
    const [alturamin, setAlturamin] = useState(data.alturamin);
    const [alturamax, setAlturamax] = useState(data.alturamax);
    const [cities_uf, setCitiesUf] = useState(cities[data.estado]);
    const [btn_atualizar_salvar, setBtnHabilitarSalvar] = useState(false);
    const [links_decretos, setLinksDecreto] = useState(data.links_decretos);
    const [comprimentomin, setComprimentomin] = useState(data.comprimentomin);
    const [comprimentomax, setComprimentomax] = useState(data.comprimentomax);

    useEffect(() => { checkFields() });

    function checkFields() {
        if (pesomin < 0) {
            setBtnHabilitarSalvar(false);
            return;
        } else if (alturamin < 0) {
            setBtnHabilitarSalvar(false);
            return;
        } else if (comprimentomin < 0) {
            setBtnHabilitarSalvar(false);
            return;
        } else if (eixomin < 0) {
            setBtnHabilitarSalvar(false);
            return;
        } else if (pesomax < 0) {
            setBtnHabilitarSalvar(false);
            return;
        } else if (alturamax < 0) {
            setBtnHabilitarSalvar(false);
            return;
        } else if (comprimentomax < 0) {
            setBtnHabilitarSalvar(false);
            return;
        } else if (eixomax < 0) {
            setBtnHabilitarSalvar(false);
            return;
        } else {
            setBtnHabilitarSalvar(decreto !== "" && cidade !== "" && estado !== "" && links_decretos !== "");
        }
    }

    function changeEstado(data) {
        if (!data) {
            setEstado("")
            setCidade("")
            setDisabled(true)
        } else if (data.value !== estado) {
            setCidade("")
            setEstado(data.value)
            setCitiesUf(cities[data.value])
            setDisabled(false)
        }
    }

    async function setSizeRestr() {
        var horario_seg_sex = hora1 + "-" + hora2 + "\n" + hora3 + "-" + hora4 + "\n" + hora5 + "-" + hora6;
        var horario_sabados = hora7 + "-" + hora8 + "\n" + hora9 + "-" + hora10 + "\n" + hora11 + "-" + hora12;
        var horario_dom_fer = hora13 + "-" + hora14 + "\n" + hora15 + "-" + hora16 + "\n" + hora17 + "-" + hora18;

        api.put(`/admin/sizerestrictions`,
            {
                _id: data._id,
                email: localStorage.getItem("email"),
                decreto,
                horario_seg_sex,
                horario_sabados,
                horario_dom_fer,
                pesomin,
                alturamin,
                comprimentomin,
                eixomin,
                pesomax,
                alturamax,
                comprimentomax,
                eixomax,
                cidade,
                estado,
                links_decretos,
            },
        )
            .then(() => {
                snack.current(`Restrição de porte editada com sucesso!`, { variant: 'success' })
                setReloadSizeRef.current()
                setUpdateFormData.current(false);
            })
            .catch((error) => {
                console.log(error);
                snack.current(`Falha ao editar restrição de porte!`, { variant: 'error' })
                setUpdateFormData.current(false);
            });
    }

    return (
        <>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="decreto"
                    value={decreto}
                    label="Descrição/Decreto"
                    onChange={(event) => setDecreto(event.target.value)}
                    type="text"
                    fullWidth
                />
                < FieldTime
                    setHora1={setHora1}
                    setHora2={setHora2}
                    setHora3={setHora3}
                    setHora4={setHora4}
                    setHora5={setHora5}
                    setHora6={setHora6}
                    setHora7={setHora7}
                    setHora8={setHora8}
                    setHora9={setHora9}
                    setHora10={setHora10}
                    setHora11={setHora11}
                    setHora12={setHora12}
                    setHora13={setHora13}
                    setHora14={setHora14}
                    setHora15={setHora15}
                    setHora16={setHora16}
                    setHora17={setHora17}
                    setHora18={setHora18}
                    hora1={hora1}
                    hora2={hora2}
                    hora3={hora3}
                    hora4={hora4}
                    hora5={hora5}
                    hora6={hora6}
                    hora7={hora7}
                    hora8={hora8}
                    hora9={hora9}
                    hora10={hora10}
                    hora11={hora11}
                    hora12={hora12}
                    hora13={hora13}
                    hora14={hora14}
                    hora15={hora15}
                    hora16={hora16}
                    hora17={hora17}
                    hora18={hora18}
                />

                <div className="text_field_variables">
                    <div >
                        <TextField
                            autoFocus
                            margin="dense"
                            id="pesomin"
                            label="Peso Mínimo (kg)"
                            value={pesomin}
                            onChange={(event) => setPesomin(event.target.value)}
                            type="number"
                        />

                        <TextField
                            autoFocus
                            margin="dense"
                            id="pesomax"
                            value={pesomax}
                            label="Peso Máximo (kg)"
                            onChange={(event) => setPesomax(event.target.value)}
                            type="number"
                        />
                    </div>

                    <div>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="alturamin"
                            value={alturamin}
                            label="Altura Mínima (m)"
                            onChange={(event) => setAlturamin(event.target.value)}
                            type="number"
                        />

                        <TextField
                            autoFocus
                            margin="dense"
                            id="alturamax"
                            value={alturamax}
                            label="Altura Máxima (m)"
                            onChange={(event) => setAlturamax(event.target.value)}
                            type="number"
                        />
                    </div>

                    <div>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="comprimentomin"
                            value={comprimentomin}
                            label="Comprimento Mínimo (m)"
                            onChange={(event) => setComprimentomin(event.target.value)}
                            type="number"
                        />

                        <TextField
                            autoFocus
                            margin="dense"
                            id="comprimentomax"
                            value={comprimentomax}
                            label="Comprimento Máximo (m)"
                            onChange={(event) => setComprimentomax(event.target.value)}
                            type="number"
                        />
                    </div>

                    <div>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="eixomin"
                            value={eixomin}
                            label="Número Mínimo de Eixos"
                            onChange={(event) => setEixomin(event.target.value)}
                            type="number"
                        />

                        <TextField
                            autoFocus
                            margin="dense"
                            id="eixomax"
                            value={eixomax}
                            label="Número Máximo de Eixos"
                            onChange={(event) => setEixomax(event.target.value)}
                            type="number"
                        />
                    </div>
                </div>

                <Autocomplete
                    id="estado"
                    options={Ufs}
                    defaultValue={{ title: estado, value: estado }}
                    getOptionLabel={(option) => option.title}
                    renderInput={(params) => <TextField {...params} label="Estado" margin="dense" />}
                    onChange={(_, data) => changeEstado(data)}
                    value={{ title: estado, value: estado }}
                    fullWidth
                />

                <Autocomplete
                    id="cities"
                    options={cities_uf}
                    disabled={disabled}
                    defaultValue={{ city: cidade }}
                    getOptionLabel={(option) => option.city}
                    renderInput={(params) => <TextField {...params} label="Cidade" margin="dense" />}
                    onChange={(_, data) => !data ? setCidade("") : setCidade(data.city)}
                    value={{ city: cidade }}
                    fullWidth
                />

                <TextField
                    autoFocus
                    margin="dense"
                    id="links_decretos"
                    value={links_decretos}
                    label="Links dos Decretos (para mais de um link separe-o com virgula)"
                    onChange={(event) => setLinksDecreto(event.target.value)}
                    type="text"
                    multiline
                    fullWidth
                />

            </DialogContent >
            <DialogActions>
                <Button disabled={!btn_atualizar_salvar} onClick={() => setSizeRestr()} color="primary">
                    Salvar
                </Button>
                <Button onClick={() => setUpdateFormData.current(false)} color="primary">
                    Cancelar
                </Button>
            </DialogActions>
        </>
    )
}
