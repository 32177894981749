import React from 'react';
import Map from '@material-ui/icons/Map';
import { useStoreActions } from 'easy-peasy';
import { Button, Tooltip } from '@material-ui/core';

export default function ButtonMap() {
    const setOpenMap = useStoreActions(actions => {
        return actions.mapStore.setOpenMap;
    });
    return (
        <Tooltip title={<span style={{ fontSize: 15 }}> Mapa </span>} >
            <Button component="span" onClick={() => setOpenMap("normal")}>
                <Map fontSize={'large'} color={'primary'} />
            </Button>
        </Tooltip>
    );
}
