import React from 'react';
import { useStoreActions } from 'easy-peasy';
import { Fab, Tooltip } from '@material-ui/core';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';

export default function ImportVehiclesButton() {
    // abre o modal de documentos
    const setImportVehicles = useStoreActions(actions => {
        return actions.ImportVehiclesStore.setImportVehicles;
    });
    return (
        <Tooltip title={<span style={{ fontSize: 15 }}> Selecione os veículos que deseja utilizar</span>}>
            <Fab
                color="primary"
                aria-label="add"
                style={{
                    margin: 10,
                    maxWidth: 45,
                    minWidth: 45,
                    maxHeight: 45,
                    minHeight: 45,
                }}
                onClick={() => {
                    setImportVehicles({ open: true })
                }}
            >
                <EmojiTransportationIcon />
            </Fab>
        </Tooltip>
    );
}
