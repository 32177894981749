const cities = {
  "AC": [
    { city: "Acrelândia" },
    { city: "Assis Brasil" },
    { city: "Brasiléia" },
    { city: "Bujari" },
    { city: "Capixaba" },
    { city: "Cruzeiro do Sul" },
    { city: "Epitaciolândia" },
    { city: "Feijó" },
    { city: "Jordão" },
    { city: "Mâncio Lima" },
    { city: "Manoel Urbano" },
    { city: "Marechal Thaumaturgo" },
    { city: "Plácido de Castro" },
    { city: "Porto Acre" },
    { city: "Porto Walter" },
    { city: "Rio Branco" },
    { city: "Rodrigues Alves" },
    { city: "Santa Rosa do Purus" },
    { city: "Sena Madureira" },
    { city: "Senador Guiomard" },
    { city: "Tarauacá" },
    { city: "Xapuri" }
  ],
  "AL": [
    { city: "Água Branca" },
    { city: "Anadia" },
    { city: "Arapiraca" },
    { city: "Atalaia" },
    { city: "Barra de Santo Antônio" },
    { city: "Barra de São Miguel" },
    { city: "Batalha" },
    { city: "Belém" },
    { city: "Belo Monte" },
    { city: "Boca da Mata" },
    { city: "Branquinha" },
    { city: "Cacimbinhas" },
    { city: "Cajueiro" },
    { city: "Campestre" },
    { city: "Campo Alegre" },
    { city: "Campo Grande" },
    { city: "Canapi" },
    { city: "Capela" },
    { city: "Carneiros" },
    { city: "Chã Preta" },
    { city: "Coité do Nóia" },
    { city: "Colônia Leopoldina" },
    { city: "Coqueiro Seco" },
    { city: "Coruripe" },
    { city: "Craíbas" },
    { city: "Delmiro Gouveia" },
    { city: "Dois Riachos" },
    { city: "Estrela de Alagoas" },
    { city: "Feira Grande" },
    { city: "Feliz Deserto" },
    { city: "Flexeiras" },
    { city: "Girau do Ponciano" },
    { city: "Ibateguara" },
    { city: "Igaci" },
    { city: "Igreja Nova" },
    { city: "Inhapi" },
    { city: "Jacaré dos Homens" },
    { city: "Jacuípe" },
    { city: "Japaratinga" },
    { city: "Jaramataia" },
    { city: "Jequiá da Praia" },
    { city: "Joaquim Gomes" },
    { city: "Jundiá" },
    { city: "Junqueiro" },
    { city: "Lagoa da Canoa" },
    { city: "Limoeiro de Anadia" },
    { city: "Maceió" },
    { city: "Major Isidoro" },
    { city: "Mar Vermelho" },
    { city: "Maragogi" },
    { city: "Maravilha" },
    { city: "Marechal Deodoro" },
    { city: "Maribondo" },
    { city: "Mata Grande" },
    { city: "Matriz de Camaragibe" },
    { city: "Messias" },
    { city: "Minador do Negrão" },
    { city: "Monteirópolis" },
    { city: "Murici" },
    { city: "Novo Lino" },
    { city: "Olho d'Água das Flores" },
    { city: "Olho d'Água do Casado" },
    { city: "Olho d'Água Grande" },
    { city: "Olivença" },
    { city: "Ouro Branco" },
    { city: "Palestina" },
    { city: "Palmeira dos Índios" },
    { city: "Pão de Açúcar" },
    { city: "Pariconha" },
    { city: "Paripueira" },
    { city: "Passo de Camaragibe" },
    { city: "Paulo Jacinto" },
    { city: "Penedo" },
    { city: "Piaçabuçu" },
    { city: "Pilar" },
    { city: "Pindoba" },
    { city: "Piranhas" },
    { city: "Poço das Trincheiras" },
    { city: "Porto Calvo" },
    { city: "Porto de Pedras" },
    { city: "Porto Real do Colégio" },
    { city: "Quebrangulo" },
    { city: "Rio Largo" },
    { city: "Roteiro" },
    { city: "Santa Luzia do Norte" },
    { city: "Santana do Ipanema" },
    { city: "Santana do Mundaú" },
    { city: "São Brás" },
    { city: "São José da Laje" },
    { city: "São José da Tapera" },
    { city: "São Luís do Quitunde" },
    { city: "São Miguel dos Campos" },
    { city: "São Miguel dos Milagres" },
    { city: "São Sebastião" },
    { city: "Satuba" },
    { city: "Senador Rui Palmeira" },
    { city: "Tanque d'Arca" },
    { city: "Taquarana" },
    { city: "Teotônio Vilela" },
    { city: "Traipu" },
    { city: "União dos Palmares" },
    { city: "Viçosa" }
  ],
  "AM": [
    { city: "Alvarães" },
    { city: "Amaturá" },
    { city: "Anamã" },
    { city: "Anori" },
    { city: "Apuí" },
    { city: "Atalaia do Norte" },
    { city: "Autazes" },
    { city: "Barcelos" },
    { city: "Barreirinha" },
    { city: "Benjamin Constant" },
    { city: "Beruri" },
    { city: "Boa Vista do Ramos" },
    { city: "Boca do Acre" },
    { city: "Borba" },
    { city: "Caapiranga" },
    { city: "Canutama" },
    { city: "Carauari" },
    { city: "Careiro" },
    { city: "Careiro da Várzea" },
    { city: "Coari" },
    { city: "Codajás" },
    { city: "Eirunepé" },
    { city: "Envira" },
    { city: "Fonte Boa" },
    { city: "Guajará" },
    { city: "Humaitá" },
    { city: "Ipixuna" },
    { city: "Iranduba" },
    { city: "Itacoatiara" },
    { city: "Itamarati" },
    { city: "Itapiranga" },
    { city: "Japurá" },
    { city: "Juruá" },
    { city: "Jutaí" },
    { city: "Lábrea" },
    { city: "Manacapuru" },
    { city: "Manaquiri" },
    { city: "Manaus" },
    { city: "Manicoré" },
    { city: "Maraã" },
    { city: "Maués" },
    { city: "Nhamundá" },
    { city: "Nova Olinda do Norte" },
    { city: "Novo Airão" },
    { city: "Novo Aripuanã" },
    { city: "Parintins" },
    { city: "Pauini" },
    { city: "Presidente Figueiredo" },
    { city: "Rio Preto da Eva" },
    { city: "Santa Isabel do Rio Negro" },
    { city: "Santo Antônio do Içá" },
    { city: "São Gabriel da Cachoeira" },
    { city: "São Paulo de Olivença" },
    { city: "São Sebastião do Uatumã" },
    { city: "Silves" },
    { city: "Tabatinga" },
    { city: "Tapauá" },
    { city: "Tefé" },
    { city: "Tonantins" },
    { city: "Uarini" },
    { city: "Urucará" },
    { city: "Urucurituba" }
  ],
  "AP": [
    { city: "Amapá" },
    { city: "Calçoene" },
    { city: "Cutias" },
    { city: "Ferreira Gomes" },
    { city: "Itaubal" },
    { city: "Laranjal do Jari" },
    { city: "Macapá" },
    { city: "Mazagão" },
    { city: "Oiapoque" },
    { city: "Pedra Branca do Amapari" },
    { city: "Porto Grande" },
    { city: "Pracuúba" },
    { city: "Santana" },
    { city: "Serra do Navio" },
    { city: "Tartarugalzinho" },
    { city: "Vitória do Jari" }
  ],
  "BA": [
    { city: "Abaíra" },
    { city: "Abaré" },
    { city: "Acajutiba" },
    { city: "Adustina" },
    { city: "Água Fria" },
    { city: "Aiquara" },
    { city: "Alagoinhas" },
    { city: "Alcobaça" },
    { city: "Almadina" },
    { city: "Amargosa" },
    { city: "Amélia Rodrigues" },
    { city: "América Dourada" },
    { city: "Anagé" },
    { city: "Andaraí" },
    { city: "Andorinha" },
    { city: "Angical" },
    { city: "Anguera" },
    { city: "Antas" },
    { city: "Antônio Cardoso" },
    { city: "Antônio Gonçalves" },
    { city: "Aporá" },
    { city: "Apuarema" },
    { city: "Araças" },
    { city: "Aracatu" },
    { city: "Araci" },
    { city: "Aramari" },
    { city: "Arataca" },
    { city: "Aratuípe" },
    { city: "Aurelino Leal" },
    { city: "Baianópolis" },
    { city: "Baixa Grande" },
    { city: "Banzaê" },
    { city: "Barra" },
    { city: "Barra da Estiva" },
    { city: "Barra do Choça" },
    { city: "Barra do Mendes" },
    { city: "Barra do Rocha" },
    { city: "Barreiras" },
    { city: "Barro Alto" },
    { city: "Barrocas" },
    { city: "Barro Preto" },
    { city: "Belmonte" },
    { city: "Belo Campo" },
    { city: "Biritinga" },
    { city: "Boa Nova" },
    { city: "Boa Vista do Tupim" },
    { city: "Bom Jesus da Lapa" },
    { city: "Bom Jesus da Serra" },
    { city: "Boninal" },
    { city: "Bonito" },
    { city: "Boquira" },
    { city: "Botuporã" },
    { city: "Brejões" },
    { city: "Brejolândia" },
    { city: "Brotas de Macaúbas" },
    { city: "Brumado" },
    { city: "Buerarema" },
    { city: "Buritirama" },
    { city: "Caatiba" },
    { city: "Cabaceiras do Paraguaçu" },
    { city: "Cachoeira" },
    { city: "Caculé" },
    { city: "Caém" },
    { city: "Caetanos" },
    { city: "Caetité" },
    { city: "Cafarnaum" },
    { city: "Cairu" },
    { city: "Caldeirão Grande" },
    { city: "Camacan" },
    { city: "Camaçari" },
    { city: "Camamu" },
    { city: "Campo Alegre de Lourdes" },
    { city: "Campo Formoso" },
    { city: "Canápolis" },
    { city: "Canarana" },
    { city: "Canavieiras" },
    { city: "Candeal" },
    { city: "Candeias" },
    { city: "Candiba" },
    { city: "Cândido Sales" },
    { city: "Cansanção" },
    { city: "Canudos" },
    { city: "Capela do Alto Alegre" },
    { city: "Capim Grosso" },
    { city: "Caraíbas" },
    { city: "Caravelas" },
    { city: "Cardeal da Silva" },
    { city: "Carinhanha" },
    { city: "Casa Nova" },
    { city: "Castro Alves" },
    { city: "Catolândia" },
    { city: "Catu" },
    { city: "Caturama" },
    { city: "Central" },
    { city: "Chorrochó" },
    { city: "Cícero Dantas" },
    { city: "Cipó" },
    { city: "Coaraci" },
    { city: "Cocos" },
    { city: "Conceição da Feira" },
    { city: "Conceição do Almeida" },
    { city: "Conceição do Coité" },
    { city: "Conceição do Jacuípe" },
    { city: "Conde" },
    { city: "Condeúba" },
    { city: "Contendas do Sincorá" },
    { city: "Coração de Maria" },
    { city: "Cordeiros" },
    { city: "Coribe" },
    { city: "Coronel João Sá" },
    { city: "Correntina" },
    { city: "Cotegipe" },
    { city: "Cravolândia" },
    { city: "Crisópolis" },
    { city: "Cristópolis" },
    { city: "Cruz das Almas" },
    { city: "Curaçá" },
    { city: "Dário Meira" },
    { city: "Dias d'Ávila" },
    { city: "Dom Basílio" },
    { city: "Dom Macedo Costa" },
    { city: "Elísio Medrado" },
    { city: "Encruzilhada" },
    { city: "Entre Rios" },
    { city: "Érico Cardoso" },
    { city: "Esplanada" },
    { city: "Euclides da Cunha" },
    { city: "Eunápolis" },
    { city: "Fátima" },
    { city: "Feira da Mata" },
    { city: "Feira de Santana" },
    { city: "Filadélfia" },
    { city: "Firmino Alves" },
    { city: "Floresta Azul" },
    { city: "Formosa do Rio Preto" },
    { city: "Gandu" },
    { city: "Gavião" },
    { city: "Gentio do Ouro" },
    { city: "Glória" },
    { city: "Gongogi" },
    { city: "Governador Mangabeira" },
    { city: "Guajeru" },
    { city: "Guanambi" },
    { city: "Guaratinga" },
    { city: "Heliópolis" },
    { city: "Iaçu" },
    { city: "Ibiassucê" },
    { city: "Ibicaraí" },
    { city: "Ibicoara" },
    { city: "Ibicuí" },
    { city: "Ibipeba" },
    { city: "Ibipitanga" },
    { city: "Ibiquera" },
    { city: "Ibirapitanga" },
    { city: "Ibirapuã" },
    { city: "Ibirataia" },
    { city: "Ibitiara" },
    { city: "Ibititá" },
    { city: "Ibotirama" },
    { city: "Ichu" },
    { city: "Igaporã" },
    { city: "Igrapiúna" },
    { city: "Iguaí" },
    { city: "Ilhéus" },
    { city: "Inhambupe" },
    { city: "Ipecaetá" },
    { city: "Ipiaú" },
    { city: "Ipirá" },
    { city: "Ipupiara" },
    { city: "Irajuba" },
    { city: "Iramaia" },
    { city: "Iraquara" },
    { city: "Irará" },
    { city: "Irecê" },
    { city: "Itabela" },
    { city: "Itaberaba" },
    { city: "Itabuna" },
    { city: "Itacaré" },
    { city: "Itaeté" },
    { city: "Itagi" },
    { city: "Itagibá" },
    { city: "Itagimirim" },
    { city: "Itaguaçu da Bahia" },
    { city: "Itaju do Colônia" },
    { city: "Itajuípe" },
    { city: "Itamaraju" },
    { city: "Itamari" },
    { city: "Itambé" },
    { city: "Itanagra" },
    { city: "Itanhém" },
    { city: "Itaparica" },
    { city: "Itapé" },
    { city: "Itapebi" },
    { city: "Itapetinga" },
    { city: "Itapicuru" },
    { city: "Itapitanga" },
    { city: "Itaquara" },
    { city: "Itarantim" },
    { city: "Itatim" },
    { city: "Itiruçu" },
    { city: "Itiúba" },
    { city: "Itororó" },
    { city: "Ituaçu" },
    { city: "Ituberá" },
    { city: "Iuiú" },
    { city: "Jaborandi" },
    { city: "Jacaraci" },
    { city: "Jacobina" },
    { city: "Jaguaquara" },
    { city: "Jaguarari" },
    { city: "Jaguaripe" },
    { city: "Jandaíra" },
    { city: "Jequié" },
    { city: "Jeremoabo" },
    { city: "Jiquiriçá" },
    { city: "Jitaúna" },
    { city: "João Dourado" },
    { city: "Juazeiro" },
    { city: "Jucuruçu" },
    { city: "Jussara" },
    { city: "Jussari" },
    { city: "Jussiape" },
    { city: "Lafaiete Coutinho" },
    { city: "Lagoa Real" },
    { city: "Laje" },
    { city: "Lajedão" },
    { city: "Lajedinho" },
    { city: "Lajedo do Tabocal" },
    { city: "Lamarão" },
    { city: "Lapão" },
    { city: "Lauro de Freitas" },
    { city: "Lençóis" },
    { city: "Licínio de Almeida" },
    { city: "Livramento de Nossa Senhora" },
    { city: "Luís Eduardo Magalhães" },
    { city: "Macajuba" },
    { city: "Macarani" },
    { city: "Macaúbas" },
    { city: "Macururé" },
    { city: "Madre de Deus" },
    { city: "Maetinga" },
    { city: "Maiquinique" },
    { city: "Mairi" },
    { city: "Malhada" },
    { city: "Malhada de Pedras" },
    { city: "Manoel Vitorino" },
    { city: "Mansidão" },
    { city: "Maracás" },
    { city: "Maragogipe" },
    { city: "Maraú" },
    { city: "Marcionílio Souza" },
    { city: "Mascote" },
    { city: "Mata de São João" },
    { city: "Matina" },
    { city: "Medeiros Neto" },
    { city: "Miguel Calmon" },
    { city: "Milagres" },
    { city: "Mirangaba" },
    { city: "Mirante" },
    { city: "Monte Santo" },
    { city: "Morpará" },
    { city: "Morro do Chapéu" },
    { city: "Mortugaba" },
    { city: "Mucugê" },
    { city: "Mucuri" },
    { city: "Mulungu do Morro" },
    { city: "Mundo Novo" },
    { city: "Muniz Ferreira" },
    { city: "Muquém de São Francisco" },
    { city: "Muritiba" },
    { city: "Mutuípe" },
    { city: "Nazaré" },
    { city: "Nilo Peçanha" },
    { city: "Nordestina" },
    { city: "Nova Canaã" },
    { city: "Nova Fátima" },
    { city: "Nova Ibiá" },
    { city: "Nova Itarana" },
    { city: "Nova Redenção" },
    { city: "Nova Soure" },
    { city: "Nova Viçosa" },
    { city: "Novo Horizonte" },
    { city: "Novo Triunfo" },
    { city: "Olindina" },
    { city: "Oliveira dos Brejinhos" },
    { city: "Ouriçangas" },
    { city: "Ourolândia" },
    { city: "Palmas de Monte Alto" },
    { city: "Palmeiras" },
    { city: "Paramirim" },
    { city: "Paratinga" },
    { city: "Paripiranga" },
    { city: "Pau Brasil" },
    { city: "Paulo Afonso" },
    { city: "Pé de Serra" },
    { city: "Pedrão" },
    { city: "Pedro Alexandre" },
    { city: "Piatã" },
    { city: "Pilão Arcado" },
    { city: "Pindaí" },
    { city: "Pindobaçu" },
    { city: "Pintadas" },
    { city: "Piraí do Norte" },
    { city: "Piripá" },
    { city: "Piritiba" },
    { city: "Planaltino" },
    { city: "Planalto" },
    { city: "Poções" },
    { city: "Pojuca" },
    { city: "Ponto Novo" },
    { city: "Porto Seguro" },
    { city: "Potiraguá" },
    { city: "Prado" },
    { city: "Presidente Dutra" },
    { city: "Presidente Jânio Quadros" },
    { city: "Presidente Tancredo Neves" },
    { city: "Queimadas" },
    { city: "Quijingue" },
    { city: "Quixabeira" },
    { city: "Rafael Jambeiro" },
    { city: "Remanso" },
    { city: "Retirolândia" },
    { city: "Riachão das Neves" },
    { city: "Riachão do Jacuípe" },
    { city: "Riacho de Santana" },
    { city: "Ribeira do Amparo" },
    { city: "Ribeira do Pombal" },
    { city: "Ribeirão do Largo" },
    { city: "Rio de Contas" },
    { city: "Rio do Antônio" },
    { city: "Rio do Pires" },
    { city: "Rio Real" },
    { city: "Rodelas" },
    { city: "Ruy Barbosa" },
    { city: "Salinas da Margarida" },
    { city: "Salvador" },
    { city: "Santa Bárbara" },
    { city: "Santa Brígida" },
    { city: "Santa Cruz Cabrália" },
    { city: "Santa Cruz da Vitória" },
    { city: "Santa Inês" },
    { city: "Santa Luzia" },
    { city: "Santa Maria da Vitória" },
    { city: "Santa Rita de Cássia" },
    { city: "Santa Teresinha" },
    { city: "Santaluz" },
    { city: "Santana" },
    { city: "Santanópolis" },
    { city: "Santo Amaro" },
    { city: "Santo Antônio de Jesus" },
    { city: "Santo Estêvão" },
    { city: "São Desidério" },
    { city: "São Domingos" },
    { city: "São Felipe" },
    { city: "São Félix" },
    { city: "São Félix do Coribe" },
    { city: "São Francisco do Conde" },
    { city: "São Gabriel" },
    { city: "São Gonçalo dos Campos" },
    { city: "São José da Vitória" },
    { city: "São José do Jacuípe" },
    { city: "São Miguel das Matas" },
    { city: "São Sebastião do Passé" },
    { city: "Sapeaçu" },
    { city: "Sátiro Dias" },
    { city: "Saubara" },
    { city: "Saúde" },
    { city: "Seabra" },
    { city: "Sebastião Laranjeiras" },
    { city: "Senhor do Bonfim" },
    { city: "Sento Sé" },
    { city: "Serra do Ramalho" },
    { city: "Serra Dourada" },
    { city: "Serra Preta" },
    { city: "Serrinha" },
    { city: "Serrolândia" },
    { city: "Simões Filho" },
    { city: "Sítio do Mato" },
    { city: "Sítio do Quinto" },
    { city: "Sobradinho" },
    { city: "Souto Soares" },
    { city: "Tabocas do Brejo Velho" },
    { city: "Tanhaçu" },
    { city: "Tanque Novo" },
    { city: "Tanquinho" },
    { city: "Taperoá" },
    { city: "Tapiramutá" },
    { city: "Teixeira de Freitas" },
    { city: "Teodoro Sampaio" },
    { city: "Teofilândia" },
    { city: "Teolândia" },
    { city: "Terra Nova" },
    { city: "Tremedal" },
    { city: "Tucano" },
    { city: "Uauá" },
    { city: "Ubaíra" },
    { city: "Ubaitaba" },
    { city: "Ubatã" },
    { city: "Uibaí" },
    { city: "Umburanas" },
    { city: "Una" },
    { city: "Urandi" },
    { city: "Uruçuca" },
    { city: "Utinga" },
    { city: "Valença" },
    { city: "Valente" },
    { city: "Várzea da Roça" },
    { city: "Várzea do Poço" },
    { city: "Várzea Nova" },
    { city: "Varzedo" },
    { city: "Vera Cruz" },
    { city: "Vereda" },
    { city: "Vitória da Conquista" },
    { city: "Wagner" },
    { city: "Wanderley" },
    { city: "Wenceslau Guimarães" },
    { city: "Xique-Xique" }
  ],
  "CE": [
    { city: "Abaiara" },
    { city: "Acarapé" },
    { city: "Acaraú" },
    { city: "Acopiara" },
    { city: "Aiuaba" },
    { city: "Alcântaras" },
    { city: "Altaneira" },
    { city: "Alto Santo" },
    { city: "Amontada" },
    { city: "Antonina do Norte" },
    { city: "Apuiarés" },
    { city: "Aquiraz" },
    { city: "Aracati" },
    { city: "Aracoiaba" },
    { city: "Ararendá" },
    { city: "Araripe" },
    { city: "Aratuba" },
    { city: "Arneiroz" },
    { city: "Assaré" },
    { city: "Aurora" },
    { city: "Baixio" },
    { city: "Banabuiú" },
    { city: "Barbalha" },
    { city: "Barreira" },
    { city: "Barro" },
    { city: "Barroquinha" },
    { city: "Baturité" },
    { city: "Beberibe" },
    { city: "Bela Cruz" },
    { city: "Boa Viagem" },
    { city: "Brejo Santo" },
    { city: "Camocim" },
    { city: "Campos Sales" },
    { city: "Canindé" },
    { city: "Capistrano" },
    { city: "Caridade" },
    { city: "Cariré" },
    { city: "Caririaçu" },
    { city: "Cariús" },
    { city: "Carnaubal" },
    { city: "Cascavel" },
    { city: "Catarina" },
    { city: "Catunda" },
    { city: "Caucaia" },
    { city: "Cedro" },
    { city: "Chaval" },
    { city: "Choró" },
    { city: "Chorozinho" },
    { city: "Coreaú" },
    { city: "Crateús" },
    { city: "Crato" },
    { city: "Croatá" },
    { city: "Cruz" },
    { city: "Deputado Irapuan Pinheiro" },
    { city: "Ererê" },
    { city: "Eusébio" },
    { city: "Farias Brito" },
    { city: "Forquilha" },
    { city: "Fortaleza" },
    { city: "Fortim" },
    { city: "Frecheirinha" },
    { city: "General Sampaio" },
    { city: "Graça" },
    { city: "Granja" },
    { city: "Granjeiro" },
    { city: "Groaíras" },
    { city: "Guaiúba" },
    { city: "Guaraciaba do Norte" },
    { city: "Guaramiranga" },
    { city: "Hidrolândia" },
    { city: "Horizonte" },
    { city: "Ibaretama" },
    { city: "Ibiapina" },
    { city: "Ibicuitinga" },
    { city: "Icapuí" },
    { city: "Icó" },
    { city: "Iguatu" },
    { city: "Independência" },
    { city: "Ipaporanga" },
    { city: "Ipaumirim" },
    { city: "Ipu" },
    { city: "Ipueiras" },
    { city: "Iracema" },
    { city: "Irauçuba" },
    { city: "Itaiçaba" },
    { city: "Itaitinga" },
    { city: "Itapagé" },
    { city: "Itapipoca" },
    { city: "Itapiúna" },
    { city: "Itarema" },
    { city: "Itatira" },
    { city: "Jaguaretama" },
    { city: "Jaguaribara" },
    { city: "Jaguaribe" },
    { city: "Jaguaruana" },
    { city: "Jardim" },
    { city: "Jati" },
    { city: "Jijoca de Jericoaroara" },
    { city: "Juazeiro do Norte" },
    { city: "Jucás" },
    { city: "Lavras da Mangabeira" },
    { city: "Limoeiro do Norte" },
    { city: "Madalena" },
    { city: "Maracanaú" },
    { city: "Maranguape" },
    { city: "Marco" },
    { city: "Martinópole" },
    { city: "Massapê" },
    { city: "Mauriti" },
    { city: "Meruoca" },
    { city: "Milagres" },
    { city: "Milhã" },
    { city: "Miraíma" },
    { city: "Missão Velha" },
    { city: "Mombaça" },
    { city: "Monsenhor Tabosa" },
    { city: "Morada Nova" },
    { city: "Moraújo" },
    { city: "Morrinhos" },
    { city: "Mucambo" },
    { city: "Mulungu" },
    { city: "Nova Olinda" },
    { city: "Nova Russas" },
    { city: "Novo Oriente" },
    { city: "Ocara" },
    { city: "Orós" },
    { city: "Pacajus" },
    { city: "Pacatuba" },
    { city: "Pacoti" },
    { city: "Pacujá" },
    { city: "Palhano" },
    { city: "Palmácia" },
    { city: "Paracuru" },
    { city: "Paraipaba" },
    { city: "Parambu" },
    { city: "Paramoti" },
    { city: "Pedra Branca" },
    { city: "Penaforte" },
    { city: "Pentecoste" },
    { city: "Pereiro" },
    { city: "Pindoretama" },
    { city: "Piquet Carneiro" },
    { city: "Pires Ferreira" },
    { city: "Poranga" },
    { city: "Porteiras" },
    { city: "Potengi" },
    { city: "Potiretama" },
    { city: "Quiterianópolis" },
    { city: "Quixadá" },
    { city: "Quixelô" },
    { city: "Quixeramobim" },
    { city: "Quixeré" },
    { city: "Redenção" },
    { city: "Reriutaba" },
    { city: "Russas" },
    { city: "Saboeiro" },
    { city: "Salitre" },
    { city: "Santa Quitéria" },
    { city: "Santana do Acaraú" },
    { city: "Santana do Cariri" },
    { city: "São Benedito" },
    { city: "São Gonçalo do Amarante" },
    { city: "São João do Jaguaribe" },
    { city: "São Luís do Curu" },
    { city: "Senador Pompeu" },
    { city: "Senador Sá" },
    { city: "Sobral" },
    { city: "Solonópole" },
    { city: "Tabuleiro do Norte" },
    { city: "Tamboril" },
    { city: "Tarrafas" },
    { city: "Tauá" },
    { city: "Tejuçuoca" },
    { city: "Tianguá" },
    { city: "Trairi" },
    { city: "Tururu" },
    { city: "Ubajara" },
    { city: "Umari" },
    { city: "Umirim" },
    { city: "Uruburetama" },
    { city: "Uruoca" },
    { city: "Varjota" },
    { city: "Várzea Alegre" },
    { city: "Viçosa do Ceará" }
  ],
  "DF": [
    { city: "Águas Claras" },
    { city: "Brasília" },
    { city: "Ceilândia" },
    { city: "Gama" },
    { city: "Guará" },
    { city: "Planaltina" },
    { city: "Plano Piloto" },
    { city: "Recanto das Emas" },
    { city: "Samambaia" },
    { city: "Santa Maria" },
    { city: "Taguatinga" }
  ],
  "ES": [
    { city: "Afonso Cláudio" },
    { city: "Água Doce do Norte" },
    { city: "Águia Branca" },
    { city: "Alegre" },
    { city: "Alfredo Chaves" },
    { city: "Alto Rio Novo" },
    { city: "Anchieta" },
    { city: "Apiacá" },
    { city: "Aracruz" },
    { city: "Atilio Vivacqua" },
    { city: "Baixo Guandu" },
    { city: "Barra de São Francisco" },
    { city: "Boa Esperança" },
    { city: "Bom Jesus do Norte" },
    { city: "Brejetuba" },
    { city: "Cachoeiro de Itapemirim" },
    { city: "Cariacica" },
    { city: "Castelo" },
    { city: "Colatina" },
    { city: "Conceição da Barra" },
    { city: "Conceição do Castelo" },
    { city: "Divino de São Lourenço" },
    { city: "Domingos Martins" },
    { city: "Dores do Rio Preto" },
    { city: "Ecoporanga" },
    { city: "Fundão" },
    { city: "Governador Lindenberg" },
    { city: "Guaçuí" },
    { city: "Guarapari" },
    { city: "Ibatiba" },
    { city: "Ibiraçu" },
    { city: "Ibitirama" },
    { city: "Iconha" },
    { city: "Irupi" },
    { city: "Itaguaçu" },
    { city: "Itapemirim" },
    { city: "Itarana" },
    { city: "Iúna" },
    { city: "Jaguaré" },
    { city: "Jerônimo Monteiro" },
    { city: "João Neiva" },
    { city: "Laranja da Terra" },
    { city: "Linhares" },
    { city: "Mantenópolis" },
    { city: "Marataizes" },
    { city: "Marechal Floriano" },
    { city: "Marilândia" },
    { city: "Mimoso do Sul" },
    { city: "Montanha" },
    { city: "Mucurici" },
    { city: "Muniz Freire" },
    { city: "Muqui" },
    { city: "Nova Venécia" },
    { city: "Pancas" },
    { city: "Pedro Canário" },
    { city: "Pinheiros" },
    { city: "Piúma" },
    { city: "Ponto Belo" },
    { city: "Presidente Kennedy" },
    { city: "Rio Bananal" },
    { city: "Rio Novo do Sul" },
    { city: "Santa Leopoldina" },
    { city: "Santa Maria de Jetibá" },
    { city: "Santa Teresa" },
    { city: "São Domingos do Norte" },
    { city: "São Gabriel da Palha" },
    { city: "São José do Calçado" },
    { city: "São Mateus" },
    { city: "São Roque do Canaã" },
    { city: "Serra" },
    { city: "Sooretama" },
    { city: "Vargem Alta" },
    { city: "Venda Nova do Imigrante" },
    { city: "Viana" },
    { city: "Vila Pavão" },
    { city: "Vila Valério" },
    { city: "Vila Velha" },
    { city: "Vitória" }
  ],
  "GO": [
    { city: "Abadia de Goiás" },
    { city: "Abadiânia" },
    { city: "Acreúna" },
    { city: "Adelândia" },
    { city: "Água Fria de Goiás" },
    { city: "Água Limpa" },
    { city: "Águas Lindas de Goiás" },
    { city: "Alexânia" },
    { city: "Aloândia" },
    { city: "Alto Horizonte" },
    { city: "Alto Paraíso de Goiás" },
    { city: "Alvorada do Norte" },
    { city: "Amaralina" },
    { city: "Americano do Brasil" },
    { city: "Amorinópolis" },
    { city: "Anápolis" },
    { city: "Anhanguera" },
    { city: "Anicuns" },
    { city: "Aparecida de Goiânia" },
    { city: "Aparecida do Rio Doce" },
    { city: "Aporé" },
    { city: "Araçu" },
    { city: "Aragarças" },
    { city: "Aragoiânia" },
    { city: "Araguapaz" },
    { city: "Arenópolis" },
    { city: "Aruanã" },
    { city: "Aurilândia" },
    { city: "Avelinópolis" },
    { city: "Baliza" },
    { city: "Barro Alto" },
    { city: "Bela Vista de Goiás" },
    { city: "Bom Jardim de Goiás" },
    { city: "Bom Jesus de Goiás" },
    { city: "Bonfinópolis" },
    { city: "Bonópolis" },
    { city: "Brazabrantes" },
    { city: "Britânia" },
    { city: "Buriti Alegre" },
    { city: "Buriti de Goiás" },
    { city: "Buritinópolis" },
    { city: "Cabeceiras" },
    { city: "Cachoeira Alta" },
    { city: "Cachoeira de Goiás" },
    { city: "Cachoeira Dourada" },
    { city: "Caçu" },
    { city: "Caiapônia" },
    { city: "Caldas Novas" },
    { city: "Caldazinha" },
    { city: "Campestre de Goiás" },
    { city: "Campinaçu" },
    { city: "Campinorte" },
    { city: "Campo Alegre de Goiás" },
    { city: "Campos Limpo de Goiás" },
    { city: "Campos Belos" },
    { city: "Campos Verdes" },
    { city: "Carmo do Rio Verde" },
    { city: "Castelândia" },
    { city: "Catalão" },
    { city: "Caturaí" },
    { city: "Cavalcante" },
    { city: "Ceres" },
    { city: "Cezarina" },
    { city: "Chapadão do Céu" },
    { city: "Cidade Ocidental" },
    { city: "Cocalzinho de Goiás" },
    { city: "Colinas do Sul" },
    { city: "Córrego do Ouro" },
    { city: "Corumbá de Goiás" },
    { city: "Corumbaíba" },
    { city: "Cristalina" },
    { city: "Cristianópolis" },
    { city: "Crixás" },
    { city: "Cromínia" },
    { city: "Cumari" },
    { city: "Damianópolis" },
    { city: "Damolândia" },
    { city: "Davinópolis" },
    { city: "Diorama" },
    { city: "Divinópolis de Goiás" },
    { city: "Doverlândia" },
    { city: "Edealina" },
    { city: "Edéia" },
    { city: "Estrela do Norte" },
    { city: "Faina" },
    { city: "Fazenda Nova" },
    { city: "Firminópolis" },
    { city: "Flores de Goiás" },
    { city: "Formosa" },
    { city: "Formoso" },
    { city: "Gameleira de Goiás" },
    { city: "Goianápolis" },
    { city: "Goiandira" },
    { city: "Goianésia" },
    { city: "Goiânia" },
    { city: "Goianira" },
    { city: "Goiás" },
    { city: "Goiatuba" },
    { city: "Gouvelândia" },
    { city: "Guapó" },
    { city: "Guaraíta" },
    { city: "Guarani de Goiás" },
    { city: "Guarinos" },
    { city: "Heitoraí" },
    { city: "Hidrolândia" },
    { city: "Hidrolina" },
    { city: "Iaciara" },
    { city: "Inaciolândia" },
    { city: "Indiara" },
    { city: "Inhumas" },
    { city: "Ipameri" },
    { city: "Ipiranga de Goiás" },
    { city: "Iporá" },
    { city: "Israelândia" },
    { city: "Itaberaí" },
    { city: "Itaguari" },
    { city: "Itaguaru" },
    { city: "Itajá" },
    { city: "Itapaci" },
    { city: "Itapirapuã" },
    { city: "Itapuranga" },
    { city: "Itarumã" },
    { city: "Itauçu" },
    { city: "Itumbiara" },
    { city: "Ivolândia" },
    { city: "Jandaia" },
    { city: "Jaraguá" },
    { city: "Jataí" },
    { city: "Jaupaci" },
    { city: "Jesúpolis" },
    { city: "Joviânia" },
    { city: "Jussara" },
    { city: "Lagoa Santa" },
    { city: "Leopoldo de Bulhões" },
    { city: "Luziânia" },
    { city: "Mairipotaba" },
    { city: "Mambaí" },
    { city: "Mara Rosa" },
    { city: "Marzagão" },
    { city: "Matrinchã" },
    { city: "Maurilândia" },
    { city: "Mimoso de Goiás" },
    { city: "Minaçu" },
    { city: "Mineiros" },
    { city: "Moiporá" },
    { city: "Monte Alegre de Goiás" },
    { city: "Montes Claros de Goiás" },
    { city: "Montividiu" },
    { city: "Montividiu do Norte" },
    { city: "Morrinhos" },
    { city: "Morro Agudo de Goiás" },
    { city: "Mossâmedes" },
    { city: "Mozarlândia" },
    { city: "Mundo Novo" },
    { city: "Mutunópolis" },
    { city: "Nazário" },
    { city: "Nerópolis" },
    { city: "Niquelândia" },
    { city: "Nova América" },
    { city: "Nova Aurora" },
    { city: "Nova Crixás" },
    { city: "Nova Glória" },
    { city: "Nova Iguaçu de Goiás" },
    { city: "Nova Roma" },
    { city: "Nova Veneza" },
    { city: "Novo Brasil" },
    { city: "Novo Gama" },
    { city: "Novo Planalto" },
    { city: "Orizona" },
    { city: "Ouro Verde de Goiás" },
    { city: "Ouvidor" },
    { city: "Padre Bernardo" },
    { city: "Palestina de Goiás" },
    { city: "Palmeiras de Goiás" },
    { city: "Palmelo" },
    { city: "Palminópolis" },
    { city: "Panamá" },
    { city: "Paranaiguara" },
    { city: "Paraúna" },
    { city: "Perolândia" },
    { city: "Petrolina de Goiás" },
    { city: "Pilar de Goiás" },
    { city: "Piracanjuba" },
    { city: "Piranhas" },
    { city: "Pirenópolis" },
    { city: "Pires do Rio" },
    { city: "Planaltina" },
    { city: "Pontalina" },
    { city: "Porangatu" },
    { city: "Porteirão" },
    { city: "Portelândia" },
    { city: "Posse" },
    { city: "Professor Jamil" },
    { city: "Quirinópolis" },
    { city: "Rialma" },
    { city: "Rianápolis" },
    { city: "Rio Quente" },
    { city: "Rio Verde" },
    { city: "Rubiataba" },
    { city: "Sanclerlândia" },
    { city: "Santa Bárbara de Goiás" },
    { city: "Santa Cruz de Goiás" },
    { city: "Santa Fé de Goiás" },
    { city: "Santa Helena de Goiás" },
    { city: "Santa Isabel" },
    { city: "Santa Rita do Araguaia" },
    { city: "Santa Rita do Novo Destino" },
    { city: "Santa Rosa de Goiás" },
    { city: "Santa Tereza de Goiás" },
    { city: "Santa Terezinha de Goiás" },
    { city: "Santo Antônio da Barra" },
    { city: "Santo Antônio de Goiás" },
    { city: "Santo Antônio do Descoberto" },
    { city: "São Domingos" },
    { city: "São Francisco de Goiás" },
    { city: "São João d'Aliança" },
    { city: "São João da Paraúna" },
    { city: "São Luís de Montes Belos" },
    { city: "São Luíz do Norte" },
    { city: "São Miguel do Araguaia" },
    { city: "São Miguel do Passa Quatro" },
    { city: "São Patrício" },
    { city: "São Simão" },
    { city: "Senador Canedo" },
    { city: "Serranópolis" },
    { city: "Silvânia" },
    { city: "Simolândia" },
    { city: "Sítio d'Abadia" },
    { city: "Taquaral de Goiás" },
    { city: "Teresina de Goiás" },
    { city: "Terezópolis de Goiás" },
    { city: "Três Ranchos" },
    { city: "Trindade" },
    { city: "Trombas" },
    { city: "Turvânia" },
    { city: "Turvelândia" },
    { city: "Uirapuru" },
    { city: "Uruaçu" },
    { city: "Uruana" },
    { city: "Urutaí" },
    { city: "Valparaíso de Goiás" },
    { city: "Varjão" },
    { city: "Vianópolis" },
    { city: "Vicentinópolis" },
    { city: "Vila Boa" },
    { city: "Vila Propício" }
  ],
  "MA": [
    { city: "Açailândia" },
    { city: "Afonso Cunha" },
    { city: "Água Doce do Maranhão" },
    { city: "Alcântara" },
    { city: "Aldeias Altas" },
    { city: "Altamira do Maranhão" },
    { city: "Alto Alegre do Maranhão" },
    { city: "Alto Alegre do Pindaré" },
    { city: "Alto Parnaíba" },
    { city: "Amapá do Maranhão" },
    { city: "Amarante do Maranhão" },
    { city: "Anajatuba" },
    { city: "Anapurus" },
    { city: "Apicum-Açu" },
    { city: "Araguanã" },
    { city: "Araioses" },
    { city: "Arame" },
    { city: "Arari" },
    { city: "Axixá" },
    { city: "Bacabal" },
    { city: "Bacabeira" },
    { city: "Bacuri" },
    { city: "Bacurituba" },
    { city: "Balsas" },
    { city: "Barão de Grajaú" },
    { city: "Barra do Corda" },
    { city: "Barreirinhas" },
    { city: "Bela Vista do Maranhão" },
    { city: "Belágua" },
    { city: "Benedito Leite" },
    { city: "Bequimão" },
    { city: "Bernardo do Mearim" },
    { city: "Boa Vista do Gurupi" },
    { city: "Bom Jardim" },
    { city: "Bom Jesus das Selvas" },
    { city: "Bom Lugar" },
    { city: "Brejo" },
    { city: "Brejo de Areia" },
    { city: "Buriti" },
    { city: "Buriti Bravo" },
    { city: "Buriticupu" },
    { city: "Buritirana" },
    { city: "Cachoeira Grande" },
    { city: "Cajapió" },
    { city: "Cajari" },
    { city: "Campestre do Maranhão" },
    { city: "Cândido Mendes" },
    { city: "Cantanhede" },
    { city: "Capinzal do Norte" },
    { city: "Carolina" },
    { city: "Carutapera" },
    { city: "Caxias" },
    { city: "Cedral" },
    { city: "Central do Maranhão" },
    { city: "Centro do Guilherme" },
    { city: "Centro Novo do Maranhão" },
    { city: "Chapadinha" },
    { city: "Cidelândia" },
    { city: "Codó" },
    { city: "Coelho Neto" },
    { city: "Colinas" },
    { city: "Conceição do Lago-Açu" },
    { city: "Coroatá" },
    { city: "Cururupu" },
    { city: "Davinópolis" },
    { city: "Dom Pedro" },
    { city: "Duque Bacelar" },
    { city: "Esperantinópolis" },
    { city: "Estreito" },
    { city: "Feira Nova do Maranhão" },
    { city: "Fernando Falcão" },
    { city: "Formosa da Serra Negra" },
    { city: "Fortaleza dos Nogueiras" },
    { city: "Fortuna" },
    { city: "Godofredo Viana" },
    { city: "Gonçalves Dias" },
    { city: "Governador Archer" },
    { city: "Governador Edison Lobão" },
    { city: "Governador Eugênio Barros" },
    { city: "Governador Luiz Rocha" },
    { city: "Governador Newton Bello" },
    { city: "Governador Nunes Freire" },
    { city: "Graça Aranha" },
    { city: "Grajaú" },
    { city: "Guimarães" },
    { city: "Humberto de Campos" },
    { city: "Icatu" },
    { city: "Igarapé do Meio" },
    { city: "Igarapé Grande" },
    { city: "Imperatriz" },
    { city: "Itaipava do Grajaú" },
    { city: "Itapecuru Mirim" },
    { city: "Itinga do Maranhão" },
    { city: "Jatobá" },
    { city: "Jenipapo dos Vieiras" },
    { city: "João Lisboa" },
    { city: "Joselândia" },
    { city: "Junco do Maranhão" },
    { city: "Lago da Pedra" },
    { city: "Lago do Junco" },
    { city: "Lago dos Rodrigues" },
    { city: "Lago Verde" },
    { city: "Lagoa do Mato" },
    { city: "Lagoa Grande do Maranhão" },
    { city: "Lajeado Novo" },
    { city: "Lima Campos" },
    { city: "Loreto" },
    { city: "Luís Domingues" },
    { city: "Magalhães de Almeida" },
    { city: "Maracaçumé" },
    { city: "Marajá do Sena" },
    { city: "Maranhãozinho" },
    { city: "Mata Roma" },
    { city: "Matinha" },
    { city: "Matões" },
    { city: "Matões do Norte" },
    { city: "Milagres do Maranhão" },
    { city: "Mirador" },
    { city: "Miranda do Norte" },
    { city: "Mirinzal" },
    { city: "Monção" },
    { city: "Montes Altos" },
    { city: "Morros" },
    { city: "Nina Rodrigues" },
    { city: "Nova Colinas" },
    { city: "Nova Iorque" },
    { city: "Nova Olinda do Maranhão" },
    { city: "Olho d'Água das Cunhãs" },
    { city: "Olinda Nova do Maranhão" },
    { city: "Paço do Lumiar" },
    { city: "Palmeirândia" },
    { city: "Paraibano" },
    { city: "Parnarama" },
    { city: "Passagem Franca" },
    { city: "Pastos Bons" },
    { city: "Paulino Neves" },
    { city: "Paulo Ramos" },
    { city: "Pedreiras" },
    { city: "Pedro do Rosário" },
    { city: "Penalva" },
    { city: "Peri Mirim" },
    { city: "Peritoró" },
    { city: "Pindaré Mirim" },
    { city: "Pinheiro" },
    { city: "Pio XII" },
    { city: "Pirapemas" },
    { city: "Poção de Pedras" },
    { city: "Porto Franco" },
    { city: "Porto Rico do Maranhão" },
    { city: "Presidente Dutra" },
    { city: "Presidente Juscelino" },
    { city: "Presidente Médici" },
    { city: "Presidente Sarney" },
    { city: "Presidente Vargas" },
    { city: "Primeira Cruz" },
    { city: "Raposa" },
    { city: "Riachão" },
    { city: "Ribamar Fiquene" },
    { city: "Rosário" },
    { city: "Sambaíba" },
    { city: "Santa Filomena do Maranhão" },
    { city: "Santa Helena" },
    { city: "Santa Inês" },
    { city: "Santa Luzia" },
    { city: "Santa Luzia do Paruá" },
    { city: "Santa Quitéria do Maranhão" },
    { city: "Santa Rita" },
    { city: "Santana do Maranhão" },
    { city: "Santo Amaro do Maranhão" },
    { city: "Santo Antônio dos Lopes" },
    { city: "São Benedito do Rio Preto" },
    { city: "São Bento" },
    { city: "São Bernardo" },
    { city: "São Domingos do Azeitão" },
    { city: "São Domingos do Maranhão" },
    { city: "São Félix de Balsas" },
    { city: "São Francisco do Brejão" },
    { city: "São Francisco do Maranhão" },
    { city: "São João Batista" },
    { city: "São João do Carú" },
    { city: "São João do Paraíso" },
    { city: "São João do Soter" },
    { city: "São João dos Patos" },
    { city: "São José de Ribamar" },
    { city: "São José dos Basílios" },
    { city: "São Luís" },
    { city: "São Luís Gonzaga do Maranhão" },
    { city: "São Mateus do Maranhão" },
    { city: "São Pedro da Água Branca" },
    { city: "São Pedro dos Crentes" },
    { city: "São Raimundo das Mangabeiras" },
    { city: "São Raimundo do Doca Bezerra" },
    { city: "São Roberto" },
    { city: "São Vicente Ferrer" },
    { city: "Satubinha" },
    { city: "Senador Alexandre Costa" },
    { city: "Senador La Rocque" },
    { city: "Serrano do Maranhão" },
    { city: "Sítio Novo" },
    { city: "Sucupira do Norte" },
    { city: "Sucupira do Riachão" },
    { city: "Tasso Fragoso" },
    { city: "Timbiras" },
    { city: "Timon" },
    { city: "Trizidela do Vale" },
    { city: "Tufilândia" },
    { city: "Tuntum" },
    { city: "Turiaçu" },
    { city: "Turilândia" },
    { city: "Tutóia" },
    { city: "Urbano Santos" },
    { city: "Vargem Grande" },
    { city: "Viana" },
    { city: "Vila Nova dos Martírios" },
    { city: "Vitória do Mearim" },
    { city: "Vitorino Freire" },
    { city: "Zé Doca" }
  ],
  "MG": [
    { city: "Abadia dos Dourados" },
    { city: "Abaeté" },
    { city: "Abre Campo" },
    { city: "Acaiaca" },
    { city: "Açucena" },
    { city: "Água Boa" },
    { city: "Água Comprida" },
    { city: "Aguanil" },
    { city: "Águas Formosas" },
    { city: "Águas Vermelhas" },
    { city: "Aimorés" },
    { city: "Aiuruoca" },
    { city: "Alagoa" },
    { city: "Albertina" },
    { city: "Além Paraíba" },
    { city: "Alfenas" },
    { city: "Alfredo Vasconcelos" },
    { city: "Almenara" },
    { city: "Alpercata" },
    { city: "Alpinópolis" },
    { city: "Alterosa" },
    { city: "Alto Caparaó" },
    { city: "Alto Jequitibá" },
    { city: "Alto Rio Doce" },
    { city: "Alvarenga" },
    { city: "Alvinópolis" },
    { city: "Alvorada de Minas" },
    { city: "Amparo do Serra" },
    { city: "Andradas" },
    { city: "Andrelândia" },
    { city: "Angelândia" },
    { city: "Antônio Carlos" },
    { city: "Antônio Dias" },
    { city: "Antônio Prado de Minas" },
    { city: "Araçaí" },
    { city: "Aracitaba" },
    { city: "Araçuaí" },
    { city: "Araguari" },
    { city: "Arantina" },
    { city: "Araponga" },
    { city: "Araporã" },
    { city: "Arapuá" },
    { city: "Araújos" },
    { city: "Araxá" },
    { city: "Arceburgo" },
    { city: "Arcos" },
    { city: "Areado" },
    { city: "Argirita" },
    { city: "Aricanduva" },
    { city: "Arinos" },
    { city: "Astolfo Dutra" },
    { city: "Ataléia" },
    { city: "Augusto de Lima" },
    { city: "Baependi" },
    { city: "Baldim" },
    { city: "Bambuí" },
    { city: "Bandeira" },
    { city: "Bandeira do Sul" },
    { city: "Barão de Cocais" },
    { city: "Barão de Monte Alto" },
    { city: "Barbacena" },
    { city: "Barra Longa" },
    { city: "Barroso" },
    { city: "Bela Vista de Minas" },
    { city: "Belmiro Braga" },
    { city: "Belo Horizonte" },
    { city: "Belo Oriente" },
    { city: "Belo Vale" },
    { city: "Berilo" },
    { city: "Berizal" },
    { city: "Bertópolis" },
    { city: "Betim" },
    { city: "Bias Fortes" },
    { city: "Bicas" },
    { city: "Biquinhas" },
    { city: "Boa Esperança" },
    { city: "Bocaina de Minas" },
    { city: "Bocaiúva" },
    { city: "Bom Despacho" },
    { city: "Bom Jardim de Minas" },
    { city: "Bom Jesus da Penha" },
    { city: "Bom Jesus do Amparo" },
    { city: "Bom Jesus do Galho" },
    { city: "Bom Repouso" },
    { city: "Bom Sucesso" },
    { city: "Bonfim" },
    { city: "Bonfinópolis de Minas" },
    { city: "Bonito de Minas" },
    { city: "Borda da Mata" },
    { city: "Botelhos" },
    { city: "Botumirim" },
    { city: "Brás Pires" },
    { city: "Brasilândia de Minas" },
    { city: "Brasília de Minas" },
    { city: "Brasópolis" },
    { city: "Braúnas" },
    { city: "Brumadinho" },
    { city: "Bueno Brandão" },
    { city: "Buenópolis" },
    { city: "Bugre" },
    { city: "Buritis" },
    { city: "Buritizeiro" },
    { city: "Cabeceira Grande" },
    { city: "Cabo Verde" },
    { city: "Cachoeira da Prata" },
    { city: "Cachoeira de Minas" },
    { city: "Cachoeira de Pajeú" },
    { city: "Cachoeira Dourada" },
    { city: "Caetanópolis" },
    { city: "Caeté" },
    { city: "Caiana" },
    { city: "Cajuri" },
    { city: "Caldas" },
    { city: "Camacho" },
    { city: "Camanducaia" },
    { city: "Cambuí" },
    { city: "Cambuquira" },
    { city: "Campanário" },
    { city: "Campanha" },
    { city: "Campestre" },
    { city: "Campina Verde" },
    { city: "Campo Azul" },
    { city: "Campo Belo" },
    { city: "Campo do Meio" },
    { city: "Campo Florido" },
    { city: "Campos Altos" },
    { city: "Campos Gerais" },
    { city: "Cana Verde" },
    { city: "Canaã" },
    { city: "Canápolis" },
    { city: "Candeias" },
    { city: "Cantagalo" },
    { city: "Caparaó" },
    { city: "Capela Nova" },
    { city: "Capelinha" },
    { city: "Capetinga" },
    { city: "Capim Branco" },
    { city: "Capinópolis" },
    { city: "Capitão Andrade" },
    { city: "Capitão Enéas" },
    { city: "Capitólio" },
    { city: "Caputira" },
    { city: "Caraí" },
    { city: "Caranaíba" },
    { city: "Carandaí" },
    { city: "Carangola" },
    { city: "Caratinga" },
    { city: "Carbonita" },
    { city: "Careaçu" },
    { city: "Carlos Chagas" },
    { city: "Carmésia" },
    { city: "Carmo da Cachoeira" },
    { city: "Carmo da Mata" },
    { city: "Carmo de Minas" },
    { city: "Carmo do Cajuru" },
    { city: "Carmo do Paranaíba" },
    { city: "Carmo do Rio Claro" },
    { city: "Carmópolis de Minas" },
    { city: "Carneirinho" },
    { city: "Carrancas" },
    { city: "Carvalhópolis" },
    { city: "Carvalhos" },
    { city: "Casa Grande" },
    { city: "Cascalho Rico" },
    { city: "Cássia" },
    { city: "Cataguases" },
    { city: "Catas Altas" },
    { city: "Catas Altas da Noruega" },
    { city: "Catuji" },
    { city: "Catuti" },
    { city: "Caxambu" },
    { city: "Cedro do Abaeté" },
    { city: "Central de Minas" },
    { city: "Centralina" },
    { city: "Chácara" },
    { city: "Chalé" },
    { city: "Chapada do Norte" },
    { city: "Chapada Gaúcha" },
    { city: "Chiador" },
    { city: "Cipotânea" },
    { city: "Claraval" },
    { city: "Claro dos Poções" },
    { city: "Cláudio" },
    { city: "Coimbra" },
    { city: "Coluna" },
    { city: "Comendador Gomes" },
    { city: "Comercinho" },
    { city: "Conceição da Aparecida" },
    { city: "Conceição da Barra de Minas" },
    { city: "Conceição das Alagoas" },
    { city: "Conceição das Pedras" },
    { city: "Conceição de Ipanema" },
    { city: "Conceição do Mato Dentro" },
    { city: "Conceição do Pará" },
    { city: "Conceição do Rio Verde" },
    { city: "Conceição dos Ouros" },
    { city: "Cônego Marinho" },
    { city: "Confins" },
    { city: "Congonhal" },
    { city: "Congonhas" },
    { city: "Congonhas do Norte" },
    { city: "Conquista" },
    { city: "Conselheiro Lafaiete" },
    { city: "Conselheiro Pena" },
    { city: "Consolação" },
    { city: "Contagem" },
    { city: "Coqueiral" },
    { city: "Coração de Jesus" },
    { city: "Cordisburgo" },
    { city: "Cordislândia" },
    { city: "Corinto" },
    { city: "Coroaci" },
    { city: "Coromandel" },
    { city: "Coronel Fabriciano" },
    { city: "Coronel Murta" },
    { city: "Coronel Pacheco" },
    { city: "Coronel Xavier Chaves" },
    { city: "Córrego Danta" },
    { city: "Córrego do Bom Jesus" },
    { city: "Córrego Fundo" },
    { city: "Córrego Novo" },
    { city: "Couto de Magalhães de Minas" },
    { city: "Crisólita" },
    { city: "Cristais" },
    { city: "Cristália" },
    { city: "Cristiano Otoni" },
    { city: "Cristina" },
    { city: "Crucilândia" },
    { city: "Cruzeiro da Fortaleza" },
    { city: "Cruzília" },
    { city: "Cuparaque" },
    { city: "Curral de Dentro" },
    { city: "Curvelo" },
    { city: "Datas" },
    { city: "Delfim Moreira" },
    { city: "Delfinópolis" },
    { city: "Delta" },
    { city: "Descoberto" },
    { city: "Desterro de Entre Rios" },
    { city: "Desterro do Melo" },
    { city: "Diamantina" },
    { city: "Diogo de Vasconcelos" },
    { city: "Dionísio" },
    { city: "Divinésia" },
    { city: "Divino" },
    { city: "Divino das Laranjeiras" },
    { city: "Divinolândia de Minas" },
    { city: "Divinópolis" },
    { city: "Divisa Alegre" },
    { city: "Divisa Nova" },
    { city: "Divisópolis" },
    { city: "Dom Bosco" },
    { city: "Dom Cavati" },
    { city: "Dom Joaquim" },
    { city: "Dom Silvério" },
    { city: "Dom Viçoso" },
    { city: "Dona Euzébia" },
    { city: "Dores de Campos" },
    { city: "Dores de Guanhães" },
    { city: "Dores do Indaiá" },
    { city: "Dores do Turvo" },
    { city: "Doresópolis" },
    { city: "Douradoquara" },
    { city: "Durandé" },
    { city: "Elói Mendes" },
    { city: "Engenheiro Caldas" },
    { city: "Engenheiro Navarro" },
    { city: "Entre Folhas" },
    { city: "Entre Rios de Minas" },
    { city: "Ervália" },
    { city: "Esmeraldas" },
    { city: "Espera Feliz" },
    { city: "Espinosa" },
    { city: "Espírito Santo do Dourado" },
    { city: "Estiva" },
    { city: "Estrela Dalva" },
    { city: "Estrela do Indaiá" },
    { city: "Estrela do Sul" },
    { city: "Eugenópolis" },
    { city: "Ewbank da Câmara" },
    { city: "Extrema" },
    { city: "Fama" },
    { city: "Faria Lemos" },
    { city: "Felício dos Santos" },
    { city: "Felisburgo" },
    { city: "Felixlândia" },
    { city: "Fernandes Tourinho" },
    { city: "Ferros" },
    { city: "Fervedouro" },
    { city: "Florestal" },
    { city: "Formiga" },
    { city: "Formoso" },
    { city: "Fortaleza de Minas" },
    { city: "Fortuna de Minas" },
    { city: "Francisco Badaró" },
    { city: "Francisco Dumont" },
    { city: "Francisco Sá" },
    { city: "Franciscópolis" },
    { city: "Frei Gaspar" },
    { city: "Frei Inocêncio" },
    { city: "Frei Lagonegro" },
    { city: "Fronteira" },
    { city: "Fronteira dos Vales" },
    { city: "Fruta de Leite" },
    { city: "Frutal" },
    { city: "Funilândia" },
    { city: "Galiléia" },
    { city: "Gameleiras" },
    { city: "Glaucilândia" },
    { city: "Goiabeira" },
    { city: "Goianá" },
    { city: "Gonçalves" },
    { city: "Gonzaga" },
    { city: "Gouveia" },
    { city: "Governador Valadares" },
    { city: "Grão Mogol" },
    { city: "Grupiara" },
    { city: "Guanhães" },
    { city: "Guapé" },
    { city: "Guaraciaba" },
    { city: "Guaraciama" },
    { city: "Guaranésia" },
    { city: "Guarani" },
    { city: "Guarará" },
    { city: "Guarda-Mor" },
    { city: "Guaxupé" },
    { city: "Guidoval" },
    { city: "Guimarânia" },
    { city: "Guiricema" },
    { city: "Gurinhatã" },
    { city: "Heliodora" },
    { city: "Iapu" },
    { city: "Ibertioga" },
    { city: "Ibiá" },
    { city: "Ibiaí" },
    { city: "Ibiracatu" },
    { city: "Ibiraci" },
    { city: "Ibirité" },
    { city: "Ibitiúra de Minas" },
    { city: "Ibituruna" },
    { city: "Icaraí de Minas" },
    { city: "Igarapé" },
    { city: "Igaratinga" },
    { city: "Iguatama" },
    { city: "Ijaci" },
    { city: "Ilicínea" },
    { city: "Imbé de Minas" },
    { city: "Inconfidentes" },
    { city: "Indaiabira" },
    { city: "Indianópolis" },
    { city: "Ingaí" },
    { city: "Inhapim" },
    { city: "Inhaúma" },
    { city: "Inimutaba" },
    { city: "Ipaba" },
    { city: "Ipanema" },
    { city: "Ipatinga" },
    { city: "Ipiaçu" },
    { city: "Ipuiúna" },
    { city: "Iraí de Minas" },
    { city: "Itabira" },
    { city: "Itabirinha de Mantena" },
    { city: "Itabirito" },
    { city: "Itacambira" },
    { city: "Itacarambi" },
    { city: "Itaguara" },
    { city: "Itaipé" },
    { city: "Itajubá" },
    { city: "Itamarandiba" },
    { city: "Itamarati de Minas" },
    { city: "Itambacuri" },
    { city: "Itambé do Mato Dentro" },
    { city: "Itamogi" },
    { city: "Itamonte" },
    { city: "Itanhandu" },
    { city: "Itanhomi" },
    { city: "Itaobim" },
    { city: "Itapagipe" },
    { city: "Itapecerica" },
    { city: "Itapeva" },
    { city: "Itatiaiuçu" },
    { city: "Itaú de Minas" },
    { city: "Itaúna" },
    { city: "Itaverava" },
    { city: "Itinga" },
    { city: "Itueta" },
    { city: "Ituiutaba" },
    { city: "Itumirim" },
    { city: "Iturama" },
    { city: "Itutinga" },
    { city: "Jaboticatubas" },
    { city: "Jacinto" },
    { city: "Jacuí" },
    { city: "Jacutinga" },
    { city: "Jaguaraçu" },
    { city: "Jaíba" },
    { city: "Jampruca" },
    { city: "Janaúba" },
    { city: "Januária" },
    { city: "Japaraíba" },
    { city: "Japonvar" },
    { city: "Jeceaba" },
    { city: "Jenipapo de Minas" },
    { city: "Jequeri" },
    { city: "Jequitaí" },
    { city: "Jequitibá" },
    { city: "Jequitinhonha" },
    { city: "Jesuânia" },
    { city: "Joaíma" },
    { city: "Joanésia" },
    { city: "João Monlevade" },
    { city: "João Pinheiro" },
    { city: "Joaquim Felício" },
    { city: "Jordânia" },
    { city: "José Gonçalves de Minas" },
    { city: "José Raydan" },
    { city: "Josenópolis" },
    { city: "Juatuba" },
    { city: "Juiz de Fora" },
    { city: "Juramento" },
    { city: "Juruaia" },
    { city: "Juvenília" },
    { city: "Ladainha" },
    { city: "Lagamar" },
    { city: "Lagoa da Prata" },
    { city: "Lagoa dos Patos" },
    { city: "Lagoa Dourada" },
    { city: "Lagoa Formosa" },
    { city: "Lagoa Grande" },
    { city: "Lagoa Santa" },
    { city: "Lajinha" },
    { city: "Lambari" },
    { city: "Lamim" },
    { city: "Laranjal" },
    { city: "Lassance" },
    { city: "Lavras" },
    { city: "Leandro Ferreira" },
    { city: "Leme do Prado" },
    { city: "Leopoldina" },
    { city: "Liberdade" },
    { city: "Lima Duarte" },
    { city: "Limeira do Oeste" },
    { city: "Lontra" },
    { city: "Luisburgo" },
    { city: "Luislândia" },
    { city: "Luminárias" },
    { city: "Luz" },
    { city: "Machacalis" },
    { city: "Machado" },
    { city: "Madre de Deus de Minas" },
    { city: "Malacacheta" },
    { city: "Mamonas" },
    { city: "Manga" },
    { city: "Manhuaçu" },
    { city: "Manhumirim" },
    { city: "Mantena" },
    { city: "Mar de Espanha" },
    { city: "Maravilhas" },
    { city: "Maria da Fé" },
    { city: "Mariana" },
    { city: "Marilac" },
    { city: "Mário Campos" },
    { city: "Maripá de Minas" },
    { city: "Marliéria" },
    { city: "Marmelópolis" },
    { city: "Martinho Campos" },
    { city: "Martins Soares" },
    { city: "Mata Verde" },
    { city: "Materlândia" },
    { city: "Mateus Leme" },
    { city: "Mathias Lobato" },
    { city: "Matias Barbosa" },
    { city: "Matias Cardoso" },
    { city: "Matipó" },
    { city: "Mato Verde" },
    { city: "Matozinhos" },
    { city: "Matutina" },
    { city: "Medeiros" },
    { city: "Medina" },
    { city: "Mendes Pimentel" },
    { city: "Mercês" },
    { city: "Mesquita" },
    { city: "Minas Novas" },
    { city: "Minduri" },
    { city: "Mirabela" },
    { city: "Miradouro" },
    { city: "Miraí" },
    { city: "Miravânia" },
    { city: "Moeda" },
    { city: "Moema" },
    { city: "Monjolos" },
    { city: "Monsenhor Paulo" },
    { city: "Montalvânia" },
    { city: "Monte Alegre de Minas" },
    { city: "Monte Azul" },
    { city: "Monte Belo" },
    { city: "Monte Carmelo" },
    { city: "Monte Formoso" },
    { city: "Monte Santo de Minas" },
    { city: "Monte Sião" },
    { city: "Montes Claros" },
    { city: "Montezuma" },
    { city: "Morada Nova de Minas" },
    { city: "Morro da Garça" },
    { city: "Morro do Pilar" },
    { city: "Munhoz" },
    { city: "Muriaé" },
    { city: "Mutum" },
    { city: "Muzambinho" },
    { city: "Nacip Raydan" },
    { city: "Nanuque" },
    { city: "Naque" },
    { city: "Natalândia" },
    { city: "Natércia" },
    { city: "Nazareno" },
    { city: "Nepomuceno" },
    { city: "Ninheira" },
    { city: "Nova Belém" },
    { city: "Nova Era" },
    { city: "Nova Lima" },
    { city: "Nova Módica" },
    { city: "Nova Ponte" },
    { city: "Nova Porteirinha" },
    { city: "Nova Resende" },
    { city: "Nova Serrana" },
    { city: "Nova União" },
    { city: "Novo Cruzeiro" },
    { city: "Novo Oriente de Minas" },
    { city: "Novorizonte" },
    { city: "Olaria" },
    { city: "Olhos-d'Água" },
    { city: "Olímpio Noronha" },
    { city: "Oliveira" },
    { city: "Oliveira Fortes" },
    { city: "Onça de Pitangui" },
    { city: "Oratórios" },
    { city: "Orizânia" },
    { city: "Ouro Branco" },
    { city: "Ouro Fino" },
    { city: "Ouro Preto" },
    { city: "Ouro Verde de Minas" },
    { city: "Padre Carvalho" },
    { city: "Padre Paraíso" },
    { city: "Pai Pedro" },
    { city: "Paineiras" },
    { city: "Pains" },
    { city: "Paiva" },
    { city: "Palma" },
    { city: "Palmópolis" },
    { city: "Papagaios" },
    { city: "Pará de Minas" },
    { city: "Paracatu" },
    { city: "Paraguaçu" },
    { city: "Paraisópolis" },
    { city: "Paraopeba" },
    { city: "Passa Quatro" },
    { city: "Passa Tempo" },
    { city: "Passa-Vinte" },
    { city: "Passabém" },
    { city: "Passos" },
    { city: "Patis" },
    { city: "Patos de Minas" },
    { city: "Patrocínio" },
    { city: "Patrocínio do Muriaé" },
    { city: "Paula Cândido" },
    { city: "Paulistas" },
    { city: "Pavão" },
    { city: "Peçanha" },
    { city: "Pedra Azul" },
    { city: "Pedra Bonita" },
    { city: "Pedra do Anta" },
    { city: "Pedra do Indaiá" },
    { city: "Pedra Dourada" },
    { city: "Pedralva" },
    { city: "Pedras de Maria da Cruz" },
    { city: "Pedrinópolis" },
    { city: "Pedro Leopoldo" },
    { city: "Pedro Teixeira" },
    { city: "Pequeri" },
    { city: "Pequi" },
    { city: "Perdigão" },
    { city: "Perdizes" },
    { city: "Perdões" },
    { city: "Periquito" },
    { city: "Pescador" },
    { city: "Piau" },
    { city: "Piedade de Caratinga" },
    { city: "Piedade de Ponte Nova" },
    { city: "Piedade do Rio Grande" },
    { city: "Piedade dos Gerais" },
    { city: "Pimenta" },
    { city: "Pingo-d'Água" },
    { city: "Pintópolis" },
    { city: "Piracema" },
    { city: "Pirajuba" },
    { city: "Piranga" },
    { city: "Piranguçu" },
    { city: "Piranguinho" },
    { city: "Pirapetinga" },
    { city: "Pirapora" },
    { city: "Piraúba" },
    { city: "Pitangui" },
    { city: "Piumhi" },
    { city: "Planura" },
    { city: "Poço Fundo" },
    { city: "Poços de Caldas" },
    { city: "Pocrane" },
    { city: "Pompéu" },
    { city: "Ponte Nova" },
    { city: "Ponto Chique" },
    { city: "Ponto dos Volantes" },
    { city: "Porteirinha" },
    { city: "Porto Firme" },
    { city: "Poté" },
    { city: "Pouso Alegre" },
    { city: "Pouso Alto" },
    { city: "Prados" },
    { city: "Prata" },
    { city: "Pratápolis" },
    { city: "Pratinha" },
    { city: "Presidente Bernardes" },
    { city: "Presidente Juscelino" },
    { city: "Presidente Kubitschek" },
    { city: "Presidente Olegário" },
    { city: "Prudente de Morais" },
    { city: "Quartel Geral" },
    { city: "Queluzito" },
    { city: "Raposos" },
    { city: "Raul Soares" },
    { city: "Recreio" },
    { city: "Reduto" },
    { city: "Resende Costa" },
    { city: "Resplendor" },
    { city: "Ressaquinha" },
    { city: "Riachinho" },
    { city: "Riacho dos Machados" },
    { city: "Ribeirão das Neves" },
    { city: "Ribeirão Vermelho" },
    { city: "Rio Acima" },
    { city: "Rio Casca" },
    { city: "Rio do Prado" },
    { city: "Rio Doce" },
    { city: "Rio Espera" },
    { city: "Rio Manso" },
    { city: "Rio Novo" },
    { city: "Rio Paranaíba" },
    { city: "Rio Pardo de Minas" },
    { city: "Rio Piracicaba" },
    { city: "Rio Pomba" },
    { city: "Rio Preto" },
    { city: "Rio Vermelho" },
    { city: "Ritápolis" },
    { city: "Rochedo de Minas" },
    { city: "Rodeiro" },
    { city: "Romaria" },
    { city: "Rosário da Limeira" },
    { city: "Rubelita" },
    { city: "Rubim" },
    { city: "Sabará" },
    { city: "Sabinópolis" },
    { city: "Sacramento" },
    { city: "Salinas" },
    { city: "Salto da Divisa" },
    { city: "Santa Bárbara" },
    { city: "Santa Bárbara do Leste" },
    { city: "Santa Bárbara do Monte Verde" },
    { city: "Santa Bárbara do Tugúrio" },
    { city: "Santa Cruz de Minas" },
    { city: "Santa Cruz de Salinas" },
    { city: "Santa Cruz do Escalvado" },
    { city: "Santa Efigênia de Minas" },
    { city: "Santa Fé de Minas" },
    { city: "Santa Helena de Minas" },
    { city: "Santa Juliana" },
    { city: "Santa Luzia" },
    { city: "Santa Margarida" },
    { city: "Santa Maria de Itabira" },
    { city: "Santa Maria do Salto" },
    { city: "Santa Maria do Suaçuí" },
    { city: "Santa Rita de Caldas" },
    { city: "Santa Rita de Ibitipoca" },
    { city: "Santa Rita de Jacutinga" },
    { city: "Santa Rita de Minas" },
    { city: "Santa Rita do Itueto" },
    { city: "Santa Rita do Sapucaí" },
    { city: "Santa Rosa da Serra" },
    { city: "Santa Vitória" },
    { city: "Santana da Vargem" },
    { city: "Santana de Cataguases" },
    { city: "Santana de Pirapama" },
    { city: "Santana do Deserto" },
    { city: "Santana do Garambéu" },
    { city: "Santana do Jacaré" },
    { city: "Santana do Manhuaçu" },
    { city: "Santana do Paraíso" },
    { city: "Santana do Riacho" },
    { city: "Santana dos Montes" },
    { city: "Santo Antônio do Amparo" },
    { city: "Santo Antônio do Aventureiro" },
    { city: "Santo Antônio do Grama" },
    { city: "Santo Antônio do Itambé" },
    { city: "Santo Antônio do Jacinto" },
    { city: "Santo Antônio do Monte" },
    { city: "Santo Antônio do Retiro" },
    { city: "Santo Antônio do Rio Abaixo" },
    { city: "Santo Hipólito" },
    { city: "Santos Dumont" },
    { city: "São Bento Abade" },
    { city: "São Brás do Suaçuí" },
    { city: "São Domingos das Dores" },
    { city: "São Domingos do Prata" },
    { city: "São Félix de Minas" },
    { city: "São Francisco" },
    { city: "São Francisco de Paula" },
    { city: "São Francisco de Sales" },
    { city: "São Francisco do Glória" },
    { city: "São Geraldo" },
    { city: "São Geraldo da Piedade" },
    { city: "São Geraldo do Baixio" },
    { city: "São Gonçalo do Abaeté" },
    { city: "São Gonçalo do Pará" },
    { city: "São Gonçalo do Rio Abaixo" },
    { city: "São Gonçalo do Rio Preto" },
    { city: "São Gonçalo do Sapucaí" },
    { city: "São Gotardo" },
    { city: "São João Batista do Glória" },
    { city: "São João da Lagoa" },
    { city: "São João da Mata" },
    { city: "São João da Ponte" },
    { city: "São João das Missões" },
    { city: "São João del Rei" },
    { city: "São João do Manhuaçu" },
    { city: "São João do Manteninha" },
    { city: "São João do Oriente" },
    { city: "São João do Pacuí" },
    { city: "São João do Paraíso" },
    { city: "São João Evangelista" },
    { city: "São João Nepomuceno" },
    { city: "São Joaquim de Bicas" },
    { city: "São José da Barra" },
    { city: "São José da Lapa" },
    { city: "São José da Safira" },
    { city: "São José da Varginha" },
    { city: "São José do Alegre" },
    { city: "São José do Divino" },
    { city: "São José do Goiabal" },
    { city: "São José do Jacuri" },
    { city: "São José do Mantimento" },
    { city: "São Lourenço" },
    { city: "São Miguel do Anta" },
    { city: "São Pedro da União" },
    { city: "São Pedro do Suaçuí" },
    { city: "São Pedro dos Ferros" },
    { city: "São Romão" },
    { city: "São Roque de Minas" },
    { city: "São Sebastião da Bela Vista" },
    { city: "São Sebastião da Vargem Alegre" },
    { city: "São Sebastião do Anta" },
    { city: "São Sebastião do Maranhão" },
    { city: "São Sebastião do Oeste" },
    { city: "São Sebastião do Paraíso" },
    { city: "São Sebastião do Rio Preto" },
    { city: "São Sebastião do Rio Verde" },
    { city: "São Thomé das Letras" },
    { city: "São Tiago" },
    { city: "São Tomás de Aquino" },
    { city: "São Vicente de Minas" },
    { city: "Sapucaí-Mirim" },
    { city: "Sardoá" },
    { city: "Sarzedo" },
    { city: "Sem-Peixe" },
    { city: "Senador Amaral" },
    { city: "Senador Cortes" },
    { city: "Senador Firmino" },
    { city: "Senador José Bento" },
    { city: "Senador Modestino Gonçalves" },
    { city: "Senhora de Oliveira" },
    { city: "Senhora do Porto" },
    { city: "Senhora dos Remédios" },
    { city: "Sericita" },
    { city: "Seritinga" },
    { city: "Serra Azul de Minas" },
    { city: "Serra da Saudade" },
    { city: "Serra do Salitre" },
    { city: "Serra dos Aimorés" },
    { city: "Serrania" },
    { city: "Serranópolis de Minas" },
    { city: "Serranos" },
    { city: "Serro" },
    { city: "Sete Lagoas" },
    { city: "Setubinha" },
    { city: "Silveirânia" },
    { city: "Silvianópolis" },
    { city: "Simão Pereira" },
    { city: "Simonésia" },
    { city: "Sobrália" },
    { city: "Soledade de Minas" },
    { city: "Tabuleiro" },
    { city: "Taiobeiras" },
    { city: "Taparuba" },
    { city: "Tapira" },
    { city: "Tapiraí" },
    { city: "Taquaraçu de Minas" },
    { city: "Tarumirim" },
    { city: "Teixeiras" },
    { city: "Teófilo Otoni" },
    { city: "Timóteo" },
    { city: "Tiradentes" },
    { city: "Tiros" },
    { city: "Tocantins" },
    { city: "Tocos do Moji" },
    { city: "Toledo" },
    { city: "Tombos" },
    { city: "Três Corações" },
    { city: "Três Marias" },
    { city: "Três Pontas" },
    { city: "Tumiritinga" },
    { city: "Tupaciguara" },
    { city: "Turmalina" },
    { city: "Turvolândia" },
    { city: "Ubá" },
    { city: "Ubaí" },
    { city: "Ubaporanga" },
    { city: "Uberaba" },
    { city: "Uberlândia" },
    { city: "Umburatiba" },
    { city: "Unaí" },
    { city: "União de Minas" },
    { city: "Uruana de Minas" },
    { city: "Urucânia" },
    { city: "Urucuia" },
    { city: "Vargem Alegre" },
    { city: "Vargem Bonita" },
    { city: "Vargem Grande do Rio Pardo" },
    { city: "Varginha" },
    { city: "Varjão de Minas" },
    { city: "Várzea da Palma" },
    { city: "Varzelândia" },
    { city: "Vazante" },
    { city: "Verdelândia" },
    { city: "Veredinha" },
    { city: "Veríssimo" },
    { city: "Vermelho Novo" },
    { city: "Vespasiano" },
    { city: "Viçosa" },
    { city: "Vieiras" },
    { city: "Virgem da Lapa" },
    { city: "Virgínia" },
    { city: "Virginópolis" },
    { city: "Virgolândia" },
    { city: "Visconde do Rio Branco" },
    { city: "Volta Grande" },
    { city: "Wenceslau Braz" }
  ],
  "MS": [
    { city: "Água Clara" },
    { city: "Alcinópolis" },
    { city: "Amambaí" },
    { city: "Anastácio" },
    { city: "Anaurilândia" },
    { city: "Angélica" },
    { city: "Antônio João" },
    { city: "Aparecida do Taboado" },
    { city: "Aquidauana" },
    { city: "Aral Moreira" },
    { city: "Bandeirantes" },
    { city: "Bataguassu" },
    { city: "Bataiporã" },
    { city: "Bela Vista" },
    { city: "Bodoquena" },
    { city: "Bonito" },
    { city: "Brasilândia" },
    { city: "Caarapó" },
    { city: "Camapuã" },
    { city: "Campo Grande" },
    { city: "Caracol" },
    { city: "Cassilândia" },
    { city: "Chapadão do Sul" },
    { city: "Corguinho" },
    { city: "Coronel Sapucaia" },
    { city: "Corumbá" },
    { city: "Costa Rica" },
    { city: "Coxim" },
    { city: "Deodápolis" },
    { city: "Dois Irmãos do Buriti" },
    { city: "Douradina" },
    { city: "Dourados" },
    { city: "Eldorado" },
    { city: "Fátima do Sul" },
    { city: "Glória de Dourados" },
    { city: "Guia Lopes da Laguna" },
    { city: "Iguatemi" },
    { city: "Inocência" },
    { city: "Itaporã" },
    { city: "Itaquiraí" },
    { city: "Ivinhema" },
    { city: "Japorã" },
    { city: "Jaraguari" },
    { city: "Jardim" },
    { city: "Jateí" },
    { city: "Juti" },
    { city: "Ladário" },
    { city: "Laguna Carapã" },
    { city: "Maracaju" },
    { city: "Miranda" },
    { city: "Mundo Novo" },
    { city: "Naviraí" },
    { city: "Nioaque" },
    { city: "Nova Alvorada do Sul" },
    { city: "Nova Andradina" },
    { city: "Novo Horizonte do Sul" },
    { city: "Paranaíba" },
    { city: "Paranhos" },
    { city: "Pedro Gomes" },
    { city: "Ponta Porã" },
    { city: "Porto Murtinho" },
    { city: "Ribas do Rio Pardo" },
    { city: "Rio Brilhante" },
    { city: "Rio Negro" },
    { city: "Rio Verde de Mato Grosso" },
    { city: "Rochedo" },
    { city: "Santa Rita do Pardo" },
    { city: "São Gabriel do Oeste" },
    { city: "Selvíria" },
    { city: "Sete Quedas" },
    { city: "Sidrolândia" },
    { city: "Sonora" },
    { city: "Tacuru" },
    { city: "Taquarussu" },
    { city: "Terenos" },
    { city: "Três Lagoas" },
    { city: "Vicentina" }
  ],
  "MT": [
    { city: "Acorizal" },
    { city: "Água Boa" },
    { city: "Alta Floresta" },
    { city: "Alto Araguaia" },
    { city: "Alto Boa Vista" },
    { city: "Alto Garças" },
    { city: "Alto Paraguai" },
    { city: "Alto Taquari" },
    { city: "Apiacás" },
    { city: "Araguaiana" },
    { city: "Araguainha" },
    { city: "Araputanga" },
    { city: "Arenápolis" },
    { city: "Aripuanã" },
    { city: "Barão de Melgaço" },
    { city: "Barra do Bugres" },
    { city: "Barra do Garças" },
    { city: "Bom Jesus do Araguaia" },
    { city: "Brasnorte" },
    { city: "Cáceres" },
    { city: "Campinápolis" },
    { city: "Campo Novo do Parecis" },
    { city: "Campo Verde" },
    { city: "Campos de Júlio" },
    { city: "Canabrava do Norte" },
    { city: "Canarana" },
    { city: "Carlinda" },
    { city: "Castanheira" },
    { city: "Chapada dos Guimarães" },
    { city: "Cláudia" },
    { city: "Cocalinho" },
    { city: "Colíder" },
    { city: "Colniza" },
    { city: "Comodoro" },
    { city: "Confresa" },
    { city: "Conquista d'Oeste" },
    { city: "Cotriguaçu" },
    { city: "Curvelândia" },
    { city: "Cuiabá" },
    { city: "Denise" },
    { city: "Diamantino" },
    { city: "Dom Aquino" },
    { city: "Feliz Natal" },
    { city: "Figueirópolis d'Oeste" },
    { city: "Gaúcha do Norte" },
    { city: "General Carneiro" },
    { city: "Glória d'Oeste" },
    { city: "Guarantã do Norte" },
    { city: "Guiratinga" },
    { city: "Indiavaí" },
    { city: "Itaúba" },
    { city: "Itiquira" },
    { city: "Jaciara" },
    { city: "Jangada" },
    { city: "Jauru" },
    { city: "Juara" },
    { city: "Juína" },
    { city: "Juruena" },
    { city: "Juscimeira" },
    { city: "Lambari d'Oeste" },
    { city: "Lucas do Rio Verde" },
    { city: "Luciára" },
    { city: "Marcelândia" },
    { city: "Matupá" },
    { city: "Mirassol d'Oeste" },
    { city: "Nobres" },
    { city: "Nortelândia" },
    { city: "Nossa Senhora do Livramento" },
    { city: "Nova Bandeirantes" },
    { city: "Nova Brasilândia" },
    { city: "Nova Canãa do Norte" },
    { city: "Nova Guarita" },
    { city: "Nova Lacerda" },
    { city: "Nova Marilândia" },
    { city: "Nova Maringá" },
    { city: "Nova Monte Verde" },
    { city: "Nova Mutum" },
    { city: "Nova Nazaré" },
    { city: "Nova Olímpia" },
    { city: "Nova Santa Helena" },
    { city: "Nova Ubiratã" },
    { city: "Nova Xavantina" },
    { city: "Novo Horizonte do Norte" },
    { city: "Novo Mundo" },
    { city: "Novo Santo Antônio" },
    { city: "Novo São Joaquim" },
    { city: "Paranaíta" },
    { city: "Paranatinga" },
    { city: "Pedra Preta" },
    { city: "Peixoto de Azevedo" },
    { city: "Planalto da Serra" },
    { city: "Poconé" },
    { city: "Pontal do Araguaia" },
    { city: "Ponte Branca" },
    { city: "Pontes e Lacerda" },
    { city: "Porto Alegre do Norte" },
    { city: "Porto dos Gaúchos" },
    { city: "Porto Esperidião" },
    { city: "Porto Estrela" },
    { city: "Poxoréo" },
    { city: "Primavera do Leste" },
    { city: "Querência" },
    { city: "Reserva do Cabaçal" },
    { city: "Ribeirão Cascalheira" },
    { city: "Ribeirãozinho" },
    { city: "Rio Branco" },
    { city: "Rondolândia" },
    { city: "Rondonópolis" },
    { city: "Rosário Oeste" },
    { city: "Salto do Céu" },
    { city: "Santa Carmem" },
    { city: "Santa Cruz do Xingu" },
    { city: "Santa Rita do Trivelato" },
    { city: "Santa Terezinha" },
    { city: "Santo Afonso" },
    { city: "Santo Antônio do Leste" },
    { city: "Santo Antônio do Leverger" },
    { city: "São Félix do Araguaia" },
    { city: "São José do Povo" },
    { city: "São José do Rio Claro" },
    { city: "São José do Xingu" },
    { city: "São José dos Quatro Marcos" },
    { city: "São Pedro da Cipa" },
    { city: "Sapezal" },
    { city: "Serra Nova Dourada" },
    { city: "Sinop" },
    { city: "Sorriso" },
    { city: "Tabaporã" },
    { city: "Tangará da Serra" },
    { city: "Tapurah" },
    { city: "Terra Nova do Norte" },
    { city: "Tesouro" },
    { city: "Torixoréu" },
    { city: "União do Sul" },
    { city: "Vale de São Domingos" },
    { city: "Várzea Grande" },
    { city: "Vera" },
    { city: "Vila Bela da Santíssima Trindade" },
    { city: "Vila Rica" }
  ],
  "PA": [
    { city: "Abaetetuba" },
    { city: "Abel Figueiredo" },
    { city: "Acará" },
    { city: "Afuá" },
    { city: "Água Azul do Norte" },
    { city: "Alenquer" },
    { city: "Almeirim" },
    { city: "Altamira" },
    { city: "Anajás" },
    { city: "Ananindeua" },
    { city: "Anapu" },
    { city: "Augusto Corrêa" },
    { city: "Aurora do Pará" },
    { city: "Aveiro" },
    { city: "Bagre" },
    { city: "Baião" },
    { city: "Bannach" },
    { city: "Barcarena" },
    { city: "Belém" },
    { city: "Belterra" },
    { city: "Benevides" },
    { city: "Bom Jesus do Tocantins" },
    { city: "Bonito" },
    { city: "Bragança" },
    { city: "Brasil Novo" },
    { city: "Brejo Grande do Araguaia" },
    { city: "Breu Branco" },
    { city: "Breves" },
    { city: "Bujaru" },
    { city: "Cachoeira do Arari" },
    { city: "Cachoeira do Piriá" },
    { city: "Cametá" },
    { city: "Canaã dos Carajás" },
    { city: "Capanema" },
    { city: "Capitão Poço" },
    { city: "Castanhal" },
    { city: "Chaves" },
    { city: "Colares" },
    { city: "Conceição do Araguaia" },
    { city: "Concórdia do Pará" },
    { city: "Cumaru do Norte" },
    { city: "Curionópolis" },
    { city: "Curralinho" },
    { city: "Curuá" },
    { city: "Curuçá" },
    { city: "Dom Eliseu" },
    { city: "Eldorado dos Carajás" },
    { city: "Faro" },
    { city: "Floresta do Araguaia" },
    { city: "Garrafão do Norte" },
    { city: "Goianésia do Pará" },
    { city: "Gurupá" },
    { city: "Igarapé-Açu" },
    { city: "Igarapé-Miri" },
    { city: "Inhangapi" },
    { city: "Ipixuna do Pará" },
    { city: "Irituia" },
    { city: "Itaituba" },
    { city: "Itupiranga" },
    { city: "Jacareacanga" },
    { city: "Jacundá" },
    { city: "Juruti" },
    { city: "Limoeiro do Ajuru" },
    { city: "Mãe do Rio" },
    { city: "Magalhães Barata" },
    { city: "Marabá" },
    { city: "Maracanã" },
    { city: "Marapanim" },
    { city: "Marituba" },
    { city: "Medicilândia" },
    { city: "Melgaço" },
    { city: "Mocajuba" },
    { city: "Moju" },
    { city: "Monte Alegre" },
    { city: "Muaná" },
    { city: "Nova Esperança do Piriá" },
    { city: "Nova Ipixuna" },
    { city: "Nova Timboteua" },
    { city: "Novo Progresso" },
    { city: "Novo Repartimento" },
    { city: "Óbidos" },
    { city: "Oeiras do Pará" },
    { city: "Oriximiná" },
    { city: "Ourém" },
    { city: "Ourilândia do Norte" },
    { city: "Pacajá" },
    { city: "Palestina do Pará" },
    { city: "Paragominas" },
    { city: "Parauapebas" },
    { city: "Pau d'Arco" },
    { city: "Peixe-Boi" },
    { city: "Piçarra" },
    { city: "Placas" },
    { city: "Ponta de Pedras" },
    { city: "Portel" },
    { city: "Porto de Moz" },
    { city: "Prainha" },
    { city: "Primavera" },
    { city: "Quatipuru" },
    { city: "Redenção" },
    { city: "Rio Maria" },
    { city: "Rondon do Pará" },
    { city: "Rurópolis" },
    { city: "Salinópolis" },
    { city: "Salvaterra" },
    { city: "Santa Bárbara do Pará" },
    { city: "Santa Cruz do Arari" },
    { city: "Santa Isabel do Pará" },
    { city: "Santa Luzia do Pará" },
    { city: "Santa Maria das Barreiras" },
    { city: "Santa Maria do Pará" },
    { city: "Santana do Araguaia" },
    { city: "Santarém" },
    { city: "Santarém Novo" },
    { city: "Santo Antônio do Tauá" },
    { city: "São Caetano de Odivela" },
    { city: "São Domingos do Araguaia" },
    { city: "São Domingos do Capim" },
    { city: "São Félix do Xingu" },
    { city: "São Francisco do Pará" },
    { city: "São Geraldo do Araguaia" },
    { city: "São João da Ponta" },
    { city: "São João de Pirabas" },
    { city: "São João do Araguaia" },
    { city: "São Miguel do Guamá" },
    { city: "São Sebastião da Boa Vista" },
    { city: "Sapucaia" },
    { city: "Senador José Porfírio" },
    { city: "Soure" },
    { city: "Tailândia" },
    { city: "Terra Alta" },
    { city: "Terra Santa" },
    { city: "Tomé-Açu" },
    { city: "Tracuateua" },
    { city: "Trairão" },
    { city: "Tucumã" },
    { city: "Tucuruí" },
    { city: "Ulianópolis" },
    { city: "Uruará" },
    { city: "Vigia" },
    { city: "Viseu" },
    { city: "Vitória do Xingu" },
    { city: "Xinguara" }
  ],
  "PB": [
    { city: "Água Branca" },
    { city: "Aguiar" },
    { city: "Alagoa Grande" },
    { city: "Alagoa Nova" },
    { city: "Alagoinha" },
    { city: "Alcantil" },
    { city: "Algodão de Jandaíra" },
    { city: "Alhandra" },
    { city: "Amparo" },
    { city: "Aparecida" },
    { city: "Araçagi" },
    { city: "Arara" },
    { city: "Araruna" },
    { city: "Areia" },
    { city: "Areia de Baraúnas" },
    { city: "Areial" },
    { city: "Aroeiras" },
    { city: "Assunção" },
    { city: "Baía da Traição" },
    { city: "Bananeiras" },
    { city: "Baraúna" },
    { city: "Barra de Santa Rosa" },
    { city: "Barra de Santana" },
    { city: "Barra de São Miguel" },
    { city: "Bayeux" },
    { city: "Belém" },
    { city: "Belém do Brejo do Cruz" },
    { city: "Bernardino Batista" },
    { city: "Boa Ventura" },
    { city: "Boa Vista" },
    { city: "Bom Jesus" },
    { city: "Bom Sucesso" },
    { city: "Bonito de Santa Fé" },
    { city: "Boqueirão" },
    { city: "Borborema" },
    { city: "Brejo do Cruz" },
    { city: "Brejo dos Santos" },
    { city: "Caaporã" },
    { city: "Cabaceiras" },
    { city: "Cabedelo" },
    { city: "Cachoeira dos Índios" },
    { city: "Cacimba de Areia" },
    { city: "Cacimba de Dentro" },
    { city: "Cacimbas" },
    { city: "Caiçara" },
    { city: "Cajazeiras" },
    { city: "Cajazeirinhas" },
    { city: "Caldas Brandão" },
    { city: "Camalaú" },
    { city: "Campina Grande" },
    { city: "Campo de Santana" },
    { city: "Capim" },
    { city: "Caraúbas" },
    { city: "Carrapateira" },
    { city: "Casserengue" },
    { city: "Catingueira" },
    { city: "Catolé do Rocha" },
    { city: "Caturité" },
    { city: "Conceição" },
    { city: "Condado" },
    { city: "Conde" },
    { city: "Congo" },
    { city: "Coremas" },
    { city: "Coxixola" },
    { city: "Cruz do Espírito Santo" },
    { city: "Cubati" },
    { city: "Cuité" },
    { city: "Cuité de Mamanguape" },
    { city: "Cuitegi" },
    { city: "Curral de Cima" },
    { city: "Curral Velho" },
    { city: "Damião" },
    { city: "Desterro" },
    { city: "Diamante" },
    { city: "Dona Inês" },
    { city: "Duas Estradas" },
    { city: "Emas" },
    { city: "Esperança" },
    { city: "Fagundes" },
    { city: "Frei Martinho" },
    { city: "Gado Bravo" },
    { city: "Guarabira" },
    { city: "Gurinhém" },
    { city: "Gurjão" },
    { city: "Ibiara" },
    { city: "Igaracy" },
    { city: "Imaculada" },
    { city: "Ingá" },
    { city: "Itabaiana" },
    { city: "Itaporanga" },
    { city: "Itapororoca" },
    { city: "Itatuba" },
    { city: "Jacaraú" },
    { city: "Jericó" },
    { city: "João Pessoa" },
    { city: "Juarez Távora" },
    { city: "Juazeirinho" },
    { city: "Junco do Seridó" },
    { city: "Juripiranga" },
    { city: "Juru" },
    { city: "Lagoa" },
    { city: "Lagoa de Dentro" },
    { city: "Lagoa Seca" },
    { city: "Lastro" },
    { city: "Livramento" },
    { city: "Logradouro" },
    { city: "Lucena" },
    { city: "Mãe d'Água" },
    { city: "Malta" },
    { city: "Mamanguape" },
    { city: "Manaíra" },
    { city: "Marcação" },
    { city: "Mari" },
    { city: "Marizópolis" },
    { city: "Massaranduba" },
    { city: "Mataraca" },
    { city: "Matinhas" },
    { city: "Mato Grosso" },
    { city: "Maturéia" },
    { city: "Mogeiro" },
    { city: "Montadas" },
    { city: "Monte Horebe" },
    { city: "Monteiro" },
    { city: "Mulungu" },
    { city: "Natuba" },
    { city: "Nazarezinho" },
    { city: "Nova Floresta" },
    { city: "Nova Olinda" },
    { city: "Nova Palmeira" },
    { city: "Olho d'Água" },
    { city: "Olivedos" },
    { city: "Ouro Velho" },
    { city: "Parari" },
    { city: "Passagem" },
    { city: "Patos" },
    { city: "Paulista" },
    { city: "Pedra Branca" },
    { city: "Pedra Lavrada" },
    { city: "Pedras de Fogo" },
    { city: "Pedro Régis" },
    { city: "Piancó" },
    { city: "Picuí" },
    { city: "Pilar" },
    { city: "Pilões" },
    { city: "Pilõezinhos" },
    { city: "Pirpirituba" },
    { city: "Pitimbu" },
    { city: "Pocinhos" },
    { city: "Poço Dantas" },
    { city: "Poço de José de Moura" },
    { city: "Pombal" },
    { city: "Prata" },
    { city: "Princesa Isabel" },
    { city: "Puxinanã" },
    { city: "Queimadas" },
    { city: "Quixabá" },
    { city: "Remígio" },
    { city: "Riachão" },
    { city: "Riachão do Bacamarte" },
    { city: "Riachão do Poço" },
    { city: "Riacho de Santo Antônio" },
    { city: "Riacho dos Cavalos" },
    { city: "Rio Tinto" },
    { city: "Salgadinho" },
    { city: "Salgado de São Félix" },
    { city: "Santa Cecília" },
    { city: "Santa Cruz" },
    { city: "Santa Helena" },
    { city: "Santa Inês" },
    { city: "Santa Luzia" },
    { city: "Santa Rita" },
    { city: "Santa Teresinha" },
    { city: "Santana de Mangueira" },
    { city: "Santana dos Garrotes" },
    { city: "Santarém" },
    { city: "Santo André" },
    { city: "São Bentinho" },
    { city: "São Bento" },
    { city: "São Domingos de Pombal" },
    { city: "São Domingos do Cariri" },
    { city: "São Francisco" },
    { city: "São João do Cariri" },
    { city: "São João do Rio do Peixe" },
    { city: "São João do Tigre" },
    { city: "São José da Lagoa Tapada" },
    { city: "São José de Caiana" },
    { city: "São José de Espinharas" },
    { city: "São José de Piranhas" },
    { city: "São José de Princesa" },
    { city: "São José do Bonfim" },
    { city: "São José do Brejo do Cruz" },
    { city: "São José do Sabugi" },
    { city: "São José dos Cordeiros" },
    { city: "São José dos Ramos" },
    { city: "São Mamede" },
    { city: "São Miguel de Taipu" },
    { city: "São Sebastião de Lagoa de Roça" },
    { city: "São Sebastião do Umbuzeiro" },
    { city: "Sapé" },
    { city: "Seridó" },
    { city: "Serra Branca" },
    { city: "Serra da Raiz" },
    { city: "Serra Grande" },
    { city: "Serra Redonda" },
    { city: "Serraria" },
    { city: "Sertãozinho" },
    { city: "Sobrado" },
    { city: "Solânea" },
    { city: "Soledade" },
    { city: "Sossêgo" },
    { city: "Sousa" },
    { city: "Sumé" },
    { city: "Taperoá" },
    { city: "Tavares" },
    { city: "Teixeira" },
    { city: "Tenório" },
    { city: "Triunfo" },
    { city: "Uiraúna" },
    { city: "Umbuzeiro" },
    { city: "Várzea" },
    { city: "Vieirópolis" },
    { city: "Vista Serrana" },
    { city: "Zabelê" }
  ],
  "PE": [
    { city: "Abreu e Lima" },
    { city: "Afogados da Ingazeira" },
    { city: "Afrânio" },
    { city: "Agrestina" },
    { city: "Água Preta" },
    { city: "Águas Belas" },
    { city: "Alagoinha" },
    { city: "Aliança" },
    { city: "Altinho" },
    { city: "Amaraji" },
    { city: "Angelim" },
    { city: "Araçoiaba" },
    { city: "Araripina" },
    { city: "Arcoverde" },
    { city: "Barra de Guabiraba" },
    { city: "Barreiros" },
    { city: "Belém de Maria" },
    { city: "Belém de São Francisco" },
    { city: "Belo Jardim" },
    { city: "Betânia" },
    { city: "Bezerros" },
    { city: "Bodocó" },
    { city: "Bom Conselho" },
    { city: "Bom Jardim" },
    { city: "Bonito" },
    { city: "Brejão" },
    { city: "Brejinho" },
    { city: "Brejo da Madre de Deus" },
    { city: "Buenos Aires" },
    { city: "Buíque" },
    { city: "Cabo de Santo Agostinho" },
    { city: "Cabrobó" },
    { city: "Cachoeirinha" },
    { city: "Caetés" },
    { city: "Calçado" },
    { city: "Calumbi" },
    { city: "Camaragibe" },
    { city: "Camocim de São Félix" },
    { city: "Camutanga" },
    { city: "Canhotinho" },
    { city: "Capoeiras" },
    { city: "Carnaíba" },
    { city: "Carnaubeira da Penha" },
    { city: "Carpina" },
    { city: "Caruaru" },
    { city: "Casinhas" },
    { city: "Catende" },
    { city: "Cedro" },
    { city: "Chã de Alegria" },
    { city: "Chã Grande" },
    { city: "Condado" },
    { city: "Correntes" },
    { city: "Cortês" },
    { city: "Cumaru" },
    { city: "Cupira" },
    { city: "Custódia" },
    { city: "Dormentes" },
    { city: "Escada" },
    { city: "Exu" },
    { city: "Feira Nova" },
    { city: "Fernando de Noronha" },
    { city: "Ferreiros" },
    { city: "Flores" },
    { city: "Floresta" },
    { city: "Frei Miguelinho" },
    { city: "Gameleira" },
    { city: "Garanhuns" },
    { city: "Glória do Goitá" },
    { city: "Goiana" },
    { city: "Granito" },
    { city: "Gravatá" },
    { city: "Iati" },
    { city: "Ibimirim" },
    { city: "Ibirajuba" },
    { city: "Igarassu" },
    { city: "Iguaraci" },
    { city: "Inajá" },
    { city: "Ingazeira" },
    { city: "Ipojuca" },
    { city: "Ipubi" },
    { city: "Itacuruba" },
    { city: "Itaíba" },
    { city: "Itamaracá" },
    { city: "Itambé" },
    { city: "Itapetim" },
    { city: "Itapissuma" },
    { city: "Itaquitinga" },
    { city: "Jaboatão dos Guararapes" },
    { city: "Jaqueira" },
    { city: "Jataúba" },
    { city: "Jatobá" },
    { city: "João Alfredo" },
    { city: "Joaquim Nabuco" },
    { city: "Jucati" },
    { city: "Jupi" },
    { city: "Jurema" },
    { city: "Lagoa do Carro" },
    { city: "Lagoa do Itaenga" },
    { city: "Lagoa do Ouro" },
    { city: "Lagoa dos Gatos" },
    { city: "Lagoa Grande" },
    { city: "Lajedo" },
    { city: "Limoeiro" },
    { city: "Macaparana" },
    { city: "Machados" },
    { city: "Manari" },
    { city: "Maraial" },
    { city: "Mirandiba" },
    { city: "Moreilândia" },
    { city: "Moreno" },
    { city: "Nazaré da Mata" },
    { city: "Olinda" },
    { city: "Orobó" },
    { city: "Orocó" },
    { city: "Ouricuri" },
    { city: "Palmares" },
    { city: "Palmeirina" },
    { city: "Panelas" },
    { city: "Paranatama" },
    { city: "Parnamirim" },
    { city: "Passira" },
    { city: "Paudalho" },
    { city: "Paulista" },
    { city: "Pedra" },
    { city: "Pesqueira" },
    { city: "Petrolândia" },
    { city: "Petrolina" },
    { city: "Poção" },
    { city: "Pombos" },
    { city: "Primavera" },
    { city: "Quipapá" },
    { city: "Quixaba" },
    { city: "Recife" },
    { city: "Riacho das Almas" },
    { city: "Ribeirão" },
    { city: "Rio Formoso" },
    { city: "Sairé" },
    { city: "Salgadinho" },
    { city: "Salgueiro" },
    { city: "Saloá" },
    { city: "Sanharó" },
    { city: "Santa Cruz" },
    { city: "Santa Cruz da Baixa Verde" },
    { city: "Santa Cruz do Capibaribe" },
    { city: "Santa Filomena" },
    { city: "Santa Maria da Boa Vista" },
    { city: "Santa Maria do Cambucá" },
    { city: "Santa Terezinha" },
    { city: "São Benedito do Sul" },
    { city: "São Bento do Una" },
    { city: "São Caitano" },
    { city: "São João" },
    { city: "São Joaquim do Monte" },
    { city: "São José da Coroa Grande" },
    { city: "São José do Belmonte" },
    { city: "São José do Egito" },
    { city: "São Lourenço da Mata" },
    { city: "São Vicente Ferrer" },
    { city: "Serra Talhada" },
    { city: "Serrita" },
    { city: "Sertânia" },
    { city: "Sirinhaém" },
    { city: "Solidão" },
    { city: "Surubim" },
    { city: "Tabira" },
    { city: "Tacaimbó" },
    { city: "Tacaratu" },
    { city: "Tamandaré" },
    { city: "Taquaritinga do Norte" },
    { city: "Terezinha" },
    { city: "Terra Nova" },
    { city: "Timbaúba" },
    { city: "Toritama" },
    { city: "Tracunhaém" },
    { city: "Trindade" },
    { city: "Triunfo" },
    { city: "Tupanatinga" },
    { city: "Tuparetama" },
    { city: "Venturosa" },
    { city: "Verdejante" },
    { city: "Vertente do Lério" },
    { city: "Vertentes" },
    { city: "Vicência" },
    { city: "Vitória de Santo Antão" },
    { city: "Xexéu" }
  ],
  "PI": [
    { city: "Acauã" },
    { city: "Agricolândia" },
    { city: "Água Branca" },
    { city: "Alagoinha do Piauí" },
    { city: "Alegrete do Piauí" },
    { city: "Alto Longá" },
    { city: "Altos" },
    { city: "Alvorada do Gurguéia" },
    { city: "Amarante" },
    { city: "Angical do Piauí" },
    { city: "Anísio de Abreu" },
    { city: "Antônio Almeida" },
    { city: "Aroazes" },
    { city: "Arraial" },
    { city: "Assunção do Piauí" },
    { city: "Avelino Lopes" },
    { city: "Baixa Grande do Ribeiro" },
    { city: "Barra d'Alcântara" },
    { city: "Barras" },
    { city: "Barreiras do Piauí" },
    { city: "Barro Duro" },
    { city: "Batalha" },
    { city: "Bela Vista do Piauí" },
    { city: "Belém do Piauí" },
    { city: "Beneditinos" },
    { city: "Bertolínia" },
    { city: "Betânia do Piauí" },
    { city: "Boa Hora" },
    { city: "Bocaina" },
    { city: "Bom Jesus" },
    { city: "Bom Princípio do Piauí" },
    { city: "Bonfim do Piauí" },
    { city: "Boqueirão do Piauí" },
    { city: "Brasileira" },
    { city: "Brejo do Piauí" },
    { city: "Buriti dos Lopes" },
    { city: "Buriti dos Montes" },
    { city: "Cabeceiras do Piauí" },
    { city: "Cajazeiras do Piauí" },
    { city: "Cajueiro da Praia" },
    { city: "Caldeirão Grande do Piauí" },
    { city: "Campinas do Piauí" },
    { city: "Campo Alegre do Fidalgo" },
    { city: "Campo Grande do Piauí" },
    { city: "Campo Largo do Piauí" },
    { city: "Campo Maior" },
    { city: "Canavieira" },
    { city: "Canto do Buriti" },
    { city: "Capitão de Campos" },
    { city: "Capitão Gervásio Oliveira" },
    { city: "Caracol" },
    { city: "Caraúbas do Piauí" },
    { city: "Caridade do Piauí" },
    { city: "Castelo do Piauí" },
    { city: "Caxingó" },
    { city: "Cocal" },
    { city: "Cocal de Telha" },
    { city: "Cocal dos Alves" },
    { city: "Coivaras" },
    { city: "Colônia do Gurguéia" },
    { city: "Colônia do Piauí" },
    { city: "Conceição do Canindé" },
    { city: "Coronel José Dias" },
    { city: "Corrente" },
    { city: "Cristalândia do Piauí" },
    { city: "Cristino Castro" },
    { city: "Curimatá" },
    { city: "Currais" },
    { city: "Curral Novo do Piauí" },
    { city: "Curralinhos" },
    { city: "Demerval Lobão" },
    { city: "Dirceu Arcoverde" },
    { city: "Dom Expedito Lopes" },
    { city: "Dom Inocêncio" },
    { city: "Domingos Mourão" },
    { city: "Elesbão Veloso" },
    { city: "Eliseu Martins" },
    { city: "Esperantina" },
    { city: "Fartura do Piauí" },
    { city: "Flores do Piauí" },
    { city: "Floresta do Piauí" },
    { city: "Floriano" },
    { city: "Francinópolis" },
    { city: "Francisco Ayres" },
    { city: "Francisco Macedo" },
    { city: "Francisco Santos" },
    { city: "Fronteiras" },
    { city: "Geminiano" },
    { city: "Gilbués" },
    { city: "Guadalupe" },
    { city: "Guaribas" },
    { city: "Hugo Napoleão" },
    { city: "Ilha Grande" },
    { city: "Inhuma" },
    { city: "Ipiranga do Piauí" },
    { city: "Isaías Coelho" },
    { city: "Itainópolis" },
    { city: "Itaueira" },
    { city: "Jacobina do Piauí" },
    { city: "Jaicós" },
    { city: "Jardim do Mulato" },
    { city: "Jatobá do Piauí" },
    { city: "Jerumenha" },
    { city: "João Costa" },
    { city: "Joaquim Pires" },
    { city: "Joca Marques" },
    { city: "José de Freitas" },
    { city: "Juazeiro do Piauí" },
    { city: "Júlio Borges" },
    { city: "Jurema" },
    { city: "Lagoa Alegre" },
    { city: "Lagoa de São Francisco" },
    { city: "Lagoa do Barro do Piauí" },
    { city: "Lagoa do Piauí" },
    { city: "Lagoa do Sítio" },
    { city: "Lagoinha do Piauí" },
    { city: "Landri Sales" },
    { city: "Luís Correia" },
    { city: "Luzilândia" },
    { city: "Madeiro" },
    { city: "Manoel Emídio" },
    { city: "Marcolândia" },
    { city: "Marcos Parente" },
    { city: "Massapê do Piauí" },
    { city: "Matias Olímpio" },
    { city: "Miguel Alves" },
    { city: "Miguel Leão" },
    { city: "Milton Brandão" },
    { city: "Monsenhor Gil" },
    { city: "Monsenhor Hipólito" },
    { city: "Monte Alegre do Piauí" },
    { city: "Morro Cabeça no Tempo" },
    { city: "Morro do Chapéu do Piauí" },
    { city: "Murici dos Portelas" },
    { city: "Nazaré do Piauí" },
    { city: "Nossa Senhora de Nazaré" },
    { city: "Nossa Senhora dos Remédios" },
    { city: "Nova Santa Rita" },
    { city: "Novo Oriente do Piauí" },
    { city: "Novo Santo Antônio" },
    { city: "Oeiras" },
    { city: "Olho d'Água do Piauí" },
    { city: "Padre Marcos" },
    { city: "Paes Landim" },
    { city: "Pajeú do Piauí" },
    { city: "Palmeira do Piauí" },
    { city: "Palmeirais" },
    { city: "Paquetá" },
    { city: "Parnaguá" },
    { city: "Parnaíba" },
    { city: "Passagem Franca do Piauí" },
    { city: "Patos do Piauí" },
    { city: "Pau d'Arco do Piauí" },
    { city: "Paulistana" },
    { city: "Pavussu" },
    { city: "Pedro II" },
    { city: "Pedro Laurentino" },
    { city: "Picos" },
    { city: "Pimenteiras" },
    { city: "Pio IX" },
    { city: "Piracuruca" },
    { city: "Piripiri" },
    { city: "Porto" },
    { city: "Porto Alegre do Piauí" },
    { city: "Prata do Piauí" },
    { city: "Queimada Nova" },
    { city: "Redenção do Gurguéia" },
    { city: "Regeneração" },
    { city: "Riacho Frio" },
    { city: "Ribeira do Piauí" },
    { city: "Ribeiro Gonçalves" },
    { city: "Rio Grande do Piauí" },
    { city: "Santa Cruz do Piauí" },
    { city: "Santa Cruz dos Milagres" },
    { city: "Santa Filomena" },
    { city: "Santa Luz" },
    { city: "Santa Rosa do Piauí" },
    { city: "Santana do Piauí" },
    { city: "Santo Antônio de Lisboa" },
    { city: "Santo Antônio dos Milagres" },
    { city: "Santo Inácio do Piauí" },
    { city: "São Braz do Piauí" },
    { city: "São Félix do Piauí" },
    { city: "São Francisco de Assis do Piauí" },
    { city: "São Francisco do Piauí" },
    { city: "São Gonçalo do Gurguéia" },
    { city: "São Gonçalo do Piauí" },
    { city: "São João da Canabrava" },
    { city: "São João da Fronteira" },
    { city: "São João da Serra" },
    { city: "São João da Varjota" },
    { city: "São João do Arraial" },
    { city: "São João do Piauí" },
    { city: "São José do Divino" },
    { city: "São José do Peixe" },
    { city: "São José do Piauí" },
    { city: "São Julião" },
    { city: "São Lourenço do Piauí" },
    { city: "São Luis do Piauí" },
    { city: "São Miguel da Baixa Grande" },
    { city: "São Miguel do Fidalgo" },
    { city: "São Miguel do Tapuio" },
    { city: "São Pedro do Piauí" },
    { city: "São Raimundo Nonato" },
    { city: "Sebastião Barros" },
    { city: "Sebastião Leal" },
    { city: "Sigefredo Pacheco" },
    { city: "Simões" },
    { city: "Simplício Mendes" },
    { city: "Socorro do Piauí" },
    { city: "Sussuapara" },
    { city: "Tamboril do Piauí" },
    { city: "Tanque do Piauí" },
    { city: "Teresina" },
    { city: "União" },
    { city: "Uruçuí" },
    { city: "Valença do Piauí" },
    { city: "Várzea Branca" },
    { city: "Várzea Grande" },
    { city: "Vera Mendes" },
    { city: "Vila Nova do Piauí" },
    { city: "Wall Ferraz" }
  ],
  "PR": [
    { city: "Abatiá" },
    { city: "Adrianópolis" },
    { city: "Agudos do Sul" },
    { city: "Almirante Tamandaré" },
    { city: "Altamira do Paraná" },
    { city: "Alto Paraná" },
    { city: "Alto Piquiri" },
    { city: "Altônia" },
    { city: "Alvorada do Sul" },
    { city: "Amaporã" },
    { city: "Ampére" },
    { city: "Anahy" },
    { city: "Andirá" },
    { city: "Ângulo" },
    { city: "Antonina" },
    { city: "Antônio Olinto" },
    { city: "Apucarana" },
    { city: "Arapongas" },
    { city: "Arapoti" },
    { city: "Arapuã" },
    { city: "Araruna" },
    { city: "Araucária" },
    { city: "Ariranha do Ivaí" },
    { city: "Assaí" },
    { city: "Assis Chateaubriand" },
    { city: "Astorga" },
    { city: "Atalaia" },
    { city: "Balsa Nova" },
    { city: "Bandeirantes" },
    { city: "Barbosa Ferraz" },
    { city: "Barra do Jacaré" },
    { city: "Barracão" },
    { city: "Bela Vista da Caroba" },
    { city: "Bela Vista do Paraíso" },
    { city: "Bituruna" },
    { city: "Boa Esperança" },
    { city: "Boa Esperança do Iguaçu" },
    { city: "Boa Ventura de São Roque" },
    { city: "Boa Vista da Aparecida" },
    { city: "Bocaiúva do Sul" },
    { city: "Bom Jesus do Sul" },
    { city: "Bom Sucesso" },
    { city: "Bom Sucesso do Sul" },
    { city: "Borrazópolis" },
    { city: "Braganey" },
    { city: "Brasilândia do Sul" },
    { city: "Cafeara" },
    { city: "Cafelândia" },
    { city: "Cafezal do Sul" },
    { city: "Califórnia" },
    { city: "Cambará" },
    { city: "Cambé" },
    { city: "Cambira" },
    { city: "Campina da Lagoa" },
    { city: "Campina do Simão" },
    { city: "Campina Grande do Sul" },
    { city: "Campo Bonito" },
    { city: "Campo do Tenente" },
    { city: "Campo Largo" },
    { city: "Campo Magro" },
    { city: "Campo Mourão" },
    { city: "Cândido de Abreu" },
    { city: "Candói" },
    { city: "Cantagalo" },
    { city: "Capanema" },
    { city: "Capitão Leônidas Marques" },
    { city: "Carambeí" },
    { city: "Carlópolis" },
    { city: "Cascavel" },
    { city: "Castro" },
    { city: "Catanduvas" },
    { city: "Centenário do Sul" },
    { city: "Cerro Azul" },
    { city: "Céu Azul" },
    { city: "Chopinzinho" },
    { city: "Cianorte" },
    { city: "Cidade Gaúcha" },
    { city: "Clevelândia" },
    { city: "Colombo" },
    { city: "Colorado" },
    { city: "Congonhinhas" },
    { city: "Conselheiro Mairinck" },
    { city: "Contenda" },
    { city: "Corbélia" },
    { city: "Cornélio Procópio" },
    { city: "Coronel Domingos Soares" },
    { city: "Coronel Vivida" },
    { city: "Corumbataí do Sul" },
    { city: "Cruz Machado" },
    { city: "Cruzeiro do Iguaçu" },
    { city: "Cruzeiro do Oeste" },
    { city: "Cruzeiro do Sul" },
    { city: "Cruzmaltina" },
    { city: "Curitiba" },
    { city: "Curiúva" },
    { city: "Diamante d'Oeste" },
    { city: "Diamante do Norte" },
    { city: "Diamante do Sul" },
    { city: "Dois Vizinhos" },
    { city: "Douradina" },
    { city: "Doutor Camargo" },
    { city: "Doutor Ulysses" },
    { city: "Enéas Marques" },
    { city: "Engenheiro Beltrão" },
    { city: "Entre Rios do Oeste" },
    { city: "Esperança Nova" },
    { city: "Espigão Alto do Iguaçu" },
    { city: "Farol" },
    { city: "Faxinal" },
    { city: "Fazenda Rio Grande" },
    { city: "Fênix" },
    { city: "Fernandes Pinheiro" },
    { city: "Figueira" },
    { city: "Flor da Serra do Sul" },
    { city: "Floraí" },
    { city: "Floresta" },
    { city: "Florestópolis" },
    { city: "Flórida" },
    { city: "Formosa do Oeste" },
    { city: "Foz do Iguaçu" },
    { city: "Foz do Jordão" },
    { city: "Francisco Alves" },
    { city: "Francisco Beltrão" },
    { city: "General Carneiro" },
    { city: "Godoy Moreira" },
    { city: "Goioerê" },
    { city: "Goioxim" },
    { city: "Grandes Rios" },
    { city: "Guaíra" },
    { city: "Guairaçá" },
    { city: "Guamiranga" },
    { city: "Guapirama" },
    { city: "Guaporema" },
    { city: "Guaraci" },
    { city: "Guaraniaçu" },
    { city: "Guarapuava" },
    { city: "Guaraqueçaba" },
    { city: "Guaratuba" },
    { city: "Honório Serpa" },
    { city: "Ibaiti" },
    { city: "Ibema" },
    { city: "Ibiporã" },
    { city: "Icaraíma" },
    { city: "Iguaraçu" },
    { city: "Iguatu" },
    { city: "Imbaú" },
    { city: "Imbituva" },
    { city: "Inácio Martins" },
    { city: "Inajá" },
    { city: "Indianópolis" },
    { city: "Ipiranga" },
    { city: "Iporã" },
    { city: "Iracema do Oeste" },
    { city: "Irati" },
    { city: "Iretama" },
    { city: "Itaguajé" },
    { city: "Itaipulândia" },
    { city: "Itambaracá" },
    { city: "Itambé" },
    { city: "Itapejara d'Oeste" },
    { city: "Itaperuçu" },
    { city: "Itaúna do Sul" },
    { city: "Ivaí" },
    { city: "Ivaiporã" },
    { city: "Ivaté" },
    { city: "Ivatuba" },
    { city: "Jaboti" },
    { city: "Jacarezinho" },
    { city: "Jaguapitã" },
    { city: "Jaguariaíva" },
    { city: "Jandaia do Sul" },
    { city: "Janiópolis" },
    { city: "Japira" },
    { city: "Japurá" },
    { city: "Jardim Alegre" },
    { city: "Jardim Olinda" },
    { city: "Jataizinho" },
    { city: "Jesuítas" },
    { city: "Joaquim Távora" },
    { city: "Jundiaí do Sul" },
    { city: "Juranda" },
    { city: "Jussara" },
    { city: "Kaloré" },
    { city: "Lapa" },
    { city: "Laranjal" },
    { city: "Laranjeiras do Sul" },
    { city: "Leópolis" },
    { city: "Lidianópolis" },
    { city: "Lindoeste" },
    { city: "Loanda" },
    { city: "Lobato" },
    { city: "Londrina" },
    { city: "Luiziana" },
    { city: "Lunardelli" },
    { city: "Lupionópolis" },
    { city: "Mallet" },
    { city: "Mamborê" },
    { city: "Mandaguaçu" },
    { city: "Mandaguari" },
    { city: "Mandirituba" },
    { city: "Manfrinópolis" },
    { city: "Mangueirinha" },
    { city: "Manoel Ribas" },
    { city: "Marechal Cândido Rondon" },
    { city: "Maria Helena" },
    { city: "Marialva" },
    { city: "Marilândia do Sul" },
    { city: "Marilena" },
    { city: "Mariluz" },
    { city: "Maringá" },
    { city: "Mariópolis" },
    { city: "Maripá" },
    { city: "Marmeleiro" },
    { city: "Marquinho" },
    { city: "Marumbi" },
    { city: "Matelândia" },
    { city: "Matinhos" },
    { city: "Mato Rico" },
    { city: "Mauá da Serra" },
    { city: "Medianeira" },
    { city: "Mercedes" },
    { city: "Mirador" },
    { city: "Miraselva" },
    { city: "Missal" },
    { city: "Moreira Sales" },
    { city: "Morretes" },
    { city: "Munhoz de Melo" },
    { city: "Nossa Senhora das Graças" },
    { city: "Nova Aliança do Ivaí" },
    { city: "Nova América da Colina" },
    { city: "Nova Aurora" },
    { city: "Nova Cantu" },
    { city: "Nova Esperança" },
    { city: "Nova Esperança do Sudoeste" },
    { city: "Nova Fátima" },
    { city: "Nova Laranjeiras" },
    { city: "Nova Londrina" },
    { city: "Nova Olímpia" },
    { city: "Nova Prata do Iguaçu" },
    { city: "Nova Santa Bárbara" },
    { city: "Nova Santa Rosa" },
    { city: "Nova Tebas" },
    { city: "Novo Itacolomi" },
    { city: "Ortigueira" },
    { city: "Ourizona" },
    { city: "Ouro Verde do Oeste" },
    { city: "Paiçandu" },
    { city: "Palmas" },
    { city: "Palmeira" },
    { city: "Palmital" },
    { city: "Palotina" },
    { city: "Paraíso do Norte" },
    { city: "Paranacity" },
    { city: "Paranaguá" },
    { city: "Paranapoema" },
    { city: "Paranavaí" },
    { city: "Pato Bragado" },
    { city: "Pato Branco" },
    { city: "Paula Freitas" },
    { city: "Paulo Frontin" },
    { city: "Peabiru" },
    { city: "Perobal" },
    { city: "Pérola" },
    { city: "Pérola d'Oeste" },
    { city: "Piên" },
    { city: "Pinhais" },
    { city: "Pinhal de São Bento" },
    { city: "Pinhalão" },
    { city: "Pinhão" },
    { city: "Piraí do Sul" },
    { city: "Piraquara" },
    { city: "Pitanga" },
    { city: "Pitangueiras" },
    { city: "Planaltina do Paraná" },
    { city: "Planalto" },
    { city: "Ponta Grossa" },
    { city: "Pontal do Paraná" },
    { city: "Porecatu" },
    { city: "Porto Amazonas" },
    { city: "Porto Barreiro" },
    { city: "Porto Rico" },
    { city: "Porto Vitória" },
    { city: "Prado Ferreira" },
    { city: "Pranchita" },
    { city: "Presidente Castelo Branco" },
    { city: "Primeiro de Maio" },
    { city: "Prudentópolis" },
    { city: "Quarto Centenário" },
    { city: "Quatiguá" },
    { city: "Quatro Barras" },
    { city: "Quatro Pontes" },
    { city: "Quedas do Iguaçu" },
    { city: "Querência do Norte" },
    { city: "Quinta do Sol" },
    { city: "Quitandinha" },
    { city: "Ramilândia" },
    { city: "Rancho Alegre" },
    { city: "Rancho Alegre d'Oeste" },
    { city: "Realeza" },
    { city: "Rebouças" },
    { city: "Renascença" },
    { city: "Reserva" },
    { city: "Reserva do Iguaçu" },
    { city: "Ribeirão Claro" },
    { city: "Ribeirão do Pinhal" },
    { city: "Rio Azul" },
    { city: "Rio Bom" },
    { city: "Rio Bonito do Iguaçu" },
    { city: "Rio Branco do Ivaí" },
    { city: "Rio Branco do Sul" },
    { city: "Rio Negro" },
    { city: "Rolândia" },
    { city: "Roncador" },
    { city: "Rondon" },
    { city: "Rosário do Ivaí" },
    { city: "Sabáudia" },
    { city: "Salgado Filho" },
    { city: "Salto do Itararé" },
    { city: "Salto do Lontra" },
    { city: "Santa Amélia" },
    { city: "Santa Cecília do Pavão" },
    { city: "Santa Cruz Monte Castelo" },
    { city: "Santa Fé" },
    { city: "Santa Helena" },
    { city: "Santa Inês" },
    { city: "Santa Isabel do Ivaí" },
    { city: "Santa Izabel do Oeste" },
    { city: "Santa Lúcia" },
    { city: "Santa Maria do Oeste" },
    { city: "Santa Mariana" },
    { city: "Santa Mônica" },
    { city: "Santa Tereza do Oeste" },
    { city: "Santa Terezinha de Itaipu" },
    { city: "Santana do Itararé" },
    { city: "Santo Antônio da Platina" },
    { city: "Santo Antônio do Caiuá" },
    { city: "Santo Antônio do Paraíso" },
    { city: "Santo Antônio do Sudoeste" },
    { city: "Santo Inácio" },
    { city: "São Carlos do Ivaí" },
    { city: "São Jerônimo da Serra" },
    { city: "São João" },
    { city: "São João do Caiuá" },
    { city: "São João do Ivaí" },
    { city: "São João do Triunfo" },
    { city: "São Jorge d'Oeste" },
    { city: "São Jorge do Ivaí" },
    { city: "São Jorge do Patrocínio" },
    { city: "São José da Boa Vista" },
    { city: "São José das Palmeiras" },
    { city: "São José dos Pinhais" },
    { city: "São Manoel do Paraná" },
    { city: "São Mateus do Sul" },
    { city: "São Miguel do Iguaçu" },
    { city: "São Pedro do Iguaçu" },
    { city: "São Pedro do Ivaí" },
    { city: "São Pedro do Paraná" },
    { city: "São Sebastião da Amoreira" },
    { city: "São Tomé" },
    { city: "Sapopema" },
    { city: "Sarandi" },
    { city: "Saudade do Iguaçu" },
    { city: "Sengés" },
    { city: "Serranópolis do Iguaçu" },
    { city: "Sertaneja" },
    { city: "Sertanópolis" },
    { city: "Siqueira Campos" },
    { city: "Sulina" },
    { city: "Tamarana" },
    { city: "Tamboara" },
    { city: "Tapejara" },
    { city: "Tapira" },
    { city: "Teixeira Soares" },
    { city: "Telêmaco Borba" },
    { city: "Terra Boa" },
    { city: "Terra Rica" },
    { city: "Terra Roxa" },
    { city: "Tibagi" },
    { city: "Tijucas do Sul" },
    { city: "Toledo" },
    { city: "Tomazina" },
    { city: "Três Barras do Paraná" },
    { city: "Tunas do Paraná" },
    { city: "Tuneiras do Oeste" },
    { city: "Tupãssi" },
    { city: "Turvo" },
    { city: "Ubiratã" },
    { city: "Umuarama" },
    { city: "União da Vitória" },
    { city: "Uniflor" },
    { city: "Uraí" },
    { city: "Ventania" },
    { city: "Vera Cruz do Oeste" },
    { city: "Verê" },
    { city: "Vila Alta" },
    { city: "Virmond" },
    { city: "Vitorino" },
    { city: "Wenceslau Braz" },
    { city: "Xambrê" }
  ],
  "RJ": [
    { city: "Angra dos Reis" },
    { city: "Aperibé" },
    { city: "Araruama" },
    { city: "Areal" },
    { city: "Armação de Búzios" },
    { city: "Arraial do Cabo" },
    { city: "Barra do Piraí" },
    { city: "Barra Mansa" },
    { city: "Belford Roxo" },
    { city: "Bom Jardim" },
    { city: "Bom Jesus do Itabapoana" },
    { city: "Cabo Frio" },
    { city: "Cachoeiras de Macacu" },
    { city: "Cambuci" },
    { city: "Campos dos Goytacazes" },
    { city: "Cantagalo" },
    { city: "Carapebus" },
    { city: "Cardoso Moreira" },
    { city: "Carmo" },
    { city: "Casimiro de Abreu" },
    { city: "Comendador Levy Gasparian" },
    { city: "Conceição de Macabu" },
    { city: "Cordeiro" },
    { city: "Duas Barras" },
    { city: "Duque de Caxias" },
    { city: "Engenheiro Paulo de Frontin" },
    { city: "Guapimirim" },
    { city: "Iguaba Grande" },
    { city: "Itaboraí" },
    { city: "Itaguaí" },
    { city: "Italva" },
    { city: "Itaocara" },
    { city: "Itaperuna" },
    { city: "Itatiaia" },
    { city: "Japeri" },
    { city: "Laje do Muriaé" },
    { city: "Macaé" },
    { city: "Macuco" },
    { city: "Magé" },
    { city: "Mangaratiba" },
    { city: "Maricá" },
    { city: "Mendes" },
    { city: "Mesquita" },
    { city: "Miguel Pereira" },
    { city: "Miracema" },
    { city: "Natividade" },
    { city: "Nilópolis" },
    { city: "Niterói" },
    { city: "Nova Friburgo" },
    { city: "Nova Iguaçu" },
    { city: "Paracambi" },
    { city: "Paraíba do Sul" },
    { city: "Parati" },
    { city: "Paty do Alferes" },
    { city: "Petrópolis" },
    { city: "Pinheiral" },
    { city: "Piraí" },
    { city: "Porciúncula" },
    { city: "Porto Real" },
    { city: "Quatis" },
    { city: "Queimados" },
    { city: "Quissamã" },
    { city: "Resende" },
    { city: "Rio Bonito" },
    { city: "Rio Claro" },
    { city: "Rio das Flores" },
    { city: "Rio das Ostras" },
    { city: "Rio de Janeiro" },
    { city: "Santa Maria Madalena" },
    { city: "Santo Antônio de Pádua" },
    { city: "São Fidélis" },
    { city: "São Francisco de Itabapoana" },
    { city: "São Gonçalo" },
    { city: "São João da Barra" },
    { city: "São João de Meriti" },
    { city: "São José de Ubá" },
    { city: "São José do Vale do Rio Preto" },
    { city: "São Pedro da Aldeia" },
    { city: "São Sebastião do Alto" },
    { city: "Sapucaia" },
    { city: "Saquarema" },
    { city: "Seropédica" },
    { city: "Silva Jardim" },
    { city: "Sumidouro" },
    { city: "Tanguá" },
    { city: "Teresópolis" },
    { city: "Trajano de Morais" },
    { city: "Três Rios" },
    { city: "Valença" },
    { city: "Varre-Sai" },
    { city: "Vassouras" },
    { city: "Volta Redonda" }
  ],
  "RN": [
    { city: "Acari" },
    { city: "Açu" },
    { city: "Afonso Bezerra" },
    { city: "Água Nova" },
    { city: "Alexandria" },
    { city: "Almino Afonso" },
    { city: "Alto do Rodrigues" },
    { city: "Angicos" },
    { city: "Antônio Martins" },
    { city: "Apodi" },
    { city: "Areia Branca" },
    { city: "Arês" },
    { city: "Augusto Severo" },
    { city: "Baía Formosa" },
    { city: "Baraúna" },
    { city: "Barcelona" },
    { city: "Bento Fernandes" },
    { city: "Bodó" },
    { city: "Bom Jesus" },
    { city: "Brejinho" },
    { city: "Caiçara do Norte" },
    { city: "Caiçara do Rio do Vento" },
    { city: "Caicó" },
    { city: "Campo Redondo" },
    { city: "Canguaretama" },
    { city: "Caraúbas" },
    { city: "Carnaúba dos Dantas" },
    { city: "Carnaubais" },
    { city: "Ceará-Mirim" },
    { city: "Cerro Corá" },
    { city: "Coronel Ezequiel" },
    { city: "Coronel João Pessoa" },
    { city: "Cruzeta" },
    { city: "Currais Novos" },
    { city: "Doutor Severiano" },
    { city: "Encanto" },
    { city: "Equador" },
    { city: "Espírito Santo" },
    { city: "Extremoz" },
    { city: "Felipe Guerra" },
    { city: "Fernando Pedroza" },
    { city: "Florânia" },
    { city: "Francisco Dantas" },
    { city: "Frutuoso Gomes" },
    { city: "Galinhos" },
    { city: "Goianinha" },
    { city: "Governador Dix-Sept Rosado" },
    { city: "Grossos" },
    { city: "Guamaré" },
    { city: "Ielmo Marinho" },
    { city: "Ipanguaçu" },
    { city: "Ipueira" },
    { city: "Itajá" },
    { city: "Itaú" },
    { city: "Jaçanã" },
    { city: "Jandaíra" },
    { city: "Janduís" },
    { city: "Januário Cicco" },
    { city: "Japi" },
    { city: "Jardim de Angicos" },
    { city: "Jardim de Piranhas" },
    { city: "Jardim do Seridó" },
    { city: "João Câmara" },
    { city: "João Dias" },
    { city: "José da Penha" },
    { city: "Jucurutu" },
    { city: "Jundiá" },
    { city: "Lagoa d'Anta" },
    { city: "Lagoa de Pedras" },
    { city: "Lagoa de Velhos" },
    { city: "Lagoa Nova" },
    { city: "Lagoa Salgada" },
    { city: "Lajes" },
    { city: "Lajes Pintadas" },
    { city: "Lucrécia" },
    { city: "Luís Gomes" },
    { city: "Macaíba" },
    { city: "Macau" },
    { city: "Major Sales" },
    { city: "Marcelino Vieira" },
    { city: "Martins" },
    { city: "Maxaranguape" },
    { city: "Messias Targino" },
    { city: "Montanhas" },
    { city: "Monte Alegre" },
    { city: "Monte das Gameleiras" },
    { city: "Mossoró" },
    { city: "Natal" },
    { city: "Nísia Floresta" },
    { city: "Nova Cruz" },
    { city: "Olho-d'Água do Borges" },
    { city: "Ouro Branco" },
    { city: "Paraná" },
    { city: "Paraú" },
    { city: "Parazinho" },
    { city: "Parelhas" },
    { city: "Parnamirim" },
    { city: "Passa e Fica" },
    { city: "Passagem" },
    { city: "Patu" },
    { city: "Pau dos Ferros" },
    { city: "Pedra Grande" },
    { city: "Pedra Preta" },
    { city: "Pedro Avelino" },
    { city: "Pedro Velho" },
    { city: "Pendências" },
    { city: "Pilões" },
    { city: "Poço Branco" },
    { city: "Portalegre" },
    { city: "Porto do Mangue" },
    { city: "Presidente Juscelino" },
    { city: "Pureza" },
    { city: "Rafael Fernandes" },
    { city: "Rafael Godeiro" },
    { city: "Riacho da Cruz" },
    { city: "Riacho de Santana" },
    { city: "Riachuelo" },
    { city: "Rio do Fogo" },
    { city: "Rodolfo Fernandes" },
    { city: "Ruy Barbosa" },
    { city: "Santa Cruz" },
    { city: "Santa Maria" },
    { city: "Santana do Matos" },
    { city: "Santana do Seridó" },
    { city: "Santo Antônio" },
    { city: "São Bento do Norte" },
    { city: "São Bento do Trairí" },
    { city: "São Fernando" },
    { city: "São Francisco do Oeste" },
    { city: "São Gonçalo do Amarante" },
    { city: "São João do Sabugi" },
    { city: "São José de Mipibu" },
    { city: "São José do Campestre" },
    { city: "São José do Seridó" },
    { city: "São Miguel" },
    { city: "São Miguel de Touros" },
    { city: "São Paulo do Potengi" },
    { city: "São Pedro" },
    { city: "São Rafael" },
    { city: "São Tomé" },
    { city: "São Vicente" },
    { city: "Senador Elói de Souza" },
    { city: "Senador Georgino Avelino" },
    { city: "Serra de São Bento" },
    { city: "Serra do Mel" },
    { city: "Serra Negra do Norte" },
    { city: "Serrinha" },
    { city: "Serrinha dos Pintos" },
    { city: "Severiano Melo" },
    { city: "Sítio Novo" },
    { city: "Taboleiro Grande" },
    { city: "Taipu" },
    { city: "Tangará" },
    { city: "Tenente Ananias" },
    { city: "Tenente Laurentino Cruz" },
    { city: "Tibau" },
    { city: "Tibau do Sul" },
    { city: "Timbaúba dos Batistas" },
    { city: "Touros" },
    { city: "Triunfo Potiguar" },
    { city: "Umarizal" },
    { city: "Upanema" },
    { city: "Várzea" },
    { city: "Venha-Ver" },
    { city: "Vera Cruz" },
    { city: "Viçosa" },
    { city: "Vila Flor" }
  ],
  "RO": [
    { city: "Alta Floresta d'Oeste" },
    { city: "Alto Alegre do Parecis" },
    { city: "Alto Paraíso" },
    { city: "Alvorada d'Oeste" },
    { city: "Ariquemes" },
    { city: "Buritis" },
    { city: "Cabixi" },
    { city: "Cacaulândia" },
    { city: "Cacoal" },
    { city: "Campo Novo de Rondônia" },
    { city: "Candeias do Jamari" },
    { city: "Castanheiras" },
    { city: "Cerejeiras" },
    { city: "Chupinguaia" },
    { city: "Colorado do Oeste" },
    { city: "Corumbiara" },
    { city: "Costa Marques" },
    { city: "Cujubim" },
    { city: "Espigão d'Oeste" },
    { city: "Governador Jorge Teixeira" },
    { city: "Guajará-Mirim" },
    { city: "Itapuã do Oeste" },
    { city: "Jaru" },
    { city: "Ji-Paraná" },
    { city: "Machadinho d'Oeste" },
    { city: "Ministro Andreazza" },
    { city: "Mirante da Serra" },
    { city: "Monte Negro" },
    { city: "Nova Brasilândia d'Oeste" },
    { city: "Nova Mamoré" },
    { city: "Nova União" },
    { city: "Novo Horizonte do Oeste" },
    { city: "Ouro Preto do Oeste" },
    { city: "Parecis" },
    { city: "Pimenta Bueno" },
    { city: "Pimenteiras do Oeste" },
    { city: "Porto Velho" },
    { city: "Presidente Médici" },
    { city: "Primavera de Rondônia" },
    { city: "Rio Crespo" },
    { city: "Rolim de Moura" },
    { city: "Santa Luzia d'Oeste" },
    { city: "São Felipe d'Oeste" },
    { city: "São Francisco do Guaporé" },
    { city: "São Miguel do Guaporé" },
    { city: "Seringueiras" },
    { city: "Teixeirópolis" },
    { city: "Theobroma" },
    { city: "Urupá" },
    { city: "Vale do Anari" },
    { city: "Vale do Paraíso" },
    { city: "Vilhena" }
  ],
  "RR": [
    { city: "Alto Alegre" },
    { city: "Amajari" },
    { city: "Boa Vista" },
    { city: "Bonfim" },
    { city: "Cantá" },
    { city: "Caracaraí" },
    { city: "Caroebe" },
    { city: "Iracema" },
    { city: "Mucajaí" },
    { city: "Normandia" },
    { city: "Pacaraima" },
    { city: "Rorainópolis" },
    { city: "São João da Baliza" },
    { city: "São Luiz" },
    { city: "Uiramutã" }
  ],
  "RS": [
    { city: "Aceguá" },
    { city: "Água Santa" },
    { city: "Agudo" },
    { city: "Ajuricaba" },
    { city: "Alecrim" },
    { city: "Alegrete" },
    { city: "Alegria" },
    { city: "Almirante Tamandaré do Sul" },
    { city: "Alpestre" },
    { city: "Alto Alegre" },
    { city: "Alto Feliz" },
    { city: "Alvorada" },
    { city: "Amaral Ferrador" },
    { city: "Ametista do Sul" },
    { city: "André da Rocha" },
    { city: "Anta Gorda" },
    { city: "Antônio Prado" },
    { city: "Arambaré" },
    { city: "Araricá" },
    { city: "Aratiba" },
    { city: "Arroio do Meio" },
    { city: "Arroio do Padre" },
    { city: "Arroio do Sal" },
    { city: "Arroio do Tigre" },
    { city: "Arroio dos Ratos" },
    { city: "Arroio Grande" },
    { city: "Arvorezinha" },
    { city: "Augusto Pestana" },
    { city: "Áurea" },
    { city: "Bagé" },
    { city: "Balneário Pinhal" },
    { city: "Barão" },
    { city: "Barão de Cotegipe" },
    { city: "Barão do Triunfo" },
    { city: "Barra do Guarita" },
    { city: "Barra do Quaraí" },
    { city: "Barra do Ribeiro" },
    { city: "Barra do Rio Azul" },
    { city: "Barra Funda" },
    { city: "Barracão" },
    { city: "Barros Cassal" },
    { city: "Benjamin Constan do Sul" },
    { city: "Bento Gonçalves" },
    { city: "Boa Vista das Missões" },
    { city: "Boa Vista do Buricá" },
    { city: "Boa Vista do Cadeado" },
    { city: "Boa Vista do Incra" },
    { city: "Boa Vista do Sul" },
    { city: "Bom Jesus" },
    { city: "Bom Princípio" },
    { city: "Bom Progresso" },
    { city: "Bom Retiro do Sul" },
    { city: "Boqueirão do Leão" },
    { city: "Bossoroca" },
    { city: "Bozano" },
    { city: "Braga" },
    { city: "Brochier" },
    { city: "Butiá" },
    { city: "Caçapava do Sul" },
    { city: "Cacequi" },
    { city: "Cachoeira do Sul" },
    { city: "Cachoeirinha" },
    { city: "Cacique Doble" },
    { city: "Caibaté" },
    { city: "Caiçara" },
    { city: "Camaquã" },
    { city: "Camargo" },
    { city: "Cambará do Sul" },
    { city: "Campestre da Serra" },
    { city: "Campina das Missões" },
    { city: "Campinas do Sul" },
    { city: "Campo Bom" },
    { city: "Campo Novo" },
    { city: "Campos Borges" },
    { city: "Candelária" },
    { city: "Cândido Godói" },
    { city: "Candiota" },
    { city: "Canela" },
    { city: "Canguçu" },
    { city: "Canoas" },
    { city: "Canudos do Vale" },
    { city: "Capão Bonito do Sul" },
    { city: "Capão da Canoa" },
    { city: "Capão do Cipó" },
    { city: "Capão do Leão" },
    { city: "Capela de Santana" },
    { city: "Capitão" },
    { city: "Capivari do Sul" },
    { city: "Caraá" },
    { city: "Carazinho" },
    { city: "Carlos Barbosa" },
    { city: "Carlos Gomes" },
    { city: "Casca" },
    { city: "Caseiros" },
    { city: "Catuípe" },
    { city: "Caxias do Sul" },
    { city: "Centenário" },
    { city: "Cerrito" },
    { city: "Cerro Branco" },
    { city: "Cerro Grande" },
    { city: "Cerro Grande do Sul" },
    { city: "Cerro Largo" },
    { city: "Chapada" },
    { city: "Charqueadas" },
    { city: "Charrua" },
    { city: "Chiapeta" },
    { city: "Chuí" },
    { city: "Chuvisca" },
    { city: "Cidreira" },
    { city: "Ciríaco" },
    { city: "Colinas" },
    { city: "Colorado" },
    { city: "Condor" },
    { city: "Constantina" },
    { city: "Coqueiro Baixo" },
    { city: "Coqueiros do Sul" },
    { city: "Coronel Barros" },
    { city: "Coronel Bicaco" },
    { city: "Coronel Pilar" },
    { city: "Cotiporã" },
    { city: "Coxilha" },
    { city: "Crissiumal" },
    { city: "Cristal" },
    { city: "Cristal do Sul" },
    { city: "Cruz Alta" },
    { city: "Cruzaltense" },
    { city: "Cruzeiro do Sul" },
    { city: "David Canabarro" },
    { city: "Derrubadas" },
    { city: "Dezesseis de Novembro" },
    { city: "Dilermando de Aguiar" },
    { city: "Dois Irmãos" },
    { city: "Dois Irmãos das Missões" },
    { city: "Dois Lajeados" },
    { city: "Dom Feliciano" },
    { city: "Dom Pedrito" },
    { city: "Dom Pedro de Alcântara" },
    { city: "Dona Francisca" },
    { city: "Doutor Maurício Cardoso" },
    { city: "Doutor Ricardo" },
    { city: "Eldorado do Sul" },
    { city: "Encantado" },
    { city: "Encruzilhada do Sul" },
    { city: "Engenho Velho" },
    { city: "Entre Rios do Sul" },
    { city: "Entre-Ijuís" },
    { city: "Erebango" },
    { city: "Erechim" },
    { city: "Ernestina" },
    { city: "Erval Grande" },
    { city: "Erval Seco" },
    { city: "Esmeralda" },
    { city: "Esperança do Sul" },
    { city: "Espumoso" },
    { city: "Estação" },
    { city: "Estância Velha" },
    { city: "Esteio" },
    { city: "Estrela" },
    { city: "Estrela Velha" },
    { city: "Eugênio de Castro" },
    { city: "Fagundes Varela" },
    { city: "Farroupilha" },
    { city: "Faxinal do Soturno" },
    { city: "Faxinalzinho" },
    { city: "Fazenda Vilanova" },
    { city: "Feliz" },
    { city: "Flores da Cunha" },
    { city: "Floriano Peixoto" },
    { city: "Fontoura Xavier" },
    { city: "Formigueiro" },
    { city: "Forquetinha" },
    { city: "Fortaleza dos Valos" },
    { city: "Frederico Westphalen" },
    { city: "Garibaldi" },
    { city: "Garruchos" },
    { city: "Gaurama" },
    { city: "General Câmara" },
    { city: "Gentil" },
    { city: "Getúlio Vargas" },
    { city: "Giruá" },
    { city: "Glorinha" },
    { city: "Gramado" },
    { city: "Gramado dos Loureiros" },
    { city: "Gramado Xavier" },
    { city: "Gravataí" },
    { city: "Guabiju" },
    { city: "Guaíba" },
    { city: "Guaporé" },
    { city: "Guarani das Missões" },
    { city: "Harmonia" },
    { city: "Herval" },
    { city: "Herveiras" },
    { city: "Horizontina" },
    { city: "Hulha Negra" },
    { city: "Humaitá" },
    { city: "Ibarama" },
    { city: "Ibiaçá" },
    { city: "Ibiraiaras" },
    { city: "Ibirapuitã" },
    { city: "Ibirubá" },
    { city: "Igrejinha" },
    { city: "Ijuí" },
    { city: "Ilópolis" },
    { city: "Imbé" },
    { city: "Imigrante" },
    { city: "Independência" },
    { city: "Inhacorá" },
    { city: "Ipê" },
    { city: "Ipiranga do Sul" },
    { city: "Iraí" },
    { city: "Itaara" },
    { city: "Itacurubi" },
    { city: "Itapuca" },
    { city: "Itaqui" },
    { city: "Itati" },
    { city: "Itatiba do Sul" },
    { city: "Ivorá" },
    { city: "Ivoti" },
    { city: "Jaboticaba" },
    { city: "Jacuizinho" },
    { city: "Jacutinga" },
    { city: "Jaguarão" },
    { city: "Jaguari" },
    { city: "Jaquirana" },
    { city: "Jari" },
    { city: "Jóia" },
    { city: "Júlio de Castilhos" },
    { city: "Lagoa Bonita do Sul" },
    { city: "Lagoa dos Três Cantos" },
    { city: "Lagoa Vermelha" },
    { city: "Lagoão" },
    { city: "Lajeado" },
    { city: "Lajeado do Bugre" },
    { city: "Lavras do Sul" },
    { city: "Liberato Salzano" },
    { city: "Lindolfo Collor" },
    { city: "Linha Nova" },
    { city: "Maçambara" },
    { city: "Machadinho" },
    { city: "Mampituba" },
    { city: "Manoel Viana" },
    { city: "Maquiné" },
    { city: "Maratá" },
    { city: "Marau" },
    { city: "Marcelino Ramos" },
    { city: "Mariana Pimentel" },
    { city: "Mariano Moro" },
    { city: "Marques de Souza" },
    { city: "Mata" },
    { city: "Mato Castelhano" },
    { city: "Mato Leitão" },
    { city: "Mato Queimado" },
    { city: "Maximiliano de Almeida" },
    { city: "Minas do Leão" },
    { city: "Miraguaí" },
    { city: "Montauri" },
    { city: "Monte Alegre dos Campos" },
    { city: "Monte Belo do Sul" },
    { city: "Montenegro" },
    { city: "Mormaço" },
    { city: "Morrinhos do Sul" },
    { city: "Morro Redondo" },
    { city: "Morro Reuter" },
    { city: "Mostardas" },
    { city: "Muçum" },
    { city: "Muitos Capões" },
    { city: "Muliterno" },
    { city: "Não-Me-Toque" },
    { city: "Nicolau Vergueiro" },
    { city: "Nonoai" },
    { city: "Nova Alvorada" },
    { city: "Nova Araçá" },
    { city: "Nova Bassano" },
    { city: "Nova Boa Vista" },
    { city: "Nova Bréscia" },
    { city: "Nova Candelária" },
    { city: "Nova Esperança do Sul" },
    { city: "Nova Hartz" },
    { city: "Nova Pádua" },
    { city: "Nova Palma" },
    { city: "Nova Petrópolis" },
    { city: "Nova Prata" },
    { city: "Nova Ramada" },
    { city: "Nova Roma do Sul" },
    { city: "Nova Santa Rita" },
    { city: "Novo Barreiro" },
    { city: "Novo Cabrais" },
    { city: "Novo Hamburgo" },
    { city: "Novo Machado" },
    { city: "Novo Tiradentes" },
    { city: "Novo Xingu" },
    { city: "Osório" },
    { city: "Paim Filho" },
    { city: "Palmares do Sul" },
    { city: "Palmeira das Missões" },
    { city: "Palmitinho" },
    { city: "Panambi" },
    { city: "Pântano Grande" },
    { city: "Paraí" },
    { city: "Paraíso do Sul" },
    { city: "Pareci Novo" },
    { city: "Parobé" },
    { city: "Passa Sete" },
    { city: "Passo do Sobrado" },
    { city: "Passo Fundo" },
    { city: "Paulo Bento" },
    { city: "Paverama" },
    { city: "Pedras Altas" },
    { city: "Pedro Osório" },
    { city: "Pejuçara" },
    { city: "Pelotas" },
    { city: "Picada Café" },
    { city: "Pinhal" },
    { city: "Pinhal da Serra" },
    { city: "Pinhal Grande" },
    { city: "Pinheirinho do Vale" },
    { city: "Pinheiro Machado" },
    { city: "Pirapó" },
    { city: "Piratini" },
    { city: "Planalto" },
    { city: "Poço das Antas" },
    { city: "Pontão" },
    { city: "Ponte Preta" },
    { city: "Portão" },
    { city: "Porto Alegre" },
    { city: "Porto Lucena" },
    { city: "Porto Mauá" },
    { city: "Porto Vera Cruz" },
    { city: "Porto Xavier" },
    { city: "Pouso Novo" },
    { city: "Presidente Lucena" },
    { city: "Progresso" },
    { city: "Protásio Alves" },
    { city: "Putinga" },
    { city: "Quaraí" },
    { city: "Quatro Irmãos" },
    { city: "Quevedos" },
    { city: "Quinze de Novembro" },
    { city: "Redentora" },
    { city: "Relvado" },
    { city: "Restinga Seca" },
    { city: "Rio dos Índios" },
    { city: "Rio Grande" },
    { city: "Rio Pardo" },
    { city: "Riozinho" },
    { city: "Roca Sales" },
    { city: "Rodeio Bonito" },
    { city: "Rolador" },
    { city: "Rolante" },
    { city: "Ronda Alta" },
    { city: "Rondinha" },
    { city: "Roque Gonzales" },
    { city: "Rosário do Sul" },
    { city: "Sagrada Família" },
    { city: "Saldanha Marinho" },
    { city: "Salto do Jacuí" },
    { city: "Salvador das Missões" },
    { city: "Salvador do Sul" },
    { city: "Sananduva" },
    { city: "Santa Bárbara do Sul" },
    { city: "Santa Cecília do Sul" },
    { city: "Santa Clara do Sul" },
    { city: "Santa Cruz do Sul" },
    { city: "Santa Margarida do Sul" },
    { city: "Santa Maria" },
    { city: "Santa Maria do Herval" },
    { city: "Santa Rosa" },
    { city: "Santa Tereza" },
    { city: "Santa Vitória do Palmar" },
    { city: "Santana da Boa Vista" },
    { city: "Santana do Livramento" },
    { city: "Santiago" },
    { city: "Santo Ângelo" },
    { city: "Santo Antônio da Patrulha" },
    { city: "Santo Antônio das Missões" },
    { city: "Santo Antônio do Palma" },
    { city: "Santo Antônio do Planalto" },
    { city: "Santo Augusto" },
    { city: "Santo Cristo" },
    { city: "Santo Expedito do Sul" },
    { city: "São Borja" },
    { city: "São Domingos do Sul" },
    { city: "São Francisco de Assis" },
    { city: "São Francisco de Paula" },
    { city: "São Gabriel" },
    { city: "São Jerônimo" },
    { city: "São João da Urtiga" },
    { city: "São João do Polêsine" },
    { city: "São Jorge" },
    { city: "São José das Missões" },
    { city: "São José do Herval" },
    { city: "São José do Hortêncio" },
    { city: "São José do Inhacorá" },
    { city: "São José do Norte" },
    { city: "São José do Ouro" },
    { city: "São José do Sul" },
    { city: "São José dos Ausentes" },
    { city: "São Leopoldo" },
    { city: "São Lourenço do Sul" },
    { city: "São Luiz Gonzaga" },
    { city: "São Marcos" },
    { city: "São Martinho" },
    { city: "São Martinho da Serra" },
    { city: "São Miguel das Missões" },
    { city: "São Nicolau" },
    { city: "São Paulo das Missões" },
    { city: "São Pedro da Serra" },
    { city: "São Pedro das Missões" },
    { city: "São Pedro do Butiá" },
    { city: "São Pedro do Sul" },
    { city: "São Sebastião do Caí" },
    { city: "São Sepé" },
    { city: "São Valentim" },
    { city: "São Valentim do Sul" },
    { city: "São Valério do Sul" },
    { city: "São Vendelino" },
    { city: "São Vicente do Sul" },
    { city: "Sapiranga" },
    { city: "Sapucaia do Sul" },
    { city: "Sarandi" },
    { city: "Seberi" },
    { city: "Sede Nova" },
    { city: "Segredo" },
    { city: "Selbach" },
    { city: "Senador Salgado Filho" },
    { city: "Sentinela do Sul" },
    { city: "Serafina Corrêa" },
    { city: "Sério" },
    { city: "Sertão" },
    { city: "Sertão Santana" },
    { city: "Sete de Setembro" },
    { city: "Severiano de Almeida" },
    { city: "Silveira Martins" },
    { city: "Sinimbu" },
    { city: "Sobradinho" },
    { city: "Soledade" },
    { city: "Tabaí" },
    { city: "Tapejara" },
    { city: "Tapera" },
    { city: "Tapes" },
    { city: "Taquara" },
    { city: "Taquari" },
    { city: "Taquaruçu do Sul" },
    { city: "Tavares" },
    { city: "Tenente Portela" },
    { city: "Terra de Areia" },
    { city: "Teutônia" },
    { city: "Tio Hugo" },
    { city: "Tiradentes do Sul" },
    { city: "Toropi" },
    { city: "Torres" },
    { city: "Tramandaí" },
    { city: "Travesseiro" },
    { city: "Três Arroios" },
    { city: "Três Cachoeiras" },
    { city: "Três Coroas" },
    { city: "Três de Maio" },
    { city: "Três Forquilhas" },
    { city: "Três Palmeiras" },
    { city: "Três Passos" },
    { city: "Trindade do Sul" },
    { city: "Triunfo" },
    { city: "Tucunduva" },
    { city: "Tunas" },
    { city: "Tupanci do Sul" },
    { city: "Tupanciretã" },
    { city: "Tupandi" },
    { city: "Tuparendi" },
    { city: "Turuçu" },
    { city: "Ubiretama" },
    { city: "União da Serra" },
    { city: "Unistalda" },
    { city: "Uruguaiana" },
    { city: "Vacaria" },
    { city: "Vale do Sol" },
    { city: "Vale Real" },
    { city: "Vale Verde" },
    { city: "Vanini" },
    { city: "Venâncio Aires" },
    { city: "Vera Cruz" },
    { city: "Veranópolis" },
    { city: "Vespasiano Correa" },
    { city: "Viadutos" },
    { city: "Viamão" },
    { city: "Vicente Dutra" },
    { city: "Victor Graeff" },
    { city: "Vila Flores" },
    { city: "Vila Lângaro" },
    { city: "Vila Maria" },
    { city: "Vila Nova do Sul" },
    { city: "Vista Alegre" },
    { city: "Vista Alegre do Prata" },
    { city: "Vista Gaúcha" },
    { city: "Vitória das Missões" },
    { city: "Westfália" },
    { city: "Xangri-lá" }
  ],
  "SC": [
    { city: "Abdon Batista" },
    { city: "Abelardo Luz" },
    { city: "Agrolândia" },
    { city: "Agronômica" },
    { city: "Água Doce" },
    { city: "Águas de Chapecó" },
    { city: "Águas Frias" },
    { city: "Águas Mornas" },
    { city: "Alfredo Wagner" },
    { city: "Alto Bela Vista" },
    { city: "Anchieta" },
    { city: "Angelina" },
    { city: "Anita Garibaldi" },
    { city: "Anitápolis" },
    { city: "Antônio Carlos" },
    { city: "Apiúna" },
    { city: "Arabutã" },
    { city: "Araquari" },
    { city: "Araranguá" },
    { city: "Armazém" },
    { city: "Arroio Trinta" },
    { city: "Arvoredo" },
    { city: "Ascurra" },
    { city: "Atalanta" },
    { city: "Aurora" },
    { city: "Balneário Arroio do Silva" },
    { city: "Balneário Barra do Sul" },
    { city: "Balneário Camboriú" },
    { city: "Balneário Gaivota" },
    { city: "Bandeirante" },
    { city: "Barra Bonita" },
    { city: "Barra Velha" },
    { city: "Bela Vista do Toldo" },
    { city: "Belmonte" },
    { city: "Benedito Novo" },
    { city: "Biguaçu" },
    { city: "Blumenau" },
    { city: "Bocaina do Sul" },
    { city: "Bom Jardim da Serra" },
    { city: "Bom Jesus" },
    { city: "Bom Jesus do Oeste" },
    { city: "Bom Retiro" },
    { city: "Bombinhas" },
    { city: "Botuverá" },
    { city: "Braço do Norte" },
    { city: "Braço do Trombudo" },
    { city: "Brunópolis" },
    { city: "Brusque" },
    { city: "Caçador" },
    { city: "Caibi" },
    { city: "Calmon" },
    { city: "Camboriú" },
    { city: "Campo Alegre" },
    { city: "Campo Belo do Sul" },
    { city: "Campo Erê" },
    { city: "Campos Novos" },
    { city: "Canelinha" },
    { city: "Canoinhas" },
    { city: "Capão Alto" },
    { city: "Capinzal" },
    { city: "Capivari de Baixo" },
    { city: "Catanduvas" },
    { city: "Caxambu do Sul" },
    { city: "Celso Ramos" },
    { city: "Cerro Negro" },
    { city: "Chapadão do Lageado" },
    { city: "Chapecó" },
    { city: "Cocal do Sul" },
    { city: "Concórdia" },
    { city: "Cordilheira Alta" },
    { city: "Coronel Freitas" },
    { city: "Coronel Martins" },
    { city: "Correia Pinto" },
    { city: "Corupá" },
    { city: "Criciúma" },
    { city: "Cunha Porã" },
    { city: "Cunhataí" },
    { city: "Curitibanos" },
    { city: "Descanso" },
    { city: "Dionísio Cerqueira" },
    { city: "Dona Emma" },
    { city: "Doutor Pedrinho" },
    { city: "Entre Rios" },
    { city: "Ermo" },
    { city: "Erval Velho" },
    { city: "Faxinal dos Guedes" },
    { city: "Flor do Sertão" },
    { city: "Florianópolis" },
    { city: "Formosa do Sul" },
    { city: "Forquilhinha" },
    { city: "Fraiburgo" },
    { city: "Frei Rogério" },
    { city: "Galvão" },
    { city: "Garopaba" },
    { city: "Garuva" },
    { city: "Gaspar" },
    { city: "Governador Celso Ramos" },
    { city: "Grão Pará" },
    { city: "Gravatal" },
    { city: "Guabiruba" },
    { city: "Guaraciaba" },
    { city: "Guaramirim" },
    { city: "Guarujá do Sul" },
    { city: "Guatambú" },
    { city: "Herval d'Oeste" },
    { city: "Ibiam" },
    { city: "Ibicaré" },
    { city: "Ibirama" },
    { city: "Içara" },
    { city: "Ilhota" },
    { city: "Imaruí" },
    { city: "Imbituba" },
    { city: "Imbuia" },
    { city: "Indaial" },
    { city: "Iomerê" },
    { city: "Ipira" },
    { city: "Iporã do Oeste" },
    { city: "Ipuaçu" },
    { city: "Ipumirim" },
    { city: "Iraceminha" },
    { city: "Irani" },
    { city: "Irati" },
    { city: "Irineópolis" },
    { city: "Itá" },
    { city: "Itaiópolis" },
    { city: "Itajaí" },
    { city: "Itapema" },
    { city: "Itapiranga" },
    { city: "Itapoá" },
    { city: "Ituporanga" },
    { city: "Jaborá" },
    { city: "Jacinto Machado" },
    { city: "Jaguaruna" },
    { city: "Jaraguá do Sul" },
    { city: "Jardinópolis" },
    { city: "Joaçaba" },
    { city: "Joinville" },
    { city: "José Boiteux" },
    { city: "Jupiá" },
    { city: "Lacerdópolis" },
    { city: "Lages" },
    { city: "Laguna" },
    { city: "Lajeado Grande" },
    { city: "Laurentino" },
    { city: "Lauro Muller" },
    { city: "Lebon Régis" },
    { city: "Leoberto Leal" },
    { city: "Lindóia do Sul" },
    { city: "Lontras" },
    { city: "Luiz Alves" },
    { city: "Luzerna" },
    { city: "Macieira" },
    { city: "Mafra" },
    { city: "Major Gercino" },
    { city: "Major Vieira" },
    { city: "Maracajá" },
    { city: "Maravilha" },
    { city: "Marema" },
    { city: "Massaranduba" },
    { city: "Matos Costa" },
    { city: "Meleiro" },
    { city: "Mirim Doce" },
    { city: "Modelo" },
    { city: "Mondaí" },
    { city: "Monte Carlo" },
    { city: "Monte Castelo" },
    { city: "Morro da Fumaça" },
    { city: "Morro Grande" },
    { city: "Navegantes" },
    { city: "Nova Erechim" },
    { city: "Nova Itaberaba" },
    { city: "Nova Trento" },
    { city: "Nova Veneza" },
    { city: "Novo Horizonte" },
    { city: "Orleans" },
    { city: "Otacílio Costa" },
    { city: "Ouro" },
    { city: "Ouro Verde" },
    { city: "Paial" },
    { city: "Painel" },
    { city: "Palhoça" },
    { city: "Palma Sola" },
    { city: "Palmeira" },
    { city: "Palmitos" },
    { city: "Papanduva" },
    { city: "Paraíso" },
    { city: "Passo de Torres" },
    { city: "Passos Maia" },
    { city: "Paulo Lopes" },
    { city: "Pedras Grandes" },
    { city: "Penha" },
    { city: "Peritiba" },
    { city: "Petrolândia" },
    { city: "Piçarras" },
    { city: "Pinhalzinho" },
    { city: "Pinheiro Preto" },
    { city: "Piratuba" },
    { city: "Planalto Alegre" },
    { city: "Pomerode" },
    { city: "Ponte Alta" },
    { city: "Ponte Alta do Norte" },
    { city: "Ponte Serrada" },
    { city: "Porto Belo" },
    { city: "Porto União" },
    { city: "Pouso Redondo" },
    { city: "Praia Grande" },
    { city: "Presidente Castelo Branco" },
    { city: "Presidente Getúlio" },
    { city: "Presidente Nereu" },
    { city: "Princesa" },
    { city: "Quilombo" },
    { city: "Rancho Queimado" },
    { city: "Rio das Antas" },
    { city: "Rio do Campo" },
    { city: "Rio do Oeste" },
    { city: "Rio do Sul" },
    { city: "Rio dos Cedros" },
    { city: "Rio Fortuna" },
    { city: "Rio Negrinho" },
    { city: "Rio Rufino" },
    { city: "Riqueza" },
    { city: "Rodeio" },
    { city: "Romelândia" },
    { city: "Salete" },
    { city: "Saltinho" },
    { city: "Salto Veloso" },
    { city: "Sangão" },
    { city: "Santa Cecília" },
    { city: "Santa Helena" },
    { city: "Santa Rosa de Lima" },
    { city: "Santa Rosa do Sul" },
    { city: "Santa Terezinha" },
    { city: "Santa Terezinha do Progresso" },
    { city: "Santiago do Sul" },
    { city: "Santo Amaro da Imperatriz" },
    { city: "São Bento do Sul" },
    { city: "São Bernardino" },
    { city: "São Bonifácio" },
    { city: "São Carlos" },
    { city: "São Cristovão do Sul" },
    { city: "São Domingos" },
    { city: "São Francisco do Sul" },
    { city: "São João Batista" },
    { city: "São João do Itaperiú" },
    { city: "São João do Oeste" },
    { city: "São João do Sul" },
    { city: "São Joaquim" },
    { city: "São José" },
    { city: "São José do Cedro" },
    { city: "São José do Cerrito" },
    { city: "São Lourenço do Oeste" },
    { city: "São Ludgero" },
    { city: "São Martinho" },
    { city: "São Miguel da Boa Vista" },
    { city: "São Miguel do Oeste" },
    { city: "São Pedro de Alcântara" },
    { city: "Saudades" },
    { city: "Schroeder" },
    { city: "Seara" },
    { city: "Serra Alta" },
    { city: "Siderópolis" },
    { city: "Sombrio" },
    { city: "Sul Brasil" },
    { city: "Taió" },
    { city: "Tangará" },
    { city: "Tigrinhos" },
    { city: "Tijucas" },
    { city: "Timbé do Sul" },
    { city: "Timbó" },
    { city: "Timbó Grande" },
    { city: "Três Barras" },
    { city: "Treviso" },
    { city: "Treze de Maio" },
    { city: "Treze Tílias" },
    { city: "Trombudo Central" },
    { city: "Tubarão" },
    { city: "Tunápolis" },
    { city: "Turvo" },
    { city: "União do Oeste" },
    { city: "Urubici" },
    { city: "Urupema" },
    { city: "Urussanga" },
    { city: "Vargeão" },
    { city: "Vargem" },
    { city: "Vargem Bonita" },
    { city: "Vidal Ramos" },
    { city: "Videira" },
    { city: "Vitor Meireles" },
    { city: "Witmarsum" },
    { city: "Xanxerê" },
    { city: "Xavantina" },
    { city: "Xaxim" },
    { city: "Zortéa" }
  ],
  "SE": [
    { city: "Amparo de São Francisco" },
    { city: "Aquidabã" },
    { city: "Aracaju" },
    { city: "Arauá" },
    { city: "Areia Branca" },
    { city: "Barra dos Coqueiros" },
    { city: "Boquim" },
    { city: "Brejo Grande" },
    { city: "Campo do Brito" },
    { city: "Canhoba" },
    { city: "Canindé de São Francisco" },
    { city: "Capela" },
    { city: "Carira" },
    { city: "Carmópolis" },
    { city: "Cedro de São João" },
    { city: "Cristinápolis" },
    { city: "Cumbe" },
    { city: "Divina Pastora" },
    { city: "Estância" },
    { city: "Feira Nova" },
    { city: "Frei Paulo" },
    { city: "Gararu" },
    { city: "General Maynard" },
    { city: "Gracho Cardoso" },
    { city: "Ilha das Flores" },
    { city: "Indiaroba" },
    { city: "Itabaiana" },
    { city: "Itabaianinha" },
    { city: "Itabi" },
    { city: "Itaporanga d'Ajuda" },
    { city: "Japaratuba" },
    { city: "Japoatã" },
    { city: "Lagarto" },
    { city: "Laranjeiras" },
    { city: "Macambira" },
    { city: "Malhada dos Bois" },
    { city: "Malhador" },
    { city: "Maruim" },
    { city: "Moita Bonita" },
    { city: "Monte Alegre de Sergipe" },
    { city: "Muribeca" },
    { city: "Neópolis" },
    { city: "Nossa Senhora Aparecida" },
    { city: "Nossa Senhora da Glória" },
    { city: "Nossa Senhora das Dores" },
    { city: "Nossa Senhora de Lourdes" },
    { city: "Nossa Senhora do Socorro" },
    { city: "Pacatuba" },
    { city: "Pedra Mole" },
    { city: "Pedrinhas" },
    { city: "Pinhão" },
    { city: "Pirambu" },
    { city: "Poço Redondo" },
    { city: "Poço Verde" },
    { city: "Porto da Folha" },
    { city: "Propriá" },
    { city: "Riachão do Dantas" },
    { city: "Riachuelo" },
    { city: "Ribeirópolis" },
    { city: "Rosário do Catete" },
    { city: "Salgado" },
    { city: "Santa Luzia do Itanhy" },
    { city: "Santa Rosa de Lima" },
    { city: "Santana do São Francisco" },
    { city: "Santo Amaro das Brotas" },
    { city: "São Cristóvão" },
    { city: "São Domingos" },
    { city: "São Francisco" },
    { city: "São Miguel do Aleixo" },
    { city: "Simão Dias" },
    { city: "Siriri" },
    { city: "Telha" },
    { city: "Tobias Barreto" },
    { city: "Tomar do Geru" },
    { city: "Umbaúba" }
  ],
  "SP": [
    { city: "Adamantina" },
    { city: "Adolfo" },
    { city: "Aguaí" },
    { city: "Águas da Prata" },
    { city: "Águas de Lindóia" },
    { city: "Águas de Santa Bárbara" },
    { city: "Águas de São Pedro" },
    { city: "Agudos" },
    { city: "Alambari" },
    { city: "Alfredo Marcondes" },
    { city: "Altair" },
    { city: "Altinópolis" },
    { city: "Alto Alegre" },
    { city: "Alumínio" },
    { city: "Álvares Florence" },
    { city: "Álvares Machado" },
    { city: "Álvaro de Carvalho" },
    { city: "Alvinlândia" },
    { city: "Americana" },
    { city: "Américo Brasiliense" },
    { city: "Américo de Campos" },
    { city: "Amparo" },
    { city: "Analândia" },
    { city: "Andradina" },
    { city: "Angatuba" },
    { city: "Anhembi" },
    { city: "Anhumas" },
    { city: "Aparecida" },
    { city: "Aparecida d'Oeste" },
    { city: "Apiaí" },
    { city: "Araçariguama" },
    { city: "Araçatuba" },
    { city: "Araçoiaba da Serra" },
    { city: "Aramina" },
    { city: "Arandu" },
    { city: "Arapeí" },
    { city: "Araraquara" },
    { city: "Araras" },
    { city: "Arco-Íris" },
    { city: "Arealva" },
    { city: "Areias" },
    { city: "Areiópolis" },
    { city: "Ariranha" },
    { city: "Artur Nogueira" },
    { city: "Arujá" },
    { city: "Aspásia" },
    { city: "Assis" },
    { city: "Atibaia" },
    { city: "Auriflama" },
    { city: "Avaí" },
    { city: "Avanhandava" },
    { city: "Avaré" },
    { city: "Bady Bassitt" },
    { city: "Balbinos" },
    { city: "Bálsamo" },
    { city: "Bananal" },
    { city: "Barão de Antonina" },
    { city: "Barbosa" },
    { city: "Bariri" },
    { city: "Barra Bonita" },
    { city: "Barra do Chapéu" },
    { city: "Barra do Turvo" },
    { city: "Barretos" },
    { city: "Barrinha" },
    { city: "Barueri" },
    { city: "Bastos" },
    { city: "Batatais" },
    { city: "Bauru" },
    { city: "Bebedouro" },
    { city: "Bento de Abreu" },
    { city: "Bernardino de Campos" },
    { city: "Bertioga" },
    { city: "Bilac" },
    { city: "Birigui" },
    { city: "Biritiba-Mirim" },
    { city: "Boa Esperança do Sul" },
    { city: "Bocaina" },
    { city: "Bofete" },
    { city: "Boituva" },
    { city: "Bom Jesus dos Perdões" },
    { city: "Bom Sucesso de Itararé" },
    { city: "Borá" },
    { city: "Boracéia" },
    { city: "Borborema" },
    { city: "Borebi" },
    { city: "Botucatu" },
    { city: "Bragança Paulista" },
    { city: "Braúna" },
    { city: "Brejo Alegre" },
    { city: "Brodowski" },
    { city: "Brotas" },
    { city: "Buri" },
    { city: "Buritama" },
    { city: "Buritizal" },
    { city: "Cabrália Paulista" },
    { city: "Cabreúva" },
    { city: "Caçapava" },
    { city: "Cachoeira Paulista" },
    { city: "Caconde" },
    { city: "Cafelândia" },
    { city: "Caiabu" },
    { city: "Caieiras" },
    { city: "Caiuá" },
    { city: "Cajamar" },
    { city: "Cajati" },
    { city: "Cajobi" },
    { city: "Cajuru" },
    { city: "Campina do Monte Alegre" },
    { city: "Campinas" },
    { city: "Campo Limpo Paulista" },
    { city: "Campos do Jordão" },
    { city: "Campos Novos Paulista" },
    { city: "Cananéia" },
    { city: "Canas" },
    { city: "Cândido Mota" },
    { city: "Cândido Rodrigues" },
    { city: "Canitar" },
    { city: "Capão Bonito" },
    { city: "Capela do Alto" },
    { city: "Capivari" },
    { city: "Caraguatatuba" },
    { city: "Carapicuíba" },
    { city: "Cardoso" },
    { city: "Casa Branca" },
    { city: "Cássia dos Coqueiros" },
    { city: "Castilho" },
    { city: "Catanduva" },
    { city: "Catiguá" },
    { city: "Cedral" },
    { city: "Cerqueira César" },
    { city: "Cerquilho" },
    { city: "Cesário Lange" },
    { city: "Charqueada" },
    { city: "Chavantes" },
    { city: "Clementina" },
    { city: "Colina" },
    { city: "Colômbia" },
    { city: "Conchal" },
    { city: "Conchas" },
    { city: "Cordeirópolis" },
    { city: "Coroados" },
    { city: "Coronel Macedo" },
    { city: "Corumbataí" },
    { city: "Cosmópolis" },
    { city: "Cosmorama" },
    { city: "Cotia" },
    { city: "Cravinhos" },
    { city: "Cristais Paulista" },
    { city: "Cruzália" },
    { city: "Cruzeiro" },
    { city: "Cubatão" },
    { city: "Cunha" },
    { city: "Descalvado" },
    { city: "Diadema" },
    { city: "Dirce Reis" },
    { city: "Divinolândia" },
    { city: "Dobrada" },
    { city: "Dois Córregos" },
    { city: "Dolcinópolis" },
    { city: "Dourado" },
    { city: "Dracena" },
    { city: "Duartina" },
    { city: "Dumont" },
    { city: "Echaporã" },
    { city: "Eldorado" },
    { city: "Elias Fausto" },
    { city: "Elisiário" },
    { city: "Embaúba" },
    { city: "Embu" },
    { city: "Embu-Guaçu" },
    { city: "Emilianópolis" },
    { city: "Engenheiro Coelho" },
    { city: "Espírito Santo do Pinhal" },
    { city: "Espírito Santo do Turvo" },
    { city: "Estiva Gerbi" },
    { city: "Estrela d'Oeste" },
    { city: "Estrela do Norte" },
    { city: "Euclides da Cunha Paulista" },
    { city: "Fartura" },
    { city: "Fernando Prestes" },
    { city: "Fernandópolis" },
    { city: "Fernão" },
    { city: "Ferraz de Vasconcelos" },
    { city: "Flora Rica" },
    { city: "Floreal" },
    { city: "Florínia" },
    { city: "Flórida Paulista" },
    { city: "Franca" },
    { city: "Francisco Morato" },
    { city: "Franco da Rocha" },
    { city: "Gabriel Monteiro" },
    { city: "Gália" },
    { city: "Garça" },
    { city: "Gastão Vidigal" },
    { city: "Gavião Peixoto" },
    { city: "General Salgado" },
    { city: "Getulina" },
    { city: "Glicério" },
    { city: "Guaiçara" },
    { city: "Guaimbê" },
    { city: "Guaíra" },
    { city: "Guapiaçu" },
    { city: "Guapiara" },
    { city: "Guará" },
    { city: "Guaraçaí" },
    { city: "Guaraci" },
    { city: "Guarani d'Oeste" },
    { city: "Guarantã" },
    { city: "Guararapes" },
    { city: "Guararema" },
    { city: "Guaratinguetá" },
    { city: "Guareí" },
    { city: "Guariba" },
    { city: "Guarujá" },
    { city: "Guarulhos" },
    { city: "Guatapará" },
    { city: "Guzolândia" },
    { city: "Herculândia" },
    { city: "Holambra" },
    { city: "Hortolândia" },
    { city: "Iacanga" },
    { city: "Iacri" },
    { city: "Iaras" },
    { city: "Ibaté" },
    { city: "Ibirá" },
    { city: "Ibirarema" },
    { city: "Ibitinga" },
    { city: "Ibiúna" },
    { city: "Icém" },
    { city: "Iepê" },
    { city: "Igaraçu do Tietê" },
    { city: "Igarapava" },
    { city: "Igaratá" },
    { city: "Iguape" },
    { city: "Ilha Comprida" },
    { city: "Ilha Solteira" },
    { city: "Ilhabela" },
    { city: "Indaiatuba" },
    { city: "Indiana" },
    { city: "Indiaporã" },
    { city: "Inúbia Paulista" },
    { city: "Ipauçu" },
    { city: "Iperó" },
    { city: "Ipeúna" },
    { city: "Ipiguá" },
    { city: "Iporanga" },
    { city: "Ipuã" },
    { city: "Iracemápolis" },
    { city: "Irapuã" },
    { city: "Irapuru" },
    { city: "Itaberá" },
    { city: "Itaí" },
    { city: "Itajobi" },
    { city: "Itaju" },
    { city: "Itanhaém" },
    { city: "Itaóca" },
    { city: "Itapecerica da Serra" },
    { city: "Itapetininga" },
    { city: "Itapeva" },
    { city: "Itapevi" },
    { city: "Itapira" },
    { city: "Itapirapuã Paulista" },
    { city: "Itápolis" },
    { city: "Itaporanga" },
    { city: "Itapuí" },
    { city: "Itapura" },
    { city: "Itaquaquecetuba" },
    { city: "Itararé" },
    { city: "Itariri" },
    { city: "Itatiba" },
    { city: "Itatinga" },
    { city: "Itirapina" },
    { city: "Itirapuã" },
    { city: "Itobi" },
    { city: "Itu" },
    { city: "Itupeva" },
    { city: "Ituverava" },
    { city: "Jaborandi" },
    { city: "Jaboticabal" },
    { city: "Jacareí" },
    { city: "Jaci" },
    { city: "Jacupiranga" },
    { city: "Jaguariúna" },
    { city: "Jales" },
    { city: "Jambeiro" },
    { city: "Jandira" },
    { city: "Jardinópolis" },
    { city: "Jarinu" },
    { city: "Jaú" },
    { city: "Jeriquara" },
    { city: "Joanópolis" },
    { city: "João Ramalho" },
    { city: "José Bonifácio" },
    { city: "Júlio Mesquita" },
    { city: "Jumirim" },
    { city: "Jundiaí" },
    { city: "Junqueirópolis" },
    { city: "Juquiá" },
    { city: "Juquitiba" },
    { city: "Lagoinha" },
    { city: "Laranjal Paulista" },
    { city: "Lavínia" },
    { city: "Lavrinhas" },
    { city: "Leme" },
    { city: "Lençóis Paulista" },
    { city: "Limeira" },
    { city: "Lindóia" },
    { city: "Lins" },
    { city: "Lorena" },
    { city: "Lourdes" },
    { city: "Louveira" },
    { city: "Lucélia" },
    { city: "Lucianópolis" },
    { city: "Luís Antônio" },
    { city: "Luiziânia" },
    { city: "Lupércio" },
    { city: "Lutécia" },
    { city: "Macatuba" },
    { city: "Macaubal" },
    { city: "Macedônia" },
    { city: "Magda" },
    { city: "Mairinque" },
    { city: "Mairiporã" },
    { city: "Manduri" },
    { city: "Marabá Paulista" },
    { city: "Maracaí" },
    { city: "Marapoama" },
    { city: "Mariápolis" },
    { city: "Marília" },
    { city: "Marinópolis" },
    { city: "Martinópolis" },
    { city: "Matão" },
    { city: "Mauá" },
    { city: "Mendonça" },
    { city: "Meridiano" },
    { city: "Mesópolis" },
    { city: "Miguelópolis" },
    { city: "Mineiros do Tietê" },
    { city: "Mira Estrela" },
    { city: "Miracatu" },
    { city: "Mirandópolis" },
    { city: "Mirante do Paranapanema" },
    { city: "Mirassol" },
    { city: "Mirassolândia" },
    { city: "Mococa" },
    { city: "Mogi das Cruzes" },
    { city: "Mogi-Guaçu" },
    { city: "Mogi-Mirim" },
    { city: "Mombuca" },
    { city: "Monções" },
    { city: "Mongaguá" },
    { city: "Monte Alegre do Sul" },
    { city: "Monte Alto" },
    { city: "Monte Aprazível" },
    { city: "Monte Azul Paulista" },
    { city: "Monte Castelo" },
    { city: "Monte Mor" },
    { city: "Monteiro Lobato" },
    { city: "Morro Agudo" },
    { city: "Morungaba" },
    { city: "Motuca" },
    { city: "Murutinga do Sul" },
    { city: "Nantes" },
    { city: "Narandiba" },
    { city: "Natividade da Serra" },
    { city: "Nazaré Paulista" },
    { city: "Neves Paulista" },
    { city: "Nhandeara" },
    { city: "Nipoã" },
    { city: "Nova Aliança" },
    { city: "Nova Campina" },
    { city: "Nova Canaã Paulista" },
    { city: "Nova Castilho" },
    { city: "Nova Europa" },
    { city: "Nova Granada" },
    { city: "Nova Guataporanga" },
    { city: "Nova Independência" },
    { city: "Nova Luzitânia" },
    { city: "Nova Odessa" },
    { city: "Novais" },
    { city: "Novo Horizonte" },
    { city: "Nuporanga" },
    { city: "Ocauçu" },
    { city: "Óleo" },
    { city: "Olímpia" },
    { city: "Onda Verde" },
    { city: "Oriente" },
    { city: "Orindiúva" },
    { city: "Orlândia" },
    { city: "Osasco" },
    { city: "Oscar Bressane" },
    { city: "Osvaldo Cruz" },
    { city: "Ourinhos" },
    { city: "Ouro Verde" },
    { city: "Ouroeste" },
    { city: "Pacaembu" },
    { city: "Palestina" },
    { city: "Palmares Paulista" },
    { city: "Palmeira d'Oeste" },
    { city: "Palmital" },
    { city: "Panorama" },
    { city: "Paraguaçu Paulista" },
    { city: "Paraibuna" },
    { city: "Paraíso" },
    { city: "Paranapanema" },
    { city: "Paranapuã" },
    { city: "Parapuã" },
    { city: "Pardinho" },
    { city: "Pariquera-Açu" },
    { city: "Parisi" },
    { city: "Patrocínio Paulista" },
    { city: "Paulicéia" },
    { city: "Paulínia" },
    { city: "Paulistânia" },
    { city: "Paulo de Faria" },
    { city: "Pederneiras" },
    { city: "Pedra Bela" },
    { city: "Pedranópolis" },
    { city: "Pedregulho" },
    { city: "Pedreira" },
    { city: "Pedrinhas Paulista" },
    { city: "Pedro de Toledo" },
    { city: "Penápolis" },
    { city: "Pereira Barreto" },
    { city: "Pereiras" },
    { city: "Peruíbe" },
    { city: "Piacatu" },
    { city: "Piedade" },
    { city: "Pilar do Sul" },
    { city: "Pindamonhangaba" },
    { city: "Pindorama" },
    { city: "Pinhalzinho" },
    { city: "Piquerobi" },
    { city: "Piquete" },
    { city: "Piracaia" },
    { city: "Piracicaba" },
    { city: "Piraju" },
    { city: "Pirajuí" },
    { city: "Pirangi" },
    { city: "Pirapora do Bom Jesus" },
    { city: "Pirapozinho" },
    { city: "Pirassununga" },
    { city: "Piratininga" },
    { city: "Pitangueiras" },
    { city: "Planalto" },
    { city: "Platina" },
    { city: "Poá" },
    { city: "Poloni" },
    { city: "Pompéia" },
    { city: "Pongaí" },
    { city: "Pontal" },
    { city: "Pontalinda" },
    { city: "Pontes Gestal" },
    { city: "Populina" },
    { city: "Porangaba" },
    { city: "Porto Feliz" },
    { city: "Porto Ferreira" },
    { city: "Potim" },
    { city: "Potirendaba" },
    { city: "Pracinha" },
    { city: "Pradópolis" },
    { city: "Praia Grande" },
    { city: "Pratânia" },
    { city: "Presidente Alves" },
    { city: "Presidente Bernardes" },
    { city: "Presidente Epitácio" },
    { city: "Presidente Prudente" },
    { city: "Presidente Venceslau" },
    { city: "Promissão" },
    { city: "Quadra" },
    { city: "Quatá" },
    { city: "Queiroz" },
    { city: "Queluz" },
    { city: "Quintana" },
    { city: "Rafard" },
    { city: "Rancharia" },
    { city: "Redenção da Serra" },
    { city: "Regente Feijó" },
    { city: "Reginópolis" },
    { city: "Registro" },
    { city: "Restinga" },
    { city: "Ribeira" },
    { city: "Ribeirão Bonito" },
    { city: "Ribeirão Branco" },
    { city: "Ribeirão Corrente" },
    { city: "Ribeirão do Sul" },
    { city: "Ribeirão dos Índios" },
    { city: "Ribeirão Grande" },
    { city: "Ribeirão Pires" },
    { city: "Ribeirão Preto" },
    { city: "Rifaina" },
    { city: "Rincão" },
    { city: "Rinópolis" },
    { city: "Rio Claro" },
    { city: "Rio das Pedras" },
    { city: "Rio Grande da Serra" },
    { city: "Riolândia" },
    { city: "Riversul" },
    { city: "Rosana" },
    { city: "Roseira" },
    { city: "Rubiácea" },
    { city: "Rubinéia" },
    { city: "Sabino" },
    { city: "Sagres" },
    { city: "Sales" },
    { city: "Sales Oliveira" },
    { city: "Salesópolis" },
    { city: "Salmourão" },
    { city: "Saltinho" },
    { city: "Salto" },
    { city: "Salto de Pirapora" },
    { city: "Salto Grande" },
    { city: "Sandovalina" },
    { city: "Santa Adélia" },
    { city: "Santa Albertina" },
    { city: "Santa Bárbara d'Oeste" },
    { city: "Santa Branca" },
    { city: "Santa Clara d'Oeste" },
    { city: "Santa Cruz da Conceição" },
    { city: "Santa Cruz da Esperança" },
    { city: "Santa Cruz das Palmeiras" },
    { city: "Santa Cruz do Rio Pardo" },
    { city: "Santa Ernestina" },
    { city: "Santa Fé do Sul" },
    { city: "Santa Gertrudes" },
    { city: "Santa Isabel" },
    { city: "Santa Lúcia" },
    { city: "Santa Maria da Serra" },
    { city: "Santa Mercedes" },
    { city: "Santa Rita d'Oeste" },
    { city: "Santa Rita do Passa Quatro" },
    { city: "Santa Rosa de Viterbo" },
    { city: "Santa Salete" },
    { city: "Santana da Ponte Pensa" },
    { city: "Santana de Parnaíba" },
    { city: "Santo Anastácio" },
    { city: "Santo André" },
    { city: "Santo Antônio da Alegria" },
    { city: "Santo Antônio de Posse" },
    { city: "Santo Antônio do Aracanguá" },
    { city: "Santo Antônio do Jardim" },
    { city: "Santo Antônio do Pinhal" },
    { city: "Santo Expedito" },
    { city: "Santópolis do Aguapeí" },
    { city: "Santos" },
    { city: "São Bento do Sapucaí" },
    { city: "São Bernardo do Campo" },
    { city: "São Caetano do Sul" },
    { city: "São Carlos" },
    { city: "São Francisco" },
    { city: "São João da Boa Vista" },
    { city: "São João das Duas Pontes" },
    { city: "São João de Iracema" },
    { city: "São João do Pau d'Alho" },
    { city: "São Joaquim da Barra" },
    { city: "São José da Bela Vista" },
    { city: "São José do Barreiro" },
    { city: "São José do Rio Pardo" },
    { city: "São José do Rio Preto" },
    { city: "São José dos Campos" },
    { city: "São Lourenço da Serra" },
    { city: "São Luís do Paraitinga" },
    { city: "São Manuel" },
    { city: "São Miguel Arcanjo" },
    { city: "São Paulo" },
    { city: "São Pedro" },
    { city: "São Pedro do Turvo" },
    { city: "São Roque" },
    { city: "São Sebastião" },
    { city: "São Sebastião da Grama" },
    { city: "São Simão" },
    { city: "São Vicente" },
    { city: "Sarapuí" },
    { city: "Sarutaiá" },
    { city: "Sebastianópolis do Sul" },
    { city: "Serra Azul" },
    { city: "Serra Negra" },
    { city: "Serrana" },
    { city: "Sertãozinho" },
    { city: "Sete Barras" },
    { city: "Severínia" },
    { city: "Silveiras" },
    { city: "Socorro" },
    { city: "Sorocaba" },
    { city: "Sud Mennucci" },
    { city: "Sumaré" },
    { city: "Suzanápolis" },
    { city: "Suzano" },
    { city: "Tabapuã" },
    { city: "Tabatinga" },
    { city: "Taboão da Serra" },
    { city: "Taciba" },
    { city: "Taguaí" },
    { city: "Taiaçu" },
    { city: "Taiúva" },
    { city: "Tambaú" },
    { city: "Tanabi" },
    { city: "Tapiraí" },
    { city: "Tapiratiba" },
    { city: "Taquaral" },
    { city: "Taquaritinga" },
    { city: "Taquarituba" },
    { city: "Taquarivaí" },
    { city: "Tarabai" },
    { city: "Tarumã" },
    { city: "Tatuí" },
    { city: "Taubaté" },
    { city: "Tejupá" },
    { city: "Teodoro Sampaio" },
    { city: "Terra Roxa" },
    { city: "Tietê" },
    { city: "Timburi" },
    { city: "Torre de Pedra" },
    { city: "Torrinha" },
    { city: "Trabiju" },
    { city: "Tremembé" },
    { city: "Três Fronteiras" },
    { city: "Tuiuti" },
    { city: "Tupã" },
    { city: "Tupi Paulista" },
    { city: "Turiúba" },
    { city: "Turmalina" },
    { city: "Ubarana" },
    { city: "Ubatuba" },
    { city: "Ubirajara" },
    { city: "Uchoa" },
    { city: "União Paulista" },
    { city: "Urânia" },
    { city: "Uru" },
    { city: "Urupês" },
    { city: "Valentim Gentil" },
    { city: "Valinhos" },
    { city: "Valparaíso" },
    { city: "Vargem" },
    { city: "Vargem Grande do Sul" },
    { city: "Vargem Grande Paulista" },
    { city: "Várzea Paulista" },
    { city: "Vera Cruz" },
    { city: "Vinhedo" },
    { city: "Viradouro" },
    { city: "Vista Alegre do Alto" },
    { city: "Vitória Brasil" },
    { city: "Votorantim" },
    { city: "Votuporanga" },
    { city: "Zacarias" }
  ],
  "TO": [
    { city: "Abreulândia" },
    { city: "Aguiarnópolis" },
    { city: "Aliança do Tocantins" },
    { city: "Almas" },
    { city: "Alvorada" },
    { city: "Ananás" },
    { city: "Angico" },
    { city: "Aparecida do Rio Negro" },
    { city: "Aragominas" },
    { city: "Araguacema" },
    { city: "Araguaçu" },
    { city: "Araguaína" },
    { city: "Araguanã" },
    { city: "Araguatins" },
    { city: "Arapoema" },
    { city: "Arraias" },
    { city: "Augustinópolis" },
    { city: "Aurora do Tocantins" },
    { city: "Axixá do Tocantins" },
    { city: "Babaçulândia" },
    { city: "Bandeirantes do Tocantins" },
    { city: "Barra do Ouro" },
    { city: "Barrolândia" },
    { city: "Bernardo Sayão" },
    { city: "Bom Jesus do Tocantins" },
    { city: "Brasilândia do Tocantins" },
    { city: "Brejinho de Nazaré" },
    { city: "Buriti do Tocantins" },
    { city: "Cachoeirinha" },
    { city: "Campos Lindos" },
    { city: "Cariri do Tocantins" },
    { city: "Carmolândia" },
    { city: "Carrasco Bonito" },
    { city: "Caseara" },
    { city: "Centenário" },
    { city: "Chapada da Natividade" },
    { city: "Chapada de Areia" },
    { city: "Colinas do Tocantins" },
    { city: "Colméia" },
    { city: "Combinado" },
    { city: "Conceição do Tocantins" },
    { city: "Couto Magalhães" },
    { city: "Cristalândia" },
    { city: "Crixás do Tocantins" },
    { city: "Darcinópolis" },
    { city: "Dianópolis" },
    { city: "Divinópolis do Tocantins" },
    { city: "Dois Irmãos do Tocantins" },
    { city: "Dueré" },
    { city: "Esperantina" },
    { city: "Fátima" },
    { city: "Figueirópolis" },
    { city: "Filadélfia" },
    { city: "Formoso do Araguaia" },
    { city: "Fortaleza do Tabocão" },
    { city: "Goianorte" },
    { city: "Goiatins" },
    { city: "Guaraí" },
    { city: "Gurupi" },
    { city: "Ipueiras" },
    { city: "Itacajá" },
    { city: "Itaguatins" },
    { city: "Itapiratins" },
    { city: "Itaporã do Tocantins" },
    { city: "Jaú do Tocantins" },
    { city: "Juarina" },
    { city: "Lagoa da Confusão" },
    { city: "Lagoa do Tocantins" },
    { city: "Lajeado" },
    { city: "Lavandeira" },
    { city: "Lizarda" },
    { city: "Luzinópolis" },
    { city: "Marianópolis do Tocantins" },
    { city: "Mateiros" },
    { city: "Maurilândia do Tocantins" },
    { city: "Miracema do Tocantins" },
    { city: "Miranorte" },
    { city: "Monte do Carmo" },
    { city: "Monte Santo do Tocantins" },
    { city: "Muricilândia" },
    { city: "Natividade" },
    { city: "Nazaré" },
    { city: "Nova Olinda" },
    { city: "Nova Rosalândia" },
    { city: "Novo Acordo" },
    { city: "Novo Alegre" },
    { city: "Novo Jardim" },
    { city: "Oliveira de Fátima" },
    { city: "Palmas" },
    { city: "Palmeirante" },
    { city: "Palmeiras do Tocantins" },
    { city: "Palmeirópolis" },
    { city: "Paraíso do Tocantins" },
    { city: "Paranã" },
    { city: "Pau d'Arco" },
    { city: "Pedro Afonso" },
    { city: "Peixe" },
    { city: "Pequizeiro" },
    { city: "Pindorama do Tocantins" },
    { city: "Piraquê" },
    { city: "Pium" },
    { city: "Ponte Alta do Bom Jesus" },
    { city: "Ponte Alta do Tocantins" },
    { city: "Porto Alegre do Tocantins" },
    { city: "Porto Nacional" },
    { city: "Praia Norte" },
    { city: "Presidente Kennedy" },
    { city: "Pugmil" },
    { city: "Recursolândia" },
    { city: "Riachinho" },
    { city: "Rio da Conceição" },
    { city: "Rio dos Bois" },
    { city: "Rio Sono" },
    { city: "Sampaio" },
    { city: "Sandolândia" },
    { city: "Santa Fé do Araguaia" },
    { city: "Santa Maria do Tocantins" },
    { city: "Santa Rita do Tocantins" },
    { city: "Santa Rosa do Tocantins" },
    { city: "Santa Tereza do Tocantins" },
    { city: "Santa Terezinha do Tocantins" },
    { city: "São Bento do Tocantins" },
    { city: "São Félix do Tocantins" },
    { city: "São Miguel do Tocantins" },
    { city: "São Salvador do Tocantins" },
    { city: "São Sebastião do Tocantins" },
    { city: "São Valério" },
    { city: "Silvanópolis" },
    { city: "Sítio Novo do Tocantins" },
    { city: "Sucupira" },
    { city: "Taguatinga" },
    { city: "Taipas do Tocantins" },
    { city: "Talismã" },
    { city: "Tocantínia" },
    { city: "Tocantinópolis" },
    { city: "Tupirama" },
    { city: "Tupiratins" },
    { city: "Wanderlândia" },
    { city: "Xambioá" }
  ]
}

export default cities
