import { action } from "easy-peasy"

const rationingStore = {
    // plot rationing restrictions in map
    rationing: [],
    center: [-22.977213, -47.101165],
    plotRationing: action((state, obj) => {
        state.rationing = obj;
        if (obj[0]) {
            state.center = [obj[obj.length - 1].area[0].lat, obj[obj.length - 1].area[0].lng]
        }
    }),

    // open store rationing form
    area: [],
    open_store_form: false,
    setOpenStoreForm: action((state, payload) => {
        state.area = payload;
        state.open_store_form = !state.open_store_form;
    }),
};

export default rationingStore;
