import React, { Fragment } from "react";
import { useStoreState } from "easy-peasy"
import { makeStyles } from "@material-ui/core/styles";
import {
    Dialog,
    DialogTitle,
    FormControl,
    DialogContent,
} from "@material-ui/core";
import FormTools from "./FormTools"

const useStyles = makeStyles((theme) => ({
    form: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        width: "fit-content",
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
}));

export default function Form() {
    const classes = useStyles();
    const open = useStoreState((state) => state.toolsStore.open_store_form);

    return (
        <Fragment>
            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={open}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="max-width-dialog-title">Cadastro de Pedágios</DialogTitle>
                <DialogContent>
                    <form className={classes.form} noValidate>
                        <FormControl className={classes.formControl}>
                            <FormTools />
                        </FormControl>
                    </form>
                </DialogContent>
            </Dialog>
        </Fragment>
    );
}
