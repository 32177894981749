import React from "react";
import { useStoreActions } from "easy-peasy";
import { grey } from "@material-ui/core/colors";
import { DatePicker } from "@material-ui/pickers";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

import api from "../../../services/api.js"

const defaultMaterialTheme = createMuiTheme({
    palette: {
        primary: grey,
    },
});

export default function SelectDate() {
    //ativa e desativa o loading abaixo da appbar
    const setLoading = useStoreActions(actions => {
        return actions.globalStore.setLoading;
    });

    // reload na tabela ao trocar a filial
    const setReload = useStoreActions(actions => {
        return actions.globalStore.setReload;
    });

    async function Update(date) {
        setLoading(true)
        api.put(`/utils/user/date/${date}`)
            .then(() => {
                localStorage.setItem("dataProgr", date);
                setLoading(false)
                setReload()
            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            });
    }

    return (
        <ThemeProvider theme={defaultMaterialTheme}>
            <DatePicker
                size="small"
                ampm={false}
                color="secondary"
                label="PROGRAMAÇÃO"
                format="dd/MM/yyyy"
                value={new Date(localStorage.getItem("dataProgr"))}
                onChange={date => {
                    Update(date)
                }}
                style={{ width: 80 * window.innerWidth / 1280, marginLeft: 9 * window.innerWidth / 1280 }}
                InputProps={{
                    style: {
                        color: "#fff",
                        alignItems: 'center',
                        height: 20 * window.innerWidth / 1280,
                        fontSize: 14 * window.innerWidth / 1280,
                    },
                    disableUnderline: true
                }}
                InputLabelProps={{
                    style: {
                        color: "#fff",
                        alignItems: 'center',
                        fontSize: 14 * window.innerWidth / 1280,
                    }
                }}
                onError={console.log}
            />
        </ThemeProvider>
    );
}
