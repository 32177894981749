import PropTypes from 'prop-types';
import { useSnackbar } from "notistack";
import { useStoreActions } from 'easy-peasy';
import DeleteIcon from '@material-ui/icons/Delete';
import { Tooltip, Button } from "@material-ui/core";
import React, { useState, useRef, Fragment } from "react";

import "../../../styles.css";
import api from "../../../../../../../services/api.js";

export default function Delete(props) {
    const snack = useRef()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    snack.current = enqueueSnackbar
    const closeSnackbarRef = useRef()
    closeSnackbarRef.current = closeSnackbar

    const [id, setId] = useState(null);
    const Id = useRef()
    Id.current = id

    //ativa e desativa o loading abaixo da appbar
    const setLoading = useRef()
    setLoading.current = useStoreActions(actions => {
        return actions.globalStore.setLoading;
    });

    const setReload = useRef()
    setReload.current = useStoreActions(actions => {
        return actions.VeicsDocsStore.setReload;
    });

    async function DeleteOne(key) {
        setLoading.current(true)
        closeSnackbarRef.current(key)
        snack.current("Excluindo, aguarde.", { variant: 'info' })
        api.delete(`/progr/doc/${Id.current}`)
            .then(() => {
                snack.current("Excluído.", { variant: 'success' })
                setReload.current()
                setLoading.current(false)
            })
            .catch((error) => {
                console.log(error);
                try {
                    snack.current(error.response.data.message, { variant: 'error' })
                } catch (e) {
                    snack.current("Falha ao excluir.", { variant: 'error' })
                }
                setReload.current()
                setLoading.current(false)
            })
    }

    const action = key => (
        <Fragment>
            <Button onClick={() => { DeleteOne(key) }}>
                Sim
            </Button>
            <Button onClick={() => { closeSnackbarRef.current(key) }}>
                Não
            </Button>
        </Fragment>
    );

    function confirmationWindow(doc) {
        snack.current(`Deseja excluir o documento ${doc}?`, {
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
            },
            variant: 'info',
            persist: true,
            preventDuplicate: true,
            action,
        })
    }

    return (
        <Tooltip title={<span style={{ fontSize: 15 }}> Excluir </span>} >
            <Button
                onClick={() => {
                    setId(props.doc._id)
                    confirmationWindow(props.doc.documento)
                }}
                color="primary"
                style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px' }}
                disabled={props.doc.ajuste !== true}
            >
                <DeleteIcon fontSize="small" />
            </Button>
        </Tooltip>
    );
}

Delete.propTypes = {
    doc: PropTypes.object,
};
