import React, { useState } from 'react';

import DocumentForm from './Components/DocumentForm/DocumentForm.js';
import DocumentTable from './Components/DocumentTable/DocumentTable.js';

export default function Documents() {
    const [open, setOpen] = useState(false)
    const [data, setData] = useState(null)
    const [reload, setReload] = useState(false);

    return (
        <div >
            < DocumentTable
                reload={reload}
                setOpen={setOpen}
                setData={setData}
                setReload={setReload}
            />
            {
                open ?
                    < DocumentForm
                        data={data}
                        open={open}
                        setOpen={setOpen}
                        setReload={setReload}
                    />
                    : null
            }
        </div>
    );
}
