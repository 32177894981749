import React from 'react';
import { useStoreState } from 'easy-peasy';
import { AppBar, Container, LinearProgress } from '@material-ui/core';
import {
    AntTab,
    AntTabs,
    TabPanel,
    tabStyles,
} from '../../components/MyTabs.js';
import ButtonMap from './Components/Buttons/ButtonMap';
import Ordenate from './Components/Buttons/Ordenate.js';
import Alocattion from './Components/Buttons/Alocattion';
import GenerateTrip from './Components/Buttons/GenerateTrip';
import CheckboxLabels from './Components/Buttons/CheckboxLabels.js';

import ToOrder from './Components/ToOrder';
import MyMap from './Components/Map/index.js';
import ToTrip from './Components/ToTrip/ToTrip';
import ToAllocate from './Components/ToAllocate';
import MyAppBar from '../../utils/MyAppBar/MyAppBar.js';
import ImportVehicles from './Components/ImportVehicles';
import WebSocket from './Components/WebSocket/WebSocket.js';
import Documents from './Components/Documents/Documents.js';
import VehicleTable from './Components/Veics/VehicleTable.js';
import PlannedTrips from './Components/PlannedTrips/PlannedTrips.js';
import TripsAnalyze from './Components/TripsAnalyze/TripsAnalyze.js';

export default function Progr() {
    const classes = tabStyles({ fontSize: 11 * window.innerWidth / 1280 });
    const [value, setValue] = React.useState(0);

    const loading = useStoreState((state) => state.globalStore.loading);
    return (
        <div className={classes.root}>
            < MyAppBar />
            < Container maxWidth={false} spacing={2}>
                <AppBar position='fixed' color='default' style={{ marginTop: 50 }}>
                    <AntTabs
                        value={value}
                        variant='fullWidth'
                        textColor='primary'
                        scrollButtons='auto'
                        indicatorColor='primary'
                        onChange={(_, newValue) => {
                            setValue(newValue)
                        }}
                        classes={{ indicator: classes.customStyleOnActiveTab }}
                    >
                        <AntTab label={<span className={value === 0 ? classes.activeTab : classes.customStyleOnTab}>1.ROTEIRIZAR</span>} />
                        <AntTab label={<span className={value === 1 ? classes.activeTab : classes.customStyleOnTab}>2.VALIDAR</span>} />
                        {/* <AntTab label={<span className={value === 2 ? classes.activeTab : classes.customStyleOnTab}>3.ESCALAR MOTORISTA(Em Breve)</span>} /> */}
                        <AntTab label={<span className={value === 2 ? classes.activeTab : classes.customStyleOnTab}>3.PLANEJADAS</span>} />
                    </AntTabs>
                    {loading && <LinearProgress />}
                </AppBar>

                <div style={{ marginTop: 50 }}>
                    <TabPanel value={value} index={0}  >
                        <AppBar position='fixed' color="#fffff" style={{ marginTop: 100 }} elevation={0}>
                            <div className="rowApp" >
                                < CheckboxLabels />
                                <div className="rowAppTool">
                                    < ButtonMap />
                                    <Alocattion />
                                    <Ordenate />
                                    <GenerateTrip />
                                </div >
                            </div>
                        </AppBar>
                        <div style={{ marginTop: 100 }}>
                            < Documents />
                            < VehicleTable />
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={1} style={{ marginTop: 50 }}>
                        <TripsAnalyze />
                    </TabPanel>
                    {/* <TabPanel value={value} index={2} style={{ marginTop: 50 }}>
                    </TabPanel> */}
                    <TabPanel value={value} index={2} style={{ marginTop: 50 }}>
                        <PlannedTrips />
                    </TabPanel>
                </div>
                < ImportVehicles />
                < ToAllocate />
                < ToOrder />
                < ToTrip />
                < MyMap />
                < WebSocket value={value} />
            </Container>
        </div >
    );
}
