import PropTypes from 'prop-types';
import { useSnackbar } from "notistack";
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useEffect, useRef } from 'react';
import { useStoreState, useStoreActions } from "easy-peasy";
import AssignmentIcon from '@material-ui/icons/Assignment';
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import { Stepper, Step, StepLabel, Button } from '@material-ui/core';

import "../../../styles.css"
import api from "../../../../../services/api.js";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    backButton: {
        // marginLeft: theme.spacing(10),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

function getSteps() {
    return ['ETAPA 1: Selecione os documentos', 'Etapa 2: Selecione um ou mais veículos'];
}

export default function MyStepper(props) {
    const classes = useStyles();
    const steps = getSteps();
    const snack = useRef()
    const { enqueueSnackbar } = useSnackbar();
    snack.current = enqueueSnackbar

    const [disabled_button, setDisabledButton] = useState(true);
    const activeRef = useRef(props)
    activeRef.current = props.activeStep

    const setActiveStep = useRef(props)
    setActiveStep.current = props.setActiveStep

    const setOpenAllocate = useStoreActions(actions => {
        return actions.toAllocateStore.setOpenAllocate;
    });

    // atualiza a tabela de documentos
    const setReload = useStoreActions(actions => {
        return actions.VeicsDocsStore.setReload;
    });

    //ativa e desativa o loading abaixo da appbar
    const setLoading = useStoreActions(actions => {
        return actions.globalStore.setLoading;
    });

    // const cd = useStoreState((state) => state.distributionCentersStore.cd);
    const alocate_type = useStoreState((state) => state.toAllocateStore.alocate_type);

    async function handleNext() {
        if (activeRef.current === 0) {
            setActiveStep.current((prevActiveStep) => prevActiveStep + 1)
        } else {
            setOpenAllocate(false)
            setLoading(true);
            api.post(`/allocation`, {
                veics: props.alocattion_vehicles,
                docs: props.alocattion_documents,
                tipo: alocate_type,
            })
                .then((res) => {
                    snack.current(res.data.message, { variant: res.data.status })
                    setLoading(false);
                    setReload()
                    setActiveStep.current(0)
                    props.setAlocattionVehicles([])
                    props.setAlocattionDocuments([])
                })
                .catch((error) => {
                    console.log(error);
                    try {
                        snack.current(`${error.response.data.message}`, { variant: 'error' });
                    } catch (e) {
                        console.error(e);
                        snack.current("Erro ao alocar os veículos", { variant: 'error' })
                    }
                    setLoading(false);
                    setActiveStep.current(0)
                    props.setAlocattionVehicles([])
                    props.setAlocattionDocuments([])
                });
        }
    };

    const handleBack = () => {
        setActiveStep.current((prevActiveStep) => prevActiveStep - 1);
    };

    const [values, setValues] = useState({
        n_docs: 0,
        peso_kg: 0,
        valor_reais: 0,
    })

    useEffect(() => {
        function CheckActive() {
            // seleção documentos
            if (activeRef.current === 0) {
                props.alocattion_documents.length > 0 ? setDisabledButton(false) : setDisabledButton(true)
            }

            // seleção veículos
            if (activeRef.current === 1) {
                props.alocattion_vehicles.length > 0 ? setDisabledButton(false) : setDisabledButton(true)
            }

            let peso_kg = 0
            let valor_reais = 0
            for (let doc of props.alocattion_documents) {
                peso_kg += doc.peso_kg
                valor_reais += doc.valor_reais
            }
            setValues({
                peso_kg,
                valor_reais,
                n_docs: props.alocattion_documents.length
            })
        }
        CheckActive();
    }, [props.alocattion_documents, props.alocattion_vehicles, props.activeStep]);

    return (
        <div className={classes.root}>
            <div id="stepper2" >
                <Stepper activeStep={props.activeStep} alternativeLabel>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </div>

            <div id="stepper1" >
                <div>
                    <Button
                        disabled={activeRef.current === 0}
                        onClick={handleBack}
                        className={classes.backButton}
                    >
                        Voltar
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleNext()}
                        disabled={disabled_button}
                    >
                        {activeRef.current === steps.length - 1 ? 'Gerar Alocação' : 'Próximo'}
                    </Button>
                </div>
            </div>
            <br />
            <div className="title_info4">
                < AssignmentIcon style={{ marginRight: '0.5%' }} /> Coletas + entregas (kg): {Number((values.peso_kg).toFixed(0))}
                <div style={{ marginLeft: '2%' }} />
                < AccountBalanceWalletIcon style={{ marginRight: '0.5%' }} /> Coletas + entregas (reais): {Number((values.valor_reais).toFixed(1))}
                <div style={{ marginLeft: '2%' }} />
                < LibraryAddCheckIcon style={{ marginRight: '0.5%' }} /> Documentos selecionados: {values.n_docs}
            </div>
        </div>
    );
}

MyStepper.propTypes = {
    activeStep: PropTypes.number,
    setActiveStep: PropTypes.func,
    alocattion_vehicles: PropTypes.array,
    alocattion_documents: PropTypes.array,
    setAlocattionVehicles: PropTypes.func,
    setAlocattionDocuments: PropTypes.func,
};
