import React from 'react';
import { Link } from "react-router-dom";
import List from '@material-ui/core/List';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { useStoreActions, useStoreState } from "easy-peasy";
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    top_: {
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
    },
}));

export default function DialogLinks() {
    const classes = useStyles();

    // link dialog
    const setOpenLinkDialogRef = React.useRef()
    setOpenLinkDialogRef.current = useStoreActions(actions => {
        return actions.sizeStore.setOpenLinkDialog;
    });

    const open_link_dialog = useStoreState((state) => state.sizeStore.open_link_dialog);
    const decree_links = useStoreState((state) => state.sizeStore.decree_links);

    return (
        <div>
            <Dialog
                open={open_link_dialog}
                TransitionComponent={Transition}
                keepMounted
                maxWidth={"lg"}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{"Links dos decretos"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <Typography className={classes.top_}>
                            <List className={classes.root} subheader={<li />}>
                                {decree_links.map((item) => (
                                    <ListItem key={`${item}`}>
                                        <Link to={item} target="_blank" onClick={(event) => { event.preventDefault(); window.open(item); }}>
                                            {item}
                                        </Link>
                                    </ListItem>
                                ))}
                            </List>
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenLinkDialogRef.current(false)} color="primary">
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}