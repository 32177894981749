import React from "react";
import PropTypes from 'prop-types';
import EditIcon from '@material-ui/icons/Edit';
import { Tooltip, Button } from "@material-ui/core";

export default function Edit(props) {
    return (
        <Tooltip title={<span style={{ fontSize: 15 }}> Editar </span>} >
            <Button
                color="primary"
                onClick={() => {
                    props.setData(props.item)
                    props.setOpen(true)
                }}
                style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px' }}
            >
                <EditIcon fontSize="small" />
            </Button>
        </Tooltip>
    );
}

Edit.propTypes = {
    item: PropTypes.object,
    setData: PropTypes.func,
    setOpen: PropTypes.func,
};
