import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import MaterialTable from 'material-table';
import { useStoreActions } from 'easy-peasy';
import React, { useEffect, useRef, useState } from 'react';

import '../../styles.css';
import Add from './Components/Add.js';
import Edit from './Components/Edit.js';
import api from '../../../../../../services/api.js';

export default function AffiliateTable(props) {
    const tableRef = useRef();
    const snack = useRef()
    const { enqueueSnackbar } = useSnackbar();
    snack.current = enqueueSnackbar

    const [affiliates, setAffiliates] = useState([]);

    const state = {
        columns: [
            {
                title: "Opções",
                field: "opcoes",
                editable: false,
                render: (rowData) => {
                    return (
                        <Edit
                            setData={props.setData}
                            setOpen={props.setOpen}
                            item={rowData}
                        />
                    )
                }
            },
            { title: "Filiais", field: "nome_filial" },
            { title: "Status", field: "status" },
            { title: "Cidade", field: "cidade" },
            { title: "Editado em", field: "editado_em" },
            { title: "Editado por", field: "editado_por" },
            { title: "identificador", field: "_id", editable: "never" },
        ],
    };

    //ativa e desativa o loading abaixo da appbar
    const setLoading = useRef()
    setLoading.current = useStoreActions(actions => {
        return actions.globalStore.setLoading;
    });

    useEffect(() => {
        setLoading.current(true);
        function fetchData() {
            api.get(`/register/affiliate`)
                .then((res) => {
                    var affiliates = []
                    for (let affil_ of res.data) {
                        let aux = {
                            "contatos": affil_.contatos,
                            "_id": affil_._id,
                            "companyId": affil_.companyId,
                            "cep": affil_.cep,
                            "pais": affil_.pais,
                            "cnpj": affil_.cnpj,
                            "status": affil_.status,
                            "cidade": affil_.cidade,
                            "bairro": affil_.bairro,
                            "estado": affil_.estado,
                            "numero": affil_.numero,
                            "logradouro": affil_.logradouro,
                            "complemento": affil_.complemento,
                            "nome_filial": affil_.nome_filial,
                            "razao_social": affil_.razao_social,
                            "nome_fantasia": affil_.nome_fantasia,
                            "fuso_horario": affil_.fuso_horario,
                            "criado_em": affil_.criado_em,
                            "criado_por": affil_.criado_por,
                            "editado_em": affil_.editado_em,
                            "editado_por": affil_.editado_por,
                            "lat": affil_.lat,
                            "lng": affil_.lng,
                        }
                        affiliates.push(aux)
                    }
                    setAffiliates(affiliates);
                    setLoading.current(false)
                })
                .catch((error) => {
                    setLoading.current(false)
                    console.log(error);
                    snack.current("Falha ao buscar as filiais", { variant: 'error' })
                });
        }
        fetchData();
    }, [props.reload]);

    return (
        <MaterialTable
            tableRef={tableRef}
            title="Filiais"
            columns={state.columns}
            data={affiliates}
            options={{
                add: true,
                search: true,
                pageSize: 10,
                exportButton: false,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [1, 5, 10, 20, affiliates.length],
                padding: "dense",
                headerStyle: {
                    backgroundColor: "#01579b",
                    textAlign: 'center',
                    color: "#FFF",
                    fontSize: 11 * window.innerWidth / 1280,
                },
                cellStyle: {
                    textAlign: "center",
                    fontSize: 11 * window.innerWidth / 1280,
                },
                rowStyle: rowData => ({
                    backgroundColor: (rowData.tableData.id % 2 === 0 ? '#fff' : '#f5f5f5')
                }),
            }}
            components={{
                Actions: () => {
                    return (
                        <Add
                            setData={props.setData}
                            setOpen={props.setOpen}
                        />
                    );
                }
            }}
            localization={{
                body: {
                    emptyDataSourceMessage: "",
                    addTooltip: "adicionar novo usuário",
                    deleteTooltip: "excluir",
                    editRow: { deleteText: "Deseja excluir o usuário?" },
                },

                header: {
                    actions: "Opções",
                },

                toolbar: {
                    searchTooltip: "Pesquisar",
                    searchPlaceholder: "Pesquisar",
                    exportTitle: "exportar",
                    nRowsSelected: "{0} Usuários(s) selecionados",
                },
                pagination: {
                    labelRowsSelect: "",
                    labelDisplayedRows: "{count} de {from}-{to}",
                    firstTooltip: "Primeira página",
                    previousTooltip: "Página anterior",
                    nextTooltip: "Próxima página",
                    lastTooltip: "Última página",
                },
            }}
        />
    );
}

AffiliateTable.propTypes = {
    reload: PropTypes.bool,
    setData: PropTypes.func,
    setOpen: PropTypes.func,
    setReload: PropTypes.func,
};
