import L from "leaflet";
import PropTypes from 'prop-types';
import { useStoreState } from "easy-peasy";
import ReactDOMServer from "react-dom/server";
import { Marker, Polyline } from "react-leaflet";
import React, { useState, useEffect, useRef } from "react";
import MarkerClusterGroup from "react-leaflet-markercluster";

import IconResults from "../../../../../icons/Results";
import IconVeiculos from "../../../../../icons/Veiculos";
import PopupDocumento from "../../../../../popups/Documento";
import IconCD from "../../../../../icons/CentroDistribuicao";
import PopupCentroDistribuicao from "../../../../../popups/CentroDistribuicao";

export default function Vehicles(props) {
    const setCenterRef = useRef()
    setCenterRef.current = props.setCenter

    const [veics, setVeics] = useState([]);

    const all_docs = useRef()
    all_docs.current = useStoreState((state) => state.VeicsDocsStore.documents);
    const open_cluster = useStoreState((state) => state.mapStore.open_cluster);
    const plot_vehicles = useStoreState((state) => state.mapStore.plot_vehicles);

    function getDarkColor() {
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += Math.floor(Math.random() * 10);
        }
        return color;
    }

    useEffect(() => {
        async function fetchData_() {
            if (plot_vehicles.length > 0) {
                for (let veic of plot_vehicles) {
                    veic["docs"] = all_docs.current.filter(x => JSON.stringify(x.TripId) === JSON.stringify(veic._id))
                    var aux_color = getDarkColor()
                    for (var doc of veic.docs) {
                        doc["color"] = aux_color;
                    }
                }
                setCenterRef.current([plot_vehicles[0].docs[0].lat_doc, plot_vehicles[0].docs[0].lon_doc]);
                var marker_g = [];
                for (let veic of plot_vehicles) {
                    var my_markers = veic.docs.map((doc, cont) => (
                        <div key={cont}>
                            {(() => {
                                var my_icon
                                if (!doc.ordem) {
                                    my_icon = L.divIcon({
                                        className: "custom-icon",
                                        html: ReactDOMServer.renderToString(<IconVeiculos id={cont} tipo={doc.tipo} doc={doc} />),
                                    });
                                } else {
                                    my_icon = L.divIcon({
                                        className: "custom-icon",
                                        html: ReactDOMServer.renderToString(<IconResults ordem={doc.ordem} tipo={doc.tipo} doc={doc} veic={veic} />),
                                    });
                                }

                                if (doc.tipo === "ENTREGA") {
                                    return (
                                        <Marker icon={my_icon} key={cont} position={[doc.lat_doc, doc.lon_doc]}>
                                            <PopupDocumento doc={doc} />
                                        </Marker>
                                    );
                                } else if (doc.tipo === "COLETA") {
                                    return (
                                        <Marker icon={my_icon} key={cont} position={[doc.lat_doc, doc.lon_doc]}>
                                            <PopupDocumento doc={doc} />
                                        </Marker>
                                    );
                                }
                            })()}
                        </div>
                    ));

                    if (veic.path.length > 0) {
                        var my_icon_cd = L.divIcon({
                            className: "custom-icon",
                            html: ReactDOMServer.renderToString(<IconCD id={0} />),
                        });
                        var aux_path = (
                            <Marker icon={my_icon_cd} key={10000000} position={[veic.path[0][0], veic.path[0][1]]}>
                                <PopupCentroDistribuicao cd={""} lat={veic.path[0][0]} lng={veic.path[0][1]} />
                                <Polyline key={0} color={"blue"} positions={veic.path} weight={2} />
                            </Marker>
                        )
                        my_markers.push(aux_path)
                    }
                    marker_g.push(my_markers);
                }
                setVeics(marker_g);
            }

            else {
                setVeics([]);
            }
        }
        fetchData_();
    }, [plot_vehicles]);

    return (
        <>
            {!open_cluster && <>{veics} </>}
            {open_cluster && <MarkerClusterGroup className={"markercluster-map"} id={0}> {veics} </MarkerClusterGroup>}
        </>
    );
}

Vehicles.propTypes = {
    setCenter: PropTypes.func,
};
