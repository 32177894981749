import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Button } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';

export default function IconWarning(props) {
    return (
        <>
            {props.trip.ocupacao > props.trip.capacidade_kg ? (
                < Tooltip title={<span style={{ fontSize: 15 }}> Ocup. Máx(kg) é maior que a capacidade </span>} size='small'>
                    <Button
                        style={{ color: 'red', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px' }}
                    >
                        <WarningIcon fontSize="small" />
                    </Button>
                </Tooltip>
            ) : null
            }
        </>
    )
}

IconWarning.propTypes = {
    trip: PropTypes.array,
};
