import React from "react";
import Routes from "./routes";
import ReactDOM from "react-dom";
import store from "./store/index";
import { StoreProvider } from 'easy-peasy';
import { SnackbarProvider } from "notistack";

import { ptBR } from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import "./App.min.css";
import "leaflet/dist/leaflet.css";

function App() {
    return (
        <StoreProvider store={store}>
            <SnackbarProvider>
                <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                    <Routes />
                </MuiPickersUtilsProvider>
            </SnackbarProvider>
        </StoreProvider>
    );
}

const rootElement = document.getElementById('root');
ReactDOM.render(<App />, rootElement);
