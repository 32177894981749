import { useSnackbar } from 'notistack';
import MaterialTable from 'material-table';
import React, { useEffect, useRef, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';

import IconToll from './IconToll.js';
import api from '../../../../../../services/api.js';

export default function DuplicateTable() {
    const snack = useRef()
    const { enqueueSnackbar } = useSnackbar();
    snack.current = enqueueSnackbar

    const setLoading = useRef()
    setLoading.current = useStoreActions(actions => {
        return actions.globalStore.setLoading;
    });

    const fatura = useStoreState((state) => state.InvoicesStore.fatura);

    const [duplicate_tolls, setDupTolls] = useState([])
    useEffect(() => {
        async function fetchData() {
            setLoading.current(true)
            api.get(`/invoice/duplicatetolls/${fatura._id}`)
                .then((resp) => {
                    let { duplicate_tolls } = resp.data
                    if (duplicate_tolls.length === 0) {
                        snack.current("Não foram encontradas cobranças duplicadas.", { variant: 'info' })
                    } else {
                        snack.current(`Total de cobranças duplicadas: ${duplicate_tolls.length}`, { variant: 'success' })
                        var dup_tolls = []
                        for (let item of duplicate_tolls) {
                            item["packageDate"] = new Date(item["packageDate"]).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })
                            dup_tolls.push(item)
                        }
                        setDupTolls(dup_tolls)
                    }

                    setLoading.current(false)
                })
                .catch((error) => {
                    snack.current("Falha ao carregar as cobranças duplicadas", { variant: 'error' })
                    setLoading.current(false)
                    console.log(error);
                });
        }
        fetchData()
    }, [])

    const tableRef = useRef();
    return (
        <MaterialTable
            tableRef={tableRef}
            title="Pedágios com Cobrança Duplicada"
            columns={[
                { title: "Placa", field: "placa" },
                { title: "Valor (R$)", field: "cost" },
                { title: "Data", field: "packageDate" },
                { title: "Número de cobranças", field: "count" },
                {
                    title: "Cobranças",
                    field: "info",
                    editable: false,
                    render: (rowData) => {
                        return (
                            <IconToll tolls={rowData.uniqueIds} />
                        )
                    }
                },
            ]}
            data={duplicate_tolls}
            options={{
                add: true,
                pageSize: 10,
                search: true,
                grouping: true,
                selection: false,
                exportButton: false,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [1, 5, 10, 20],
                padding: "dense",
                headerStyle: {
                    backgroundColor: "#01579b",
                    textAlign: 'center',
                    color: "#FFF",
                    fontSize: 11 * window.innerWidth / 1280,
                },
                cellStyle: {
                    textAlign: "center",
                    fontSize: 11 * window.innerWidth / 1280,
                },
            }}
            localization={{
                body: {
                    addTooltip: "Adicionar novo",
                    deleteTooltip: "Excluir",
                    editTooltip: "Editar",
                    editRow: {
                        deleteText: "Deseja excluir o documento?",
                        cancelTooltip: "Cancelar",
                        saveTooltip: "Salvar",
                    },
                    emptyDataSourceMessage: (
                        ""
                    ),
                    bulkEditTooltip: "Editar todos",
                    bulkEditCancel: "Descartar edições",
                    bulkEditApprove: "Salvar todas as edições"
                },

                grouping: {
                    placeholder: "Arraste os cabeçalhos aqui para agrupar por",
                    groupedBy: "Agrupado por:",
                },

                header: {
                    actions: "Opções",
                },

                toolbar: {
                    searchTooltip: "Pesquisar",
                    searchPlaceholder: "Pesquisar",
                    exportTitle: "Exportar",
                    nRowsSelected: "{0} documento(s) selecionados",
                },
                pagination: {
                    labelRowsSelect: "",
                    labelDisplayedRows: "{count} de {from}-{to}",
                    firstTooltip: "Primeira página",
                    previousTooltip: "Página anterior",
                    nextTooltip: "Próxima página",
                    lastTooltip: "Última página",
                },
            }}
        />
    );
}
