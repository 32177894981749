import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';

import '../../styles.css';

const carrocerias = [
    { value: '', label: '' },
    { value: 'aberta', label: 'Aberta' },
    { value: 'bau', label: 'Baú' },
    { value: 'cacamba', label: 'Caçamba' },
    { value: 'furgao', label: 'Furgão' },
    { value: 'sider', label: 'Sider' },
    { value: 'tanque', label: 'Tanque' },
];

export default function BodyInfo(props) {
    const handleChange = (event) => {
        props.setValues({
            ...props.values,
            [event.target.id]: event.target.value,
        });
    };

    return (
        <>
            <div className="title_info2">Informações da Carroceria</div>
            <div className="fantasia">
                <div className="basic">
                    <TextField
                        select
                        size="small"
                        id="tipo_carroceria"
                        label="Tipo de Carroceria"
                        variant="outlined"
                        onChange={handleChange}
                        SelectProps={{ native: true }}
                        value={props.values.tipo_carroceria}
                    >
                        {carrocerias.map((option) => (
                            <option key={option.value} >
                                {option.label}
                            </option>
                        ))}
                    </TextField>

                    <TextField
                        required
                        size="small"
                        type="number"
                        id="capacidade_kg"
                        variant="outlined"
                        label="Capacidade (kg)"
                        onChange={handleChange}
                        value={props.values.capacidade_kg}
                        error={props.values.capacidade_kg > 0 || props.values.capacidade_kg === '' ? false : true}
                        helperText={props.values.capacidade_kg > 0 || props.values.capacidade_kg === '' ? "" : "valor inválido"}
                    />

                    <TextField
                        size="small"
                        type="number"
                        id="capacidade_m3"
                        variant="outlined"
                        label="Capacidade (m3)"
                        onChange={handleChange}
                        value={props.values.capacidade_m3}
                        error={(
                            props.values.capacidade_m3 > 0 ||
                            props.values.capacidade_m3 === '' ||
                            props.values.capacidade_m3 === null
                        ) ? false : true}
                        helperText={(
                            props.values.capacidade_m3 > 0 ||
                            props.values.capacidade_m3 === '' ||
                            props.values.capacidade_m3 === null
                        ) ? "" : "valor inválido"
                        }
                    />
                </div>

                <div className="basic">
                    <TextField
                        type="number"
                        size="small"
                        id="altura_carroceria_m"
                        variant="outlined"
                        label="Altura Carroceria (m)"
                        onChange={handleChange}
                        value={props.values.altura_carroceria_m}
                        error={(
                            props.values.altura_carroceria_m > 0 ||
                            props.values.altura_carroceria_m === '' ||
                            props.values.altura_carroceria_m === null
                        ) ? false : true}
                        helperText={(
                            props.values.altura_carroceria_m > 0 ||
                            props.values.altura_carroceria_m === '' ||
                            props.values.altura_carroceria_m === null
                        ) ? "" : "valor inválido"
                        }
                    />

                    <TextField
                        type="number"
                        size="small"
                        id="largura_carroceria_m"
                        variant="outlined"
                        label="Largura Carroceria (m)"
                        onChange={handleChange}
                        value={props.values.largura_carroceria_m}
                        error={(
                            props.values.largura_carroceria_m > 0 ||
                            props.values.largura_carroceria_m === '' ||
                            props.values.largura_carroceria_m === null
                        ) ? false : true}
                        helperText={(
                            props.values.largura_carroceria_m > 0 ||
                            props.values.largura_carroceria_m === '' ||
                            props.values.largura_carroceria_m === null
                        ) ? "" : "valor inválido"
                        }
                    />

                    <TextField
                        type="number"
                        size="small"
                        id="comprimento_carroceria_m"
                        variant="outlined"
                        label="Comprimento Carroceria (m)"
                        onChange={handleChange}
                        value={props.values.comprimento_carroceria_m}
                        error={(
                            props.values.comprimento_carroceria_m > 0 ||
                            props.values.comprimento_carroceria_m === '' ||
                            props.values.comprimento_carroceria_m === null
                        ) ? false : true}
                        helperText={(
                            props.values.comprimento_carroceria_m > 0 ||
                            props.values.comprimento_carroceria_m === '' ||
                            props.values.comprimento_carroceria_m === null
                        ) ? "" : "valor inválido"
                        }
                    />
                </div>
            </div>
        </>
    )
}

BodyInfo.propTypes = {
    values: PropTypes.object,
    setValues: PropTypes.func
};
