import React, { useState } from "react";

import Form from "./Components/Form";
import Table from "./Components/Table";

export default function Plans() {
    const [open, setOpen] = useState(false)
    const [data, setData] = useState(null)
    const [reload, setReload] = useState(false);

    return (
        <>
            < Table
                reload={reload}
                setOpen={setOpen}
                setData={setData}
                setReload={setReload}
            />
            < Form
                data={data}
                open={open}
                setOpen={setOpen}
                setReload={setReload}
            />
        </>
    );
}
