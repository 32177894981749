import React, { useState } from 'react';
import Cancel from "@material-ui/icons/Cancel";
import { makeStyles } from '@material-ui/core/styles';
import { useStoreState, useStoreActions } from "easy-peasy";
import { SwipeableDrawer, Container, IconButton } from '@material-ui/core';

import VehicleTable from "./Components/VehicleTable.js"

const useStyles = makeStyles((theme) => ({
    paper: {
        top: 0,
    },
    menuButton: {
        marginRight: theme.spacing(1),
    },
}));

export default function Trip() {
    const classes = useStyles();

    const [trip_vehicles, setTripVehicles] = useState([])
    const open_trip = useStoreState((state) => state.toTripStore.open_trip);

    // fecha a janela de gerar viagem
    const setOpenTrip = useStoreActions(actions => {
        return actions.toTripStore.setOpenTrip;
    });

    function cancelarViagem() {
        setTripVehicles([])
        setOpenTrip(false)
    }

    return (
        <SwipeableDrawer
            anchor={"bottom"}
            onOpen={() => console.log("open")}
            onClose={() => console.log("close")}
            open={open_trip}
            classes={{ paper: classes.paper }}
        >
            < Container maxWidth={false} spacing={2}>

                <IconButton
                    edge="start"
                    className={classes.menuButton}
                    aria-label="menu"
                    onClick={() => cancelarViagem()} >
                    <Cancel />
                </IconButton>

                < VehicleTable
                    setTripVehicles={setTripVehicles}
                    trip_vehicles={trip_vehicles}
                />

            </Container>
        </SwipeableDrawer>
    );
}
