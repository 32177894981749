import React from 'react';
import { useStoreState } from 'easy-peasy';
import { Tab, AppBar, Container, LinearProgress } from '@material-ui/core';

import Users from './Components/Users/Users.js';
import MyAppBar from '../../utils/MyAppBar/MyAppBar.js';
import Vehicles from './Components/Vehicles/Vehicles.js';
import Afiliates from './Components/Afiliates/Afiliates.js';

import {
    AntTab,
    AntTabs,
    TabPanel,
    tabStyles,
} from '../../components/MyTabs.js'

export default function RegistrationsTabs() {
    const classes = tabStyles({ fontSize: 12 * window.innerWidth / 1280 });
    const [value, setValue] = React.useState(0);
    const loading = useStoreState((state) => state.globalStore.loading);
    return (
        <div className={classes.root}>
            < MyAppBar />
            < Container maxWidth={false} spacing={2}>
                <AppBar position='fixed' color='default' style={{ marginTop: 50 }}>
                    <AntTabs
                        value={value}
                        variant='fullWidth'
                        textColor='primary'
                        scrollButtons='auto'
                        aria-label="some text"
                        indicatorColor='primary'
                        onChange={(_, newValue) => { setValue(newValue) }}
                        classes={{ indicator: classes.customStyleOnActiveTab }}
                    >
                        <AntTab label={<span className={value === 0 ? classes.activeTab : classes.customStyleOnTab}>VEÍCULOS</span>} />
                        <AntTab label={<span className={value === 1 ? classes.activeTab : classes.customStyleOnTab}>FILIAIS</span>} />
                        <AntTab label={<span className={value === 2 ? classes.activeTab : classes.customStyleOnTab}>USUÁRIOS</span>} />
                        {/* <AntTab label={<span className={value === 3 ? classes.activeTab : classes.customStyleOnTab}>Motoristas (Em Breve)</span>} /> */}
                        {/* <AntTab label={<span className={value === 4 ? classes.activeTab : classes.customStyleOnTab}>Restrições do Cliente(Em Breve)</span>} /> */}
                    </AntTabs>
                    {loading && <LinearProgress />}
                </AppBar>
                <div >
                    <TabPanel value={value} index={0} style={{ marginTop: 50 }}>
                        <Vehicles />
                    </TabPanel>
                    <TabPanel value={value} index={1} style={{ marginTop: 50 }}>
                        <Afiliates />
                    </TabPanel>
                    <TabPanel value={value} index={2} style={{ marginTop: 50 }}>
                        <Users />
                    </TabPanel>
                    {/* <TabPanel value={value} index={3} style={{ marginTop: 50 }}>
                    </TabPanel> */}
                </div>
            </Container>
        </div >
    );
}
