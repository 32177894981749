import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import { Button, Box, Popover } from '@material-ui/core';
import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';

function Popup(props) {
    const { toll } = props
    return (
        <Table style={{ backgroundColor: '#fff' }} size='small'>
            <TableBody>
                <TableRow>
                    <TableCell>Nome da fatura</TableCell>
                    <TableCell>{toll.invoice_name}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Tag</TableCell>
                    <TableCell>{toll.tag}</TableCell>
                </TableRow>

                <TableRow>
                    <TableCell>Custo </TableCell>
                    <TableCell>R${toll.cost}</TableCell>
                </TableRow>

                <TableRow>
                    <TableCell>Tipo</TableCell>
                    <TableCell>{toll.passagem_pedagio ? "pedágio" : "vale pedágio"}</TableCell>
                </TableRow>

                <TableRow>
                    <TableCell>Placa</TableCell>
                    <TableCell>{toll.placa}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Endereço</TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap' }}>{toll.address}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Data da passagem</TableCell>
                    <TableCell >{new Date(toll.packageDate).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Categoria</TableCell>
                    <TableCell>{toll.categoria}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Concessão</TableCell>
                    <TableCell>{toll.concession}</TableCell>
                </TableRow>
                <TableRow >
                    <TableCell>Identificador Registro Optcargo</TableCell>
                    <TableCell>{toll._id}</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    )
}

export default function IconToll(props) {
    const { tolls } = props
    return (
        <>
            {
                tolls.map((toll) => (
                    <PopupState variant='popover' popupId='demo-popup-popover'>
                        {(popupState) => (
                            <>
                                <Button
                                    size='large'
                                    color='primary'
                                    {...bindTrigger(popupState)}
                                    style={{ color: 'blue', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px' }}
                                >
                                    <MarkunreadMailboxIcon fontSize='small' />
                                </Button>
                                <Popover
                                    {...bindPopover(popupState)}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                >
                                    <Box p={2}>
                                        <Popup toll={toll} />
                                    </Box>
                                </Popover>
                            </>
                        )
                        }
                    </PopupState >
                ))
            }
        </>
    )
}

IconToll.propTypes = {
    tolls: PropTypes.array,
};
