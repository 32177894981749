import React from 'react';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import SlowMotionVideoIcon from '@material-ui/icons/SlowMotionVideo';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

export default function Boxes(props) {
    const { results } = props

    return (
        <div className="invoice_dash_row" >
            <div className="invoice_dash_rectangle1">
                <div className="invoice_dash_tittle">
                    Pedágios
                </div>
                <hr className="invoice_dash_lineTitle1" />
                <div className="invoice_dash_row_dahatabs">
                    <div className="invoice_dash_iconDash1">
                        <AutorenewIcon style={{ fontSize: '4vw' }} />
                    </div>
                    <div className="invoice_dash_value"> {results.n_passagem_ped} passagens</div>
                </div>
                <div className="invoice_dash_row_dahatabs">
                    <div className="invoice_dash_iconDash1">
                        <MonetizationOnIcon style={{ fontSize: '4vw' }} />
                    </div>
                    <div className="invoice_dash_value"> R${results.valor_passagem_ped} </div>
                </div>
            </div>

            <div className="invoice_dash_rectangle2">
                <div className="invoice_dash_tittle">
                    Vale Pedágios
                </div>
                <hr className="invoice_dash_lineTitle2" />
                <div className="invoice_dash_row_dahatabs">
                    <div className="invoice_dash_iconDash2">
                        <AutorenewIcon style={{ fontSize: '4vw' }} />
                    </div>
                    <div className="invoice_dash_value"> {results.n_vale_ped} passagens </div>
                </div>
                <div className="invoice_dash_row_dahatabs">
                    <div className="invoice_dash_iconDash2">
                        <MonetizationOnIcon style={{ fontSize: '4vw' }} />
                    </div>
                    <div className="invoice_dash_value"> R${results.valor_vale_ped} </div>
                </div>
            </div>

            <div className="invoice_dash_rectangle3">
                <div className="invoice_dash_tittle">
                    Total de Cobranças
                </div>
                <hr className="invoice_dash_lineTitle3" />
                <div className="invoice_dash_row_dahatabs">
                    <div className="invoice_dash_iconDash3">
                        <AutorenewIcon style={{ fontSize: '4vw' }} />
                    </div>
                    <div className="invoice_dash_value"> {results.n_passagem_ped_vale_ped} Total </div>
                </div>
                <div className="invoice_dash_row_dahatabs">
                    <div className="invoice_dash_iconDash3">
                        <MonetizationOnIcon style={{ fontSize: '4vw' }} />
                    </div>
                    <div className="invoice_dash_value"> R${results.valor_passagem_ped_vale_ped} </div>
                </div>
            </div>
        </div>
    );
}
