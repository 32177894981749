import { Container } from '@material-ui/core';
import React, { useEffect, useRef } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';

import VeicsTable from './Components/VeicsTable.js';
import GeneralResults from './Components/GeneralResults.js';
import DownloadReport from './Components/DownloadReport.js';
import VeicsForm from './Components/VeicsForm/VeicsForm.js';
import MyAppBar from '../../../../utils/MyAppBar/MyAppBar.js';
import SelectDateRange from './Components/SelectDateRange.js';

export default function TripsDone() {
    const setLoading = useRef() //ativa e desativa o loading abaixo da appbar
    setLoading.current = useStoreActions(actions => { return actions.globalStore.setLoading });
    const setGeneral = useStoreActions(actions => { return actions.PlanedTripsStore.setGeneral });

    const setOpenMap = useStoreActions(actions => { return actions.mapStore.setOpenMap });
    useEffect(() => { setOpenMap() }, [])   // fecha o mapa se estiver aberto

    const end = useStoreState((state) => state.PlanedTripsStore.end);
    const veics = useStoreState((state) => state.PlanedTripsStore.veics);
    const start = useStoreState((state) => state.PlanedTripsStore.start);

    useEffect(() => {
        async function fetchData() {
            if (veics.length > 0) {
                var my_resul = {
                    restricao: "não",
                    custo_km: 0,
                    valor_diaria: 0,
                    custo_pedagio: 0,
                    tam_viagem_km: 0,
                    ocupacao_media: 0,
                    custo_total_reais: 0,
                    custo_diaria_mais_km: 0,
                };
                var ocupacao = 0;
                var capacidade_kg = 0;
                var custo_pedagio = 0
                var valor_diaria = 0
                var tam_viagem_km = 0
                var custo_diaria_mais_km = 0
                var custo_total_reais = 0

                for (let veic of veics) {
                    if (veic.possui_restr === 'sim') {
                        my_resul["restricao"] = "sim";
                    }
                    custo_pedagio += veic.custo_pedagio;
                    valor_diaria += veic.valor_diaria;
                    tam_viagem_km += veic.tam_viagem_km;
                    custo_diaria_mais_km += veic.custo_diaria_mais_km;
                    custo_total_reais += veic.custo_total_reais;
                    capacidade_kg += veic.capacidade_kg;
                    ocupacao += veic.maior_peso_carga_kg;
                }

                my_resul["custo_pedagio"] = Number(custo_pedagio.toFixed(2));
                my_resul["valor_diaria"] = Number(valor_diaria.toFixed(2));
                my_resul["tam_viagem_km"] = Number(tam_viagem_km.toFixed(2));
                my_resul["custo_diaria_mais_km"] = Number(custo_diaria_mais_km.toFixed(2));
                my_resul["custo_total_reais"] = Number(custo_total_reais.toFixed(2));
                my_resul["ocupacao_media"] = Number(((ocupacao / capacidade_kg) * 100).toFixed(0));
                my_resul["custo_km"] = Number(custo_diaria_mais_km - valor_diaria).toFixed(1);
                my_resul["data_inicio"] = new Date(start).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })
                my_resul["data_fim"] = new Date(end).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })
                setGeneral(my_resul)
            }
            setLoading.current(false)
        }
        fetchData()
    }, [veics])

    return (
        <>
            <MyAppBar />
            <Container maxWidth={false} spacing={2}>
                <SelectDateRange />
                < DownloadReport />
                <GeneralResults />
                <VeicsTable />
                < VeicsForm />
            </Container>
        </>
    )
}
