import React from 'react';
import { useStoreState } from 'easy-peasy';
import { AppBar, Container, LinearProgress } from '@material-ui/core';

import "./styles.css";
import Dash from './Components/Dash/Dash.js';
import MyAppBar from '../../utils/MyAppBar/MyAppBar.js';
import Invoices from './Components/Invoices/Invoices.js';
// import TollsAux from './Components/TollsAux/TollsAux.js';
// import Upload from './Components/TollsConference/FixedAppBar/Upload.js';
// import TollsConference from './Components/TollsConference/TollsConference.js';
// import ButtonMap from './Components/TollsConference/FixedAppBar/ButtonMap.js';
// import SelectDateRange from './Components/TollsConference/FixedAppBar/SelectDateRange.js';

import {
    AntTab,
    AntTabs,
    TabPanel,
    tabStyles,
} from '../../components/MyTabs.js'

export default function InvoiceTabs() {
    const classes = tabStyles({ fontSize: 12 * window.innerWidth / 1280 });
    const [value, setValue] = React.useState(0);
    const loading = useStoreState((state) => state.globalStore.loading);
    return (
        <div className={classes.root}>
            < MyAppBar />
            < Container maxWidth={false} spacing={2}>
                <AppBar position='fixed' color='default' style={{ marginTop: 50 }}>
                    <AntTabs
                        value={value}
                        variant='fullWidth'
                        textColor='primary'
                        scrollButtons='auto'
                        aria-label="some text"
                        indicatorColor='primary'
                        onChange={(_, newValue) => { setValue(newValue) }}
                        classes={{ indicator: classes.customStyleOnActiveTab }}
                    >
                        <AntTab label={<span className={value === 0 ? classes.activeTab : classes.customStyleOnTab}> RESUMO DAS FATURAS </span>} />
                        <AntTab label={<span className={value === 1 ? classes.activeTab : classes.customStyleOnTab}> FATURAS </span>} />
                        {/* <AntTab label={<span className={value === 4 ? classes.activeTab : classes.customStyleOnTab}>Relatório de Pedágios</span>} /> */}
                        {/* <AntTab label={<span className={value === 5 ? classes.activeTab : classes.customStyleOnTab}>Pedágios Aux</span>} /> */}
                    </AntTabs>
                    {loading && <LinearProgress />}
                </AppBar>
                <div >
                    <TabPanel value={value} index={0} style={{ marginTop: 50, fontSize: 13 }}>
                        <Dash />
                    </TabPanel>
                    <TabPanel value={value} index={1} style={{ marginTop: 50 }}>
                        < Invoices />
                    </TabPanel>
                    {/* <TabPanel value={value} index={4} style={{ marginTop: 50 }}>
                        <AppBar position='fixed' color="#fffff" style={{ marginTop: 100 }} elevation={0}>
                            <div className="rowAppTollsConference" >
                                <div className="rowAppTollsConferenceSelectDateRange">
                                    < SelectDateRange />
                                </div>
                                <div className="rowAppTollsConferencerowAppTool">
                                    < ButtonMap />
                                    < Upload />
                                </div >
                            </div>
                        </AppBar>
                        <div style={{ marginTop: 100 }}>
                            < TollsConference />
                        </div>
                    </TabPanel> */}
                    {/* <TabPanel value={value} index={5} style={{ marginTop: 50 }}>
                        < TollsAux />
                    </TabPanel> */}
                </div>
            </Container>
        </div >
    );
}
