import { useSnackbar } from 'notistack';
import { useStoreActions } from 'easy-peasy';
import { grey } from '@material-ui/core/colors';
import React, { useRef, useState, useEffect } from 'react';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { Button, Tooltip, TextField } from '@material-ui/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import api from '../../../../../services/api.js';

const materialTheme = createMuiTheme({
    palette: {
        primary: grey,
    },
});

export default function SelectDateRange() {
    const snack = useRef()
    const { enqueueSnackbar } = useSnackbar();
    snack.current = enqueueSnackbar

    const [pĺacas, setPlacas] = useState([])
    useEffect(() => {
        async function loadData() {
            setLoading.current(true)
            api.get(`/register/vehicles`)
                .then((res) => {
                    var plates = []
                    for (let vehicle of res.data) {
                        plates.push({
                            label: vehicle.placa,
                            value: vehicle.placa,
                        })
                    }
                    setPlacas(plates)
                    setPlaca(plates[0].label)
                    setLoading.current(false)
                })
                .catch((error) => {
                    setLoading.current(false)
                    console.log(error);
                });
        }
        loadData()
    }, [])

    //ativa e desativa o loading abaixo da appbar
    const setLoading = useRef()
    setLoading.current = useStoreActions(actions => {
        return actions.globalStore.setLoading;
    });

    const setTollsCalc = useStoreActions(actions => {
        return actions.TollsConferenceStore.setTollsCalc;
    });

    const [start, setStart] = useState(new Date());
    const [end, setEnd] = useState(new Date());
    const [placa, setPlaca] = useState("");

    async function loadDocs() {
        setLoading.current(true)
        let opt_tolls = await api.get(`/opt/tolls/${placa}/${start}/${end}`)
            .catch((error) => {
                console.log(error);
                snack.current("Falha ao carregar.", { variant: 'error' })
            });
        if (opt_tolls) {
            setTollsCalc(opt_tolls.data)
            setLoading.current(false)
        }
    }

    return (
        <>
            <TextField
                select
                id="placa"
                size="small"
                value={placa}
                label="Placa"
                variant="outlined"
                onChange={(event) => { setPlaca(event.target.value) }}
                style={{ margin: 10, marginTop: "1%" }}
                SelectProps={{ native: true }}
            >
                {pĺacas.map((option) => (
                    <option key={option.value} >
                        {option.label}
                    </option>
                ))}
            </TextField>
            <ThemeProvider theme={materialTheme}>
                <KeyboardDateTimePicker
                    style={{ margin: 10, marginTop: "1%" }}
                    size="small"
                    ampm={false}
                    label="Inicial"
                    inputVariant="outlined"
                    format="dd/MM/yyyy HH:mm"
                    value={start}
                    disableFuture={true}
                    onChange={date => {
                        setStart(date)
                    }}
                    onError={console.log}
                />

                <KeyboardDateTimePicker
                    style={{ margin: 10, marginTop: "1%" }}
                    size="small"
                    ampm={false}
                    label="Final"
                    inputVariant="outlined"
                    format="dd/MM/yyyy HH:mm"
                    value={end}
                    disableFuture={true}
                    onChange={date => {
                        setEnd(date)
                    }}
                    onError={console.log}
                />
            </ThemeProvider>

            <Tooltip style={{ whiteSpace: 'nowrap', overflow: 'hidden' }} title={<span style={{ fontSize: 15 }}>  Consulte as Viagens em determinado período. </span>} >
                <Button
                    color="secundary"
                    variant="contained"
                    style={{ marginTop: "1.2%" }}
                    onClick={() => { loadDocs() }}
                >
                    Consultar
                </Button>
            </Tooltip>
        </>
    );
}
