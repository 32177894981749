import { action } from "easy-peasy"

const mapStore = {
    // open and close map in Study
    open_map: false,
    setOpenMap: action((state, payload) => {
        if (payload === "normal") state.open_map = !state.open_map
        else if (payload === "open") state.open_map = true
        else state.open_map = false
    }),

    // set and reset mapcluster "agrupar"
    open_cluster: false,
    setCluster: action((state, _) => {
        state.open_cluster = !state.open_cluster
    }),

    affiliate: {},
    setPlotAffiliate: action((state, payload) => {
        if (payload.length === 0) {
            state.affiliate = {}
        } else {
            state.affiliate = payload[0]
        }
    }),

    // documentos plotados no mapa e nas funções de alocação
    plot_documents: [],
    plotDocuments: action((state, payload) => {
        let docs = []
        for (let doc of payload) {
            if (doc.valido) docs.push(doc)
        }
        // open map
        if (docs.length > 0 && state.open_map === false) {
            state.open_map = true
            state.center = [docs[0].lat_doc, docs[0].lon_doc]
        }
        // close map
        if (docs.length === 0 && state.open_map) {
            state.open_map = false
        }
        state.plot_documents = docs;
    }),

    // Plot selected vehicles in map
    plot_vehicles: [],
    plotVehicles: action((state, payload) => {
        // open map
        if (payload.length > 0 && state.open_map === false) {
            state.open_map = true
        }
        if (payload.length === 0 && state.open_map) {
            state.open_map = false
        }
        state.plot_vehicles = payload;
    }),

    path: [],
    plotPath: action((state, payload) => {
        state.path = payload;
    }),

};

export default mapStore;
