import React from 'react';
import PropTypes from 'prop-types';
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { Button, Box, Popover } from '@material-ui/core';
import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';

function Popup(props) {
    return (
        <Table style={{ backgroundColor: '#fff' }} size="small">
            <TableBody>
                <TableRow >
                    <TableCell>Documento</TableCell>
                    <TableCell>{props.doc.documento}</TableCell>
                </TableRow>

                <TableRow>
                    <TableCell>Tipo</TableCell>
                    <TableCell>{props.doc.tipo}</TableCell>
                </TableRow>

                <TableRow>
                    <TableCell>Placa</TableCell>
                    <TableCell>{props.doc.placa}</TableCell>
                </TableRow>

                <TableRow>
                    <TableCell>Peso Mercadoria</TableCell>
                    <TableCell>{props.doc.peso_kg}&nbsp;kg</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Valor Mercadoria</TableCell>
                    <TableCell>{props.doc.valor_reais}&nbsp;R$</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Endereço</TableCell>
                    <TableCell style={{ whiteSpace: "nowrap" }}>{props.doc.logradouro}, {props.doc.numero}, {props.doc.cidade}, {props.doc.estado}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>CEP</TableCell>
                    <TableCell>{props.doc.cep}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Razão Social</TableCell>
                    <TableCell>{props.doc.razao_social}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Lat, Lon</TableCell>
                    <TableCell>
                        {props.doc.lat_doc}, {props.doc.lon_doc}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ whiteSpace: "nowrap" }}>Criado por: </TableCell>
                    <TableCell>
                        {props.doc.criado_por}
                    </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell style={{ whiteSpace: "nowrap" }}>Criado em: </TableCell>
                    <TableCell>
                        {props.doc.criado_em}
                    </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell style={{ whiteSpace: "nowrap" }}>Editado por: </TableCell>
                    <TableCell>
                        {props.doc.editado_por}
                    </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell style={{ whiteSpace: "nowrap" }}>Editado em: </TableCell>
                    <TableCell>
                        {props.doc.editado_em}
                    </TableCell>
                </TableRow>

            </TableBody>
        </Table>
    )
}


export default function IconDoc(props) {
    return (
        <>
            {props.doc.tipo === 'ENTREGA' ? (
                <PopupState variant="popover" popupId="demo-popup-popover">
                    {(popupState) => (
                        <>
                            <Button
                                size="large"
                                color="primary"
                                {...bindTrigger(popupState)}
                                style={{ color: 'green', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px' }}
                            >
                                <MarkunreadMailboxIcon fontSize="small" />
                            </Button>
                            <Popover
                                {...bindPopover(popupState)}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <Box p={2}>
                                    <Popup doc={props.doc} />
                                </Box>
                            </Popover>
                        </>
                    )
                    }
                </PopupState >
            ) : (
                <PopupState variant="popover" popupId="demo-popup-popover">
                    {(popupState) => (
                        <>
                            <Button
                                size="large"
                                color="primary"
                                {...bindTrigger(popupState)}
                                style={{ color: 'darkviolet', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px' }}
                            >
                                <MarkunreadMailboxIcon fontSize="small" />
                            </Button>
                            <Popover
                                {...bindPopover(popupState)}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <Box p={2}>
                                    <Popup doc={props.doc} />
                                </Box>
                            </Popover>
                        </>
                    )}
                </PopupState >
            )}
        </>
    )
}

IconDoc.propTypes = {
    doc: PropTypes.array,
};
