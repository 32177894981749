import React from "react";
import PropTypes from 'prop-types';
import { Popup } from "react-leaflet";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

export default function PopupDocumento(props) {
    return (
        <Popup>
            <h2> {props.doc.tipo} </h2>
            <Table size="small">
                <TableBody>
                    <TableRow>
                        <TableCell>Placa</TableCell>
                        <TableCell>{props.doc.placa}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Documento</TableCell>
                        <TableCell>{props.doc.documento}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Peso Mercadoria</TableCell>
                        <TableCell>{props.doc.peso_kg}&nbsp;kg</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Valor Mercadoria</TableCell>
                        <TableCell>{props.doc.valor_reais}&nbsp;R$</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Endereço</TableCell>
                        <TableCell>{props.doc.logradouro}, {props.doc.numero}, {props.doc.cidade}, {props.doc.estado}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>CEP</TableCell>
                        <TableCell>{props.doc.cep}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Razão Social</TableCell>
                        <TableCell>{props.doc.razao_social}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Lat, Lon</TableCell>
                        <TableCell>
                            {props.doc.lat_doc}, {props.doc.lon_doc}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Popup>
    );
}

PopupDocumento.propTypes = {
    doc: PropTypes.object,
};
