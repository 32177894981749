import { action, thunk } from "easy-peasy"

import DocumentValidation from "./Components/DocumentValidation"
import VehicleValidation from "./Components/VehicleValidation"

const toTripStore = {

    // input information in trip module
    setTrip: thunk(async (actions, payload) => {
        actions.setTripType(payload.type)
        let valid_docs = DocumentValidation(payload.documents)
        actions.setTripDocuments(valid_docs)
        actions.setTripVehicles(payload)
        actions.setOpenTrip(payload.open_trip)
    }),

    // ----- store ------
    // tipo da gerar viagem
    trip_type: "",
    setTripType: action((state, payload) => {
        state.trip_type = payload;
    }),

    // lista de documentos validos disponiveis para gerar viagem
    avaliable_docs: [],
    setTripDocuments: action((state, payload) => {
        state.avaliable_docs = payload
    }),

    // lista de documentos validos disponiveis para gerar viagem
    veics_available: [],
    setTripVehicles: action((state, payload) => {
        state.veics_available = VehicleValidation({ my_vehicles: payload.my_vehicles, documents: payload.documents })
    }),

    // abre e fecha o modal gerar viagem
    open_trip: false,
    setOpenTrip: action((state, payload) => {
        state.open_trip = payload;
    }),

};

export default toTripStore;
