import XLSX from 'sheetjs-style'; // https://github.com/ShanaMaid/sheetjs-style
import React, { useRef } from 'react';
import { useSnackbar } from 'notistack';
import { Fab, Tooltip } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';

export default function DownloadSheet() {
    const snack = useRef()
    const { enqueueSnackbar } = useSnackbar();
    snack.current = enqueueSnackbar

    const header_style = {
        alignment: {
            vertical: "center",
            horizontal: "center",
        },
        font: {
            name: 'arial',
            sz: 14,
            bold: true,
        },
    }

    // const cell_style = {
    //     alignment: {
    //         vertical: "center",
    //         horizontal: "center",
    //         wrapText: true,
    //     },
    //     font: {
    //         name: 'arial',
    //         sz: 11,
    //         bold: false,
    //     },
    // }

    async function DownloadData() {
        let veics = [{
            'placa': "ABC1234",
            'capacidade_kg': 13500,
            'n_eixos': 3,
            'categoria': "Caminhão",
            'valor_diaria': 530,
            'valor_km': 1.06,
            'tipo_eixo': "Rodagem Dupla",
            'vinculo': "Terceiro",
            'status': "ativo",
            'altura': 2.61,
            'comprimento': 8.38,
            'largura': 2,
            'filial': "Filial Teste",
            'tipo_carroceria': "",
            'capacidade_m3': "",
            'tipo_veiculo': "",
            'codigo_frota': "",
            'marca': "",
            'ano_fabricacao': "",
            'ano_modelo': "",
            'combustivel': "",
            'altura_carroceria_m': "",
            'largura_carroceria_m': "",
            'comprimento_carroceria_m': "",
            'vencimento_crlv': "",
            'num_antt': "",
            'vencimento_antt': "",
            'tec_rastreio': "",
            'id_tec_rastreio': "",
        }]

        var workbook = XLSX.utils.book_new();
        let ws_veics = XLSX.utils.json_to_sheet(veics);
        ws_veics['!cols'] = []
        for (let i = 1; i <= 29; i++) {
            ws_veics['!cols'].push({ width: 15 })
        }

        XLSX.utils.book_append_sheet(workbook, ws_veics, "veiculos");

        var ws = XLSX.utils.aoa_to_sheet([
            ['placa *', "", "placa do veículo"],
            ['capacidade_kg *', "", "kg"],
            ['n_eixos *', "", ""],
            ['categoria *', "", "Pode ser:", "Moto", "Carro", "Ônibus", "Caminhão"],
            ['valor_diaria *', "", "reais"],
            ['valor_km *', "", "reais"],
            ['tipo_eixo *', "", "Pode ser", "Rodagem Simples", "Rodagem Dupla"],
            ['vinculo *', "", "Pode ser:", "Frota", "Agregado", "Terceiro"],
            ['status *', "", "Pode ser:", "ativo", "inativo"],
            ['altura *', "", "metros"],
            ['comprimento *', "", "metros"],
            ['largura *', "", "metros"],
            ['filial *', "", "Sem esse campo a importação não funciona, utilizar o nome da filial cadastrada no optcargo"],
            ['tipo_carroceria', "", "Pode ser:", "Aberta", "Sider", "Baú", "Tanque", "Caçamba"],
            ['capacidade_m3', "", "metros cubicos"],
            ['tipo_veiculo', "", "Pode ser:", "Passeio", "Utilitário", "3/4", "VUC", "Truck", "Cavalo", "Cavalo", "Trucado", "Moto", "Ônibus", "Toco"],
            ['codigo_frota', "", "Código interno do cliente se houver"],
            ['marca', "", ""],
            ['ano_fabricacao', "", "formatar como data"],
            ['ano_modelo', "", "formatar como data"],
            ['combustivel', "", "Pode ser:", "Diesel", "Gasolina", "Álcool", "Flex", "Eletricidade"],
            ['altura_carroceria_m', "", "metros"],
            ['largura_carroceria_m', "", "metros"],
            ['comprimento_carroceria_m', "", "metros"],
            ['vencimento_crlv', "", "formatar como data"],
            ['num_antt', "", ""],
            ['vencimento_antt', "", "formatar como data"],
            ['tec_rastreio', "", "Pode ser:", "Sascar", "Autotrac", "Omnlink", "OnixSat", "Sighra", "Positron", "Tracker", "Ituran", "Não Possui", "Outra"],
            ['id_tec_rastreio', "", ""],
        ])

        const n_rows = 29

        // add header styles // first column
        for (let i = 1; i <= n_rows; i++) {
            let aux = "A" + i
            ws[aux].s = header_style
        }
        // ws['C1'].s = cell_style
        // ws['C2'].s = cell_style
        // ws['C3'].s = cell_style

        // column size
        ws['!cols'] = [{ width: 25 }, { width: 4 }]
        for (let i = 1; i <= 12; i++) {
            ws['!cols'].push({ width: 15 })
        }
        // row size 
        ws['!rows'] = []
        for (let i = 1; i <= n_rows; i++) {
            ws['!rows'].push({ hpt: 20 })
        }

        XLSX.utils.book_append_sheet(workbook, ws, "Orientações");
        XLSX.writeFile(workbook, 'Importação de Veículos Optcargo.xlsx');
    }

    return (
        <Tooltip title={<span style={{ fontSize: 15 }}> Baixar Planilha Modelo </span>}>
            <Fab
                color="primary"
                aria-label="add"
                style={{
                    margin: 10,
                    maxWidth: 45,
                    minWidth: 45,
                    maxHeight: 45,
                    minHeight: 45,
                }}
                onClick={() => { DownloadData() }}
            >
                <GetAppIcon />
            </Fab>
        </Tooltip>
    );
}
