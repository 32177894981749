import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox';
import { Tab, Tabs, AppBar, Box, Container, Typography, Button } from '@material-ui/core';

import Plans from './Components/Plans/Plans.js'
import Tolls from './Components/Tolls/Tolls.js'
import Companies from './Components/Companies/Companies.js'
import Rationing from './Components/Rationing/Rationing.js'
import UserStatistics from './Components/UserStatistics/UserStatistics.js'
import UsersManagement from './Components/UsersManagement/UsersManagement.js'
import SizeRestrictions from './Components/SizeRestrictions/SizeRestrictions.js'

import MarkunreadMailboxRoundedIcon from '@material-ui/icons/MarkunreadMailboxRounded';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={4} padding={0} >
                    <Typography component={'span'} >{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function AdminArea() {
    const classes = useStyles();
    const history = useHistory();

    const auth = Cookies.get("is_authenticated");
    const auth_provisoria = localStorage.getItem("auth_provisoria");
    if (auth !== "true" && auth_provisoria !== "true") {
        history.push("/");
    }

    const acess_token = Cookies.get("x-access-token");
    if (!acess_token) history.push("/");

    const [value, setValue] = useState(2);

    return (
        <div className={classes.root}>
            < Container maxWidth={false} spacing={2}>
                <AppBar position="fixed" color="primary" className={classes.AppBar}>
                    <div >
                        <Tabs
                            value={value}
                            onChange={(_, newValue) => { newValue < 2 ? setValue(2) : setValue(newValue) }}
                            indicatorColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example"
                        >
                            <Button className={{ width: '5%' }} color="inherit" aria-label="menu" onClick={() => history.push("/dash")}>
                                <ArrowBackIcon />
                            </Button>

                            <Tab label="Área do Adm" icon={<MarkunreadMailboxRoundedIcon />} {...a11yProps(1)} />

                            <Tab label="Empresas" {...a11yProps(2)} />
                            <Tab label="Planos" {...a11yProps(3)} />
                            <Tab label="Usuários" {...a11yProps(4)} />
                            <Tab label="Estatísticas" {...a11yProps(5)} />
                            <Tab label="Pedágios" {...a11yProps(6)} />
                            <Tab label="Restrições de Porte" {...a11yProps(7)} />
                            <Tab label="Restrições de Rodízio" {...a11yProps(8)} />
                            {/* <Tooltip title={<span> Minha Empresa </span>} >
                                <Button
                                    color="default"
                                    size="large"
                                    onClick={() => { console.log() }}
                                >
                                    <MarkunreadMailboxIcon />
                                    &nbsp; Área do Adm
                                </Button>
                            </Tooltip> */}
                        </Tabs>

                    </div>
                </AppBar>
                <TabPanel value={value} index={2} style={{ marginTop: 50 }}>
                    < Companies />
                </TabPanel>
                <TabPanel value={value} index={3} style={{ marginTop: 50 }}>
                    < Plans />
                </TabPanel>
                <TabPanel value={value} index={4} style={{ marginTop: 50 }}>
                    < UsersManagement />
                </TabPanel>
                <TabPanel value={value} index={5} style={{ marginTop: 50 }}>
                    < UserStatistics />
                </TabPanel>
                <TabPanel value={value} index={6} style={{ marginTop: 50 }}>
                    < Tolls />
                </TabPanel>
                <TabPanel value={value} index={7} style={{ marginTop: 50 }}>
                    < SizeRestrictions />
                </TabPanel>
                <TabPanel value={value} index={8} style={{ marginTop: 50 }}>
                    < Rationing />
                </TabPanel>
            </Container>
        </div >
    );
}
