export default function dataValidation(documents) {
    var my_valid_documents = []
    for (var doc of documents) {
        if (doc.valido) {
            doc["tableData"].checked = false
            my_valid_documents.push(doc)
        }
    }
    return my_valid_documents
}
