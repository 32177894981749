import L from "leaflet";
import "react-leaflet-fullscreen-control";
import ReactDOMServer from "react-dom/server";
import React, { useState, useEffect } from "react";
import ReactLeafletSearch from "react-leaflet-search";
import { Map, TileLayer, Marker } from "react-leaflet";

import '../../../../styles.css';
import IconDoc from './IconDoc.js';
import Documento from './Documento.js';
import IconBox from "../../../../../../../../icons/Box.js";
import PopupLatLon from "../../../../../../../../popups/LatLon";

export default function MyMap(props) {
    const [my_markers, setMyMarkers] = useState([])
    const [boxlatlng, setLatLngBox] = useState(null);
    const [center, setCenter] = useState([-22.977213, -47.101165]);

    useEffect(() => {
        function fetchData() {
            if (props.values.lat_doc !== "" &&
                props.values.lat_doc &&
                props.values.lon_doc !== "" &&
                props.values.lon_doc) {
                if (props.values.lat_doc < 5.53 &&
                    props.values.lat_doc > -34.09 &&
                    props.values.lon_doc < -33.79 &&
                    props.values.lon_doc > -74.62) {
                    setCenter([props.values.lat_doc, props.values.lon_doc])

                    var my_markers = (
                        <div key={1}>
                            {(() => {
                                var my_icon = L.divIcon({
                                    className: "custom-icon",
                                    html: ReactDOMServer.renderToString(<IconDoc id={1} tipo={props.values.tipo} />),
                                });

                                return (
                                    < Marker icon={my_icon} key={1} position={[props.values.lat_doc, props.values.lon_doc]} >
                                        <Documento values={props.values} />
                                    </Marker>
                                );
                            })()
                            }
                        </div >
                    );
                    setMyMarkers(my_markers);
                }

            } else {
                setMyMarkers([]);
            }
        }
        fetchData();
    }, [props]);

    const MyMarker = props => {
        const initMarker = ref => {
            if (ref) {
                ref.leafletElement.openPopup()
            }
        }
        return <Marker ref={initMarker} {...props} />
    }

    var handleClickBox = (e) => {
        const { lat, lng } = e.latlng;
        var my_icon = L.divIcon({
            className: "custom-icon",
            html: ReactDOMServer.renderToString(<IconBox />),
        });
        var my_marker = (
            <div>
                <MyMarker key={0} icon={my_icon} position={[lat, lng]}>
                    <PopupLatLon lat={lat} lon={lng} />
                </MyMarker>
            </div>
        )
        setLatLngBox(my_marker)
    }

    return (
        <div style={{ width: "48%", marginTop: "1.3%", marginLeft: "1%" }}>
            <Map
                zoom={10}
                center={center}
                fullscreenControl
                className="company_map"
                onclick={(event) => handleClickBox(event)}
            >
                <TileLayer url="http://{s}.tile.osm.org/{z}/{x}/{y}.png" />
                <ReactLeafletSearch position="topright" providerOptions={{ region: "br" }} />
                {my_markers}
                {boxlatlng}
            </Map>
        </div>
    );
}
