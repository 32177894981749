import PropTypes from 'prop-types';
import { useSnackbar } from "notistack";
import { useStoreActions } from "easy-peasy";
import React, { useRef, Fragment } from "react";
import Language from "@material-ui/icons/Language";
import { Button, Tooltip, Fab } from "@material-ui/core";

import api from "../../../../../../../services/api";

export default function Geocoder(props) {
    const snack = useRef()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    snack.current = enqueueSnackbar
    const closeSnackbarRef = useRef()
    closeSnackbarRef.current = closeSnackbar

    //ativa e desativa o loading abaixo da appbar
    const setLoading = useRef()
    setLoading.current = useStoreActions(actions => {
        return actions.globalStore.setLoading;
    });

    const setReload = useRef()
    setReload.current = useStoreActions(actions => {
        return actions.VeicsDocsStore.setReload;
    });

    async function Geocoding(key) {
        closeSnackbarRef.current(key)
        setLoading.current(true);
        // filtrando os documentos
        var my_docs = []
        var id_docs = []
        for (let doc of props.documents) {
            if ((doc.lat_doc === undefined && doc.lon_doc === undefined) ||
                (doc.lat_doc === 0 && doc.lon_doc === 0) ||
                (doc.lat_doc > 5.53 || doc.lat_doc < -34.09) ||
                (doc.lon_doc > -33.79 || doc.lon_doc < -74.62) ||
                (isNaN(doc.lat_doc) && isNaN(doc.lon_doc))) {
                if (doc.cep.toString().length === 8) {
                    // if number is null
                    let my_number = doc.numero
                    if (doc.numero) {
                        my_number = '' + doc.numero
                    }
                    let doc_aux = {
                        "id": doc._id,
                        "zipcode": '' + doc.cep,
                        "number": my_number,
                        "city": doc.cidade,
                        "state": doc.estado,
                        "road": doc.logradouro,
                    }
                    my_docs.push(doc_aux)
                    id_docs.push(doc._id)
                }
            }
        }

        if (my_docs.length === 0) {
            setLoading.current(false)
            snack.current("Não há documentos para geolocalizar.", { variant: 'info' })
        } else {
            api.put(`/geocoder`, {
                docs: my_docs,
                id_docs
            })
                .then((res) => {
                    // busca os documentos, faz atualização após o geocoder executar
                    setReload.current()
                    setLoading.current(false)
                    snack.current(res.data.message, { variant: 'info' })
                })
                .catch((error) => {
                    console.log(error);
                    try {
                        snack.current(error.response.data.message, { variant: 'error' })
                    } catch (e) {
                        snack.current("Erro ao executar o geocoder", { variant: 'error' })
                    }
                    setLoading.current(false)
                    setReload.current()
                });
        }
    }

    const action = key => (
        <Fragment>
            <Button onClick={() => { Geocoding(key) }}>
                Sim
            </Button>
            <Button onClick={() => { closeSnackbarRef.current(key) }}>
                Não
            </Button>
        </Fragment>
    );

    function confirmationWindow() {
        snack.current("Deseja geolocalizar os documentos", {
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
            },
            variant: 'info',
            persist: true,
            preventDuplicate: true,
            action,
        })
    }

    return (
        <Tooltip
            title={
                <span style={{ fontSize: 15 }}>
                    Módulo Geocoder: Busca as latitudes e longitudes a partir do campo endereço. Se a latitude ou longitudes não
                    estiver preenchida ele busca encontrá-las.
                </span>
            }
        >
            <Fab
                color="primary"
                aria-label="add"
                style={{
                    margin: 10,
                    maxWidth: 45,
                    minWidth: 45,
                    maxHeight: 45,
                    minHeight: 45,
                }}
                onClick={() => {
                    confirmationWindow()
                }}
                disabled={props.documents.length === 0 ? true : false}
            >
                <Language />
            </Fab>
        </Tooltip>
    )
}

Geocoder.propTypes = {
    documents: PropTypes.array,
};
