import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Button } from '@material-ui/core';
import DepartureBoardIcon from '@material-ui/icons/DepartureBoard';

export default function IconRot(props) {
    return (
        <>
            {props.doc.ajuste ? (
                <Tooltip title={<span style={{ fontSize: 15 }} > Em ajuste </span>} >
                    <Button
                        style={{ color: 'rgba(151, 151, 151, 0.637)', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px' }}
                    >
                        <DepartureBoardIcon fontSize="small" />
                    </Button>
                </Tooltip>
            ) : null
            }
            {props.doc.roteirizando ? (
                <Tooltip title={<span style={{ fontSize: 15 }} > Em roteirização </span>} >
                    <Button
                        style={{ color: 'rgb(98, 201, 219)', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px' }}
                    >
                        <DepartureBoardIcon fontSize="small" />
                    </Button>
                </Tooltip>
            ) : null
            }
        </>
    )
}

IconRot.propTypes = {
    doc: PropTypes.array,
};
