import { useSnackbar } from 'notistack';
import { useStoreActions } from 'easy-peasy';
import React, { useRef, useState, useEffect } from 'react';
import { Button, Tooltip, TextField } from '@material-ui/core';

import api from '../../../../../services/api.js';

export default function Select() {
    const snack = useRef()
    const { enqueueSnackbar } = useSnackbar();
    snack.current = enqueueSnackbar

    const setDoc = useStoreActions(actions => {
        return actions.DocumentStore.setDoc;
    });

    const [documento, setDocumento] = useState('')
    async function loadDocs() {
        setLoading.current(true)
        api.get(`/dash/doc/history/${documento}`)
            .then((res) => {
                console.log(res.data)
                if (res.data.length === 0) {
                    snack.current("documento não encontrado", { variant: 'info' })
                }
                setDoc(res.data)
                setLoading.current(false)
            })
            .catch((error) => {
                console.log(error);
                setLoading.current(false)
            });
    }

    //ativa e desativa o loading abaixo da appbar
    const setLoading = useRef()
    setLoading.current = useStoreActions(actions => {
        return actions.globalStore.setLoading;
    });

    const handleChange = (event) => {
        setDocumento(event.target.value);
    };

    return (
        <>
            <TextField
                autoFocus
                type="text"
                margin="dense"
                id="documento"
                value={documento}
                variant="outlined"
                label="Documento"
                onChange={handleChange}
                style={{ margin: 10, marginTop: "1.2%" }}
            />

            <Tooltip style={{ whiteSpace: 'nowrap', overflow: 'hidden' }} title={<span style={{ fontSize: 15 }}>  Consulte o histórico do documento. </span>} >
                <Button
                    color="secundary"
                    variant="contained"
                    style={{ marginTop: "1.2%" }}
                    onClick={() => {
                        if (documento === '') {
                            snack.current("informe o código do documento", { variant: 'info' })
                        } else {
                            loadDocs()
                        }
                    }}
                >
                    Consultar
                </Button>
            </Tooltip>
        </>
    );
}
