import React from "react";
import { Paper } from "@material-ui/core";

import Form from "./Components/Form";
import MyMap from "./Components/MyMap"
import Table from "./Components/Table.js";
import FormUpdate from "./Components/FormUpdate"

export default function Tolls() {
    return (
        <>
            <Paper elevation={2}>
                <MyMap />
            </Paper>
            < Form />
            < FormUpdate />
            < Table />
        </>
    );
}
