import React from "react";
import PropTypes from 'prop-types';

import RoomIcon from '@material-ui/icons/Room';
import LensIcon from '@material-ui/icons/Lens';
import LabelIcon from '@material-ui/icons/Label';
import AlarmIcon from '@material-ui/icons/Alarm';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

import "../../../styles.css"

export default function TripInfo(props) {
    return (
        <div style={{ width: "48%" }}>
            <div className="title_info4">Informações Gerais da Viagem</div>
            <div className="fantasia">
                <div className="rowData">
                    <LabelIcon />
                    <div className="itemMap">Placa:</div>
                    <div className="dadoMap">{props.values.placa} </div>

                    <LocalShippingIcon />
                    <div className="itemMap">Nº Eixos:</div>
                    <div className="dadoMap">{props.values.n_eixos}</div>

                    <InsertDriveFileIcon />
                    <div className="itemMap">Nº Docs:</div>
                    <div className="dadoMap">{props.values.n_docs}</div>
                </div>

                <div className="rowData">
                    <NotInterestedIcon />
                    <div className="itemMap">Houve restrição:</div>
                    <div className="dadoMap">{props.values.possui_restr}</div>

                    <RoomIcon />
                    <div className="itemMap">Km Total:</div>
                    <div className="dadoMap">{props.values.tam_viagem_km}km</div>
                </div>
            </div>

            <div className="title_info4">Ocupações do Veículo</div>
            <div className="fantasia">
                <div className="rowData">
                    <LensIcon fontSize='small' />
                    <div className="itemMap">Inicial: </div>
                    <div className="dadoMap">{props.values.ocup_inicial_kg}kg - {props.values.ocup_inicial_porc}% - R${props.values.ocup_inicial_reais}</div>

                    <LensIcon fontSize='small' />
                    <div className="itemMap"> Final:</div>
                    <div className="dadoMap">{props.values.ocup_final_kg}kg - {props.values.ocup_final_porc}% - R${props.values.ocup_final_reais}</div>
                </div>

                <div className="rowData">
                    <LensIcon fontSize='small' />
                    <div className="itemMap">Máxima: </div>
                    <div className="dadoMap">{props.values.maior_peso_carga_kg}kg - {props.values.maior_taxa_ocup}% - R${props.values.maior_peso_carga_reais}</div>
                </div>

            </div>
            <div className="title_info4">Tempos do Percurso</div>
            <div className="fantasia">
                <div className="rowData">
                    <AlarmIcon />
                    <div className="itemMap">Previsão:</div>

                    <div className="itemMap"> - Saída:</div>
                    <div className="dadoMap">{props.values.horario_saida_cd_format}, </div>

                    <div className="itemMap"> - Chegada: </div>
                    <div className="dadoMap">{props.values.horario_retorno_cd_format}</div>
                </div>

                <div className="rowData">
                    <AccessTimeIcon />
                    <div className="itemMap">Tempo de viagem:</div>
                    <div className="dadoMap">{props.values.tempo_viagem_format}</div>
                </div>
            </div>

            <div className="title_info4">Custos </div>
            <div className="fantasia">
                <div className="rowData">
                    <LocalAtmIcon />
                    <div className="itemMap">Valor da Diária:</div>
                    <div className="dadoMap">R${props.values.valor_diaria}</div>
                    <div className="itemMap">Valor por km:</div>
                    <div className="dadoMap">R${props.values.valor_km}</div>
                </div>

                <div className="rowData">
                    <LocalAtmIcon />
                    <div className="itemMap">Custo Pedágio:</div>
                    <div className="dadoMap">R${props.values.custo_pedagio}</div>

                    <div className="itemMap">Custo com km:</div>
                    <div className="dadoMap">R${(props.values.custo_diaria_mais_km - props.values.valor_diaria).toFixed(2)}</div>
                </div>

                <div className="rowData">
                    <MonetizationOnIcon />
                    <div className="itemMap">Custo Total:</div>
                    <div className="dadoMap">R${props.values.custo_total_reais}</div>
                </div>
            </div>
        </div>
    )
}

TripInfo.propTypes = {
    values: PropTypes.object,
};
