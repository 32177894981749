import L from 'leaflet';
import React from 'react';
import PropTypes from 'prop-types';
import { useStoreState } from "easy-peasy"
import { FeatureGroup } from 'react-leaflet';
import { EditControl } from "react-leaflet-draw"

import "../../../../../styles.css"

export default function AllocationEditControl(props) {
    const avaliable_docs = useStoreState((state) => state.toAllocateStore.avaliable_docs);

    L.drawLocal = {
        // format: {
        // 	numeric: {
        // 		delimiters: {
        // 			thousands: ',',
        // 			decimal: '.'
        // 		}
        // 	}
        // },
        draw: {
            toolbar: {
                // #TODO: this should be reorganized where actions are nested in actions
                // ex: actions.undo  or actions.cancel
                actions: {
                    title: 'Cancelar desenho',
                    text: 'Cancelar'
                },
                finish: {
                    title: 'Finalizar o desenho',
                    text: 'Finalizar'
                },
                undo: {
                    title: 'Excluir o último ponto desenhado',
                    text: 'Apagar o último ponto'
                },
                buttons: {
                    polyline: 'Desenhe uma polilinha',
                    polygon: 'Desenhe o polígono',
                    rectangle: 'Desenhe um retângulo',
                    circle: 'Desenhar um círculo',
                    marker: 'Desenhe um marcador',
                    circlemarker: 'Desenhe um marcador de círculo'
                }
            },
            handlers: {
                circle: {
                    tooltip: {
                        start: 'Clique e arraste para desenhar o círculo'
                    },
                    radius: 'raio'
                },
                circlemarker: {
                    tooltip: {
                        start: 'Clique no mapa para colocar o marcador do círculo'
                    }
                },
                marker: {
                    tooltip: {
                        start: 'Clique no mapa para colocar o marcador.'
                    }
                },
                polygon: {
                    tooltip: {
                        start: 'Clique para começar a desenhar',
                        cont: 'Clique para continuar a desenhar',
                        end: 'Clique no primeiro ponto para fechar esta forma'
                    }
                },
                polyline: {
                    error: '<strong>Error:</strong> bordas da forma não podem cruzar!',
                    tooltip: {
                        start: 'Clique para começar a desenhar a linha',
                        cont: 'Clique para continuar desenhando a linha',
                        end: 'Clique no último ponto para terminar a linha'
                    }
                },
                rectangle: {
                    tooltip: {
                        start: 'Clique e arraste para desenhar um retângulo'
                    }
                },
                simpleshape: {
                    tooltip: {
                        end: 'Solte o mouse para terminar o desenho'
                    }
                }
            }
        },
        edit: {
            toolbar: {
                actions: {
                    save: {
                        title: 'Salvar alterações',
                        text: 'Salvar'
                    },
                    cancel: {
                        title: 'Cancelar a edição, descartar todas as alterações',
                        text: 'Cancelar'
                    },
                    clearAll: {
                        title: 'Limpar tudo',
                        text: 'Limpar tudo'
                    }
                },
                buttons: {
                    edit: 'Editar layers',
                    editDisabled: 'Sem layers para editar',
                    remove: 'Excluir layers',
                    removeDisabled: 'Sem layers para excluir'
                }
            },
            handlers: {
                edit: {
                    tooltip: {
                        text: '',
                        subtext: 'Arraste alças ou marcadores para editar recursos'
                    }
                },
                remove: {
                    tooltip: {
                        text: 'Clique em um recurso para remover'
                    }
                }
            }
        }
    };

    const _onEdited = (e) => {
        let numEdited = 0;
        e.layers.eachLayer((layer) => {
            numEdited += 1;
        });
        console.log(`_onEdited: edited ${numEdited} layers`, e);

        // this._onChange();
    };

    function isMarkerInsidePolygon(marker, polyPoints) {
        var x = marker.lat, y = marker.lng;

        var inside = false;
        for (var i = 0, j = polyPoints.length - 1; i < polyPoints.length; j = i++) {
            var xi = polyPoints[i].lat, yi = polyPoints[i].lng;
            var xj = polyPoints[j].lat, yj = polyPoints[j].lng;

            var intersect = ((yi > y) !== (yj > y))
                && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
            if (intersect) inside = !inside;
        }
        return inside;
    };

    const _onCreated = (e) => {
        var poly = e.layer.editing.latlngs[0][0]
        for (let doc of avaliable_docs) {
            var point = {
                lat: doc.lat_doc,
                lng: doc.lon_doc
            };
            if (isMarkerInsidePolygon(point, poly)) {
                doc.tableData.checked = true
            }
        }
        var alocate_docs = []
        for (let doc of avaliable_docs) {
            if (doc.tableData.checked === true) alocate_docs.push(doc)
        }
        props.setAlocattionDocuments(alocate_docs)
    };

    const _onDeleted = (e) => {
        let numDeleted = 0;
        e.layers.eachLayer((layer) => {
            numDeleted += 1;
        });
        console.log(`onDeleted: removed ${numDeleted} layers`, e);

        // this._onChange();
    };

    const _onMounted = (drawControl) => {
        console.log('_onMounted', drawControl);
    };

    const _onEditStart = (e) => {
        console.log('_onEditStart', e);
    };

    const _onEditStop = (e) => {
        console.log('_onEditStop', e);
    };

    const _onDeleteStart = (e) => {
        console.log('_onDeleteStart', e);
    };

    const _onDeleteStop = (e) => {
        console.log('_onDeleteStop', e);
    };

    return (
        <FeatureGroup>
            <EditControl
                position="topleft"
                onEdited={_onEdited}
                onCreated={_onCreated}
                onDeleted={_onDeleted}
                onMounted={_onMounted}
                onEditStart={_onEditStart}
                onEditStop={_onEditStop}
                onDeleteStart={_onDeleteStart}
                onDeleteStop={_onDeleteStop}
                draw={{
                    rectangle: false,
                    polyline: false,
                    circle: false,
                    circlemarker: false,
                    marker: false,
                    polygon: {
                        allowIntersection: false, // Restricts shapes to simple polygons
                        drawError: {
                            color: '#e1e100', // Color the shape will turn when intersects
                            message: '<strong>Oh não!<strong> você não pode fazer isto!' // Message that will show when intersect
                        },
                        shapeOptions: {
                            color: '#bada55'
                        },
                    },
                }}
                edit={{
                    edit: false,
                }}
            />
        </FeatureGroup>
    );
}

AllocationEditControl.propTypes = {
    setAlocattionDocuments: PropTypes.func,
};
