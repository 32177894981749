import { useSnackbar } from 'notistack';
import MaterialTable from 'material-table';
import React, { useRef, useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';

import api from '../../../../../services/api.js';
import Open from '../../../../../buttons/Open.js';
import Hidden from '../../../../../buttons/Hidden.js';

export default function VeicsTable() {
    const snack = useRef()
    const { enqueueSnackbar } = useSnackbar();
    snack.current = enqueueSnackbar

    const setOpenDrawer = useRef()
    setOpenDrawer.current = useStoreActions(actions => { return actions.PlanedTripsStore.setOpenDrawer });
    const setReload = useRef()
    setReload.current = useStoreActions(actions => { return actions.PlanedTripsStore.setReload });

    const setCollumns = useStoreActions(actions => { return actions.PlanedTripsStore.setCollumns });
    const setVeicInfo = useStoreActions(actions => { return actions.PlanedTripsStore.setVeicInfo });
    const plotVehiclesResults = useStoreActions(actions => { return actions.ResultStore.plotVehiclesResults });

    const docs = useStoreState((state) => state.PlanedTripsStore.docs);
    const veics = useStoreState((state) => state.PlanedTripsStore.veics);
    const reload = useStoreState((state) => state.PlanedTripsStore.reload);
    const collumns = useStoreState((state) => state.PlanedTripsStore.collumns);

    function verificaRow(veics) {
        if (veics.length > 0) plotVehiclesResults({ veics, docs: docs })
        else plotVehiclesResults({ veics: [], docs: [] });
    }

    useEffect(() => {
        async function fecthData() {
            api.get(`/utils/user/config/config_planned_trip`)
                .then((resp) => {
                    let { data } = resp
                    if (data) setCollumns(data)
                })
                .catch((error) => {
                    console.log(error);
                    snack.current("Falha ao buscar as configurações", { variant: 'error' })
                });
        }
        fecthData();
    }, []);

    useEffect(() => { }, [reload]);

    const tableRef = React.useRef();
    return (
        <MaterialTable
            tableRef={tableRef}
            title="Viagens"
            columns={
                [
                    {
                        title: "Opções",
                        field: "opcoes",
                        render: (rowData) => {
                            return (
                                <Open
                                    open={true}
                                    data={rowData}
                                    setData={setVeicInfo}
                                    setOpen={setOpenDrawer.current}
                                />
                            )
                        }
                    },
                    { title: "Placa", field: "placa", hidden: !collumns.placa.value },
                    { title: "Identificador da Viagem", field: "_id", hidden: !collumns._id.value },
                    { title: "Ocup. Inicial(kg)", field: "ocup_inicial_kg", hidden: !collumns.ocup_inicial_kg.value },
                    { title: "Ocup. Inicial(%)", field: "ocup_inicial_porc", hidden: !collumns.ocup_inicial_porc.value },
                    { title: "Ocup. Inicial(reais)", field: "ocup_inicial_reais", hidden: !collumns.ocup_inicial_reais.value },
                    { title: "Ocup. Final(kg)", field: "ocup_final_kg", hidden: !collumns.ocup_final_kg.value },
                    { title: "Ocup. Final(%)", field: "ocup_final_porc", hidden: !collumns.ocup_final_porc.value },
                    { title: "Ocup. Final(reais)", field: "ocup_final_reais", hidden: !collumns.ocup_final_reais.value },
                    { title: "Ocup. Máx(kg)", field: "maior_peso_carga_kg", hidden: !collumns.maior_peso_carga_kg.value },
                    { title: "Ocup. Máx(%)", field: "maior_taxa_ocup", hidden: !collumns.maior_taxa_ocup.value },
                    { title: "Ocup. Máx(reais)", field: "maior_peso_carga_reais", hidden: !collumns.maior_peso_carga_reais.value },
                    { title: "Nº Docs", field: "n_docs", hidden: !collumns.n_docs.value },
                    { title: "Houve restrição", field: "possui_restr", hidden: !collumns.possui_restr.value },
                    { title: "Previsão saída", field: "horario_saida_cd_format", hidden: !collumns.horario_saida_cd_format.value },
                    { title: "Previsão chegada", field: "horario_retorno_cd_format", hidden: !collumns.horario_retorno_cd_format.value },
                    { title: "Tempo de viagem", field: "tempo_viagem_format", hidden: !collumns.tempo_viagem_format.value },
                    { title: "Nº Eixos", field: "n_eixos", hidden: !collumns.n_eixos.value },
                    { title: "Custo Pedágio", field: "custo_pedagio", hidden: !collumns.custo_pedagio.value },
                    { title: "Valor da Diária", field: "valor_diaria", hidden: !collumns.valor_diaria.value },
                    { title: "Km Total", field: "tam_viagem_km", hidden: !collumns.tam_viagem_km.value },
                    { title: "Valor Km", field: "valor_km", hidden: !collumns.valor_km.value },
                    { title: "Custo da diária + km", field: "custo_diaria_mais_km", hidden: !collumns.custo_diaria_mais_km.value },
                    { title: "Custo Total", field: "custo_total_reais", hidden: !collumns.custo_total_reais.value },
                    { title: "criado_por", field: "criado_por", hidden: !collumns.criado_por.value },
                    { title: "Criado em", field: "criado_em", hidden: !collumns.criado_em.value },
                    { title: "Editado por", field: "editado_por", hidden: !collumns.editado_por.value },
                    { title: "Editado em", field: "editado_em", hidden: !collumns.editado_em.value },
                ]
            }
            data={veics}
            options={{
                search: true,
                padding: "dense",
                emptyRowsWhenPaging: false,
                pageSizeOptions: [1, 5, 10, 20],
                headerStyle: {
                    backgroundColor: "#01579b",
                    textAlign: 'center',
                    color: "#FFF",
                    fontSize: 11 * window.innerWidth / 1280,
                },
                cellStyle: {
                    textAlign: "center",
                    fontSize: 11 * window.innerWidth / 1280,
                },
                rowStyle: rowData => ({
                    backgroundColor: (rowData.tableData.id % 2 === 0 ? '#fff' : '#f5f5f5')
                }),
            }}
            onSelectionChange={(rows) => verificaRow(rows)}
            localization={{
                body: {
                    addTooltip: "Adicionar novo",
                    deleteTooltip: "Excluir",
                    editTooltip: "Editar",
                    editRow: {
                        deleteText: "Deseja excluir a viagem?",
                        cancelTooltip: "Cancelar",
                        saveTooltip: "Salvar",
                    },
                    emptyDataSourceMessage: "",
                    bulkEditTooltip: "Editar todos",
                    bulkEditCancel: "Descartar edições",
                    bulkEditApprove: "Salvar todas as edições"
                },

                grouping: {
                    placeholder: "Arraste os cabeçalhos aqui para agrupar por",
                    groupedBy: "Agrupado por:",
                },

                header: {
                    actions: "Opções",
                },

                toolbar: {
                    searchTooltip: "Pesquisar",
                    searchPlaceholder: "Pesquisar",
                    exportTitle: "Exportar",
                    nRowsSelected: "{0} veículos(s) selecionados",
                },
                pagination: {
                    labelRowsSelect: "",
                    labelDisplayedRows: "{count} de {from}-{to}",
                    firstTooltip: "Primeira página",
                    previousTooltip: "Página anterior",
                    nextTooltip: "Próxima página",
                    lastTooltip: "Última página",
                },
            }}
            components={{
                Actions: () => {
                    return (
                        < Hidden
                            type={"config_planned_trip"}
                            collumns={collumns}
                            setReload={setReload.current}
                        />
                    );
                }
            }}
        />
    );
}
