import React from "react";
import PropTypes from 'prop-types';
import { grey } from "@material-ui/core/colors";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

const materialTheme = createMuiTheme({
    palette: {
        primary: grey,
    },
});

export default function SelectDate(props) {
    return (
        <>
            <h4>Selecione o intervalo: </h4>
            <ThemeProvider theme={materialTheme}>
                <KeyboardDateTimePicker
                    style={{ margin: 10, marginTop: "1%" }}
                    size="small"
                    ampm={false}
                    label="Inicial"
                    inputVariant="outlined"
                    format="dd/MM/yyyy HH:mm"
                    value={props.start}
                    disableFuture={true}
                    onChange={date => {
                        props.setStart(date)
                    }}
                    onError={console.log}
                />

                <KeyboardDateTimePicker
                    style={{ margin: 10, marginTop: "1%" }}
                    size="small"
                    ampm={false}
                    label="Final"
                    inputVariant="outlined"
                    format="dd/MM/yyyy HH:mm"
                    value={props.end}
                    disableFuture={true}
                    onChange={date => {
                        props.setEnd(date)
                    }}
                    onError={console.log}
                />
            </ThemeProvider>
        </>
    );
}

SelectDate.propTypes = {
    end: PropTypes.string,
    start: PropTypes.string,
    setEnd: PropTypes.func,
    setStart: PropTypes.func,
};
