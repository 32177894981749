import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';

import '../../styles.css';

const currencies = [
    { value: '', label: '' },
    { value: 'ativo', label: 'ativo' },
    { value: 'inativo', label: 'inativo' },
];

const vinculos = [
    { value: '', label: '' },
    { value: 'Frota', label: 'Frota' },
    { value: 'Agregado', label: 'Agregado' },
    { value: 'Terceiro', label: 'Terceiro' },
];

export default function BasicInfo(props) {
    const handleChange = (event) => {
        props.setValues({
            ...props.values,
            [event.target.id]: event.target.value,
        });
    };

    return (
        <>
            <div className="title_info2">Informações Gerais</div>
            <div className="fantasia">
                <div className="basic">
                    <TextField
                        required
                        id="placa"
                        size="small"
                        label="Placa"
                        variant="outlined"
                        onChange={handleChange}
                        value={props.values.placa}
                        error={(
                            props.values.placa === "" &&
                            props.update === true)
                            ? true : false}
                        helperText={(
                            props.values.placa === "" &&
                            props.update === true)
                            ? "insira a placa" : ""}
                    />

                    <TextField
                        select
                        required
                        id="filial"
                        size="small"
                        label="Filial"
                        variant="outlined"
                        onChange={handleChange}
                        value={props.values.filial}
                        SelectProps={{ native: true }}
                        error={(
                            props.values.filial === "" &&
                            props.update === true)
                            ? true : false}
                        helperText={(
                            props.values.filial === "" &&
                            props.update === true)
                            ? "selecione uma filial" : ""}
                    >
                        {props.affiliates.map((option) => (
                            <option key={option.value} >
                                {option.label}
                            </option>
                        ))}
                    </TextField>

                    <TextField
                        select
                        required
                        size="small"
                        id="vinculo"
                        label="Vínculo"
                        variant="outlined"
                        onChange={handleChange}
                        value={props.values.vinculo}
                        SelectProps={{ native: true }}
                        error={(
                            props.values.vinculo === "" &&
                            props.update === true)
                            ? true : false}
                        helperText={(
                            props.values.vinculo === "" &&
                            props.update === true)
                            ? "selecione um vínculo" : ""}
                    >
                        {vinculos.map((option) => (
                            <option key={option.value} value={props.values.vinculo.value}>
                                {option.label}
                            </option>
                        ))}
                    </TextField>
                </div>
                <div className="basic3">
                    <TextField
                        select
                        required
                        id="status"
                        size="small"
                        label="Status"
                        variant="outlined"
                        onChange={handleChange}
                        value={props.values.status}
                        SelectProps={{ native: true }}
                        error={(
                            props.values.status === "" &&
                            props.update === true)
                            ? true : false}
                        helperText={(
                            props.values.status === "" &&
                            props.update === true)
                            ? "selecione o status" : ""}
                    >
                        {currencies.map((option) => (
                            <option key={option.value} >
                                {option.label}
                            </option>
                        ))}
                    </TextField>

                    <TextField
                        size="small"
                        id="codigo_frota"
                        variant="outlined"
                        label="Código da Frota"
                        onChange={handleChange}
                        value={props.values.codigo_frota}
                    />

                    <TextField
                        required
                        size="small"
                        type="number"
                        id="valor_diaria"
                        variant="outlined"
                        onChange={handleChange}
                        label="Valor da Diária (R$)"
                        value={props.values.valor_diaria}
                        error={props.values.valor_diaria < 0 ? true : false}
                        helperText={props.values.valor_diaria < 0 ? "valor inválido" : ""}
                    />

                    <TextField
                        required
                        size="small"
                        type="number"
                        id="valor_km"
                        variant="outlined"
                        onChange={handleChange}
                        label="Valor do km (R$)"
                        value={props.values.valor_km}
                        error={props.values.valor_km < 0 ? true : false}
                        helperText={props.values.valor_km < 0 ? "valor inválido" : ""}
                    />
                </div>
            </div>
        </>
    )
}

BasicInfo.propTypes = {
    update: PropTypes.bool,
    values: PropTypes.object,
    affiliates: PropTypes.array,
    setValues: PropTypes.func
};
