import PropTypes from 'prop-types';
import { useSnackbar } from "notistack";
import { useStoreActions } from "easy-peasy";
import React, { useRef, Fragment } from "react";
import { Tooltip, Button } from "@material-ui/core";
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

import api from "../../../../../services/api.js";

export default function ClearTrip(props) {
    const snack = useRef()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    snack.current = enqueueSnackbar
    const closeSnackbarRef = useRef()
    closeSnackbarRef.current = closeSnackbar

    // atualiza a tabela de documentos
    const setReload = useStoreActions(actions => {
        return actions.VeicsDocsStore.setReload;
    });

    // limpa os veiculos plotados no mapa
    const plotVehicles = useStoreActions(actions => {
        return actions.mapStore.plotVehicles;
    });

    // limpa os documentos plotados no mapa
    const plotDocuments = useStoreActions(actions => {
        return actions.mapStore.plotDocuments;
    });

    //ativa e desativa o loading abaixo da appbar
    const setLoading = useRef()
    setLoading.current = useStoreActions(actions => {
        return actions.globalStore.setLoading;
    });

    async function ClearTrip_(key) {
        closeSnackbarRef.current(key)
        setLoading.current(true)
        api.put(`/clear/plates/${props.veic._id}`)
            .then(() => {
                plotVehicles([])
                plotDocuments([])
                setReload()
                setLoading.current(false)
            })
            .catch((error) => {
                console.log(error)
                snack.current(`Falha ao remover!`, { variant: 'error' })
                plotVehicles([])
                plotDocuments([])
                setLoading.current(false)
            })
    }

    const action = key => (
        <Fragment>
            <Button onClick={() => { ClearTrip_(key) }}>
                Sim
            </Button>
            <Button onClick={() => { closeSnackbarRef.current(key) }}>
                Não
            </Button>
        </Fragment>
    );

    function confirmationWindow(placa) {
        snack.current(`Deseja remover as ordens e documentos associados a viagem ${placa}?`, {
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
            },
            variant: 'info',
            persist: true,
            preventDuplicate: true,
            action,
        })
    }

    return (
        <Tooltip title={<span style={{ fontSize: 15 }}> Limpar ordens e documentos associados a viagem </span>} >
            <Button
                onClick={() => {
                    confirmationWindow(props.veic.placa)
                }}
                color="primary"
                style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px' }}
            >
                <RemoveCircleIcon fontSize="small" />
            </Button>
        </Tooltip>
    );
}

ClearTrip.propTypes = {
    veic: PropTypes.object,
};
