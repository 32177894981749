import PropTypes from 'prop-types';
import 'react-leaflet-fullscreen-control';
import { Map, TileLayer } from 'react-leaflet';
import React, { useState, useEffect } from 'react';
import ReactLeafletSearch from 'react-leaflet-search';

import '../../styles.css';
import Results from './Components/Results';
import Vehicles from './Components//Vehicles';
import Documents from './Components//Documents';
import MapButtons from '../../../../buttons/MapButtons.js';
import DistributionCenter from './Components/DistributionCenter';

export default function StudyMap(props) {
    const [center, setCenter] = useState([-22.977213, -47.101165]);

    useEffect(() => {
        const map = mapRef.current.leafletElement;
        map.setView(center);
    }, [center]);

    const mapRef = React.useRef();
    return (
        <Map fullscreenControl style={{ width: props.width, height: props.height }} ref={mapRef} center={center} zoom={8} >
            <TileLayer url="http://{s}.tile.osm.org/{z}/{x}/{y}.png" />
            <ReactLeafletSearch position="topright" providerOptions={{ region: "br" }} />
            <MapButtons />
            <Documents setCenter={setCenter} />
            <DistributionCenter setCenter={setCenter} />
            <Vehicles setCenter={setCenter} />
            <Results setCenter={setCenter} />
        </Map>
    );
}

StudyMap.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
};
