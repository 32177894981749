import React from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'react-leaflet';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';

export default function PopupTolls(props) {
    return (
        <Popup>
            <h2> Pedágio {props.data.name}</h2>
            <Table size="small">
                <TableBody>
                    <TableRow>
                        <TableCell>Fonte</TableCell>
                        <TableCell>{props.fonte}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Concessionária</TableCell>
                        <TableCell>{props.data.concession}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Endereço</TableCell>
                        <TableCell>{props.data.address}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Custo</TableCell>
                        <TableCell>{props.data.cost}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Popup>
    );
}

PopupTolls.propTypes = {
    data: PropTypes.object,
};
