import L from "leaflet";
import PropTypes from 'prop-types';
import { Marker } from "react-leaflet";
import { useStoreState } from "easy-peasy";
import ReactDOMServer from "react-dom/server";
import React, { useState, useEffect, useRef } from "react";

import "../../../styles.css";
import IconCD from "../../../../../icons/CentroDistribuicao";
import PopupCentroDistribuicao from "../../../../../popups/CentroDistribuicao";

export default function DistributionCenter(props) {
    const setCenterRef = useRef()
    setCenterRef.current = props.setCenter

    const [distributionCentersMarkers, setDistributionCentersMarkers] = useState([]);
    const affiliate = useStoreState((state) => state.mapStore.affiliate);

    useEffect(() => {
        function fetchData() {
            if (Object.keys(affiliate).length !== 0) {
                setCenterRef.current([affiliate.lat, affiliate.lng])
                var my_icon = L.divIcon({
                    className: "custom-icon",
                    html: ReactDOMServer.renderToString(<IconCD id={'0'} />),
                });

                var my_markers = () => (
                    <div key={0}>
                        {
                            <Marker icon={my_icon} key={0} position={[affiliate.lat, affiliate.lng]}>
                                <PopupCentroDistribuicao cd={affiliate.nome_filial} lat={affiliate.lat} lng={affiliate.lng} />
                            </Marker>
                        }
                    </div>
                );
                setDistributionCentersMarkers(my_markers);
            } else {
                setDistributionCentersMarkers([]);
            }
        }
        fetchData();
    }, [affiliate]);

    return <> { distributionCentersMarkers} </>
}

DistributionCenter.propTypes = {
    setCenter: PropTypes.func,
};
