import L from "leaflet";
import PropTypes from 'prop-types';
import "react-leaflet-fullscreen-control";
import Control from "react-leaflet-control";
import ReactDOMServer from "react-dom/server";
import React, { useState, useEffect } from "react";
import { Tooltip, Button } from "@material-ui/core";
import GroupWork from "@material-ui/icons/GroupWork";
import ReactLeafletSearch from "react-leaflet-search";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { Map, TileLayer, Marker, Polyline } from "react-leaflet";

import '../../../../styles.css';
import IconCD from "./IconCD.js";
import PopupToll from "../../../../../../../../popups/Toll.js";
import IconResults from "../../../../../../../../icons/Results";
import IconToll from "../../../../../../../../icons/IconToll.js";
import IconRestr from "../../../../../../../../icons/Restricoes";
import PopupDocumentoResult from "../../../../../../../../popups/DocumentoResult";
import PopupCentroDistribuicaoResult from "../../../../../../../../popups/CentroDistribuicaoResult";

export default function MyMap(props) {
    const [results, setResults] = useState([]);
    const [tolls, setTolls] = useState([]);
    const [open_tolls, setOpenTolls] = useState(false);
    const [open_cluster, setOpenCluster] = useState(false);
    const [center, setCenter] = useState([-22.977213, -47.101165]);

    var { veic } = props
    const { docs } = props

    function getDarkColor() {
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += Math.floor(Math.random() * 10);
        }
        return color;
    }

    useEffect(() => {
        async function fetchData_() {
            setCenter([veic.path[0][0], veic.path[0][1]]);
            veic["docs"] = docs.filter(x => JSON.stringify(x.TripId) === JSON.stringify(veic._id))
            var aux_color = getDarkColor()
            for (var doc of veic.docs) {
                doc["color"] = aux_color;
            }
            var marker_g = [];
            var my_markers = veic.docs.map((doc, cont) => (
                <div key={cont}>
                    {(() => {
                        var my_icon = L.divIcon({
                            className: "custom-icon",
                            html: ReactDOMServer.renderToString(<IconResults ordem={doc.ordem} tipo={doc.tipo} doc={doc} veic={veic} />),
                        });

                        if (doc.tipo === "ENTREGA") {
                            return (
                                <Marker icon={my_icon} key={cont} position={[doc.lat_doc, doc.lon_doc]}>
                                    <PopupDocumentoResult doc={doc} placa={veic.placa} />
                                </Marker>
                            );
                        } else if (doc.tipo === "COLETA") {
                            return (
                                <Marker icon={my_icon} key={cont} position={[doc.lat_doc, doc.lon_doc]}>
                                    <PopupDocumentoResult doc={doc} placa={veic.placa} />
                                </Marker>
                            );
                        }
                    })()}
                </div>
            ));

            if (veic.path.length > 0) {
                var my_icon = L.divIcon({
                    className: "custom-icon",
                    html: ReactDOMServer.renderToString(<IconCD id={0} veic={veic} />),
                });
                var aux_path = (
                    <Marker icon={my_icon} key={0} position={[veic.path[0][0], veic.path[0][1]]}>
                        <PopupCentroDistribuicaoResult veic={veic} />
                        <Polyline key={0} color={"blue"} positions={veic.path} weight={2} />
                    </Marker>
                )
                my_markers.push(aux_path)

                // tolls
                var tools = []
                var cont = 0
                for (let toll of veic.tolls) {
                    let toll_icon = L.divIcon({
                        className: "custom-icon",
                        html: ReactDOMServer.renderToString(<IconToll id={cont} />),
                    });
                    var toll_comp = (
                        <Marker icon={toll_icon} key={cont} position={[toll.coordinates.lat, toll.coordinates.lng]}>
                            <PopupToll toll={toll} />
                        </Marker>
                    )
                    cont += 1
                    tools.push(toll_comp)
                }
                setTolls(tools)
            }
            marker_g.push(my_markers);
            setResults(marker_g);
        }
        fetchData_();
    }, []);

    return (
        <div style={{ width: "50%", height: "100%", marginTop: "1.5%", marginLeft: "1.5%" }}>
            <Map
                zoom={7}
                center={center}
                fullscreenControl
                className="company_map"
            >
                <TileLayer url="http://{s}.tile.osm.org/{z}/{x}/{y}.png" />
                <ReactLeafletSearch position="topright" providerOptions={{ region: "br" }} />
                <Control position="topleft">
                    <div className="leaflet-bar custom-control">
                        <Tooltip title={<span>Ativar ou desativar o agrupamento nos documentos</span>}>
                            <Button
                                color="inherit"
                                component="span"
                                startIcon={<GroupWork />}
                                onClick={() => setOpenCluster(!open_cluster)}
                            >
                            </Button>
                        </Tooltip>
                    </div>
                    <div className="leaflet-bar custom-control">
                        <Tooltip title={<span>Plota os pedágios no mapa</span>}>
                            <Button
                                color="inherit"
                                component="span"
                                startIcon={<IconRestr size="small" color={"black"} tipo={"pedagio"} />}
                                onClick={() => setOpenTolls(x => !x)}
                            >
                            </Button>
                        </Tooltip>
                    </div>
                </Control>
                {!open_cluster && <>{results} </>}
                {open_cluster && <MarkerClusterGroup className={"markercluster-map"} id={0}> {results} </MarkerClusterGroup>}
                {open_tolls && <MarkerClusterGroup className={"markercluster-map"} id={0}> {tolls} </MarkerClusterGroup>}
            </Map>
        </div>
    );
}

MyMap.propTypes = {
    docs: PropTypes.array,
    veic: PropTypes.object,
};
