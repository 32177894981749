import PropTypes from 'prop-types';
import React, { Component } from "react";

export default class IconVeiculos extends Component {
    render() {
        var seta = "";
        if (this.props.tipo === "ENTREGA") {
            seta = "m 22.558791,20.573008 0,-6 -6,0 0,-4 -7.0000003,7 7.0000003,7 0,-4 z";
        } else if (this.props.tipo === "COLETA") {
            seta = "m 3.5587907,20.573008 0,-6 6.0000004,0 0,-4 6.9999999,7 -6.9999999,7 0,-4 z";
        }

        return (
            <svg version="1.1" width="36" viewBox="0 0 36 36" height="36">
                <path
                    style={{ fill: this.props.doc.color, fillOpacity: 0.9 }}
                    id="truck"
                    d="m 30.827524,14.770795 -4.807302,0 0,-2.975776 c 0,-1.130148 -0.820585,-3.433959 -3.621304,-3.433959 l -18.8127689,0 c -1.7626771,0 -3.20486753,1.4421903 -3.20486753,3.204867 l 0,17.626772 3.20486753,0 C 3.5861491,31.852739 5.7334103,34 8.3934504,34 11.05349,34 13.200752,31.852739 13.200752,29.192699 l 9.614603,0 c 0,2.66004 2.147261,4.807301 4.807301,4.807301 2.66004,0 4.807301,-2.147261 4.807301,-4.807301 l 3.204868,0 0,-8.012169 z M 8.3934504,31.596349 c -1.33002,0 -2.4036506,-1.07363 -2.4036506,-2.40365 0,-1.33002 1.0736306,-2.403651 2.4036506,-2.403651 1.33002,0 2.4036506,1.073631 2.4036506,2.403651 0,1.33002 -1.0736306,2.40365 -2.4036506,2.40365 z m 21.6328566,-14.421903 3.14077,4.006084 -7.146855,0 0,-4.006084 z m -2.403651,14.421903 c -1.33002,0 -2.403651,-1.07363 -2.403651,-2.40365 0,-1.33002 1.073631,-2.403651 2.403651,-2.403651 1.33002,0 2.403651,1.073631 2.403651,2.403651 0,1.33002 -1.073631,2.40365 -2.403651,2.40365 z"
                />
                {/* <rect
                    ry="1.556259"
                    rx="1.5562589"
                    y="0.45287237"
                    x="0.24965264"
                    height="7.2858944"
                    width="35.389393"
                    id="bg"
                    //style="color:#000000;clip-rule:nonzero;display:inline;overflow:visible;visibility:visible;opacity:1;isolation:auto;mix-blend-mode:normal;color-interpolation:sRGB;color-interpolation-filters:linearRGB;solid-color:#000000;solid-opacity:1;fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.20000005;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;marker:none;color-rendering:auto;image-rendering:auto;shape-rendering:auto;text-rendering:auto;enable-background:accumulate" />
                    style={{ fill: "#ffffff" }}
                /> */}
                {/* <text
                    id="order"
                    y="6.5"
                    x="18"
                    style={{
                        fontSize: "8.4px",
                        fontFamily: "sans-serif",
                        textAlign: "center",
                        textAnchor: "middle",
                    }}
                >
                    <tspan y="6.5" x="18">
                        {this.props.doc.placa}
                    </tspan>
                </text> */}
                <path d={seta} style={{ fill: "#ffffff" }} />
            </svg>
        );
    }
}

IconVeiculos.propTypes = {
    ordem: PropTypes.number,
    doc: PropTypes.object,
    tipo: PropTypes.string,
};
