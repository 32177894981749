import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { useSnackbar } from 'notistack';
import MaterialTable from 'material-table';
import { useStoreActions } from 'easy-peasy';

import IconVeic from './Components/IconVeic.js';
import DeleteVeic from './Components/DeleteVeic.js';
import AproveTrip from './Components/AproveTrip.js';
import IconRestr from './Components/IconRestr.js';
import IconWarning from './Components/IconWarning.js';
import ButtonMap from '../../../Buttons/ButtonMap.js';

export default function VehicleResultsTable(props) {
    const snack = useRef()
    const { enqueueSnackbar } = useSnackbar();
    snack.current = enqueueSnackbar

    const state = {
        columns: [
            {
                title: "Opções",
                field: "opcoes",
                editable: false,
                render: (rowData) => {
                    return (
                        <div className="rowIcon">
                            <DeleteVeic veic={rowData} />
                            <AproveTrip veic={rowData} />
                        </div>
                    )
                }
            },
            {
                title: "info",
                field: "info",
                editable: false,
                render: (rowData) => {
                    return (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <IconVeic trip={rowData} />
                            <IconWarning trip={rowData} />
                            <IconRestr trip={rowData} />
                        </ div >
                    )
                }
            },
            { title: "Placa", field: "placa" },
            { title: "Ocup. Máx(kg)", field: "maior_peso_carga_kg" },
            { title: "Ocup. Máx(%)", field: "maior_taxa_ocup" },
            { title: "Ocup. Máx(reais)", field: "maior_peso_carga_reais" },
            { title: "Nº Docs", field: "n_docs" },
            { title: "Previsão saída", field: "horario_saida_cd_format" },
            { title: "Previsão chegada", field: "horario_retorno_cd_format" },
            { title: "Tempo de viagem", field: "tempo_viagem_format" },
            { title: "Custo Pedágio", field: "custo_pedagio" },
            { title: "Km Total", field: "tam_viagem_km" },
            { title: "Custo da diária + km", field: "custo_diaria_mais_km" },
            { title: "Custo Total", field: "custo_total_reais" },
            { title: "Usuário", field: "editado_por" },
            { title: "Id da Viagem", field: "_id" },
        ],
    };

    const plotVehiclesResults = useStoreActions(actions => {
        return actions.ResultStore.plotVehiclesResults;
    });

    const filename = localStorage.getItem("dataProgr") + '_Resultados_Por_Veiculo'

    function verificaRow(veics) {
        if (veics.length > 0) plotVehiclesResults({ veics, docs: props.docs })
        else plotVehiclesResults({ veics: [], docs: [] });
    }

    const tableRef = React.useRef();
    return (
        <MaterialTable
            tableRef={tableRef}
            title="Resultados por Veículo"
            columns={state.columns}
            data={props.veics}
            options={{
                grouping: true,
                exportButton: true,
                exportFileName: filename,
                search: true,
                pageSizeOptions: [1, 5, 10, 20],
                padding: "dense",
                selection: true,
                emptyRowsWhenPaging: false,
                headerStyle: {
                    backgroundColor: "#01579b",
                    textAlign: 'center',
                    color: "#FFF",
                    fontSize: 11 * window.innerWidth / 1280,
                },
                cellStyle: {
                    textAlign: "center",
                    fontSize: 11 * window.innerWidth / 1280,
                },
                rowStyle: rowData => ({
                    backgroundColor: (rowData.tableData.id % 2 === 0 ? '#fff' : '#f5f5f5')
                }),
            }}
            onSelectionChange={(rows) => verificaRow(rows)}
            localization={{
                body: {
                    addTooltip: "Adicionar novo",
                    deleteTooltip: "Excluir",
                    editTooltip: "Editar",
                    editRow: {
                        deleteText: "Deseja excluir a viagem?",
                        cancelTooltip: "Cancelar",
                        saveTooltip: "Salvar",
                    },
                    emptyDataSourceMessage: "",
                    bulkEditTooltip: "Editar todos",
                    bulkEditCancel: "Descartar edições",
                    bulkEditApprove: "Salvar todas as edições"
                },

                grouping: {
                    placeholder: "Arraste os cabeçalhos aqui para agrupar por",
                    groupedBy: "Agrupado por:",
                },

                header: {
                    actions: "Opções",
                },

                toolbar: {
                    searchTooltip: "Pesquisar",
                    searchPlaceholder: "Pesquisar",
                    exportTitle: "Exportar",
                    nRowsSelected: "{0} veículos(s) selecionados",
                },
                pagination: {
                    labelRowsSelect: "",
                    labelDisplayedRows: "{count} de {from}-{to}",
                    firstTooltip: "Primeira página",
                    previousTooltip: "Página anterior",
                    nextTooltip: "Próxima página",
                    lastTooltip: "Última página",
                },
            }}
            components={{
                Actions: () => {
                    return (
                        <>
                            < ButtonMap />
                        </>
                    );
                }
            }}
        />
    );
}


VehicleResultsTable.propTypes = {
    docs: PropTypes.array,
    veics: PropTypes.array,
};
