import React from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import IconDoc from './Components/IconDoc.js';
import IconRestr from './Components/IconRestr.js';

export default function DocumentsResulsTable(props) {
    const state = {
        columns: [
            { title: "Placa", field: "placa" },
            {
                title: "info",
                field: "info",
                editable: false,
                render: (rowData) => {
                    return (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <IconDoc doc={rowData} />
                            <IconRestr doc={rowData} />
                        </ div >
                    )
                }
            },
            { title: "Ordem", field: "ordem" },
            { title: "Documento", field: "documento" },
            { title: "Tipo 'Documento", field: "tipo" },
            { title: "Ocupação %", field: "taxa_ocup" },
            { title: "Ocupação kg", field: "taxa_ocup_kg" },
            { title: "Ocupação reais", field: "taxa_ocup_reais" },
            { title: "Peso do Documento kg", field: "peso_kg" },
            { title: "Chegada ao Cliente", field: "horario_chegada_cli_format" },
            { title: "Tempo Carga/Descarga", field: "tempo_carg_desc_format" },
            { title: "Logradouro", field: "logradouro" },
            { title: "Número", field: "numero" },
            { title: "Cidade", field: "cidade" },
            { title: "Estado", field: "estado" },
            { title: "CEP", field: "cep" },
            { title: "Razão Social", field: "razao_social" },
            { title: "Id da Viagem", field: "TripId" },
        ],
    };

    const filename = localStorage.getItem("dataProgr") + '_Resultados_Por_Documento'

    const tableRef = React.useRef();
    return (
        <MaterialTable
            tableRef={tableRef}
            title="Resultados por documento"
            columns={state.columns}
            data={props.docs}
            options={{
                grouping: true,
                exportButton: true,
                exportFileName: filename,
                search: true,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [1, 5, 10, 20],
                padding: "dense",
                headerStyle: {
                    backgroundColor: "#01579b",
                    textAlign: 'center',
                    color: "#FFF",
                    fontSize: 11 * window.innerWidth / 1280,
                },
                cellStyle: {
                    textAlign: "center",
                    fontSize: 11 * window.innerWidth / 1280,
                },
                rowStyle: rowData => ({
                    backgroundColor: (rowData.tableData.id % 2 === 0 ? '#fff' : '#f5f5f5')
                }),
            }}
            localization={{
                body: {
                    addTooltip: "Adicionar novo",
                    deleteTooltip: "Excluir",
                    editTooltip: "Editar",
                    editRow: {
                        deleteText: "Deseja excluir o documento?",
                        cancelTooltip: "Cancelar",
                        saveTooltip: "Salvar",
                    },
                    emptyDataSourceMessage: "",
                    bulkEditTooltip: "Editar todos",
                    bulkEditCancel: "Descartar edições",
                    bulkEditApprove: "Salvar todas as edições"
                },

                grouping: {
                    placeholder: "Arraste os cabeçalhos aqui para agrupar por",
                    groupedBy: "Agrupado por:",
                },

                header: {
                    actions: "Opções",
                },

                toolbar: {
                    searchTooltip: "Pesquisar",
                    searchPlaceholder: "Pesquisar",
                    exportTitle: "Exportar",
                    nRowsSelected: "{0} documento(s) selecionados",
                },
                pagination: {
                    labelRowsSelect: "",
                    labelDisplayedRows: "{count} de {from}-{to}",
                    firstTooltip: "Primeira página",
                    previousTooltip: "Página anterior",
                    nextTooltip: "Próxima página",
                    lastTooltip: "Última página",
                },
            }}
        />
    );
}

DocumentsResulsTable.propTypes = {
    docs: PropTypes.array,
};
