import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';

import '../../styles.css'

const tecnologias_rastreio = [
    { value: '', label: '' },
    { value: 'Sascar', label: 'Sascar' },
    { value: 'Autotrac', label: 'Autotrac' },
    { value: 'Omnlink', label: 'Omnlink' },
    { value: 'Onixsat', label: 'Onixsat' },
    { value: 'Sighra', label: 'Sighra' },
    { value: 'Positron', label: 'Positron' },
    { value: 'Tracker', label: 'Tracker' },
    { value: 'Ituran', label: 'Ituran' },
    { value: 'Não Possui', label: 'Não Possui' },
    { value: 'Outra', label: 'Outra' },
]

export default function TechnologyInfo(props) {
    const handleChange = (event) => {
        props.setValues({
            ...props.values,
            [event.target.id]: event.target.value,
        });
    };

    return (
        <>
            <div className="title_info2">Tecnologia de Rastreamento</div>
            <div className="fantasia">
                <div className="tech">
                    <TextField
                        select
                        size="small"
                        id="tec_rastreio"
                        label="Tecnologia de Rastreamento"
                        variant="outlined"
                        onChange={handleChange}
                        SelectProps={{ native: true }}
                        value={props.values.tec_rastreio}
                    >
                        {tecnologias_rastreio.map((option) => (
                            <option key={option.value} >
                                {option.label}
                            </option>
                        ))}
                    </TextField>
                    <TextField
                        size="small"
                        id="id_tec_rastreio"
                        variant="outlined"
                        label="Id Tecnologia Rastreamento"
                        onChange={handleChange}
                        value={props.values.id_tec_rastreio}
                    />
                </div>
            </div>
        </>
    )
}

TechnologyInfo.propTypes = {
    values: PropTypes.object,
    setValues: PropTypes.func
};
