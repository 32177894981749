import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState, useRef } from 'react';
import DragIndicator from '@material-ui/icons/DragIndicator';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import {
    Box,
    Button,
    Popover,
    Checkbox,
    FormGroup,
    FormLabel,
    FormControl,
    FormControlLabel,
} from '@material-ui/core'

import api from '../services/api.js'

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    formControl: {
        margin: theme.spacing(3)
    }
}));


export default function Hidden(props) {
    const { setReload, collumns, type } = props

    const classes = useStyles();
    const snack = useRef()
    const { enqueueSnackbar } = useSnackbar();
    snack.current = enqueueSnackbar

    const [array, setArray] = useState([])

    useEffect(() => {
        let array = []
        for (let key in collumns) {
            array.push(collumns[key])
        }
        setArray(array)
    }, [])

    const handleChange = (event) => {
        collumns[event.target.id].value = event.target.checked
        let array = []
        for (let key in collumns) {
            array.push(collumns[key])
        }
        setArray(array)
    }

    async function Save(collumns) {
        api.put(`/utils/user/config/${type}`, { collumns: collumns })
            .then(() => {
                setReload()
                snack.current("Colunas editadas com sucesso!", { variant: 'success' })
            })
            .catch((error) => {
                console.log(error)
                snack.current("Falha ao editar colunas!", { variant: 'error' })
            });
    }

    return (
        <PopupState variant="popover" popupId="demo-popup-popover">
            {(popupState) => (
                <>
                    <Button
                        size="large"
                        color="primary"
                        {...bindTrigger(popupState)}
                        style={{
                            margin: 10,
                            maxWidth: 45,
                            minWidth: 45,
                            maxHeight: 45,
                            minHeight: 45,
                        }}
                    >
                        <DragIndicator fontSize={'large'} />
                    </Button>
                    <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <Box p={2}>
                            <div className={classes.root}>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormLabel component="legend">Adicionar/Remover Colunas</FormLabel>
                                    <FormGroup>
                                        {array.map((item) => (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        id={item.field}
                                                        name={item.name}
                                                        checked={item.value}
                                                        onChange={handleChange}
                                                    />
                                                }
                                                label={item.label}
                                            />
                                        ))}
                                    </FormGroup>
                                </FormControl>
                            </div>
                            <div>
                                <Button
                                    variant="contained"
                                    className={classes.formControl}
                                    color="primary"
                                    onClick={() => {
                                        Save(collumns)
                                    }}
                                >
                                    SALVAR
                                </Button>
                            </div>

                        </Box>
                    </Popover>
                </>
            )
            }
        </PopupState >
    );
}
