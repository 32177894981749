import React from 'react';
import BlockIcon from '@material-ui/icons/Block';
import { useStoreState, useStoreActions } from 'easy-peasy';
import NoEncryptionIcon from '@material-ui/icons/NoEncryption';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

import './styles.css';

export default function Sumary() {
    const setTab = useStoreActions(actions => { return actions.InvoicesStore.setTab });

    const fatura = useStoreState((state) => state.InvoicesStore.fatura);

    function changeTab(props) {
        const { tab, value } = props
        if (value > 0) setTab(tab)
    }

    return (
        <div className="invoice_container" >

            <div className="invoice_title" >
                Resumo da Fatura - {fatura.nome}
            </div>

            <div className="invoice_line">
                <div className="invoice_width">
                    <div className="invoice_valueDate"> {fatura.mes}/{fatura.ano}</div>
                    <div className="invoice_item"> Data de Referência</div>
                </div>

                <div className="invoice_width">
                    <div className="invoice_valuePedagioCobrado"> {fatura.n_passagem_ped}</div>
                    <div className="invoice_item"> Nº Passagens Pedágios (cobrado)</div>
                </div>

                <div className="invoice_width">
                    <div className="invoice_valuePedagioCobrado"> {fatura.n_vale_ped} </div>
                    <div className="invoice_item"> Nº Vale Pedágio (cobrado)</div>
                </div>
            </div>


            <div className="invoice_line">
                <div className="invoice_width">
                    <div className="invoice_valueFonte"> {fatura.fonte} </div>
                    <div className="invoice_item"> Fonte</div>
                </div>

                <div className="invoice_width">
                    <div className="invoice_valuePedagio"> R$ {fatura.valor_passagem_ped}</div>
                    <div className="invoice_item"> Passagens Pedágios (cobrado)</div>
                </div>

                <div className="invoice_width">
                    <div className="invoice_valuePedagio"> R$ {fatura.valor_vale_ped} </div>
                    <div className="invoice_item"> Passagens Vale Pedágios (cobrado)</div>
                </div>
            </div>


            <div
                className="invoice_line"
                onClick={() =>
                    changeTab({
                        tab: 1,
                        value: fatura.n_total_duplicate
                    })}
            >
                <div className="invoice_backgroundColorItens">
                    <MonetizationOnIcon style={{ fontSize: 55, color: "#737373", margin: "1%" }} />
                    <div className="invoice_titleQuadro"> Cobrança Duplicada</div>
                    {/* <div style={{ textAlign: "center", marginTop: "10%", fontSize: 25, fontWeight: "bold", color: "#000" }}>{fatura.n_total_duplicate > 0 ? "sim" : "não"} </div> */}

                    <div className="invoice_valueQuadro">{fatura.n_total_duplicate} </div>
                    <div className="invoice_item"> Nº de Cobranças Duplicadas</div>

                    <div className="invoice_valueQuadro">{fatura.total_duplicate_cost} </div>
                    <div className="invoice_item"> Custo pedágios duplicados</div>
                </div>

                <div
                    className="invoice_backgroundColorItens"
                    onClick={() =>
                        changeTab({
                            tab: 2,
                            value: fatura.n_total_inc_category
                        })}
                >
                    <NoEncryptionIcon style={{ fontSize: 55, color: "#737373", margin: "1%" }} />
                    <div className="invoice_titleQuadro"> Cobrança por Categoria Incorreta</div>
                    {/* <div className="invoice_valueQuadro">{fatura.n_total_inc_category > 0 ? "sim" : "não"} </div> */}

                    <div className="invoice_valueQuadro">{fatura.n_total_inc_category}</div>
                    <div className="invoice_item"> Nº de Categoria Incorreta</div>

                    <div className="invoice_valueQuadro">{fatura.total_inc_category_cost} </div>
                    <div className="invoice_item"> Custo Categoria Incorreta</div>
                </div>

                <div
                    className="invoice_backgroundColorItens"
                    onClick={() =>
                        changeTab({
                            tab: 3,
                            value: fatura.n_total_vehicle_inconsistency
                        })}
                >
                    <BlockIcon style={{ fontSize: 55, color: "#737373", margin: "1%" }} />
                    <div className="invoice_titleQuadro"> Cobrança por Veículo não Cadastrado/Inativo</div>
                    {/* <div className="invoice_valueQuadro">sim/não</div> */}

                    <div className="invoice_valueQuadro"> {fatura.n_total_vehicle_inconsistency} </div>
                    <div className="invoice_item">Nº de Cobranças</div>

                    <div className="invoice_valueQuadro"> R$ {fatura.n_total_vehicle_inconsistency_cost} </div>
                    <div className="invoice_item"> Custo pedágios</div>
                </div>
            </div>

        </div>
    );
}
