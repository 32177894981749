import React, { Component } from "react";

export default class IconCD extends Component {
	render() {
		return (
			<svg version="1.1" width="36" viewBox="0 0 36 36" height="36">
				<path
					style={{ fill: "#0055a7" }}
					d="m 9.6312219,21.567307 0,-7.106334 4.7375561,0 0,7.106334 5.921946,0 0,-9.475112 3.553167,0 L 12,1.4326929 0.15610927,12.092195 l 3.55316723,0 0,9.475112 z"
				/>
			</svg>
		);
	}
}
