import { action } from "easy-peasy"

const DocumentStore = {
    document: [],
    setDoc: action((state, payload) => {
        state.document = payload
    }),

    reload: false,
    setReload: action((state, _) => {
        state.reload = !state.reload
    }),

    collumns: {
        placa: {
            label: "Placa",
            field: "placa",
            value: true
        },
        ordem: {
            label: "Ordem",
            field: "ordem",
            value: true
        },
        documento: {
            label: "Documento",
            field: "documento",
            value: true
        },
        peso_kg: {
            label: "Peso (kg)",
            field: "peso_kg",
            value: true
        },
        valor_reais: {
            label: "Valor (reais)",
            field: "valor_reais",
            value: true
        },
        tipo: {
            label: "Tipo",
            field: "tipo",
            value: true
        },
        situacao: {
            label: "Situação",
            field: "situacao",
            value: false
        },
        cont_situacao: {
            label: "Tentativas de Entrega",
            field: "cont_situacao",
            value: true
        },
        prazo: {
            label: "Prazo",
            field: "prazo",
            value: false
        },

        dataProgr: {
            label: "Programação",
            field: "dataProgr",
            value: true
        },
        cep: {
            label: "CEP",
            field: "cep",
            value: true
        },
        pais: {
            label: "País",
            field: "pais",
            value: false
        },
        cidade: {
            label: "Cidade",
            field: "cidade",
            value: true
        },
        estado: {
            label: "Estado",
            field: "estado",
            value: false
        },
        bairro: {
            label: "Bairro",
            field: "bairro",
            value: false
        },
        numero: {
            label: "Número",
            field: "numero",
            value: false
        },
        logradouro: {
            label: "Logradouro",
            field: "logradouro",
            value: false
        },
        complemento: {
            label: "Complemento",
            field: "complemento",
            value: false
        },
        razao_social: {
            label: "Razao Social",
            field: "razao_social",
            value: true
        },
        lat_doc: {
            label: "Lat",
            field: "lat_doc",
            value: false
        },
        lon_doc: {
            label: "Lon",
            field: "lon_doc",
            value: false
        },
        criado_por: {
            label: "Criado",
            field: "criado_por",
            value: true
        },
        criado_em: {
            label: "Criado em",
            field: "criado_em",
            value: false
        },
        editado_por: {
            label: "Editado",
            field: "editado_por",
            value: true
        },
        editado_em: {
            label: "Editado em",
            field: "editado_em",
            value: false
        },
    },
    setCollumns: action((state, payload) => {
        state.collumns = payload
    }),

};
export default DocumentStore;
