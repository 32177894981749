import React, { useEffect, useState, useRef } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';

import api from '../../../../services/api.js'
import GeneralResults from './Components/GeneralResults.js'
import DocumentsResulsTable from './Components/DocsTable/DocumentsResulsTable.js'
import VehicleResultsTable from './Components/VehicleResultsTable/VehicleResultsTable.js'

export default function TripsAnalyze() {
    const [veics, setVeics] = useState([])
    const [docs, setDocs] = useState([])
    const [general, setGeneral] = useState({
        restricao: "não",
        custo_km: 0,
        valor_diaria: 0,
        custo_pedagio: 0,
        tam_viagem_km: 0,
        ocupacao_media: 0,
        custo_total_reais: 0,
        custo_diaria_mais_km: 0,
    })

    //ativa e desativa o loading abaixo da appbar
    const setLoading = useRef()
    setLoading.current = useStoreActions(actions => {
        return actions.globalStore.setLoading;
    });

    const reload = useStoreState((state) => state.ResultStore.reload);
    const reload_ = useStoreState((state) => state.globalStore.reload);

    useEffect(() => {
        async function fetchData() {
            setLoading.current(true)
            let resp = await api.get(`/trips/analyzes`)
                .catch((error) => {
                    console.log(error);
                });
            const { veics, docs } = resp.data

            if (veics) {
                setDocs(docs)
                setVeics(veics)
                if (veics.length > 0) {
                    var my_resul = {
                        restricao: "não",
                        custo_km: 0,
                        valor_diaria: 0,
                        custo_pedagio: 0,
                        tam_viagem_km: 0,
                        ocupacao_media: 0,
                        custo_total_reais: 0,
                        custo_diaria_mais_km: 0,
                    };
                    var ocupacao = 0;
                    var capacidade_kg = 0;
                    var custo_pedagio = 0
                    var valor_diaria = 0
                    var tam_viagem_km = 0
                    var custo_diaria_mais_km = 0
                    var custo_total_reais = 0

                    for (let veic of veics) {
                        if (veic.possui_restr === 'sim') {
                            my_resul["restricao"] = "sim";
                        }
                        custo_pedagio += veic.custo_pedagio;
                        valor_diaria += veic.valor_diaria;
                        tam_viagem_km += veic.tam_viagem_km;
                        custo_diaria_mais_km += veic.custo_diaria_mais_km;
                        custo_total_reais += veic.custo_total_reais;
                        capacidade_kg += veic.capacidade_kg;
                        ocupacao += veic.maior_peso_carga_kg;
                    }

                    my_resul["custo_pedagio"] = Number(custo_pedagio.toFixed(2));
                    my_resul["valor_diaria"] = Number(valor_diaria.toFixed(2));
                    my_resul["tam_viagem_km"] = Number(tam_viagem_km.toFixed(2));
                    my_resul["custo_diaria_mais_km"] = Number(custo_diaria_mais_km.toFixed(2));
                    my_resul["custo_total_reais"] = Number(custo_total_reais.toFixed(2));
                    my_resul["ocupacao_media"] = Number(((ocupacao / capacidade_kg) * 100).toFixed(0));
                    my_resul["custo_km"] = Number(custo_diaria_mais_km - valor_diaria).toFixed(1);
                    setGeneral(my_resul)
                }
            }
            setLoading.current(false)
        }
        fetchData()
    }, [reload, reload_])

    return (
        <>
            <GeneralResults general={general} />
            <VehicleResultsTable veics={veics} docs={docs} />
            <DocumentsResulsTable docs={docs} />
        </>
    )
}
