import React from 'react';

import DocumentTable from './DocumentTable.js';

export default function Documents() {
    return (
        <>
            < DocumentTable />
        </>
    );
}
