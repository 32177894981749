import React, { useState } from "react";
import { Resizable } from "re-resizable";
import { useStoreState } from "easy-peasy";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import StudyMap from "./StudyMap"

import "../../styles.css"

const useStyles = makeStyles({
    paper: {
        width: props => props.width,
        top: props => props.top,
        backgroundColor: 'transparent',
    }
});

export default function MyMap() {
    const [width, setWidth] = useState(window.innerWidth / 2.5);
    const [height, setHeight] = useState(window.innerHeight / 2);
    const [top, setTop] = useState(-20 + window.innerHeight / 2);
    const props = { width: width, top: top };
    const classes = useStyles(props);

    const open_map = useStoreState((state) => state.mapStore.open_map);

    return (
        < SwipeableDrawer
            anchor={"right"}
            variant="persistent"
            open={open_map}
            onOpen={() => console.log("open")}
            onClose={() => console.log("close")}
            classes={{ paper: classes.paper }}
        >
            <div className="container1">
                <Resizable
                    size={{ height, width }}
                    maxHeight={window.innerHeight - 100}
                    maxWidth={window.innerWidth - 100}
                    onResizeStop={(e, direction, ref, d) => {
                        setHeight(height + d.height);
                        setWidth(width + d.width)
                        setTop(top - d.height);
                    }}
                >
                    <StudyMap width={width} height={height} />
                </Resizable>
            </div>
        </SwipeableDrawer >
    );
}
