import React, { useRef } from 'react';
import { useSnackbar } from "notistack";
import { makeStyles } from '@material-ui/core/styles';
import { useStoreState, useStoreActions } from "easy-peasy";
import { Stepper, Step, StepLabel, Button } from '@material-ui/core';

import "../../../styles.css"
import api from "../../../../../services/api.js";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

function getSteps() {
    return ['ETAPA 1: Selecione a mês e ano de referência', 'Etapa 2: Selecione a sua fatura'];
}

export default function MyStepper() {
    const classes = useStyles();
    const steps = getSteps();
    const snack = useRef()
    const { enqueueSnackbar } = useSnackbar();
    snack.current = enqueueSnackbar

    const setActiveStep = useStoreActions(actions => {
        return actions.InvoicesStore.setActiveStep;
    });

    const date = useStoreState((state) => state.InvoicesStore.date);
    const files = useStoreState((state) => state.InvoicesStore.files);
    const activeStep = useStoreState((state) => state.InvoicesStore.activeStep);

    //ativa e desativa o loading abaixo da appbar
    const setLoading = useRef()
    setLoading.current = useStoreActions(actions => {
        return actions.globalStore.setLoading;
    });

    const setOpen = useStoreActions(actions => {
        return actions.InvoicesStore.setOpenUploadDrawer;
    });

    const setReload = useRef()
    setReload.current = useStoreActions(actions => {
        return actions.InvoicesStore.setReload;
    });

    async function handleSubmit() {
        snack.current("Iniciando o carregamento, aguarde.", { variant: 'info' })
        setLoading.current(true)
        const data = new FormData();
        data.append("thumbnail", files[0].file);
        data.append("source", "sem parar");
        data.append("dateRef", date);
        setOpen(false)
        api.post(`/invoices/upload`, data)
            .then((res) => {
                setLoading.current(false)
                snack.current(res.data.message, { variant: 'success' })
                setReload.current()
            })
            .catch((error) => {
                console.log(error);
                setLoading.current(false)
                snack.current("Falha ao carregar!", { variant: 'error' })
            });
    }

    return (
        <div className={classes.root}>
            <div id="stepper2" >
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </div>

            <div id="stepper1" >
                <div>
                    <Button
                        disabled={activeStep === 0}
                        onClick={() => setActiveStep("back")}
                        className={classes.backButton}
                    >
                        Voltar
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            activeStep === steps.length - 1 ?
                                handleSubmit() :
                                setActiveStep("next")
                        }}
                        disabled={files.length === 0 && activeStep === 1}
                    >
                        {activeStep === steps.length - 1 ? 'Salvar' : 'Próximo'}
                    </Button>
                </div>
            </div>

        </div>
    );
}
