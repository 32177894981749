import L from 'leaflet';
import { useStoreState } from 'easy-peasy';
import ReactDOMServer from 'react-dom/server';
import { Marker, Popup } from 'react-leaflet';
import React, { useState, useEffect } from 'react';
import MarkerClusterGroup from 'react-leaflet-markercluster';

import VehicleIcon from './VehicleIcon.js';

export default function VehiclesPositionsMap() {
    const open_cluster = useStoreState((state) => state.VehicleTrackingStore.open_cluster);
    const positions_map = useStoreState((state) => state.VehicleTrackingStore.positions_map);

    const [veics, setVeics] = useState([])

    useEffect(() => {
        async function fetchData_() {
            const my_icon = L.divIcon({
                className: "custom-icon",
                html: ReactDOMServer.renderToString(
                    <VehicleIcon />
                ),
            });

            var markers = []
            for (let veic of positions_map) {
                let aux = (
                    <Marker icon={my_icon} key={veic._id} position={[veic.lat, veic.lon]}>
                        <Popup>
                            <h2> {veic.placa}  </h2>
                        </Popup>
                    </Marker>
                )
                markers.push(aux)
            }
            setVeics(markers);
        }
        fetchData_();
    }, [positions_map]);


    return (
        <>
            {!open_cluster && <>{veics} </>}
            {open_cluster && <MarkerClusterGroup className={"markercluster-map"} id={0}> {veics} </MarkerClusterGroup>}
        </>
    );
}
