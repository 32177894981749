import React, { useState } from 'react';
import Cancel from "@material-ui/icons/Cancel";
import { makeStyles } from '@material-ui/core/styles';
import { useStoreState, useStoreActions } from "easy-peasy";
import { SwipeableDrawer, Container, IconButton } from '@material-ui/core';

import VehicleTable from "./Components/VehicleTable.js"

const useStyles = makeStyles((theme) => ({
    paper: {
        top: 0,
    },
    menuButton: {
        marginRight: theme.spacing(1),
    },
}));

export default function ToOrder() {
    const classes = useStyles();

    const [order_vehicles, setOrderVehicles] = useState([])
    const open_order = useStoreState((state) => state.toOrderStore.open_order);

    // fecha a janela de gerar viagem
    const setOpenOrder = useStoreActions(actions => {
        return actions.toOrderStore.setOpenOrder;
    });

    function cancelarViagem() {
        setOrderVehicles([])
        setOpenOrder(false)
    }

    return (
        <SwipeableDrawer
            anchor={"bottom"}
            onOpen={() => console.log("open")}
            onClose={() => console.log("close")}
            open={open_order}
            classes={{ paper: classes.paper }}
        >
            < Container maxWidth={false} spacing={2}>

                <IconButton
                    edge="start"
                    className={classes.menuButton}
                    aria-label="menu"
                    onClick={() => cancelarViagem()} >
                    <Cancel />
                </IconButton>

                < VehicleTable
                    setOrderVehicles={setOrderVehicles}
                    order_vehicles={order_vehicles}
                />

            </Container>
        </SwipeableDrawer>
    );
}
