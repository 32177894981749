import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';

import '../../styles.css'

import { grey } from '@material-ui/core/colors';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const materialTheme = createMuiTheme({
    palette: {
        primary: grey,
    },
});

export default function DocsInfo(props) {

    const handleChange = (event) => {
        props.setValues({
            ...props.values,
            [event.target.id]: event.target.value,
        });
    };

    return (
        <>
            <div className="title_info2">Documentação</div>
            <div className="fantasia">
                <div className="docs">
                    <ThemeProvider theme={materialTheme}>
                        <KeyboardDatePicker
                            disabled={props.update}
                            id="vencimento_crlv"
                            size="small"
                            autoOk
                            variant="inline"
                            inputVariant="outlined"
                            label="Vencimento CRLV"
                            format="dd/MM/yyyy"
                            value={props.values.vencimento_crlv}
                            InputAdornmentProps={{ position: "start" }}
                            onChange={date => {
                                props.setValues({
                                    ...props.values,
                                    ["vencimento_crlv"]: date,
                                });
                            }}
                            maxDateMessage="Data inválida"
                            minDateMessage="Data inválida"
                        />
                    </ThemeProvider>
                    <TextField
                        size="small"
                        id="num_antt"
                        variant="outlined"
                        label="Número ANTT"
                        onChange={handleChange}
                        value={props.values.num_antt}
                    />

                    <ThemeProvider theme={materialTheme}>
                        <KeyboardDatePicker
                            disabled={props.update}
                            id="vencimento_antt"
                            size="small"
                            autoOk
                            variant="inline"
                            inputVariant="outlined"
                            label="Vencimento ANTT"
                            format="dd/MM/yyyy"
                            value={props.values.vencimento_antt}
                            InputAdornmentProps={{ position: "start" }}
                            onChange={date => {
                                props.setValues({
                                    ...props.values,
                                    ["vencimento_antt"]: date,
                                });
                            }}
                            maxDateMessage="Data inválida"
                            minDateMessage="Data inválida"
                        />
                    </ThemeProvider>
                </div>
            </div>
        </>
    )
}

DocsInfo.propTypes = {
    values: PropTypes.object,
    setValues: PropTypes.func
};
