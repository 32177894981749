import React, { useRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useStoreActions, useStoreState } from "easy-peasy";
import { green, red, blue } from '@material-ui/core/colors';
import { FormGroup, FormControlLabel, Checkbox, Box } from '@material-ui/core';

import "../../styles.css"

const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const RedCheckbox = withStyles({
    root: {
        color: red[400],
        '&$checked': {
            color: red[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const BlueCheckbox = withStyles({
    root: {
        color: blue[400],
        '&$checked': {
            color: blue[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function CheckboxLabels() {
    const setCheckStates = useRef()
    setCheckStates.current = useStoreActions(actions => {
        return actions.VeicsDocsStore.setCheckStates;
    });

    const check_states = useStoreState((state) => state.VeicsDocsStore.check_states);

    const handleChange = (event) => {
        setCheckStates.current({ ...check_states, [event.target.name]: event.target.checked });
    };

    return (
        <div className="Ckeckbox">
            <h4>Filtrar por: &nbsp;</h4>
            <FormGroup row>
                <FormControlLabel
                    control={
                        <GreenCheckbox
                            checked={check_states.valido}
                            onChange={handleChange}
                            name="valido"
                        />}
                    label={
                        <Box component="div" fontSize={15}>
                            válidos
                        </Box>
                    }
                />

                <FormControlLabel
                    control={
                        <RedCheckbox
                            checked={check_states.invalido}
                            onChange={handleChange}
                            name="invalido"
                        />}
                    label={
                        <Box component="div" fontSize={15}>
                            inválidos
                        </Box>
                    }
                />

                <FormControlLabel
                    control={
                        <BlueCheckbox
                            checked={check_states.processando}
                            onChange={handleChange}
                            name="processando"
                        />}
                    label={
                        <Box component="div" fontSize={15}>
                            em processamento
                        </Box>
                    }
                />

            </FormGroup>
        </ div >
    );
}
