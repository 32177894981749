import { action } from "easy-peasy"

const VehicleStore = {
    select_veics: [],
    setSelectVeics: action((state, payload) => {
        state.select_veics = payload;
    }),

    collumns: {
        status: {
            label: "Status do Veículo",
            field: "status",
            value: true
        },
        placa: {
            label: "Placa",
            field: "placa",
            value: true
        },
        filial: {
            label: "Filial",
            field: "filial",
            value: true
        },
        status_error: {
            label: "Status Error",
            field: "status_error",
            value: true
        },
        _id: {
            label: "Identificador",
            field: "_id",
            value: false
        },
        vinculo: {
            label: "Vínculo",
            field: "vinculo",
            value: false
        },
        codigo_frota: {
            label: "Código da Frota",
            field: "codigo_frota",
            value: false
        },
        valor_diaria: {
            label: "Valor da Diária",
            field: "valor_diaria",
            value: false
        },
        valor_km: {
            label: "Valor do km (reais)",
            field: "valor_km",
            value: false
        },
        tipo_veiculo: {
            label: "Tipo de Veículo",
            field: "tipo_veiculo",
            value: false
        },
        marca: {
            label: "Marca",
            field: "marca",
            value: false
        },
        ano_modelo: {
            label: "Ano do Modelo",
            field: "ano_modelo",
            value: false
        },
        ano_fabricacao: {
            label: "Ano da fabricação",
            field: "ano_fabricacao",
            value: false
        },
        combustivel: {
            label: "Combustível",
            field: "combustivel",
            value: false
        },
        categoria: {
            label: "Categoria",
            field: "categoria",
            value: false
        },
        n_eixos: {
            label: "Número de Eixos",
            field: "n_eixos",
            value: false
        },
        tipo_eixo: {
            label: "Tipo de Eixo",
            field: "tipo_eixo",
            value: false
        },
        altura: {
            label: "Altura Total (m)",
            field: "altura",
            value: false
        },
        largura: {
            label: "Largura Total (m)",
            field: "largura",
            value: false
        },
        comprimento: {
            label: "Comprimento Total (m)",
            field: "comprimento",
            value: false
        },
        tipo_carroceria: {
            label: "Tipo de Carroceria",
            field: "tipo_carroceria",
            value: false
        },
        capacidade_kg: {
            label: "Capacidade (kg)",
            field: "capacidade_kg",
            value: false
        },
        capacidade_m3: {
            label: "Capacidade (m3)",
            field: "capacidade_m3",
            value: false
        },
        altura_carroceria_m: {
            label: "Altura carroceria (m)",
            field: "altura_carroceria_m",
            value: false
        },
        largura_carroceria_m: {
            label: "Largura carroceria (m)",
            field: "largura_carroceria_m",
            value: false
        },
        comprimento_carroceria_m: {
            label: "Comprimento carroceria (m)",
            field: "comprimento_carroceria_m",
            value: false
        },
        vencimento_crlv: {
            label: "Vencimento CRLV",
            field: "vencimento_crlv",
            value: false
        },
        num_antt: {
            label: "Número ANTT",
            field: "num_antt",
            value: false
        },
        vencimento_antt: {
            label: "Vencimento ANTT",
            field: "vencimento_antt",
            value: false
        },
        tec_rastreio: {
            label: "Tecnologia de rastreio",
            field: "tec_rastreio",
            value: false
        },
        id_tec_rastreio: {
            label: "Identificador Tecnologia de Rastreio",
            field: "id_tec_rastreio",
            value: false
        },
        criado_por: {
            label: "Criado",
            field: "criado_por",
            value: false
        },
        criado_em: {
            label: "Criado em",
            field: "criado_em",
            value: false
        },
        editado_por: {
            label: "Editado",
            field: "editado_por",
            value: false
        },
        editado_em: {
            label: "Editado em",
            field: "editado_em",
            value: false
        },
    },

    setCollumns: action((state, payload) => {
        state.collumns = payload
    }),

};

export default VehicleStore;
