import { useSnackbar } from 'notistack';
import MaterialTable from 'material-table';
import React, { useRef, useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';

import api from '../../../../services/api.js';
import Hidden from '../../../../buttons/Hidden.js';

export default function DocumentTable() {
    const snack = useRef()
    const { enqueueSnackbar } = useSnackbar();
    snack.current = enqueueSnackbar

    const setReload = useRef()
    setReload.current = useStoreActions(actions => { return actions.DocumentStore.setReload });
    const setCollumns = useStoreActions(actions => { return actions.DocumentStore.setCollumns });

    const reload = useStoreState((state) => state.DocumentStore.reload);
    const collumns = useStoreState((state) => state.DocumentStore.collumns);
    const document = useStoreState((state) => state.DocumentStore.document);

    useEffect(() => {
        async function fecthData() {
            api.get(`/utils/user/config/config_document_consult`)
                .then((resp) => {
                    let { data } = resp
                    if (data) setCollumns(data)
                })
                .catch((error) => {
                    console.log(error);
                    // snack.current("Falha ao buscar config", { variant: 'error' })
                });
        }
        fecthData();
    }, [reload]);

    const tableRef = useRef();
    return (
        <MaterialTable
            tableRef={tableRef}
            title="Histórico do Documento"
            columns={
                [
                    {
                        title: "Placa",
                        field: "placa",
                        hidden: !collumns.placa.value,
                    },
                    {
                        title: "Ordem",
                        field: "ordem",
                        hidden: !collumns.ordem.value,
                    },
                    {
                        title: "Documento",
                        field: "documento",
                        hidden: !collumns.documento.value,
                    },
                    {
                        title: "Peso (kg)",
                        field: "peso_kg",
                        hidden: !collumns.peso_kg.value,
                    },
                    {
                        title: "Valor (R$)",
                        field: "valor_reais",
                        hidden: !collumns.tipo.value,
                    },
                    {
                        title: "Tipo",
                        field: "tipo",
                        hidden: !collumns.tipo.value,
                    },
                    {
                        title: "Situação",
                        field: "situacao",
                        hidden: !collumns.situacao.value,
                    },
                    {
                        title: "Tentativas de Entrega",
                        field: "cont_situacao",
                        hidden: !collumns.cont_situacao.value,
                    },
                    {
                        title: "Prazo",
                        field: "prazo",
                        hidden: !collumns.prazo.value,
                    },
                    {
                        title: "Programação",
                        field: "dataProgr",
                        hidden: !collumns.dataProgr.value,
                    },
                    {
                        title: "CEP",
                        field: "cep",
                        hidden: !collumns.cep.value,
                    },
                    {
                        title: "País",
                        field: "pais",
                        hidden: !collumns.pais.value,
                    },
                    {
                        title: "Cidade",
                        field: "cidade",
                        hidden: !collumns.cidade.value,
                    },
                    {
                        title: "Estado",
                        field: "estado",
                        hidden: !collumns.estado.value,
                    },
                    {
                        title: "Bairro",
                        field: "bairro",
                        hidden: !collumns.bairro.value,
                    },
                    {
                        title: "Número",
                        field: "numero",
                        hidden: !collumns.numero.value,
                    },
                    {
                        title: "Logradouro",
                        field: "logradouro",
                        hidden: !collumns.logradouro.value,
                    },
                    {
                        title: "Complemento",
                        field: "complemento",
                        hidden: !collumns.complemento.value,
                    },
                    {
                        title: "Razao Social",
                        field: "razao_social",
                        hidden: !collumns.razao_social.value,
                    },
                    {
                        title: "Lat",
                        field: "lat_doc",
                        hidden: !collumns.lat_doc.value,
                    },
                    {
                        title: "Lon",
                        field: "lon_doc",
                        hidden: !collumns.lon_doc.value,
                    },
                    {
                        title: "Criado",
                        field: "criado_por",
                        hidden: !collumns.criado_por.value,
                    },
                    {
                        title: "Criado em",
                        field: "criado_em",
                        hidden: !collumns.criado_em.value,
                    },
                    {
                        title: "Editado",
                        field: "editado_por",
                        hidden: !collumns.editado_por.value,
                    },
                    {
                        title: "Editado em",
                        field: "editado_em",
                        hidden: !collumns.editado_em.value,
                    },
                ]
            }
            data={document}
            options={{
                search: true,
                pageSize: 5,
                grouping: true,
                selection: false,
                exportButton: false,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [1, 5, 10, 20],
                padding: "dense",
                headerStyle: {
                    backgroundColor: "#01579b",
                    textAlign: 'center',
                    color: "#FFF",
                    fontSize: 11 * window.innerWidth / 1280,
                },
                cellStyle: {
                    textAlign: "center",
                    fontSize: 11 * window.innerWidth / 1280,
                },
            }}
            localization={{
                body: {
                    addTooltip: "Adicionar novo",
                    deleteTooltip: "Excluir",
                    editTooltip: "Editar",
                    editRow: {
                        deleteText: "Deseja excluir o veículo?",
                        cancelTooltip: "Cancelar",
                        saveTooltip: "Salvar",
                    },
                    emptyDataSourceMessage: (
                        ""
                    ),
                    bulkEditTooltip: "Editar todos",
                    bulkEditCancel: "Descartar edições",
                    bulkEditApprove: "Salvar todas as edições"
                },

                grouping: {
                    placeholder: "Arraste os cabeçalhos aqui para agrupar por",
                    groupedBy: "Agrupado por:",
                },

                header: {
                    actions: "Opções",
                },

                toolbar: {
                    searchTooltip: "Pesquisar",
                    searchPlaceholder: "Pesquisar",
                    exportTitle: "Exportar",
                    nRowsSelected: "{0} veículos(s) selecionados",
                },
                pagination: {
                    labelRowsSelect: "",
                    labelDisplayedRows: "{count} de {from}-{to}",
                    firstTooltip: "Primeira página",
                    previousTooltip: "Página anterior",
                    nextTooltip: "Próxima página",
                    lastTooltip: "Última página",
                },
            }}
            components={{
                Actions: () => {
                    return (
                        <div >
                            < Hidden
                                type={"config_document_consult"}
                                collumns={collumns}
                                setReload={setReload.current}
                            />
                        </div>
                    );
                }
            }}
        />
    );
}
