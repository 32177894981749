import React from "react";
import PropTypes from 'prop-types';
import BlockIcon from '@material-ui/icons/Block';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';

import "../styles.css"

export default function GeneralResults(props) {

    return (
        <>
            <div className="fantasia">
                <div className='title'>Roteirizações do Usuário</div>
            </div>

            <div className="rowData">
                <BlockIcon />
                <div className="item"> Houve Restrição:</div>
                <div className="dado">{props.general.restricao} </div>
            </div>

            <div className="rowData">
                <LocalShippingIcon />
                <div className="item"> Ocupação média:</div>
                <div className="dado">{props.general.ocupacao_media}%</div>
                <div className="item"> Distância Total:</div>
                <div className="dado">{props.general.tam_viagem_km} km </div>
            </div>

            <div className="rowData">
                <LocalAtmIcon />
                <div className="item"> Custo Pedágio:</div>
                <div className="dado">R$ {props.general.custo_pedagio} </div>
                <div className="item"> Custo Diárias:</div>
                <div className="dado">R$ {props.general.valor_diaria}</div>
                <div className="item"> Custo km:</div>
                <div className="dado">R$ {props.general.custo_km} </div>
                <div className="item"> Custo Total:</div>
                <div className="dado">R$ {props.general.custo_total_reais} </div>
            </div>
        </>
    );
}

GeneralResults.propTypes = {
    general: PropTypes.object,
};
