import React from "react";
import Map from "@material-ui/icons/Map";
import { useStoreActions } from "easy-peasy";
import { Button, Tooltip } from "@material-ui/core";

export default function ButtonMap() {
    const setOpenMap = useStoreActions(actions => {
        return actions.toAllocateStore.setOpenMap;
    });

    return (
        <Tooltip title={<span> Mapa </span>} >
            <Button color="primary" component="span" startIcon={<Map />} onClick={() => setOpenMap()}>
                Mapa
			</Button>
        </Tooltip>
    );
}
