import { action } from "easy-peasy"

const globalStore = {
    // enables and disables loading below the appbar
    loading: false,
    setLoading: action((state, payload) => {
        state.loading = payload;
    }),

    // troca de filial e data de programação
    reload: false,
    setReload: action((state, _) => {
        state.reload = !state.reload;
    }),
};

export default globalStore;
