import axios from "axios";
import Cookies from "js-cookie";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "http://localhost:3030",
});

api.interceptors.request.use(
  function (config) {
    const token = Cookies.get("x-access-token");
    if (token) {
      config.headers["x-access-token"] = token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default api;
