import { action, thunk } from "easy-peasy"

import DocumentValidation from "./Components/DocumentValidation"
import VehicleValidation from "./Components/VehicleValidation"

const toAllocateStore = {

    // input information in alocattion module
    setAllocate: thunk(async (actions, payload) => {
        actions.setAllocateType(payload.type)
        let valid_docs = DocumentValidation(payload.documents)
        actions.setAllocateDocuments(valid_docs)
        actions.setAllocateVehicles(payload)
        actions.setOpenAllocate(payload.open_allocate)
    }),

    // ----- store ------
    // tipo da alocação, pode ser alocacao_simples, alocacao_aprimorada, etc
    alocate_type: "",
    setAllocateType: action((state, payload) => {
        state.alocate_type = payload;
    }),

    // lista de documentos validos disponiveis para alocação
    avaliable_docs: [],
    setAllocateDocuments: action((state, payload) => {
        let docs = []
        for (let doc of payload) {
            if (doc.ajuste) docs.push(doc)
        }
        state.avaliable_docs = docs
    }),

    // auxilia na atualização da tabela de documentos
    reload_documents_table: false,
    setReloadDocumentsTable: action((state, _) => {
        console.log("Reload Document Table")
        state.reload_documents_table = !state.reload_documents_table
    }),


    // lista de documentos validos disponiveis para alocação
    veics_available: [],
    setAllocateVehicles: action((state, payload) => {
        state.veics_available = VehicleValidation({ my_vehicles: payload.my_vehicles, documents: payload.documents })
    }),

    // abre e fecha o modal alocação
    open_allocate: false,
    setOpenAllocate: action((state, payload) => {
        state.open_allocate = payload;
    }),

    // abre e fecha o modal do mapa
    open_map: false,
    setOpenMap: action((state, _) => {
        console.log("Mapa de alocação:", !state.open_map)
        state.open_map = !state.open_map;
    }),

    // set and reset mapcluster "agrupar"
    open_cluster: false,
    setCluster: action((state, _) => {
        state.open_cluster = !state.open_cluster
    }),

};


export default toAllocateStore;
