import React from 'react';
import { grey } from '@material-ui/core/colors';
import { DatePicker } from '@material-ui/pickers';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const materialTheme = createMuiTheme({
    palette: {
        primary: grey,
    },
});

export default function YearMonthPicker() {
    const setDate = useStoreActions(actions => {
        return actions.InvoicesStore.setDate;
    });

    const date = useStoreState((state) => state.InvoicesStore.date);

    return (
        <>
            <h1> Data de Referência </h1>
            <ThemeProvider theme={materialTheme}>

                <DatePicker
                    variant="inline"
                    openTo="year"
                    views={["year", "month"]}
                    label="Mês e Ano"
                    // helperText="selecione mês e ano de referência"
                    value={date}
                    onChange={(date) => setDate(date)}
                    style={{ margin: 10, marginTop: "1%" }}
                    inputVariant="outlined"
                />
            </ThemeProvider>
        </>
    );
}
