import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { useSnackbar } from "notistack";
import { Button, Tooltip } from "@material-ui/core";
import { useStoreState, useStoreActions } from "easy-peasy";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";

import api from "../../../../../services/api.js";

export default function TripGen(props) {
    const snack = useRef()
    const { enqueueSnackbar } = useSnackbar();
    snack.current = enqueueSnackbar

    const setOpenTrip = useStoreActions(actions => {
        return actions.toTripStore.setOpenTrip;
    });

    // atualiza a tabela de documentos
    const setReload = useStoreActions(actions => {
        return actions.VeicsDocsStore.setReload;
    });

    //ativa e desativa o loading abaixo da appbar
    const setLoading = useStoreActions(actions => {
        return actions.globalStore.setLoading;
    });

    // limpa os veiculos plotados no mapa
    const plotVehicles = useStoreActions(actions => {
        return actions.mapStore.plotVehicles;
    });

    // limpa os documentos plotados no mapa
    const plotDocuments = useStoreActions(actions => {
        return actions.mapStore.plotDocuments;
    });

    const avaliable_docs = useStoreState((state) => state.toTripStore.avaliable_docs);

    async function GerarViagem() {
        setOpenTrip(false)
        setLoading(true);
        api.post(`/trip`, {
            avaliable_docs: avaliable_docs,
            veics: props.trip_vehicles,
        })
            .then((res) => {
                snack.current(res.data.message, { variant: res.data.status })
                setLoading(false);
                plotVehicles([])
                plotDocuments([])
                setReload()
                props.setTripVehicles([])
            })
            .catch((error) => {
                console.log(error);
                try {
                    snack.current(`${error.response.data.message}`, { variant: 'error' });
                } catch (e) {
                    console.error(e);
                    snack.current("Não foi possível gerar a viagem", { variant: 'error' })
                }
                setLoading(false);
                plotVehicles([])
                plotDocuments([])
                setReload()
                setLoading(false);
                props.setTripVehicles([])
            });
    }

    return (
        <Tooltip title={<span> Gerar Viagem </span>} >
            <Button
                color="primary"
                component="span"
                disabled={props.trip_vehicles.length === 0 ? true : false}
                startIcon={<LocalShippingIcon />}
                onClick={() => GerarViagem()}
            >
                Gerar Viagem
            </Button>
        </Tooltip>
    );
}

TripGen.propTypes = {
    trip_vehicles: PropTypes.array,
    setTripVehicles: PropTypes.func,
};