import PropTypes from 'prop-types';
import { useStoreState } from 'easy-peasy';
import { grey } from '@material-ui/core/colors';
import TextField from '@material-ui/core/TextField';
import React, { useState, useEffect, useRef } from 'react';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import '../../../styles.css'

const tipos = [
    { value: 'ENTREGA', label: 'ENTREGA' },
    { value: 'COLETA', label: 'COLETA' },
];

// https://material-ui-pickers.dev/guides/css-overrides
const materialTheme = createMuiTheme({
    palette: {
        primary: grey,
    },
});

export default function BasicInfo(props) {
    const handleChange = (event) => {
        props.setValues({
            ...props.values,
            [event.target.id]: event.target.value,
        });
    };

    const plateChange = (event) => {
        console.log(docs.current)
        var index = docs.current.findIndex(x => JSON.stringify(x._id) === JSON.stringify(props.values._id))
        docs.current[index]["TripId"] = event.target.value
        props.setValues({
            ...props.values,
            [event.target.id]: event.target.value,
        });
    };

    const docs_ = useRef()
    docs_.current = useStoreState((state) => state.VeicsDocsStore.documents);
    const my_vehicles = useRef()
    my_vehicles.current = useStoreState((state) => state.VeicsDocsStore.my_vehicles);
    const docs = React.useRef()

    const [plates, setPlates] = useState([
        { value: '', label: '' },
    ])

    const [orders, setOrders] = useState([
        { value: '', label: '' },
    ])

    // cria os labels para as placas
    useEffect(() => {
        docs.current = JSON.parse(JSON.stringify(docs_.current))
        var aux = [{ value: '', label: '' },]
        for (let veic of my_vehicles.current) {
            aux.push({ value: veic._id, label: veic.placa })
        }
        setPlates(aux)
    }, [])

    var { TripId } = props.values
    useEffect(() => {
        if (TripId !== "") {
            var aux = [{ value: "", label: "" },]
            let docs_plate = docs.current.filter(x => JSON.stringify(x.TripId) === JSON.stringify(TripId)); // docs associados a placa
            for (let i = 1; i < docs_plate.length + 1; i++) {
                aux.push({ value: i.toString(), label: i.toString() })
            }
            setOrders(aux)
        }

        else if (TripId === "" && props.values.ordem !== "") { // remove
            props.setValues({
                ...props.values,
                ["ordem"]: "",
            });
        }
    }, [props])

    return (
        <>
            <div className="title_info4">Informações Gerais</div>
            <div className="fantasia">
                <div className="space5doc">
                    <div className="rowDoc">
                        <TextField
                            disabled={props.update === false ? true : false}
                            select
                            id="TripId"
                            size="small"
                            label="Placa"
                            variant="outlined"
                            onChange={plateChange}
                            value={props.values.TripId}
                            SelectProps={{ native: true }}
                        >
                            {plates.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </TextField>

                        <TextField
                            select
                            id="ordem"
                            size="small"
                            label="Ordem"
                            variant="outlined"
                            onChange={handleChange}
                            value={props.values.ordem}
                            SelectProps={{ native: true }}
                            disabled={props.values.TripId === "" || !props.values.TripId ? true : false}
                        >
                            {orders.map((option) => (
                                <option key={option.value} >
                                    {option.label}
                                </option>
                            ))}
                        </TextField>

                        <div className="docfilial">
                            <TextField
                                disabled={props.update}
                                required
                                autoFocus
                                type="text"
                                margin="dense"
                                id="documento"
                                variant="outlined"
                                label="Documento"
                                value={props.values.documento}
                                onChange={handleChange}
                                error={(
                                    props.values.documento === "" &&
                                    props.update === true)
                                    ? true : false}
                                helperText={(
                                    props.values.documento === "" &&
                                    props.update === true)
                                    ? "informe o documento" : ""}
                            />
                        </div>

                        <TextField
                            required
                            autoFocus
                            type="number"
                            margin="dense"
                            id="peso_kg"
                            variant="outlined"
                            label="Peso Kg"
                            onChange={handleChange}
                            value={props.values.peso_kg}
                            error={
                                !props.values.peso_kg ? false :
                                    props.values.peso_kg <= 0 ?
                                        true : false}
                            helperText={
                                !props.values.peso_kg ? "" :
                                    props.values.peso_kg <= 0 ?
                                        "peso inválido" : ""}
                        />

                        <TextField
                            required
                            autoFocus
                            type="number"
                            margin="dense"
                            id="valor_reais"
                            variant="outlined"
                            label="Valor (reais)"
                            onChange={handleChange}
                            value={props.values.valor_reais}
                            error={
                                !props.values.valor_reais ? false :
                                    props.values.valor_reais <= 0 ?
                                        true : false}
                            helperText={
                                !props.values.valor_reais ? "" :
                                    props.values.valor_reais <= 0 ?
                                        "valor em reais inválido" : ""}
                        />

                        <TextField
                            required
                            select
                            id="tipo"
                            size="small"
                            label="Tipo"
                            variant="outlined"
                            onChange={handleChange}
                            value={props.values.tipo}
                            SelectProps={{ native: true }}
                        >
                            {tipos.map((option) => (
                                <option key={option.value} >
                                    {option.label}
                                </option>
                            ))}
                        </TextField>
                        <TextField
                            required
                            disabled
                            autoFocus
                            type="text"
                            id="filial"
                            label="Filial"
                            margin="dense"
                            variant="outlined"
                            value={localStorage.getItem("nome_filial")}
                        />
                    </div>
                </div>

                <div className="row_b">
                    <div className="spaceData">
                        <ThemeProvider theme={materialTheme}>
                            <KeyboardDateTimePicker
                                required
                                disabled
                                size="small"
                                ampm={false}
                                label="Data da Programação"
                                format="dd/MM/yyyy"
                                value={props.values.dataProgr}
                                onError={console.log}
                                inputVariant="outlined"
                            />
                        </ThemeProvider>
                    </div>
                    <div className="spaceData">
                        <ThemeProvider theme={materialTheme}>
                            <KeyboardDateTimePicker
                                required
                                size="small"
                                ampm={false}
                                label="Prazo"
                                inputVariant="outlined"
                                format="dd/MM/yyyy HH:mm"
                                value={props.values.prazo}
                                onChange={date => {
                                    props.setValues({
                                        ...props.values,
                                        ["prazo"]: date,
                                    });
                                }}
                                onError={console.log}
                            />
                        </ThemeProvider>
                    </div>
                    <div className="spaceSocial">
                        <TextField
                            required
                            autoFocus
                            type="text"
                            margin="dense"
                            id="razao_social"
                            variant="outlined"
                            label="Razão Social"
                            onChange={handleChange}
                            value={props.values.razao_social}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

BasicInfo.propTypes = {
    update: PropTypes.bool,
    values: PropTypes.object,
    setValues: PropTypes.func,
    dataProgr: PropTypes.string,
};
