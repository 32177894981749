import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useRef, useEffect } from 'react';
import { Button, DialogActions, DialogContent } from '@material-ui/core';

import Adress from './Adress.js';
import MyMap from './MyMap/MyMap.js';
import Contacts from './Contacts.js';
import BasicInfo from './BasicInfo.js';
import CreatedBy from './CreatedBy.js';

import api from '../../../../../../../services/api.js';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
}));

export default function FormStore(props) {
    const classes = useStyles();
    const snack = useRef()
    const { enqueueSnackbar } = useSnackbar();
    snack.current = enqueueSnackbar

    const [values, setValues] = useState({
        nome_fantasia: '',
        razao_social: '',
        nome_filial: '',
        cnpj: '',
        status: '',
        cep: '',
        logradouro: '',
        numero: '',
        complemento: '',
        bairro: '',
        cidade: '',
        estado: '',
        pais: '',
        lat: '',
        lng: '',
        fuso_horario: '',
        criado_por: '',
        criado_em: '',
        editado_por: '',
        editado_em: '',
        contatos: [],
    });

    const [update, setUpdate] = useState(false) // true in edit mode
    const my_props = useRef(props)
    useEffect(() => {
        if (my_props.current.data) {
            setValues(my_props.current.data)
            setUpdate(true)
        }
    }, [])

    async function AddNew() {
        snack.current("Adicionando, aguarde.", { variant: 'info' })
        var new_affiliate = {
            'companyId': localStorage.getItem("companyId"),
            'nome_filial': values.nome_filial,
            'cnpj': values.cnpj,
            'nome_fantasia': values.nome_fantasia,
            'razao_social': values.razao_social,
            'logradouro': values.logradouro,
            'complemento': values.complemento,
            'numero': values.numero,
            'bairro': values.bairro,
            'cidade': values.cidade,
            'pais': values.pais,
            'cep': values.cep,
            'estado': values.estado,
            'status': values.status,
            "fuso_horario": values.fuso_horario,
            'lat': Number(values.lat),
            'lng': Number(values.lng),
            'contatos': values.contatos,
        }
        api.post(`/register/affiliate`, { new_affiliate })
            .then(() => {
                props.setReload(currentIsOpen => !currentIsOpen)
                props.setOpen(false)
                snack.current("Filial adicionada!", { variant: 'success' })
            })
            .catch((error) => {
                if (error.response.data.message) {
                    snack.current(`${error.response.data.message}`, { variant: 'error' })
                } else {
                    snack.current("Falha ao adicionar a Filial!", { variant: 'error' })
                }
            });
    }

    async function Update() {
        snack.current("Editando, aguarde.", { variant: 'info' })
        var update_affiliate = {
            '_id': values._id,

            'nome_filial': values.nome_filial,
            'cnpj': values.cnpj,
            'nome_fantasia': values.nome_fantasia,
            'razao_social': values.razao_social,
            'logradouro': values.logradouro,
            'complemento': values.complemento,
            'numero': values.numero,
            'bairro': values.bairro,
            'cidade': values.cidade,
            'pais': values.pais,
            'cep': values.cep,
            'estado': values.estado,
            'status': values.status,
            "fuso_horario": values.fuso_horario,
            'lat': Number(values.lat),
            'lng': Number(values.lng),

            'contatos': values.contatos,
        }
        api.put(`/register/affiliate`, { update_affiliate })
            .then(() => {
                props.setReload(currentIsOpen => !currentIsOpen)
                props.setOpen(false)
                snack.current("Filial editada!", { variant: 'success' })
            })
            .catch((error) => {
                if (error.response.data.message) {
                    snack.current(`${error.response.data.message}`, { variant: 'error' })
                } else {
                    snack.current("Falha ao editar a Filial!", { variant: 'error' })
                }
            });
    }

    return (
        <>
            <DialogContent className={classes.root}>
                <BasicInfo values={values} setValues={setValues} />
                <Adress values={values} setValues={setValues} snack={snack.current} update={update} />
                <CreatedBy values={values} setValues={setValues} />
                <div className="row">
                    <MyMap values={values} />
                    <Contacts values={values} setValues={setValues} snack={snack.current} />
                </div>
            </DialogContent >
            <DialogActions>
                <Button
                    disabled={(
                        values.lat &&
                        values.lng &&
                        values.cep &&
                        values.cnpj &&
                        values.pais &&
                        values.status &&
                        values.bairro &&
                        values.cidade &&
                        values.estado &&
                        values.nome_filial &&
                        values.logradouro &&
                        values.razao_social &&
                        values.fuso_horario &&
                        values.nome_fantasia &&
                        values.contatos.length > 0 &&
                        values.lng < -33.79 &&
                        values.lng > -74.62 &&
                        values.lat < 5.53 &&
                        values.lat > -34.09
                    ) ? false : true}
                    onClick={() => { update === true ? Update() : AddNew() }}
                    color="primary"
                >
                    Salvar
                </Button>
                <Button onClick={() => { props.setOpen(false) }} color="primary"> Cancelar </Button>
            </DialogActions>
        </>
    )
}

FormStore.propTypes = {
    setOpen: PropTypes.func,
    setReload: PropTypes.func
};
