import React, { Fragment } from "react";
import { useStoreState } from "easy-peasy"
import { makeStyles } from "@material-ui/core/styles";
import { Dialog, DialogTitle, FormControl, DialogContent } from "@material-ui/core";

import FormRationing from "./FormRationing"

const useStyles = makeStyles((theme) => ({
    form: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        width: "fit-content",
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
}));

export default function Form() {
    const classes = useStyles();
    const open = useStoreState((state) => state.rationingStore.open_store_form);

    return (
        <Fragment>
            <Dialog
                fullWidth={true}
                maxWidth={"md"}
                open={open}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="max-width-dialog-title">Cadastro de Rodízios</DialogTitle>
                <DialogContent>
                    <form className={classes.form} noValidate>
                        <FormControl className={classes.formControl}>
                            <FormRationing />
                        </FormControl>
                    </form>
                </DialogContent>
            </Dialog>
        </Fragment>
    );
}
