import React, { useEffect, useState } from "react";
import { Button, Tooltip } from "@material-ui/core";
import SwapCalls from '@material-ui/icons/SwapCalls';
import { useStoreActions, useStoreState } from "easy-peasy";

export default function Ordenate() {
    // cria todas as variaveis necessárias para a alocação
    const setOrder = useStoreActions(actions => {
        return actions.toOrderStore.setOrder;
    });
    const my_vehicles = useStoreState((state) => state.VeicsDocsStore.my_vehicles);
    const documents = useStoreState((state) => state.VeicsDocsStore.documents);

    const [disabled, setDisabled] = useState(true)
    useEffect(() => {
        var flag = false
        if (!my_vehicles || !documents) {
            setDisabled(true)
        } else if (my_vehicles.length === 0 || documents.length === 0) {
            setDisabled(true)
        }
        else {
            for (let trip of my_vehicles) {
                // se tiver documento associado e for valido
                if (trip.n_docs > 0 && trip.valido) {
                    setDisabled(false)
                    flag = true
                }
            }
        }
        if (flag === false) {
            setDisabled(true)
        }
    }, [my_vehicles, documents])

    return (
        <Tooltip title={
            <span style={{ fontSize: 15 }}>
                Ordena os documentos de um determinado veículo.
            </span>} >
            <Button
                component="span"
                disabled={disabled}
                onClick={() => {
                    setOrder({
                        documents,
                        my_vehicles,
                        open_order: true,
                        type: "ordenar_por_distancia",
                    })
                }}
            >
                <SwapCalls
                    fontSize={'large'}
                    color={disabled ? 'disabled' : 'primary'}
                />
            </Button>
        </Tooltip>
    );
}
