import React from "react";
import PropTypes from 'prop-types';
import { Popup } from "react-leaflet";
import Icon from "@material-ui/core/Icon";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

export default function PopupDocumentoResult(props) {
    return (
        <Popup>
            <h2> {props.doc.tipo} </h2>
            {props.doc.restri_porte === "sim" ?
                <p className="obs">
                    <Icon>block</Icon> &quot;Existe restrição de Porte&quot;
                </p>
                : null
            }
            {props.doc.restri_placa === "sim" ?
                <p className="obs">
                    <Icon>block</Icon> &quot;Existe restrição de Rodízio&quot;
                </p>
                : null
            }
            {props.doc.parada_almoco ?
                <p className="obs">
                    <Icon>restaurant</Icon> &quot;Parada para Almoço: 01:00:00&quot;
                </p>
                : null
            }
            {props.doc.flag_travamento_ordem === "usuario" ?
                <p className="obs">
                    <Icon>lock</Icon> &quot;Documento travado pelo usuário&quot;
                </p>
                : null
            }
            <Table size="small">
                <TableBody>
                    <TableRow style={{ width: '100%' }}>
                        <TableCell>Documento</TableCell>
                        <TableCell style={{ whiteSpace: "normal", wordWrap: "break-word" }}>{props.doc.documento}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell>Peso Mercadoria (kg)</TableCell>
                        <TableCell style={{ whiteSpace: "normal", wordWrap: "break-word" }}>{props.doc.peso_kg}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Ocupação (kg)</TableCell>
                        <TableCell style={{ whiteSpace: "normal", wordWrap: "break-word" }}>{props.doc.taxa_ocup_kg}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Taxa de ocupação</TableCell>
                        <TableCell style={{ whiteSpace: "normal", wordWrap: "break-word" }}>{props.doc.taxa_ocup}%</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Horário chegada ao cliente</TableCell>
                        <TableCell style={{ whiteSpace: "normal", wordWrap: "break-word" }}>{props.doc.horario_chegada_cli_format}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Tempo carga/descarga</TableCell>
                        <TableCell style={{ whiteSpace: "normal", wordWrap: "break-word" }}>{props.doc.tempo_carg_desc_format}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Placa</TableCell>
                        <TableCell >{props.placa}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Popup>
    );
}

PopupDocumentoResult.propTypes = {
    doc: PropTypes.object,
    placa: PropTypes.string,
};
