import Form from "./Components/Form";
import MyMap from "./Components/MyMap";
import Table from "./Components/Table";

import React from "react";
import { Paper, Container } from "@material-ui/core";

export default function RationingRestrictions() {
    return (
        <Container maxWidth={false} spacing={2}>
            <Paper elevation={2}>
                <MyMap />
            </Paper>
            < Form />
            < Table />
        </Container>
    );
}
