import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
    Tab,
    Tabs,
    Box,
    Typography
} from '@material-ui/core';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={4} padding={0} >
                    <Typography component={'span'} >{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const tabStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    inactiveTab: {
        fontSize: props => props.fontSize,
    },
    customStyleOnActiveTab: {
        color: 'blue'
    },
    activeTab: {
        fontSize: props => props.fontSize,
        fontWeight: '600',
    },
    paper: {
        top: 30,
    },
}));

const AntTabs = withStyles({
    root: {
        borderBottom: "1px solid #e8e8e8"
    },
    indicator: {
        backgroundColor: "#3A54AD"
    }
})(Tabs);

const AntTab = withStyles((theme) => ({
    root: {
        textTransform: "none",
        minWidth: 80,
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(4),
        fontFamily: ["Roboto", '"Helvetica Neue"', "Arial", "sans-serif"].join(","),
        "&:hover": {
            color: "#3A54AD",
            opacity: 1
        },
        "&$selected": {
            color: "#3A54AD",
            fontWeight: "bold",
            backgroundColor: "#d9d9d9"
        },
        "&:focus": {
            color: "#3A54AD"
        }
    },
    selected: {}
}))((props) => <Tab disableRipple {...props} />);

export {
    AntTab,
    AntTabs,
    TabPanel,
    tabStyles,
}