import List from '@material-ui/core/List';
import { useStoreActions } from "easy-peasy";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItem from '@material-ui/core/ListItem';
import DialogTitle from '@material-ui/core/DialogTitle';
import ListItemText from '@material-ui/core/ListItemText';
import React, { useState, useEffect, useRef } from 'react';

import api from "../../../services/api.js"

export default function SelectAffiliate() {
    const [open, setOpen] = useState(false);
    const [affiliates, setAffiliates] = useState([{
        label: '',
        value: ''
    }]);

    //ativa e desativa o loading abaixo da appbar
    const setLoading = useRef()
    setLoading.current = useStoreActions(actions => {
        return actions.globalStore.setLoading;
    });

    // reload na tabela ao trocar a filial
    const setReload = useStoreActions(actions => {
        return actions.globalStore.setReload;
    });

    useEffect(() => {
        async function fetchData() {
            setLoading.current(true)
            api.get(`/register/affiliate`)
                .then((res) => {
                    var affiliates = [{
                        label: '',
                        value: ''
                    }]
                    if (res.data) {
                        for (let affiliate of res.data) {
                            let aux = {
                                label: affiliate.nome_filial,
                                value: affiliate._id
                            }
                            affiliates.push(aux)
                        }
                        setAffiliates(affiliates)
                    }

                    setLoading.current(false)
                })
                .catch((error) => {
                    console.log(error);
                    setLoading.current(false)
                });
        }
        fetchData();
    }, []);

    async function Update(filial) {
        setLoading.current(true)
        api.put(`/utils/user/affiliate/${filial.value}`)
            .then(() => {
                localStorage.setItem("affiliateId", filial.value);
                localStorage.setItem("nome_filial", filial.label);
                setOpen(false)
                setLoading.current(false)
                setReload()
            })
            .catch((error) => {
                setOpen(false)
                setLoading.current(false)
                console.log(error)
            });
    }

    return (
        <div>
            <Button
                color="inherit"
                variant="outlined"
                onClick={() => setOpen(true)}
                style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    fontSize: 11 * window.innerWidth / 1280,
                }}
            >
                {localStorage.getItem("nome_filial")}
            </Button>
            <Dialog
                open={open}
                aria-labelledby="simple-dialog-title"
                onClose={() => { setOpen(false) }}
            >
                <DialogTitle id="simple-dialog-title">Selecione a Filial</DialogTitle>
                <List>
                    {affiliates.map((filial) => (
                        <ListItem
                            button
                            key={filial}
                            onClick={() => Update(filial)}
                        >
                            <ListItemText primary={filial.label} />
                        </ListItem>
                    ))}
                </List>
            </Dialog>
        </div >
    );
}