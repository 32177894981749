import { useSnackbar } from "notistack";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useStoreActions, useStoreState } from "easy-peasy"
import React, { useState, useRef, useEffect } from "react";
import { Button, TextField, DialogActions, DialogContent } from "@material-ui/core";

import Ufs from "../../../../../../utils/Ufs"
import api from "../../../../../../services/api";

export default function FormTools() {
    const snack = useRef()
    const { enqueueSnackbar } = useSnackbar();
    snack.current = enqueueSnackbar

    const setReloadToolsRef = useRef()
    setReloadToolsRef.current = useStoreActions(actions => {
        return actions.reloadStore.setReloadTools;
    });
    const setOpenStoreFormRef = useRef()
    setOpenStoreFormRef.current = useStoreActions(actions => {
        return actions.toolsStore.setOpenStoreForm;
    });

    const area = useStoreState((state) => state.toolsStore.area);

    const [nome, setNome] = useState("");
    const [auto, setAuto] = useState("");
    const [eixo, setEixo] = useState("");
    const [estado, setEstado] = useState("");
    const [rodovia, setRodovia] = useState("");
    const [concessionaria, setConcessionaria] = useState("");
    const [btn_atualizar_salvar, setBtnHabilitarSalvar] = useState(false);

    useEffect(() => { checkFields() });

    function checkFields() {
        if (auto < 0) {
            setBtnHabilitarSalvar(false);
            return;
        } else if (auto < 0) {
            setBtnHabilitarSalvar(false);
            return;
        } else {
            setBtnHabilitarSalvar(nome !== "" && concessionaria !== "" && rodovia !== "" && estado !== "" && auto !== "" && eixo !== "");
        }
    }

    async function setToll() {
        let add = {
            nome: nome,
            auto: Number(auto),
            eixo: Number(eixo),
            area: area,
            estado: estado,
            center: area[0],
            rodovia: rodovia,
            concessionaria: concessionaria,
        }
        api.post(`/admin/tolls`, { add })
            .then(() => {
                snack.current(`Adicionada com sucesso!`, { variant: 'success' })
                setReloadToolsRef.current();
                setOpenStoreFormRef.current();
            })
            .catch((error) => {
                console.log(error);
                snack.current(`Falha ao adicionar!`, { variant: 'success' })
            });
    }

    return (
        <>
            <DialogContent>
                <TextField
                    id="nome"
                    fullWidth
                    autoFocus
                    type="text"
                    margin="dense"
                    label="Nome da Praça"
                    onChange={(event) => setNome(event.target.value)}
                />

                <TextField
                    fullWidth
                    autoFocus
                    type="text"
                    margin="dense"
                    id="concessionaria"
                    label="Concessionária"
                    onChange={(event) => setConcessionaria(event.target.value)}
                />

                <TextField
                    fullWidth
                    autoFocus
                    id="rodovia"
                    margin="dense"
                    type="rodovia"
                    label="Nome da Rodovia"
                    onChange={(event) => setRodovia(event.target.value)}
                />

                <TextField
                    id="auto"
                    autoFocus
                    fullWidth
                    type="number"
                    margin="dense"
                    label="Automóvel (R$)"
                    onChange={(event) => setAuto(event.target.value)}
                />

                <TextField
                    id="eixo"
                    autoFocus
                    fullWidth
                    type="number"
                    margin="dense"
                    label="Eixo (R$)"
                    onChange={(event) => setEixo(event.target.value)}
                />

                <Autocomplete
                    fullWidth
                    id="estado_"
                    options={Ufs}
                    getOptionLabel={(option) => option.title}
                    onChange={(_, data) => setEstado(data.value)}
                    renderInput={(params) => <TextField {...params} label="Estado" margin="dense" />}
                />

                <TextField
                    autoFocus
                    fullWidth
                    margin="dense"
                    variant="filled"
                    value={JSON.stringify(area)}
                    label="latitudes e longitudes"
                    InputProps={{ readOnly: true }}
                />
            </DialogContent>
            <DialogActions>
                <Button disabled={!btn_atualizar_salvar} onClick={() => setToll()} color="primary">
                    Salvar
                </Button>
                <Button onClick={() => setOpenStoreFormRef.current(false)} color="primary">
                    Cancelar
                </Button>
            </DialogActions>
        </>
    )
}
