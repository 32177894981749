import PropTypes from 'prop-types';
import { useSnackbar } from "notistack";
import { useStoreActions } from "easy-peasy";
import React, { useRef, Fragment } from "react";
import DeleteIcon from '@material-ui/icons/Delete';
import { Tooltip, Button } from "@material-ui/core";

import api from "../../../../../../../services/api.js";

export default function DeleteVeic(props) {
    const snack = useRef()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    snack.current = enqueueSnackbar
    const closeSnackbarRef = useRef()
    closeSnackbarRef.current = closeSnackbar

    // atualiza a tabela de documentos
    const setReload = useStoreActions(actions => {
        return actions.VeicsDocsStore.setReload;
    });

    // atualiza aba viagens
    const setReloadResults = useStoreActions(actions => {
        return actions.ResultStore.setReload;
    });

    const plotVehiclesResults = useStoreActions(actions => {
        return actions.ResultStore.plotVehiclesResults;
    });

    //ativa e desativa o loading abaixo da appbar
    const setLoading = useStoreActions(actions => {
        return actions.globalStore.setLoading;
    });

    async function DeleteVeic_(key) {
        closeSnackbarRef.current(key)
        snack.current(`Excluindo viagem, aguarde.`, { variant: 'success' })
        setLoading(true)
        api.delete(`/trips/analyzes/${props.veic._id}`)
            .then(() => {
                snack.current(`Viagem excluída`, { variant: 'success' })
                plotVehiclesResults([])
                setReload()
                setReloadResults()
                setLoading(false)
            })
            .catch((error) => {
                console.log(error)
                snack.current(`Falha ao excluir veículo!`, { variant: 'error' })
                plotVehiclesResults([])
                setLoading(false)
            })
    }

    const action = key => (
        <Fragment>
            <Button onClick={() => { DeleteVeic_(key) }}>
                Sim
            </Button>
            <Button onClick={() => { closeSnackbarRef.current(key) }}>
                Não
            </Button>
        </Fragment>
    );

    function confirmationWindow(placa) {
        if (props.veic.roteirizada) {
            snack.current(`Não é possível excluir uma viagem aprovada, cancele a aprovação para excluir.`, { variant: 'info' })
        } else {
            snack.current(`Deseja remover a viagem ${placa}?`, {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                variant: 'info',
                persist: true,
                preventDuplicate: true,
                action,
            })
        }
    }

    return (
        <Tooltip title={<span style={{ fontSize: 15 }}> Excluir </span>} >
            <Button
                onClick={() => {
                    confirmationWindow(props.veic.placa)
                }}
                color="primary"
                style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px' }}
            >
                <DeleteIcon fontSize="small" />
            </Button>
        </Tooltip>
    );
}

DeleteVeic.propTypes = {
    veic: PropTypes.object,
};
