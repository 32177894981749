import React, { useState } from 'react';

import MyTable from './Components/MyTable';
import SelectDate from './Components/SelectDate.js';

export default function UserStatistics() {
    const [end, setEnd] = useState(new Date());
    const [start, setStart] = useState(new Date());

    return (
        <>
            <SelectDate end={end} setEnd={setEnd} start={start} setStart={setStart} />
            < MyTable end={end} start={start} />
        </>
    );
}
