import React from "react";
import MaterialTable from "material-table";
import { useStoreState, useStoreActions } from "easy-peasy";

import ImportVehicles from "./ImportVehicles.js"

export default function VehicleTable() {
    const state = {
        columns: [
            { title: "Placa", field: "placa" },
            { title: "Status Error", field: "status_error" },
            { title: "Capacidade (kg)", field: "capacidade_kg", type: "numeric" },
            { title: "Vínculo", field: "vinculo" },
            { title: "Altura (m)", field: "altura", type: "numeric" },
            { title: "Comprimento (m)", field: "comprimento", type: "numeric" },
            { title: "Número de eixos", field: "n_eixos", type: "numeric" },
            { title: "Valor da diária", field: "valor_diaria", type: "numeric" },
            { title: "Valor do km", field: "valor_km", type: "numeric" },
        ],
    };

    const veics_available = useStoreState((state) => state.ImportVehiclesStore.veics_available);
    const setVehicles = useStoreActions(actions => {
        return actions.ImportVehiclesStore.setVehicles;
    });

    const tableRef = React.useRef();
    return (
        <MaterialTable
            tableRef={tableRef}
            title="Importação de Veículos (criar viagens)"
            columns={state.columns}
            data={veics_available.map(rowData => {
                rowData.tableData = { ...rowData.tableData, disabled: rowData.status_error !== '' };
                return rowData;
            })}
            options={{
                add: true,
                pageSize: 10,
                search: true,
                grouping: true,
                selection: true,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [1, 5, 10, 20, veics_available.length],
                padding: "dense",
                headerStyle: {
                    backgroundColor: "#01579b",
                    textAlign: 'center',
                    color: "#FFF",
                    fontSize: 11 * window.innerWidth / 1280,
                },
                cellStyle: {
                    textAlign: "center",
                    fontSize: 11 * window.innerWidth / 1280,
                },
                selectionProps: rowData => ({ disabled: rowData.tableData.disabled }),
                rowStyle: rowData => ({
                    backgroundColor: (rowData.status_error === "" ? '#ebfaeb' : "#ffcccc")
                }),
            }}
            onSelectionChange={(rows) => { setVehicles(rows) }}
            localization={{
                body: {
                    addTooltip: "Adicionar novo",
                    deleteTooltip: "Excluir",
                    editTooltip: "Editar",
                    editRow: {
                        deleteText: "Deseja excluir o veículo?",
                        cancelTooltip: "Cancelar",
                        saveTooltip: "Salvar",
                    },
                    emptyDataSourceMessage: "",
                    bulkEditTooltip: "Editar todos",
                    bulkEditCancel: "Descartar edições",
                    bulkEditApprove: "Salvar todas as edições"
                },

                grouping: {
                    placeholder: "Arraste os cabeçalhos aqui para agrupar por",
                    groupedBy: "Agrupado por:",
                },

                header: {
                    actions: "Opções",
                },

                toolbar: {
                    searchTooltip: "Pesquisar",
                    searchPlaceholder: "Pesquisar",
                    exportTitle: "Exportar",
                    nRowsSelected: "{0} veículos(s) selecionados",
                },
                pagination: {
                    labelRowsSelect: "",
                    labelDisplayedRows: "{count} de {from}-{to}",
                    firstTooltip: "Primeira página",
                    previousTooltip: "Página anterior",
                    nextTooltip: "Próxima página",
                    lastTooltip: "Última página",
                },
            }}
            components={{
                Actions: () => {
                    return (
                        <ImportVehicles />
                    );
                }
            }}
        />
    );
}
