import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useStoreActions } from 'easy-peasy';
import React, { useRef, Fragment } from 'react';
import GridOn from '@material-ui/icons/GridOn';
import GridOff from '@material-ui/icons/GridOff';
import { Tooltip, Button, Fab } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import api from '../services/api.js'

export default function EditMany(props) {
    const {
        type, // delete, habilitar, desabilitar
        route, // route para excluir itens
        setSelect, // função que define os itens a serem excluidos
        setReload, // atualiza a pagina após a exclusão
        select_itens, // lista com os itens a serem excluidos
    } = props

    const snack = useRef()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    snack.current = enqueueSnackbar
    const closeSnackbarRef = useRef()
    closeSnackbarRef.current = closeSnackbar

    //ativa e desativa o loading abaixo da appbar
    const setLoading = useRef()
    setLoading.current = useStoreActions(actions => { return actions.globalStore.setLoading });

    async function HandleChange(key) {
        closeSnackbarRef.current(key)
        snack.current("Aguarde.", { variant: 'info' })
        setLoading.current(true)

        var list_id = []
        for (let veic of select_itens) {
            list_id.push(veic._id)
        }

        api.put(route, {
            type: type,
            select_ids: list_id,
        })
            .then(() => {
                setReload(currentIsOpen => !currentIsOpen)
                setSelect([])
                if (type === "EXCLUIR") snack.current(`Excluído com sucesso`, { variant: 'success' })
                else snack.current(`Editados com sucesso`, { variant: 'success' })
            })
            .catch((error) => {
                console.log(error);
                setSelect([])
                setLoading.current(false)
                if (type === "EXCLUIR") snack.current(`Falha ao excluir`, { variant: 'error' })
                else snack.current(`Falha ao editar`, { variant: 'error' })
            })
    }

    const action = key => (
        <Fragment>
            <Button onClick={() => { HandleChange(key) }}>
                Sim
            </Button>
            <Button onClick={() => { closeSnackbarRef.current(key) }}>
                Não
            </Button>
        </Fragment>
    );

    function confirmationWindow() {
        snack.current(`Deseja ${type} os itens selecionados?`, {
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
            },
            variant: 'info',
            persist: true,
            preventDuplicate: true,
            action,
        })
    }

    return (
        <Tooltip title={<span style={{ fontSize: 15 }}> {type} </span>}>
            <Fab
                color="primary"
                aria-label="add"
                style={{
                    margin: 10,
                    maxWidth: 45,
                    minWidth: 45,
                    maxHeight: 45,
                    minHeight: 45,
                }}
                onClick={() => { confirmationWindow() }}
                disabled={select_itens.length === 0 ? true : false}
            >
                {type === "ATIVAR" ? <GridOn /> : null}
                {type === "INATIVAR" ? <GridOff /> : null}
                {type === "EXCLUIR" ? <DeleteForeverIcon /> : null}
            </Fab>
        </Tooltip>
    );
}

EditMany.propTypes = {
    type: PropTypes.string,
    setReload: PropTypes.func,
    setSelect: PropTypes.func,
    desabilitar: PropTypes.bool,
    select_itens: PropTypes.array,
};
