import React from "react";
import PropTypes from 'prop-types';
import { Popup } from "react-leaflet";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

export default function PopupEmpresa(props) {
    return (
        <Popup>
            <h2> Empresa: {props.values.nome_fantasia}</h2>
            <Table size="small">
                <TableBody>
                    <TableRow>
                        <TableCell>Lat, Lon</TableCell>
                        <TableCell>
                            {props.values.lat}, {props.values.lng}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Popup>
    );
}

PopupEmpresa.propTypes = {
    values: PropTypes.object,
};
