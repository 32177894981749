import React from 'react';

import UploadDrawer from './UploadDrawer/UploadDrawer.js';
import InvoiceDrawer from './InvoiceDrawer/InvoiceDrawer.js';
import InvoicesTable from './InvoicesTable/InvoicesTable.js';

export default function Invoices() {
    return (
        <>
            < InvoicesTable />
            < UploadDrawer />
            < InvoiceDrawer />
        </>
    );
}
