import React from 'react';
import Map from '@material-ui/icons/Map';
import { useStoreActions } from 'easy-peasy';
import { Fab, Tooltip } from '@material-ui/core';

export default function ButtonMap() {
    const setOpenMap = useStoreActions(actions => {
        return actions.TollsConferenceStore.setOpenMap;
    });

    return (
        <Tooltip title={<span style={{ fontSize: 15 }}>Mapa</span>}>
            <Fab
                color="primary"
                aria-label="add"
                style={{
                    margin: 10,
                    maxWidth: 45,
                    minWidth: 45,
                    maxHeight: 45,
                    minHeight: 45,
                }}
                onClick={() => {
                    setOpenMap("normal")
                }}
            >
                <Map />
            </Fab>
        </Tooltip >
    );
}
