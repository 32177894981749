import React, { useRef } from "react";
import { useSnackbar } from "notistack";
import { useStoreActions } from 'easy-peasy';
import { Tooltip, Button } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

import "../../../styles.css";
import api from "../../../../../../../services/api.js";

export default function Upload() {
    const snack = useRef()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    snack.current = enqueueSnackbar
    const closeSnackbarRef = useRef()
    closeSnackbarRef.current = closeSnackbar

    //ativa e desativa o loading abaixo da appbar
    const setLoading = useRef()
    setLoading.current = useStoreActions(actions => {
        return actions.globalStore.setLoading;
    });

    const setReload = useRef()
    setReload.current = useStoreActions(actions => {
        return actions.VeicsDocsStore.setReload;
    });

    async function handleSubmit(event) {
        event.preventDefault();
        snack.current("Iniciando o carregamento, aguarde.", { variant: 'info' })
        setLoading.current(true)
        const data = new FormData();
        console.log(event.target.files[0])
        data.append("thumbnail", event.target.files[0]);
        data.append("dataProgr", localStorage.getItem("dataProgr"));
        api.post(`/progr/docs`, data)
            .then((res) => {
                // atualiza os documentos apos a adição
                setReload.current()
                setLoading.current(false)
                snack.current(res.data.message, { variant: 'success' })
            })
            .catch((error) => {
                console.log(error);
                setReload.current()
                setLoading.current(false)
                snack.current("Falha ao carregar os documentos!", { variant: 'error' })
            });
    }

    return (
        <form>
            <label htmlFor="fileee"> </label>
            <input
                multiple
                id="fileee"
                type="file"
                accept=".xlsx, .xls, .ods"
                onChange={(event) => handleSubmit(event)}
            />
            <label htmlFor="fileee">
                <Tooltip
                    title={<span style={{ fontSize: 15 }}> Importe a planilha com seus documentos(entregas/coletas). Formatos aceitos: .xls, .xlsx, .ods (excel, libreoffice) </span>}
                >
                    <Button
                        size="large"
                        color="inherit"
                        component="span"
                        startIcon={<CloudUploadIcon
                            style={{
                                maxWidth: 50,
                                maxHeight: 50,
                                minWidth: 50,
                                minHeight: 50,
                                marginRight: -10,
                                marginLeft: 10,
                                fontSize: 62,
                                color: "#2093d3"
                            }}
                        />}>
                    </Button>
                </Tooltip>
            </label>
        </form>
    );
}
