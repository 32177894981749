import L from "leaflet";
import { useStoreState } from "easy-peasy"
import ReactDOMServer from "react-dom/server";
import { Polyline, Marker } from "react-leaflet";
import React, { useEffect, useState } from "react";
import MarkerClusterGroup from "react-leaflet-markercluster";

import "../../styles.css";
import IconRestr from "../../../../../../icons/Restricoes";
import PopupRestricaoRodizio from "../../../../../../popups/RestricaoRodizio";

export default function MapRationing() {
    const [restricao_rodizio, setRestricoesRodizio] = useState([]);

    const rationing = useStoreState((state) => state.rationingStore.rationing);

    useEffect(() => {
        function fetchData() {
            var restr_rodizio = rationing.map((data, index) => (
                <div key={index}>
                    {(() => {
                        var paths_latlon = data.area;
                        var my_icon = L.divIcon({
                            className: "custom-icon",
                            html: ReactDOMServer.renderToString(<IconRestr color={"purple"} tipo={"restricao_rodizio"} />),
                        });
                        return (
                            <Marker key={index} icon={my_icon} position={[data.center[0].lat, data.center[0].lng]}>
                                <Polyline color={"purple"} positions={paths_latlon} />
                                <PopupRestricaoRodizio data={data} />
                            </Marker>
                        );
                    })()}
                </div>
            ));
            setRestricoesRodizio(restr_rodizio);
        }
        fetchData();
    }, [rationing]);

    return (
        <div>
            <MarkerClusterGroup className={"markercluster-maprestr"} id={0}>
                {restricao_rodizio}
            </MarkerClusterGroup>
        </div>
    );
}
