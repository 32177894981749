import { useSnackbar } from 'notistack';
import MaterialTable from 'material-table';
import React, { useEffect, useRef, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';

import api from '../../../../../../services/api.js';

export default function IncorrectCategory() {
    const snack = useRef()
    const { enqueueSnackbar } = useSnackbar();
    snack.current = enqueueSnackbar

    //ativa e desativa o loading abaixo da appbar
    const setLoading = useRef()
    setLoading.current = useStoreActions(actions => {
        return actions.globalStore.setLoading;
    });

    const fatura = useStoreState((state) => state.InvoicesStore.fatura);

    const [values, setValues] = useState([])
    useEffect(() => {
        async function fetchData() {
            setLoading.current(true)
            api.get(`/invoice/incorrectcategory/${fatura._id}`)
                .then((resp) => {
                    if (resp.data.length === 0) {
                        snack.current("Não foram encontradas cobranças por categoria incorreta.", { variant: 'info' })
                    } else {
                        snack.current(`Total de cobranças incorretas: ${resp.data.length}`, { variant: 'success' })
                        var aux = []
                        for (let item of resp.data) {
                            let { invoiceTollId } = item
                            invoiceTollId["packageDate"] = new Date(invoiceTollId["packageDate"]).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })

                            invoiceTollId["informed_category"] = item["informed_category"]
                            aux.push(invoiceTollId)
                        }
                        setValues(aux)
                    }
                    setLoading.current(false)
                })
                .catch((error) => {
                    setLoading.current(false)
                    console.log(error);
                });
        }
        fetchData()
    }, [])

    const state = {
        columns: [
            { title: "Placa", field: "placa" },
            { title: "tag", field: "tag" },
            { title: "Nome", field: "address" },
            { title: "Categoria Cobrada", field: "categoria" },
            { title: "Categoria Informada", field: "informed_category" },
            { title: "Custo R$", field: "cost" },
            { title: "Concessionária", field: "concession" },
            { title: "Hora", field: "packageDate" },
        ],
    };

    const tableRef = useRef();
    return (
        <MaterialTable
            tableRef={tableRef}
            title="Pedágios com Cobrança de Categoria Incorreta"
            columns={state.columns}
            data={values}
            options={{
                add: true,
                search: true,
                pageSize: 5,
                grouping: true,
                selection: false,
                exportButton: false,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [1, 5, 10, 20],
                padding: "dense",
                headerStyle: {
                    backgroundColor: "#01579b",
                    textAlign: 'center',
                    color: "#FFF",
                    fontSize: 11 * window.innerWidth / 1280,
                },
                cellStyle: {
                    textAlign: "center",
                    fontSize: 11 * window.innerWidth / 1280,
                },
            }}
            localization={{
                body: {
                    addTooltip: "Adicionar novo",
                    deleteTooltip: "Excluir",
                    editTooltip: "Editar",
                    editRow: {
                        deleteText: "Deseja excluir?",
                        cancelTooltip: "Cancelar",
                        saveTooltip: "Salvar",
                    },
                    emptyDataSourceMessage: (
                        ""
                    ),
                    bulkEditTooltip: "Editar todos",
                    bulkEditCancel: "Descartar edições",
                    bulkEditApprove: "Salvar todas as edições"
                },

                grouping: {
                    placeholder: "Arraste os cabeçalhos aqui para agrupar por",
                    groupedBy: "Agrupado por:",
                },

                header: {
                    actions: "Opções",
                },

                toolbar: {
                    searchTooltip: "Pesquisar",
                    searchPlaceholder: "Pesquisar",
                    exportTitle: "Exportar",
                    nRowsSelected: "{0} selecionados",
                },
                pagination: {
                    labelRowsSelect: "",
                    labelDisplayedRows: "{count} de {from}-{to}",
                    firstTooltip: "Primeira página",
                    previousTooltip: "Página anterior",
                    nextTooltip: "Próxima página",
                    lastTooltip: "Última página",
                },
            }}
        />
    );
}
