import PropTypes from 'prop-types';
import { useSnackbar } from "notistack";
import { useStoreActions } from "easy-peasy";
import React, { useRef, Fragment } from "react";
import CancelIcon from '@material-ui/icons/Cancel';
import { Tooltip, Button } from "@material-ui/core";
import SpellcheckIcon from '@material-ui/icons/Spellcheck';

import api from "../../../../../../../services/api.js";

export default function AproveTrip(props) {
    const snack = useRef()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    snack.current = enqueueSnackbar
    const closeSnackbarRef = useRef()
    closeSnackbarRef.current = closeSnackbar

    // atualiza aba viagens
    const setReloadResults = useStoreActions(actions => {
        return actions.ResultStore.setReload;
    });

    //ativa e desativa o loading abaixo da appbar
    const setLoading = useStoreActions(actions => {
        return actions.globalStore.setLoading;
    });

    async function AproveTrip_(key) {
        closeSnackbarRef.current(key)
        snack.current(`Em processamento, aguarde.`, { variant: 'info' })
        setLoading(true)
        var resp = "Viagem aprovada"
        var status = {
            "analisando": false,
            "roteirizada": true
        }
        // cancelar aprovação
        if (props.veic.roteirizada) {
            resp = "Aprovação removida"
            status = {
                "analisando": true,
                "roteirizada": false
            }
        }
        api.put(`/trips/analyzes/${props.veic._id}`,
            { status })
            .then(() => {
                snack.current(resp, { variant: 'success' })
                setReloadResults()
                setLoading(false)
            })
            .catch((error) => {
                console.log(error)
                snack.current(`Falha ao atualizar o status de aprovação.`, { variant: 'error' })
                setLoading(false)
            })
    }

    const action = key => (
        <Fragment>
            <Button onClick={() => { AproveTrip_(key) }}>
                Sim
            </Button>
            <Button onClick={() => { closeSnackbarRef.current(key) }}>
                Não
            </Button>
        </Fragment>
    );

    function confirmationWindow(placa) {
        if (props.veic.analisando) {
            snack.current(`Deseja aprovar a viagem ${placa}?`, {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                variant: 'info',
                persist: true,
                preventDuplicate: true,
                action,
            })
        } else {
            snack.current(`Deseja cancelar a aprovação da viagem ${placa}?`, {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                variant: 'info',
                persist: true,
                preventDuplicate: true,
                action,
            })
        }
    }

    return (
        <Button
            onClick={() => {
                confirmationWindow(props.veic.placa)
            }}
            color="primary"
            size="small"
        >
            {props.veic.analisando ? (
                <Tooltip title={<span style={{ fontSize: 15 }} > Aprovar Viagem </span>} >
                    <Button
                        style={{ color: 'green', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px' }}
                    >
                        <SpellcheckIcon />
                    </Button>
                </Tooltip>
            ) : (
                <Tooltip title={<span style={{ fontSize: 15 }}> Cancelar Aprovação </span>} >
                    <Button
                        style={{ color: 'red', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px' }}
                    >
                        <CancelIcon />
                    </Button>
                </Tooltip>
            )
            }
        </Button >
    );
}

AproveTrip.propTypes = {
    veic: PropTypes.object,
};
