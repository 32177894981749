import React from 'react';
import PropTypes from 'prop-types';
import Language from '@material-ui/icons/Language';
import { Tooltip, Button } from '@material-ui/core';

export default function IconGeo(props) {
    return (
        <>
            {props.doc.geocoder ? (
                <Tooltip title={<span style={{ fontSize: 15 }} > Geolocalizado pelo Optcargo </span>} >
                    <Button
                        style={{ color: 'green', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px' }}
                    >
                        <Language fontSize="small" />
                    </Button>
                </Tooltip>
            ) : (
                <Tooltip title={<span style={{ fontSize: 15 }}> Geolocalizado pelo usuário </span>} >
                    <Button
                        style={{ color: 'grey', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px' }}
                    >
                        <Language fontSize="small" />
                    </Button>
                </Tooltip>
            )
            }
        </>
    )
}

IconGeo.propTypes = {
    doc: PropTypes.array,
};
