import PropTypes from 'prop-types';
import { useSnackbar } from "notistack";
import MaterialTable from "material-table";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState, useRef } from "react";
import { Fab, Tooltip, Container, Button } from "@material-ui/core";

import "../../styles.css";
import api from "../../../../../../services/api";

const useStyles = makeStyles((theme) => ({
    fab: {
        position: "absolute",
        bottom: theme.spacing(4),
        right: theme.spacing(4),
    },
}));

export default function Table(props) {
  const tableRef = useRef();
  const classes = useStyles();
  const snack = useRef()
  const { enqueueSnackbar } = useSnackbar();
  snack.current = enqueueSnackbar

  const [companies, setCompanies] = useState([]);

  const state = {
    columns: [
      { title: "Empresas", field: "apelido" },
      { title: "Status", field: "status" },
      { title: "identificador", field: "_id" },
    ]
  };

  useEffect(() => {
    function fetchData() {
      api.get(`/admin/plan`)
        .then((res) => {
          var plans = [{
            label: '',
            value: ''
          }]
          for (let plan of res.data) {
            let aux = {
              label: plan.nome_plano,
              value: plan._id
            }
            plans.push(aux)
          }
          props.setPlans(plans)
        })
        .catch((error) => {
          console.log(error);
          snack.current("Falha ao buscar os empresas", { variant: 'error' })
        });

      api
        .get(`/admin/company`)
        .then((res) => {
          var companies = []
          for (let company of res.data) {
            let aux = {
              "_id": company._id,
              "lat": company.lat,
              "lng": company.lng,
              "cep": company.cep,
              "cnpj": company.cnpj,
              "pais": company.pais,
              "plano": company.planId.nome_plano,
              "status": company.status,
              "bairro": company.bairro,
              "cidade": company.cidade,
              "estado": company.estado,
              "numero": company.numero,
              "apelido": company.apelido,
              "contatos": company.contatos,
              "criado_em": company.criado_em,
              "criado_por": company.criado_por,
              "editado_em": company.editado_em,
              "logradouro": company.logradouro,
              "editado_por": company.editado_por,
              "complemento": company.complemento,
              "razao_social": company.razao_social,
              "nome_fantasia": company.nome_fantasia,
              "tempo_contrato": company.tempo_contrato,
              "webhook_url": company.webhook_url,
            }
            companies.push(aux)
          }
          setCompanies(companies);
        })
        .catch((error) => {
          console.log(error);
          snack.current("Falha ao buscar os empresas", { variant: 'error' })
        });
    }
    fetchData();
  }, [props.reload]);

  // // Checking if key exists in local storage
  // if (localStorage.getItem('table_empresas') === null) {
  //     // If key doesn't exists then store the actual column order in local storage
  //     localStorage.setItem(
  //         'table_empresas',
  //         JSON.stringify({
  //             0: { title: "Empresas", field: "apelido" },
  //             1: { title: "Status", field: "status" },
  //             2: { title: "identificador", field: "_id" },
  //         })
  //     );
  // }

  // let savedColumns = JSON.parse(localStorage.getItem('table_empresas'));
  // for (var columnIndex in savedColumns) {
  //     state.columns.push(savedColumns[columnIndex]);
  // }

  // function handleColumnDrag(sourceIndex, destinationIndex) {
  //     const sourceColumn = savedColumns[sourceIndex];
  //     const destinationColumn = savedColumns[destinationIndex];

  //     // Swapping the column order
  //     savedColumns[sourceIndex] = destinationColumn;
  //     savedColumns[destinationIndex] = sourceColumn;
  //     localStorage.setItem('table_empresas', JSON.stringify(savedColumns));
  // }

  return (
    <Container maxWidth={false} spacing={2}>
      <Tooltip title={<span>Inserir Nova Empresa</span>}>
        <Fab color="primary" aria-label="add" className={classes.fab} onClick={() => { props.setOpen(true); props.setData(null); }}>
          <AddIcon />
        </Fab>
      </Tooltip>
      <MaterialTable
        tableRef={tableRef}
        title="Empresas"
        columns={state.columns}
        data={companies}
    // onColumnDragged={handleColumnDrag}
        options={{
          add: true,
          search: true,
          pageSize: 10,
          exportButton: false,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [1, 5, 10, 20, companies.length],
          padding: "dense",
          headerStyle: {
            backgroundColor: "#01579b",
            textAlign: "center",
            color: "#FFF",
          },
          cellStyle: {
            textAlign: "center",
          },
          rowStyle: rowData => ({
            backgroundColor: (rowData.tableData.id % 2 === 0 ? '#fff' : '#f5f5f5')
          }),
        }}
        localization={{
          body: {
            emptyDataSourceMessage: "",
            addTooltip: "adicionar novo usuário",
            deleteTooltip: "excluir",
            editRow: { deleteText: "Deseja excluir o usuário?" },
          },

          header: {
            actions: "Opções",
          },

          toolbar: {
            searchTooltip: "Pesquisar",
            searchPlaceholder: "Pesquisar",
            exportTitle: "exportar",
            nRowsSelected: "{0} Usuários(s) selecionados",
          },
          pagination: {
            labelRowsSelect: "",
            labelDisplayedRows: "{count} de {from}-{to}",
            firstTooltip: "Primeira página",
            previousTooltip: "Página anterior",
            nextTooltip: "Próxima página",
            lastTooltip: "Última página",
          },
        }}
        components={{
          Action: props_ => (
            <Tooltip title={<span> Editar </span>} >
              <Button
                onClick={() => {
                  props.setData(props_.data)
                  props.setOpen(true)
                }}
                color="primary"
                size="small"
              >
                <EditIcon />
              </Button>
            </Tooltip>
          ),
        }}
        editable={{
          onRowDelete: (_) =>
            new Promise((resolve) => {
              setTimeout(() => {
                resolve();
              }, 1000);
            }),
        }}
      />
    </Container>
  );
}

Table.propTypes = {
  reload: PropTypes.bool,
  setData: PropTypes.func,
  setOpen: PropTypes.func,
  setPlans: PropTypes.func,
  setReload: PropTypes.func,
};
