import React from 'react';
import MyMap from './Map/MyMap.js';
import VehicleTable from './Veics/VehicleTable.js';

export default function TollsConference() {
    return (
        <>
            < VehicleTable />
            < MyMap />
        </>
    );
}
