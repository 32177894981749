import React from "react";
import { Paper, Container } from "@material-ui/core";

import Form from "./Components/Form";
import Table from "./Components/Table.js";
import MyMap from "./Components/MyMap/index.js";
import FormUpdate from "./Components/FormUpdate";
import DialogLinks from "./Components/DialogLinks.js"

export default function SizeRestrictions() {
    return (
        <>
            <Container maxWidth={false} spacing={2}>
                <Paper elevation={2}>
                    <MyMap />
                </Paper>
                < Table />
                < Form />
                < FormUpdate />
                < DialogLinks />
            </Container>
        </>
    );
}
