import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useRef, useEffect } from 'react';
import {
    Button, DialogActions, Dialog, DialogTitle, FormControl, DialogContent, Typography
} from '@material-ui/core';

import BodyInfo from './Components/BodyInfo';
import DocsInfo from './Components/DocsInfo';
import CreatedBy from './Components/CreatedBy';
import api from '../../../../../services/api.js';
import BasicInfo from './Components/BasicInfo.js';
import VehicleType from './Components/VehicleType';
import TechnologyInfo from './Components/TechnologyInfo';


const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    form: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        width: "fit-content",
    },
    formControl: {
        marginTop: theme.spacing(1),
        minWidth: 70,
    },
}));

export default function VehicleForm(props) {
    const classes = useStyles();
    const snack = useRef()
    const { enqueueSnackbar } = useSnackbar();
    snack.current = enqueueSnackbar

    const [values, setValues] = useState({
        placa: "",
        filial: "",
        status: "",
        marca: "",
        altura: "",
        n_eixos: "",
        vinculo: "",
        largura: "",
        valor_km: "",
        num_antt: "",
        tipo_eixo: "",
        categoria: "",
        combustivel: "",
        comprimento: "",
        codigo_frota: "",
        tipo_veiculo: "",
        tec_rastreio: "",
        valor_diaria: "",
        capacidade_kg: "",
        capacidade_m3: "",
        tipo_carroceria: "",
        id_tec_rastreio: "",
        altura_carroceria_m: "",
        largura_carroceria_m: "",
        comprimento_carroceria_m: "",

        ano_modelo: new Date(),
        ano_fabricacao: new Date(),
        vencimento_antt: new Date(),
        vencimento_crlv: new Date(),

        criado_em: "",
        criado_por: "",
        editado_em: "",
        editado_por: "",
    });

    const [update, setUpdate] = useState(false) // true in edit mode
    var { data } = props
    useEffect(() => {
        if (data) {
            setValues(data)
            setUpdate(true)
        }
    }, [data])

    async function AddNew() {
        snack.current("Adicionando, aguarde.", { variant: 'info' })
        let my_affiliate = props.affiliates.filter(x => x.label === values.filial)
        var veic = {
            "affiliateId": my_affiliate[0].value,
            'companyId': localStorage.getItem("companyId"),
            "id_veic": values.id_veic,

            "placa": values.placa,
            "marca": values.marca,
            "status": values.status,
            "vinculo": values.vinculo,
            "num_antt": values.num_antt,
            "tipo_eixo": values.tipo_eixo,
            "categoria": values.categoria,
            "ano_modelo": values.ano_modelo,
            "combustivel": values.combustivel,
            "tec_rastreio": values.tec_rastreio,
            "codigo_frota": values.codigo_frota,
            "tipo_veiculo": values.tipo_veiculo,
            "ano_fabricacao": values.ano_fabricacao,
            "tipo_carroceria": values.tipo_carroceria,
            "vencimento_crlv": values.vencimento_crlv,
            "vencimento_antt": values.vencimento_antt,
            "id_tec_rastreio": values.id_tec_rastreio,

            "altura": Number(values.altura),
            "n_eixos": Number(values.n_eixos),
            "largura": Number(values.largura),
            "valor_km": Number(values.valor_km),

            "comprimento": Number(values.comprimento),
            "valor_diaria": Number(values.valor_diaria),
            "capacidade_kg": Number(values.capacidade_kg),
            "capacidade_m3": Number(values.capacidade_m3),
            "altura_carroceria_m": Number(values.altura_carroceria_m),
            "largura_carroceria_m": Number(values.largura_carroceria_m),
            "comprimento_carroceria_m": Number(values.comprimento_carroceria_m),
        }
        api.post(`/register/vehicle`, { veic })
            .then(() => {
                props.setReload(currentIsOpen => !currentIsOpen)
                props.setOpen(false)
                snack.current("Veículo adicionado!", { variant: 'success' })
            })
            .catch((error) => {
                snack.current("Falha ao adicionar Veículo!", { variant: 'error' })
            });
    }

    async function Update() {
        snack.current("Editando, aguarde.", { variant: 'info' })
        let my_affiliate = props.affiliates.filter(x => x.label === values.filial)
        var veic = {
            '_id': values._id,
            "affiliateId": my_affiliate[0].value,
            "id_veic": values.id_veic,

            "placa": values.placa,
            "marca": values.marca,
            "status": values.status,
            "vinculo": values.vinculo,
            "num_antt": values.num_antt,
            "tipo_eixo": values.tipo_eixo,
            "categoria": values.categoria,
            "ano_modelo": values.ano_modelo,
            "combustivel": values.combustivel,
            "tec_rastreio": values.tec_rastreio,
            "codigo_frota": values.codigo_frota,
            "tipo_veiculo": values.tipo_veiculo,
            "ano_fabricacao": values.ano_fabricacao,
            "tipo_carroceria": values.tipo_carroceria,
            "vencimento_crlv": values.vencimento_crlv,
            "vencimento_antt": values.vencimento_antt,
            "id_tec_rastreio": values.id_tec_rastreio,

            "altura": Number(values.altura),
            "n_eixos": Number(values.n_eixos),
            "largura": Number(values.largura),
            "valor_km": Number(values.valor_km),
            "comprimento": Number(values.comprimento),
            "valor_diaria": Number(values.valor_diaria),
            "capacidade_kg": Number(values.capacidade_kg),
            "capacidade_m3": Number(values.capacidade_m3),
            "altura_carroceria_m": Number(values.altura_carroceria_m),
            "largura_carroceria_m": Number(values.largura_carroceria_m),
            "comprimento_carroceria_m": Number(values.comprimento_carroceria_m),
        }
        api.put(`/register/vehicle`, { veic })
            .then(() => {
                props.setReload(currentIsOpen => !currentIsOpen)
                props.setOpen(false)
                snack.current("Veículo editado!", { variant: 'success' })
            })
            .catch((error) => {
                console.log(error)
                snack.current("Falha ao editar Veículo!", { variant: 'error' })
            });
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"lg"}
            open={props.open}
            onClose={() => props.setOpen(false)}
            aria-labelledby="max-width-dialog-title"
        >
            <DialogTitle id="max-width-dialog-title" >
                <Typography variant="h5" align="center">Cadastro de Veículos </Typography>
            </DialogTitle>
            <DialogContent>
                <form className={classes.form} noValidate>
                    <FormControl className={classes.formControl}>
                        <DialogContent className={classes.root}>
                            <BasicInfo
                                update={update}
                                values={values}
                                setValues={setValues}
                                affiliates={props.affiliates}
                            />
                            <VehicleType
                                values={values}
                                update={update}
                                setValues={setValues}
                            />
                            <BodyInfo
                                values={values}
                                setValues={setValues}
                            />
                            <DocsInfo
                                values={values}
                                setValues={setValues}
                            />
                            <TechnologyInfo
                                values={values}
                                setValues={setValues}
                            />
                            <CreatedBy
                                values={values}
                                setValues={setValues}
                            />
                        </DialogContent >
                        <DialogActions>
                            <Button
                                disabled={(
                                    values.placa &&
                                    values.filial &&
                                    values.status &&
                                    values.vinculo &&
                                    values.n_eixos &&
                                    values.valor_km &&
                                    values.tipo_eixo &&
                                    values.categoria &&
                                    values.valor_diaria &&
                                    values.capacidade_kg
                                ) ? false : true}
                                onClick={() => { update === true ? Update() : AddNew() }}
                                color="primary"
                            >
                                Salvar
                            </Button>
                            <Button onClick={() => { props.setOpen(false) }} color="primary"> Cancelar </Button>
                        </DialogActions>
                    </FormControl>
                </form>
            </DialogContent>
        </Dialog >
    );
}

VehicleForm.propTypes = {
    open: PropTypes.bool,
    data: PropTypes.object,
    setOpen: PropTypes.func,
    setReload: PropTypes.func,
    affiliates: PropTypes.array,
};
