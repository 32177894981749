import React from "react";
import PropTypes from 'prop-types';
import { Popup } from "react-leaflet";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

export default function PopupRestricaoRodizio(props) {
    var horario_seg_sex = ""
    if (props.data.horario_seg_sex.length === 15) {
        horario_seg_sex = (
            <TableRow>
                <TableCell>segunda - sexta</TableCell>
                <TableCell width="40%" >{props.data.horario_seg_sex.substr(0, 11)}</TableCell>
            </TableRow>
        )
    } else if (props.data.horario_seg_sex.length === 25) {
        horario_seg_sex = (
            <TableRow>
                <TableCell>segunda - sexta</TableCell>
                <TableCell width="40%" >{props.data.horario_seg_sex.substr(0, 23)}</TableCell>
            </TableRow>
        )
    } else if (props.data.horario_seg_sex.length === 35) {
        horario_seg_sex = (
            <TableRow>
                <TableCell>segunda - sexta</TableCell>
                <TableCell width="40%" >{props.data.horario_seg_sex}</TableCell>
            </TableRow>
        )
    }

    var horario_sabados = ""
    if (props.data.horario_sabados.length === 15) {
        horario_sabados = (
            <TableRow>
                <TableCell>sábados</TableCell>
                <TableCell width="40%" >{props.data.horario_sabados.substr(0, 11)}</TableCell>
            </TableRow>
        )
    } else if (props.data.horario_sabados.length === 25) {
        horario_sabados = (
            <TableRow>
                <TableCell>sábados</TableCell>
                <TableCell width="40%" >{props.data.horario_sabados.substr(0, 23)}</TableCell>
            </TableRow>
        )
    } else if (props.data.horario_sabados.length === 35) {
        horario_sabados = (
            <TableRow>
                <TableCell>sábados</TableCell>
                <TableCell width="40%" >{props.data.horario_sabados.substr(0, 23)}</TableCell>
            </TableRow>
        )
    }

    var horario_dom_fer = ""
    if (props.data.horario_dom_fer.length === 15) {
        horario_dom_fer = (
            <TableRow>
                <TableCell>domingos e feriados</TableCell>
                <TableCell width="40%" >{props.data.horario_dom_fer.substr(0, 11)}</TableCell>
            </TableRow>
        )
    } else if (props.data.horario_dom_fer.length === 25) {
        horario_dom_fer = (
            <TableRow>
                <TableCell>domingos e feriados</TableCell>
                <TableCell width="40%" >{props.data.horario_dom_fer.substr(0, 23)}</TableCell>
            </TableRow>
        )
    } else if (props.data.horario_dom_fer.length === 35) {
        horario_dom_fer = (
            <TableRow>
                <TableCell>domingos e feriados</TableCell>
                <TableCell width="40%" >{props.data.horario_dom_fer}</TableCell>
            </TableRow>
        )
    }


    return (
        <Popup>
            <h2>Decreto {props.data.decreto}</h2>
            <Table size="small">
                <TableBody>
                    <TableRow>
                        <TableCell>Restricão</TableCell>
                        <TableCell>{props.data.tipo_restr}</TableCell>
                    </TableRow>
                    {horario_seg_sex}
                    {horario_sabados}
                    {horario_dom_fer}
                    <TableRow>
                        <TableCell>Cidade</TableCell>
                        <TableCell>{props.data.cidade}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Estado</TableCell>
                        <TableCell>{props.data.estado}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Popup>
    );
}

PopupRestricaoRodizio.propTypes = {
    data: PropTypes.object,
};
