import React, { useState } from "react";
import { Container } from "@material-ui/core";

import MyTable from "./Components/MyTable";
import SelectDate from "./Components/SelectDate.js"

export default function UserStatistics() {
    const [end, setEnd] = useState(new Date());
    const [start, setStart] = useState(new Date());

    return (
        <Container maxWidth="xl" spacing={2}>
            <SelectDate end={end} setEnd={setEnd} start={start} setStart={setStart} />
            < MyTable end={end} start={start} />
        </Container>
    );
}
