import React from 'react';
import PropTypes from 'prop-types';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import OfflinePinIcon from '@material-ui/icons/OfflinePin';
import { Tooltip, CircularProgress } from '@material-ui/core';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';

export default function ProcessingStatus(props) {
    const { fatura } = props

    return (
        <>
            <>
                {
                    fatura.processing === true ?
                        <Tooltip title={<span style={{ fontSize: 15 }}> fatura em processamento </span>} >
                            <CircularProgress
                                size={15}
                                style={{
                                    color: "blue",
                                    maxWidth: '30px',
                                    maxHeight: '30px',
                                    minWidth: '30px',
                                    minHeight: '30px',
                                    marginRight: '10px',
                                }}
                            />
                        </Tooltip> : null
                }
                {
                    fatura.completed === true ?
                        <Tooltip title={<span style={{ fontSize: 15 }}> fatura processada com sucesso </span>} >
                            <OfflinePinIcon
                                fontSize="small"
                                style={{
                                    color: "#21b6ae",
                                    maxWidth: '30px',
                                    maxHeight: '30px',
                                    minWidth: '30px',
                                    minHeight: '30px',
                                    marginRight: '10px',
                                }}
                            />
                        </Tooltip> : null
                }
                {
                    fatura.failure === true ?
                        <Tooltip title={<span style={{ fontSize: 15 }}> falha ao processar </span>} >
                            <PriorityHighIcon
                                fontSize="small"
                                style={{
                                    color: "red",
                                    maxWidth: '30px',
                                    maxHeight: '30px',
                                    minWidth: '30px',
                                    minHeight: '30px',
                                    marginRight: '10px',
                                }}
                            />
                        </Tooltip> : null
                }
            </>
            <>
                {
                    (fatura.n_total_duplicate > 0 || fatura.n_total_inc_category > 0) ?
                        <Tooltip title={<span style={{ fontSize: 15 }}> Fatura possui cobranças indevidas </span>} >
                            <LocalAtmIcon
                                color="primary"
                                fontSize="small"
                                style={{
                                    maxWidth: '30px',
                                    maxHeight: '30px',
                                    minWidth: '30px',
                                    minHeight: '30px',
                                    marginRight: '10px',
                                }}
                            />
                        </Tooltip> : null
                }
            </>
        </>

    );
}

ProcessingStatus.propTypes = {
    fatura: PropTypes.object,
};
