import L from 'leaflet';
import PropTypes from 'prop-types';
import { Marker } from 'react-leaflet';
import { useStoreState } from 'easy-peasy';
import ReactDOMServer from 'react-dom/server';
import React, { useEffect, useState } from 'react';

import IconRestr from '../../../../../../icons/Restricoes.js';
import PopupTolls from './PopupTolls.js';

export default function Vehicles() {
    const tolls_opt_calc = useStoreState((state) => state.TollsConferenceStore.tolls_opt_calc);
    const [pedagios, setPedagios] = useState([])

    useEffect(() => {
        async function gerarPedagios() {
            var pedagios = tolls_opt_calc.map((data, index) => (
                <div key={index}>
                    {(() => {
                        var my_icon = L.divIcon({
                            className: "custom-icon",
                            html: ReactDOMServer.renderToString(<IconRestr color={"green"} tipo={"pedagio"} />),
                        });
                        return (
                            <Marker key={index} icon={my_icon} position={[data.coordinates.lat, data.coordinates.lng]}>
                                <PopupTolls data={data} />
                            </Marker>
                        );
                    })()}
                </div>
            ));
            setPedagios(pedagios)
        }
        gerarPedagios()
    }, [tolls_opt_calc])

    return (
        <>
            {pedagios}
        </>
    );
}

Vehicles.propTypes = {
    setCenter: PropTypes.func,
};
