import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';

import '../../../styles.css';

export default function CreatedBy(props) {
    const handleChange = (event) => {
        props.setValues({
            ...props.values,
            [event.target.id]: event.target.value,
        });
    };

    return (
        <div className="fantasia_plan1">
            <div className="createdUser">
                <TextField
                    disabled
                    autoFocus
                    type="text"
                    id="criado_por:"
                    margin="dense"
                    label="Criado por:"
                    variant="outlined"
                    value={props.values.criado_por}
                    onChange={handleChange}
                />
                <TextField
                    disabled
                    autoFocus
                    type="text"
                    id="criado_por:"
                    margin="dense"
                    label="Criado em:"
                    variant="outlined"
                    value={props.values.criado_em}
                    onChange={handleChange}
                />
                <TextField
                    disabled
                    autoFocus
                    type="text"
                    id="editado_por:"
                    margin="dense"
                    label="Editado por:"
                    variant="outlined"
                    value={props.values.editado_por}
                    onChange={handleChange}
                />
                <TextField
                    disabled
                    autoFocus
                    type="text"
                    id="editado_em:"
                    margin="dense"
                    label="Editado em:"
                    variant="outlined"
                    value={props.values.editado_em}
                    onChange={handleChange}
                />
            </div>
        </div>
    )
}

CreatedBy.propTypes = {
    values: PropTypes.object,
    setValues: PropTypes.func
};
