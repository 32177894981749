import Cookies from 'js-cookie';
import React, { useState } from 'react';
import DnsIcon from '@material-ui/icons/Dns';
import FlagIcon from '@material-ui/icons/Flag';
import BallotIcon from '@material-ui/icons/Ballot';
import { makeStyles } from '@material-ui/core/styles';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { useHistory, useLocation } from 'react-router-dom';
import {
    Grow, Card, Button, Popper, AppBar, Toolbar, Tooltip, Typography,
    IconButton, CardActions, CardContent, ClickAwayListener, Box
} from '@material-ui/core';

import userAvatar from '../../assets/user.png';
import optcargo from '../../assets/optcargo.png';
import SelectDate from './Components/SelectDate.js';
import SelectAffiliate from './Components/SelectAffiliate.js';
const pjson = require('../../../package.json');

const useStyles = makeStyles(() => ({
    title: {
        flexGrow: 1,
    },
}));

export default function MyAppBar() {
    const history = useHistory();
    const location = useLocation();
    const auth = Cookies.get("is_authenticated");
    const auth_provisoria = localStorage.getItem("auth_provisoria");
    if (auth !== "true" && auth_provisoria !== "true") { history.push("/"); }

    const acess_token = Cookies.get("x-access-token");
    if (!acess_token) history.push("/");

    const [anchorEl, setAnchorEl] = useState(false);
    const handleMenu = (event) => { setAnchorEl(anchorEl ? null : event.currentTarget) };

    const user_type = localStorage.getItem("tipo"); // admin, master or user
    const acesso = localStorage.getItem("acesso"); // site or api
    const avatar = localStorage.getItem("avatar");

    function Sair() {
        Cookies.remove("is_authenticated");
        history.push("/");
    }

    const classes = useStyles();
    const fontSize = 12 * window.innerWidth / 1280

    function openInNewTab(href) {
        Object.assign(document.createElement('a'), {
            target: 'new',
            href: href,
        }).click();
    }

    return (
        <div >
            <AppBar position="fixed">
                <Toolbar variant="dense">
                    <a onClick={() => openInNewTab("https://optcargo.com.br/")} className={classes.title}>
                        <img src={optcargo} alt="OPTCARGO" className="nav-logo" />
                    </a>

                    <Box display='flex' flexGrow={15} >
                        <SelectAffiliate style={{ marginLeft: "5" }} />
                        <SelectDate style={{ marginRight: "5" }} />
                    </Box>

                    {acesso === "site" ? (
                        <Tooltip style={{ whiteSpace: 'nowrap', overflow: 'hidden' }} title={<span style={{ fontSize: 15 }}>  Dashboard </span>} >
                            <Button
                                onClick={() => { history.push("/dash") }}
                                color={location.pathname === "/dash" ? "primary" : "inherit"}
                                style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    fontSize,
                                }}
                            >
                                <DashboardIcon
                                    fontSize={'small'}
                                />
                                &nbsp; Dash
                            </Button>
                        </Tooltip>
                    ) : null
                    }

                    {(user_type === "admin" || user_type === "master") && acesso === "site" ? (
                        <Tooltip style={{ whiteSpace: 'nowrap', overflow: 'hidden' }} title={<span style={{ fontSize: 15 }}>  Gestão de Cadastros </span>} >
                            <Button
                                onClick={() => { history.push("/registration") }}
                                color={location.pathname === "/registration" ? "primary" : "inherit"}
                                style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    fontSize
                                }}
                            >
                                <AllInboxIcon
                                    fontSize={'small'}
                                />
                                &nbsp; Cadastros
                            </Button>
                        </Tooltip>
                    ) : null
                    }

                    {acesso === "site" ? (
                        <Tooltip style={{ whiteSpace: 'nowrap', overflow: 'hidden' }} title={<span style={{ fontSize: 15 }}>  Faturas de Pedágios </span>} >
                            <Button
                                onClick={() => { history.push("/invoices") }}
                                color={location.pathname === "/invoices" ? "primary" : "inherit"}
                                style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    fontSize,
                                }}
                            >
                                <DnsIcon
                                    fontSize={'small'}
                                />
                                &nbsp; Controle de Pedágios
                            </Button>
                        </Tooltip>
                    ) : null
                    }


                    {(user_type === "admin" || user_type === "master" || user_type === "user") && acesso === "site" ? (
                        <Tooltip style={{ whiteSpace: 'nowrap', overflow: 'hidden' }} title={<span style={{ fontSize: 15 }}> Planejamento das Viagens </span>} >
                            <Button
                                onClick={() => { history.push("/progr") }}
                                color={location.pathname === "/progr" ? "primary" : "inherit"}
                                style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    fontSize,
                                }}
                            >
                                <BallotIcon
                                    fontSize={'small'}
                                />
                                &nbsp; Planejar
                            </Button>
                        </Tooltip>
                    ) : null
                    }

                    {(user_type === "admin" || user_type === "master") && acesso === "site" ? (
                        <Tooltip style={{ whiteSpace: 'nowrap', overflow: 'hidden' }} title={<span style={{ fontSize: 15 }}> Viagens em Execução </span>} >
                            <Button
                                onClick={() => { history.push("/perform") }}
                                color={location.pathname === "/perform" ? "primary" : "inherit"}
                                style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    fontSize,
                                }}
                            >
                                <FlagIcon
                                    fontSize={'small'}
                                />
                                &nbsp; Executar
                            </Button>
                        </Tooltip>
                    ) : null
                    }

                    {/* MENU USUARIO */}
                    <IconButton
                        aria-label="usuário"
                        aria-controls="menu-usuario"
                        aria-haspopup="true"
                        onClick={handleMenu}
                        color="inherit"
                        className="userIcon"
                        style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}
                    >
                        <img
                            width="30"
                            height="30"
                            alt="Usuário"
                            src={avatar !== "" ? `data:image/jpeg;base64,${avatar}` : userAvatar}
                            style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}
                        />
                    </IconButton>

                    <Popper
                        id="menu-usuario"
                        anchorEl={anchorEl}
                        open={anchorEl && anchorEl.getAttribute("aria-controls") === "menu-usuario" ? true : false}
                        onClose={() => { setAnchorEl(null) }}
                        transition
                        style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}
                    >
                        {({ TransitionProps }) => (
                            <ClickAwayListener onClickAway={() => { setAnchorEl(null) }}>
                                <Grow {...TransitionProps} timeout={400}>
                                    <Card elevation={4}>
                                        <CardContent>
                                            <div>
                                                <a
                                                    href="!#"
                                                    className="user-upload-avatar"
                                                >
                                                    <img
                                                        src={avatar !== "" ? `data:image/jpeg;base64,${avatar}` : userAvatar}
                                                        width="100"
                                                        height="100"
                                                        alt="Usuário"
                                                    />
                                                </a>
                                                <Typography>
                                                    <strong>{localStorage.getItem("nome_completo")}</strong>
                                                </Typography>
                                                <Tooltip>
                                                    <Typography display="block" noWrap>
                                                        {localStorage.getItem("email")}
                                                    </Typography>
                                                </Tooltip>
                                                <Tooltip>
                                                    <Typography display="block" noWrap>
                                                        <em>{localStorage.getItem("empresa")}</em>
                                                    </Typography>
                                                </Tooltip>
                                            </div>
                                            <br />
                                            <br />
                                            <div style={{ marginTop: '2%' }}>
                                                <Button
                                                    color="secundary" variant="contained"
                                                    onClick={() => history.push("/minhaconta")}
                                                >
                                                    Minha conta
                                                </Button>
                                                {user_type === "admin" ? (
                                                    <Button
                                                        style={{ marginLeft: "4%" }}
                                                        color="secundary" variant="contained"
                                                        onClick={() => history.push("/admin/area")}
                                                    >
                                                        Administrador
                                                    </Button>)
                                                    : null
                                                }
                                            </div>
                                        </CardContent>
                                        <CardActions className="space-between">
                                            <Button style={{ whiteSpace: 'nowrap', overflow: 'hidden' }} size="small" color="secondary" >
                                                Termos de serviço
                                            </Button>
                                            versão {pjson.version}
                                            <Button style={{ whiteSpace: 'nowrap', overflow: 'hidden' }} size="small" onClick={() => Sair()} className="text-danger">
                                                Sair
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </Grow>
                            </ClickAwayListener>
                        )}
                    </Popper>
                </Toolbar>
            </AppBar>
        </div>
    );
}
