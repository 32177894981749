import PropTypes from 'prop-types';
import React, { Component } from "react";

export default class IconResults extends Component {
    render() {
        const ordem = this.props.ordem || 0;
        var carga = (this.props.doc.taxa_ocup * 23) / 100;
        var barOpacity = 0.4;
        if (this.props.doc.taxa_ocup >= 100) {
            carga = 23;
            barOpacity = 1;
        }

        var detalhes = "";
        // if (this.props.doc.flag_travamento_ordem === "usuario") {
        //     detalhes = (
        //         <path
        //             style={{ fill: "#4d4d4d" }}
        //             id="lock"
        //             d="m 34.582758,3.6106391 -0.512793,0 0,-1.0255887 c 0,-1.4153114 -1.14866,-2.5639703 -2.563973,-2.5639703 -1.415311,0 -2.563971,1.1486589 -2.563971,2.5639703 l 0,1.0255887 -0.512795,0 c -0.564072,0 -1.025588,0.4615159 -1.025588,1.0255885 l 0,5.1279463 c 0,0.5640711 0.461516,1.0255871 1.025588,1.0255871 l 6.153532,0 c 0.564076,0 1.025589,-0.461516 1.025589,-1.0255871 l 0,-5.1279463 c 0,-0.5640726 -0.461513,-1.0255885 -1.025589,-1.0255885 z m -3.076766,4.6151498 c -0.564073,0 -1.025589,-0.461516 -1.025589,-1.0255886 0,-0.5640754 0.461516,-1.0255884 1.025589,-1.0255884 0.564075,0 1.025588,0.461513 1.025588,1.0255884 0,0.5640726 -0.461513,1.0255886 -1.025588,1.0255886 z m 1.589663,-4.6151498 -3.179324,0 0,-1.0255887 c 0,-0.8768784 0.712782,-1.5896613 1.589661,-1.5896613 0.876878,0 1.589663,0.7127829 1.589663,1.5896613 l 0,1.0255887 z"
        //         />
        //     );
        // } 
        if (this.props.doc.parada_almoco) {
            detalhes = (
                <path
                    style={{ fill: "#4d4d4d" }}
                    id="lunch"
                    d="m 30.860324,4.1457905 -1.025588,0 0,-3.5895614 -1.025588,0 0,3.5895614 -1.02559,0 0,-3.5895614 -1.025589,0 0,3.5895614 c 0,1.0871227 0.85124,1.9691294 1.922979,2.0357918 l 0,4.6305327 1.281986,0 0,-4.6305327 c 1.071741,-0.066671 1.922979,-0.9486691 1.922979,-2.0357918 l 0,-3.5895614 -1.025589,0 0,3.5895614 z m 2.563971,-1.5383841 0,4.1023544 1.281986,0 0,4.1023542 1.281987,0 0,-10.2558859 c -1.415312,0 -2.563973,1.148659 -2.563973,2.0511773 z"
                />
            );
        }

        var color = null;
        if (this.props.doc.restri_porte === "sim") {
            color = "#ce420f";
        } else if (this.props.doc.restri_placa === "sim") {
            color = "#ce420f";
        } else if (this.props.tipo === "ENTREGA") {
            color = "#3b7b00";
        } else if (this.props.tipo === "COLETA") {
            color = "#1E90FF";
        } else {
            if (this.props.veic.restricoes_placa.length > 0) color = "red"
            if (this.props.veic.restricoes_porte.length > 0) color = "red"
            else color = "#0055a7"
            return (
                <svg version="1.1" width="36" viewBox="0 0 36 36" height="36">
                    <path
                        style={{ fill: color }}
                        d="m 9.6312219,21.567307 0,-7.106334 4.7375561,0 0,7.106334 5.921946,0 0,-9.475112 3.553167,0 L 12,1.4326929 0.15610927,12.092195 l 3.55316723,0 0,9.475112 z"
                    />
                </svg>
            );
        }
        return (
            <svg version="1.1" width="36" viewBox="0 0 36 36" height="36">
                <path
                    style={{ fill: color }}
                    id="truck"
                    d="m 30.827524,12.770795 -4.807302,0 0,-2.9757756 c 0,-1.1301484 -0.820585,-3.4339594 -3.621304,-3.4339594 l -18.8127689,0 c -1.7626771,0 -3.20486753,1.4421903 -3.20486753,3.2048675 l 0,17.6267715 3.20486753,0 C 3.5861491,29.852739 5.7334103,32 8.3934504,32 11.05349,32 13.200752,29.852739 13.200752,27.192699 l 9.614603,0 c 0,2.66004 2.147261,4.807301 4.807301,4.807301 2.66004,0 4.807301,-2.147261 4.807301,-4.807301 l 3.204868,0 0,-8.012169 z M 8.3934504,29.596349 c -1.33002,0 -2.4036506,-1.07363 -2.4036506,-2.40365 0,-1.33002 1.0736306,-2.403651 2.4036506,-2.403651 1.33002,0 2.4036506,1.073631 2.4036506,2.403651 0,1.33002 -1.0736306,2.40365 -2.4036506,2.40365 z m 21.6328566,-14.421903 3.14077,4.006084 -7.146855,0 0,-4.006084 z m -2.403651,14.421903 c -1.33002,0 -2.403651,-1.07363 -2.403651,-2.40365 0,-1.33002 1.073631,-2.403651 2.403651,-2.403651 1.33002,0 2.403651,1.073631 2.403651,2.403651 0,1.33002 -1.073631,2.40365 -2.403651,2.40365 z"
                />
                <rect
                    id="bg"
                    width="22.837976"
                    height="16.406591"
                    x="1.7976608"
                    y="7.5653424"
                    rx="1.556259"
                    ry="1.556259"
                    style={{ fill: "#ffffff", fillOpacity: 0.9 }}
                //style="color:#000000;clip-rule:nonzero;display:inline;overflow:visible;visibility:visible;opacity:1;isolation:auto;mix-blend-mode:normal;color-interpolation:sRGB;color-interpolation-filters:linearRGB;solid-color:#000000;solid-opacity:1;fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.20000005;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;marker:none;color-rendering:auto;image-rendering:auto;shape-rendering:auto;text-rendering:auto;enable-background:accumulate"
                />
                <rect
                    style={{ fill: color, fillOpacity: barOpacity }}
                    //style="color:#000000;clip-rule:nonzero;display:inline;overflow:visible;visibility:visible;opacity:1;isolation:auto;mix-blend-mode:normal;color-interpolation:sRGB;color-interpolation-filters:linearRGB;solid-color:#000000;solid-opacity:1;fill:{color};fill-opacity:0.38150294;fill-rule:nonzero;stroke:none;stroke-width:1.20000005;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;marker:none;color-rendering:auto;image-rendering:auto;shape-rendering:auto;text-rendering:auto;enable-background:accumulate"
                    id="bar"
                    ry="1.556259"
                    rx="1.556259"
                    y="7.5653424"
                    x="1.7976608"
                    height="16.406591"
                    width={carga}
                />
                <text
                    id="order"
                    x="13"
                    y="20"
                    style={{ TextAlign: "center", textAnchor: "middle" }}
                //style="font-style:normal;font-weight:normal;font-size:9.1867075px;line-height:125%;font-family:sans-serif;text-align:center;letter-spacing:0px;word-spacing:0px;text-anchor:middle;fill:#4d4d4d;fill-opacity:1;stroke:none;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
                >
                    <tspan x="10" y="20">
                        {ordem}
                    </tspan>
                </text>
                {detalhes}
            </svg>
        );
    }
}

IconResults.propTypes = {
    ordem: PropTypes.number,
    doc: PropTypes.object,
    veic: PropTypes.object,
    tipo: PropTypes.string,
};
