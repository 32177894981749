import React, { useRef } from 'react';
import { useSnackbar } from 'notistack';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Button, DialogActions, Dialog, DialogTitle, FormControl, DialogContent, Typography } from '@material-ui/core';

import CreatedBy from './Components/CreatedBy';
import MyMap from './Components/MyMap/MyMap.js';
import DocsInfo from './Components/DocsInfo.js';
import TripInfo from './Components/TripInfo.js';

export default function VeicsForm() {
    const snack = useRef()
    const { enqueueSnackbar } = useSnackbar();
    snack.current = enqueueSnackbar

    //ativa e desativa o loading abaixo da appbar
    const setLoading = useRef()
    setLoading.current = useStoreActions(actions => {
        return actions.globalStore.setLoading;
    });

    const setOpenDrawer = useStoreActions(actions => { return actions.PlanedTripsStore.setOpenDrawer });
    const docs = useStoreState((state) => state.PlanedTripsStore.docs);
    const open = useStoreState((state) => state.PlanedTripsStore.open_drawer);
    const veic_info = useStoreState((state) => state.PlanedTripsStore.veic_info);

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"xl"}
            open={open}
            onClose={() => setOpenDrawer(false)}
            aria-labelledby="max-width-dialog-title"
        >
            <DialogTitle id="max-width-dialog-title" >
                <Typography variant="h5" align="center"> Informações da Viagem </Typography>
            </DialogTitle>
            <DialogContent>
                <form noValidate className="form">
                    <FormControl className="formControl">
                        <DialogContent className="root">
                            <div className="rowMapViagem">
                                <TripInfo values={veic_info} />
                                <MyMap veic={veic_info} docs={docs} />
                            </div>
                            <DocsInfo veic={veic_info} docs={docs} />
                            <CreatedBy values={veic_info} />
                        </DialogContent >
                        <DialogActions>
                            <Button onClick={() => { setOpenDrawer(false) }} color="primary"> Fechar </Button>
                        </DialogActions>
                    </FormControl>
                </form>
            </DialogContent>
        </Dialog>
    );
}
