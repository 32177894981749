import React from "react";
import { TextField } from "@material-ui/core";

export default function FieldTime(props) {
    return (
        <>
            <h3> SEGUNDA - SEXTA </h3>

            <TextField
                id="hora1"
                label="Início"
                type="time"
                value={props.hora1}
                onChange={(event) => props.setHora1(event.target.value)}
                InputLabelProps={{
                    shrink: true,
                }}
            />

            <TextField
                id="hora2"
                label="Fim"
                type="time"
                value={props.hora2}
                onChange={(event) => props.setHora2(event.target.value)}
                InputLabelProps={{
                    shrink: true,
                }}
            />

            <TextField
                id="hora3"
                label="Início"
                type="time"
                value={props.hora3}
                onChange={(event) => props.setHora3(event.target.value)}
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{
                    step: 300, // 5 min
                }}
            />

            <TextField
                id="hora4"
                label="Fim"
                type="time"
                value={props.hora4}
                onChange={(event) => props.setHora4(event.target.value)}
                InputLabelProps={{
                    shrink: true,
                }}
            />

            <TextField
                id="hora5"
                label="Início"
                type="time"
                value={props.hora5}
                onChange={(event) => props.setHora5(event.target.value)}
                InputLabelProps={{
                    shrink: true,
                }}
            />

            <TextField
                id="hora6"
                label="Fim"
                type="time"
                value={props.hora6}
                onChange={(event) => props.setHora6(event.target.value)}
                InputLabelProps={{
                    shrink: true,
                }}
            />

            <h3>SÁBADOS</h3>
            <TextField
                id="hora7"
                label="Início"
                type="time"
                value={props.hora7}
                onChange={(event) => props.setHora7(event.target.value)}
                InputLabelProps={{
                    shrink: true,
                }}
            />

            <TextField
                id="hora8"
                label="Fim"
                type="time"
                value={props.hora8}
                onChange={(event) => props.setHora8(event.target.value)}
                InputLabelProps={{
                    shrink: true,
                }}
            />

            <TextField
                id="hora9"
                label="Início"
                type="time"
                value={props.hora9}
                onChange={(event) => props.setHora9(event.target.value)}
                InputLabelProps={{
                    shrink: true,
                }}
            />

            <TextField
                id="hora10"
                label="Fim"
                type="time"
                value={props.hora10}
                onChange={(event) => props.setHora10(event.target.value)}
                InputLabelProps={{
                    shrink: true,
                }}
            />

            <TextField
                id="hora11"
                label="Início"
                type="time"
                value={props.hora11}
                onChange={(event) => props.setHora11(event.target.value)}
                InputLabelProps={{
                    shrink: true,
                }}
            />

            <TextField
                id="hora12"
                label="Fim"
                type="time"
                value={props.hora12}
                onChange={(event) => props.setHora12(event.target.value)}
                InputLabelProps={{
                    shrink: true,
                }}
            />

            <h3>DOMINGOS E FERIADOS</h3>
            <TextField
                id="hora13"
                label="Início"
                type="time"
                value={props.hora13}
                onChange={(event) => props.setHora13(event.target.value)}
                InputLabelProps={{
                    shrink: true,
                }}
            />

            <TextField
                id="hora14"
                label="Fim"
                type="time"
                value={props.hora14}
                onChange={(event) => props.setHora14(event.target.value)}
                InputLabelProps={{
                    shrink: true,
                }}
            />

            <TextField
                id="hora15"
                label="Início"
                type="time"
                value={props.hora15}
                onChange={(event) => props.setHora15(event.target.value)}
                InputLabelProps={{
                    shrink: true,
                }}
            />

            <TextField
                id="hora16"
                label="Fim"
                type="time"
                value={props.hora16}
                onChange={(event) => props.setHora16(event.target.value)}
                InputLabelProps={{
                    shrink: true,
                }}
            />

            <TextField
                id="hora17"
                label="Início"
                type="time"
                value={props.hora17}
                onChange={(event) => props.setHora17(event.target.value)}
                InputLabelProps={{
                    shrink: true,
                }}
            />

            <TextField
                id="hora18"
                label="Fim"
                type="time"
                value={props.hora18}
                onChange={(event) => props.setHora18(event.target.value)}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </>
    );
}
