import PropTypes from 'prop-types';
import React, { useRef } from "react";
import MaterialTable from "material-table";

export default function Charges(props) {
    const state = {
        columns: [
            { title: "Quant. Mínima de Requisições", field: "quant_min", type: "numeric" },
            { title: "Quant. Máxima de Requisições", field: "quant_max", type: "numeric" },
            { title: "Contrato 12 Meses (R$/requisição)", field: "contrato_12_meses", type: "numeric" },
            { title: "Contrato 24 Meses (R$/requisição)", field: "contrato_24_meses", type: "numeric" },
            { title: "Contrato 36 Meses (R$/requisição)", field: "contrato_36_meses", type: "numeric" },
        ],
    };

    const tableRef = useRef();
    return (
        <div >
            <MaterialTable
                style={{ maxWidth: "87%" }}
                tableRef={tableRef}
                title="Transações adicionais (faixas)"
                columns={state.columns}
                data={props.values.faixas}
                options={{
                    add: true,
                    search: true,
                    pageSize: 6,
                    exportButton: false,
                    emptyRowsWhenPaging: false,
                    pageSizeOptions: [6],

                    padding: "dense",
                    headerStyle: {
                        backgroundColor: "#01579b",
                        textAlign: "center",
                        color: "#FFF",
                    },
                    cellStyle: {
                        textAlign: "center",
                    },
                }}
                localization={{
                    body: {
                        emptyDataSourceMessage: "",
                        addTooltip: "adicionar nova faixa",
                        deleteTooltip: "excluir",
                        editRow: { deleteText: "Deseja excluir o faixa?" },
                    },

                    header: {
                        actions: "Opções",
                    },

                    toolbar: {
                        searchTooltip: "Pesquisar",
                        searchPlaceholder: "Pesquisar",
                        exportTitle: "exportar",
                        nRowsSelected: "{0} faixas selecionadas",
                    },
                    pagination: {
                        labelRowsSelect: "",
                        labelDisplayedRows: "{count} de {from}-{to}",
                        firstTooltip: "Primeira página",
                        previousTooltip: "Página anterior",
                        nextTooltip: "Próxima página",
                        lastTooltip: "Última página",
                    },
                }}
                editable={{
                    onRowAdd: (newData) =>
                        new Promise((resolve) => {
                            setTimeout(() => {
                                const dataAdd = [...props.values.faixas];
                                dataAdd.push(newData);
                                props.setValues({
                                    ...props.values,
                                    'faixas': dataAdd,
                                });
                                resolve();
                            }, 1000);
                        }),

                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve) => {
                            setTimeout(() => {
                                const dataUpdate = [...props.values.faixas];
                                const index = oldData.tableData.id;
                                dataUpdate[index] = newData;
                                props.setValues({
                                    ...props.values,
                                    'faixas': dataUpdate,
                                });
                                resolve();
                            }, 1000);
                        }),

                    onRowDelete: (oldData) =>
                        new Promise((resolve) => {
                            setTimeout(() => {
                                const dataDelete = [...props.values.faixas];
                                const index = oldData.tableData.id;
                                dataDelete.splice(index, 1);
                                props.setValues({
                                    ...props.values,
                                    'faixas': dataDelete,
                                });
                                resolve();
                            }, 1000);
                        }),
                }}
            />
        </div>
    );
}

Charges.propTypes = {
    snack: PropTypes.func,
    values: PropTypes.object,
    setValues: PropTypes.func
};
