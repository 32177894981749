import React, { useRef } from 'react';
import MaterialTable from 'material-table';
import { useStoreState, useStoreActions } from 'easy-peasy';

export default function TollsOptcargoCalc() {
    const state = {
        columns: [
            { title: "Placa", field: "placa" },
            { title: "Nome", field: "name" },
            { title: "Custo R$", field: "cost" },
            { title: "Endereço", field: "address" },
            { title: "J1", field: "packageDateStart" },
            { title: "J2", field: "packageDateEnd" },
        ],
    };

    const tolls_opt_calc = useStoreState((state) => state.TollsConferenceStore.tolls_opt_calc);

    const setTollsMap = useStoreActions(actions => {
        return actions.TollsConferenceStore.setTollsMap;
    });

    const tableRef = useRef();
    return (
        <MaterialTable
            tableRef={tableRef}
            title="Pedágios calculados"
            columns={state.columns}
            data={tolls_opt_calc}
            options={{
                search: true,
                pageSize: 5,
                grouping: true,
                selection: true,
                exportButton: false,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [1, 5, 10, 20],
                padding: "dense",
                headerStyle: {
                    backgroundColor: "#01579b",
                    textAlign: 'center',
                    color: "#FFF",
                    fontSize: 11 * window.innerWidth / 1280,
                },
                cellStyle: {
                    textAlign: "center",
                    fontSize: 11 * window.innerWidth / 1280,
                },
            }}
            onSelectionChange={(rows) => setTollsMap(rows)}
            localization={{
                body: {
                    addTooltip: "Adicionar novo",
                    deleteTooltip: "Excluir",
                    editTooltip: "Editar",
                    editRow: {
                        deleteText: "Deseja excluir o veículo?",
                        cancelTooltip: "Cancelar",
                        saveTooltip: "Salvar",
                    },
                    emptyDataSourceMessage: (
                        ""
                    ),
                    bulkEditTooltip: "Editar todos",
                    bulkEditCancel: "Descartar edições",
                    bulkEditApprove: "Salvar todas as edições"
                },

                grouping: {
                    placeholder: "Arraste os cabeçalhos aqui para agrupar por",
                    groupedBy: "Agrupado por:",
                },

                header: {
                    actions: "Opções",
                },

                toolbar: {
                    searchTooltip: "Pesquisar",
                    searchPlaceholder: "Pesquisar",
                    exportTitle: "Exportar",
                    nRowsSelected: "{0} veículos(s) selecionados",
                },
                pagination: {
                    labelRowsSelect: "",
                    labelDisplayedRows: "{count} de {from}-{to}",
                    firstTooltip: "Primeira página",
                    previousTooltip: "Página anterior",
                    nextTooltip: "Próxima página",
                    lastTooltip: "Última página",
                },
            }}
        />
    );
}
