import L from "leaflet";
import PropTypes from 'prop-types';
import { Marker } from "react-leaflet";
import { useStoreState } from "easy-peasy";
import ReactDOMServer from "react-dom/server";
import React, { useState, useEffect, useRef } from "react";
import MarkerClusterGroup from "react-leaflet-markercluster";

import IconDocs from "../../../../../../../icons/Documentos.js";
import PopupDocumento from "../../../../../../../popups/Documento";

export default function Documents(props) {
    const setCenterRef = useRef()
    setCenterRef.current = props.setCenter

    const [docs, setDocs] = useState([]);

    const open_cluster = useStoreState((state) => state.toAllocateStore.open_cluster);
    const avaliable_docs = useStoreState((state) => state.toAllocateStore.avaliable_docs);

    useEffect(() => {
        async function fetchData_() {
            if (avaliable_docs.length > 0) {
                setCenterRef.current([avaliable_docs[0].lat_doc, avaliable_docs[0].lon_doc]);
                var my_markers = avaliable_docs.map((doc, cont) => (
                    <div key={cont}>
                        {(() => {
                            var my_icon = L.divIcon({
                                className: "custom-icon",
                                html: ReactDOMServer.renderToString(<IconDocs id={cont} doc={doc} />),
                            });
                            if (doc.tipo === "ENTREGA") {
                                return (
                                    <Marker icon={my_icon} key={cont} position={[doc.lat_doc, doc.lon_doc]}>
                                        <PopupDocumento doc={doc} />
                                    </Marker>
                                );
                            } else if (doc.tipo === "COLETA") {
                                return (
                                    <Marker icon={my_icon} key={cont} position={[doc.lat_doc, doc.lon_doc]}>
                                        <PopupDocumento doc={doc} />
                                    </Marker>
                                );
                            }
                        })()}
                    </div>
                ));
                setDocs(my_markers);
            } else {
                setDocs([]);
            }
        }
        fetchData_();
    }, [avaliable_docs]);

    return (
        <>
            {!open_cluster && <>{docs} </>}
            {open_cluster && <MarkerClusterGroup className={"markercluster-map"} id={0}> {docs} </MarkerClusterGroup>}
        </>
    )
}

Documents.propTypes = {
    setCenter: PropTypes.func,
};
