import React, { useState } from 'react';
import Cancel from "@material-ui/icons/Cancel";
import { makeStyles } from '@material-ui/core/styles';
import { useStoreState, useStoreActions } from "easy-peasy";
import { SwipeableDrawer, Container, IconButton } from '@material-ui/core';

import MyStepper from "./Components/MyStepper.js"
import VehicleTable from "./Components/VehicleTable.js"
import DocumentTable from "./Components/DocumentTable.js"
import MapAllocation from "./Components/MapAllocation"

const useStyles = makeStyles((theme) => ({
    paper: {
        top: 0,
    },
    menuButton: {
        marginRight: theme.spacing(1),
    },
}));

export default function ToAllocate() {
    const classes = useStyles();

    const [activeStep, setActiveStep] = useState(0);

    const [alocattion_vehicles, setAlocattionVehicles] = useState([])
    const [alocattion_documents, setAlocattionDocuments] = useState([])

    const open_allocate = useStoreState((state) => state.toAllocateStore.open_allocate);

    // fecha a janela de alocação
    const setOpenAllocate = useStoreActions(actions => {
        return actions.toAllocateStore.setOpenAllocate;
    });

    function cancelarAlocacao() {
        setActiveStep(0)
        setAlocattionVehicles([])
        setAlocattionDocuments([])
        setOpenAllocate(false)
    }

    return (
        <SwipeableDrawer
            anchor={"bottom"}
            // variant="persistent"
            onOpen={() => console.log("open")}
            onClose={() => console.log("close")}
            open={open_allocate}
            classes={{ paper: classes.paper }}
        >
            < Container maxWidth={false} spacing={2}>

                <IconButton
                    edge="start"
                    className={classes.menuButton}
                    aria-label="menu"
                    onClick={() => cancelarAlocacao()} >
                    <Cancel />
                </IconButton>

                <MyStepper
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    alocattion_vehicles={alocattion_vehicles}
                    alocattion_documents={alocattion_documents}
                    setAlocattionVehicles={setAlocattionVehicles}
                    setAlocattionDocuments={setAlocattionDocuments}
                />

                {activeStep === 0 ?
                    <>
                        < DocumentTable setAlocattionDocuments={setAlocattionDocuments} />
                        <MapAllocation setAlocattionDocuments={setAlocattionDocuments} />
                    </>
                    : null
                }

                {activeStep === 1 ?
                    < VehicleTable setAlocattionVehicles={setAlocattionVehicles} />
                    : null
                }

            </Container>
        </SwipeableDrawer>
    );
}
