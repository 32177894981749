import React from "react";
import "react-leaflet-fullscreen-control";
import { useStoreState } from "easy-peasy";
import { Map, TileLayer } from "react-leaflet";
import { makeStyles } from "@material-ui/core/styles";
import ReactLeafletSearch from "react-leaflet-search";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Accordion, Typography, AccordionSummary, AccordionDetails } from "@material-ui/core";

import "../../styles.css";
import MapSizeRestrictions from "./MapSizeRestrictions"
import AllocationEditControl from "./AllocationEditControl.js"

const useStyles = makeStyles((theme) => ({
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular
    }
}));

export default function MyMap() {
    const classes = useStyles();
    const center = useStoreState((state) => state.sizeStore.center);

    return (
        <Accordion >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                <Typography className={classes.heading}>Mapa</Typography>
            </AccordionSummary>

            <AccordionDetails>
                <Map fullscreenControl className="map_restr_ped" center={center} zoom={10} maxZoom={19}>
                    <TileLayer url="http://{s}.tile.osm.org/{z}/{x}/{y}.png" />
                    <ReactLeafletSearch position="topright" providerOptions={{ region: "br" }} />
                    < MapSizeRestrictions />
                    < AllocationEditControl />
                </Map>
            </AccordionDetails>
        </Accordion>
    );
}
