import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import AdminArea from "./pages/AdminArea";
import Progr from "./pages/Progr/Progr.js";
import Login from "./pages/Login/Login.js";
import Perform from "./pages/Perform/Perform.js";
import DashTabs from "./pages/DashTabs/DashTabs.js";
import MinhaConta from "./pages/UserArea/MinhaConta";
import AlterarSenha from "./pages/UserArea/AlterarSenha";
import RecuperarSenha from "./pages/UserArea/RecuperarSenha";
import InvoicesTabs from "./pages/InvoiceTabs/InvoiceTabs.js";
import RegistrationsTabs from "./pages/RegistrationsTabs/RegistrationsTabs.js";

export default function Routes() {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/progr" component={Progr} />
                <Route path="/" exact component={Login} />
                <Route path="/perform" component={Perform} />
                <Route path="/invoices" component={InvoicesTabs} />
                <Route path="/admin/area" component={AdminArea} />
                <Route path="/dash" exact component={DashTabs} />
                <Route path="/minhaconta" component={MinhaConta} />
                <Route path="/registration" component={RegistrationsTabs} />
                <Route path="/recuperar-a-senha" component={RecuperarSenha} />
                <Route path="/alterar-a-senha/:token" component={AlterarSenha} />
            </Switch>
        </BrowserRouter>
    );
}
