import L from "leaflet";
import "../../styles.css";
import { useStoreState } from "easy-peasy"
import ReactDOMServer from "react-dom/server";
import { Polyline, Marker } from "react-leaflet";
import React, { useEffect, useState } from "react";
import MarkerClusterGroup from "react-leaflet-markercluster";

import IconRestr from "../../../../../../icons/Restricoes";
import PopupRestricaoPorte from "../../../../../../popups/RestricaoPorte";

export default function MapSizeRestrictions() {
    const [restricao_porte, setRestricoesPorte] = useState([]);

    const size = useStoreState((state) => state.sizeStore.size);

    useEffect(() => {
        function fetchData() {
            var restr_porte = size.map((data, index) => (
                <div key={index}>
                    {(() => {
                        var paths_latlon = data.area;
                        var my_icon = L.divIcon({
                            className: "custom-icon",
                            html: ReactDOMServer.renderToString(<IconRestr color={"red"} tipo={"restricao_porte"} />),
                        });
                        return (
                            <Marker key={index} icon={my_icon} position={[data.area[0].lat, data.area[0].lng]}>
                                <Polyline color={"red"} positions={paths_latlon} />
                                <PopupRestricaoPorte data={data} />
                            </Marker>
                        );
                    })()}
                </div>
            ));
            setRestricoesPorte(restr_porte);
        }
        fetchData();
    }, [size]);

    return (
        <MarkerClusterGroup className={"markercluster-maprestr"} id={0}>
            {restricao_porte}
        </MarkerClusterGroup>
    );
}
