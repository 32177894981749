const Ufs = [
    { title: "AC", value: "AC", state_name: "Acre" },
    { title: "AL", value: "AL", state_name: "Alagoas" },
    { title: "AP", value: "AP", state_name: "Amapá" },
    { title: "AM", value: "AM", state_name: "Amazonas" },
    { title: "BA", value: "BA", state_name: "Bahia" },
    { title: "CE", value: "CE", state_name: "Ceará" },
    { title: "DF", value: "DF", state_name: "Distrito Federal" },
    { title: "ES", value: "ES", state_name: "Espírito Santo" },
    { title: "GO", value: "GO", state_name: "Goiás" },
    { title: "MA", value: "MA", state_name: "Maranhão" },
    { title: "MT", value: "MT", state_name: "Mato Grosso", },
    { title: "MS", value: "MS", state_name: "Mato Grosso do Sul" },
    { title: "MG", value: "MG", state_name: "Minas Gerais" },
    { title: "PA", value: "PA", state_name: "Pará" },
    { title: "PB", value: "PB", state_name: "Paraíba" },
    { title: "PR", value: "PR", state_name: "Paraná" },
    { title: "PE", value: "PE", state_name: "Pernambuco" },
    { title: "PI", value: "PI", state_name: "Piauí" },
    { title: "RJ", value: "RJ", state_name: "Rio de Janeiro" },
    { title: "RN", value: "RN", state_name: "Rio Grande do Norte" },
    { title: "RS", value: "RS", state_name: "Rio Grande do Sul" },
    { title: "RO", value: "RO", state_name: "Rondônia" },
    { title: "RR", value: "RR", state_name: "Roraima" },
    { title: "SC", value: "SC", state_name: "Santa Catarina" },
    { title: "SP", value: "SP", state_name: "São Paulo" },
    { title: "SE", value: "SE", state_name: "Sergipe" },
    { title: "TO", value: "TO", state_name: "Tocantins" },
];
export default Ufs;
