import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { useSnackbar } from 'notistack';
import AddIcon from '@material-ui/icons/Add';
import { Fab, Tooltip } from '@material-ui/core';

export default function Add(props) {
    const snack = useRef()
    const { enqueueSnackbar } = useSnackbar();
    snack.current = enqueueSnackbar

    return (
        <Tooltip title={<span style={{ fontSize: 15 }}>Adicionar Nova Filial</span>}>
            <Fab
                color="primary"
                aria-label="add"
                style={{
                    margin: 10,
                    maxWidth: 45,
                    minWidth: 45,
                    maxHeight: 45,
                    minHeight: 45,
                }}
                onClick={() => {
                    props.setOpen(true);
                    props.setData(null);
                }}
            >
                <AddIcon />
            </Fab>
        </Tooltip>
    );
}

Add.propTypes = {
    setData: PropTypes.func,
    setOpen: PropTypes.func,
};
