import { useSnackbar } from "notistack";
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useState, useRef, useEffect } from "react";
import { useStoreActions, useStoreState } from "easy-peasy"
import { Button, TextField, DialogActions, DialogContent } from "@material-ui/core";

import FieldTime from "../FieldTime.js"
import Ufs from "../../../../../../utils/Ufs"
import api from "../../../../../../services/api";
import cities from "../../../../../../utils/Cities"

export default function SizeFormStore() {
    const snack = useRef()
    const { enqueueSnackbar } = useSnackbar();
    snack.current = enqueueSnackbar

    const [hora1, setHora1] = useState("");
    const [hora2, setHora2] = useState("");
    const [hora3, setHora3] = useState("");
    const [hora4, setHora4] = useState("");
    const [hora5, setHora5] = useState("");
    const [hora6, setHora6] = useState("");
    const [hora7, setHora7] = useState("");
    const [hora8, setHora8] = useState("");
    const [hora9, setHora9] = useState("");
    const [hora10, setHora10] = useState("");
    const [hora11, setHora11] = useState("");
    const [hora12, setHora12] = useState("");
    const [hora13, setHora13] = useState("");
    const [hora14, setHora14] = useState("");
    const [hora15, setHora15] = useState("");
    const [hora16, setHora16] = useState("");
    const [hora17, setHora17] = useState("");
    const [hora18, setHora18] = useState("");
    const [estado, setEstado] = useState("");
    const [cidade, setCidade] = useState([""]);
    const [decreto, setDecreto] = useState("");
    const [pesomin, setPesomin] = useState(null);
    const [eixomin, setEixomin] = useState(null);
    const [pesomax, setPesomax] = useState(null);
    const [eixomax, setEixomax] = useState(null);
    const [disabled, setDisabled] = useState(true);
    const [cities_uf, setCitiesUf] = useState(true);
    const [alturamin, setAlturamin] = useState(null);
    const [alturamax, setAlturamax] = useState(null);
    const [links_decretos, setLinksDecreto] = useState("");
    const [comprimentomax, setComprimentomax] = useState(null);
    const [comprimentomin, setComprimentomin] = useState(null);
    const [btn_atualizar_salvar, setBtnHabilitarSalvar] = useState(false);

    const setReloadSizeRef = useRef()
    setReloadSizeRef.current = useStoreActions(actions => {
        return actions.reloadStore.setReloadSize;
    });

    const setOpenStoreFormRef = useRef()
    setOpenStoreFormRef.current = useStoreActions(actions => {
        return actions.sizeStore.setOpenStoreForm;
    });

    const area = useStoreState((state) => state.sizeStore.area);

    useEffect(() => { checkFields() });

    function checkFields() {
        if (pesomin < 0) {
            setBtnHabilitarSalvar(false);
            return;
        } else if (alturamin < 0) {
            setBtnHabilitarSalvar(false);
            return;
        } else if (comprimentomin < 0) {
            setBtnHabilitarSalvar(false);
            return;
        } else if (eixomin < 0) {
            setBtnHabilitarSalvar(false);
            return;
        } else if (pesomax < 0) {
            setBtnHabilitarSalvar(false);
            return;
        } else if (alturamax < 0) {
            setBtnHabilitarSalvar(false);
            return;
        } else if (comprimentomax < 0) {
            setBtnHabilitarSalvar(false);
            return;
        } else if (eixomax < 0) {
            setBtnHabilitarSalvar(false);
            return;
        } else {
            setBtnHabilitarSalvar(decreto !== "" && cidade !== "" && estado !== "" && links_decretos !== "");
        }
    }

    function changeEstado(data) {
        if (!data) {
            setEstado("")
            setCidade("")
            setDisabled(true)
        } else if (data.value !== estado) {
            setCidade("")
            setEstado(data.value)
            setCitiesUf(cities[data.value])
            setDisabled(false)
        }
    }

    async function setSizeRestr() {
        var horario_seg_sex = hora1 + "-" + hora2 + "\n" + hora3 + "-" + hora4 + "\n" + hora5 + "-" + hora6;
        var horario_sabados = hora7 + "-" + hora8 + "\n" + hora9 + "-" + hora10 + "\n" + hora11 + "-" + hora12;
        var horario_dom_fer = hora13 + "-" + hora14 + "\n" + hora15 + "-" + hora16 + "\n" + hora17 + "-" + hora18;

        await api.post(`/admin/sizerestrictions`,
            {
                email: localStorage.getItem("email"),
                decreto,
                horario_seg_sex,
                horario_sabados,
                horario_dom_fer,
                pesomin,
                alturamin,
                comprimentomin,
                eixomin,
                pesomax,
                alturamax,
                comprimentomax,
                eixomax,
                cidade,
                estado,
                links_decretos: links_decretos,
                area: area,
                center: area[0],
            },
        )
            .then(() => {
                snack.current(`Restrição de porte cadastrada com sucesso!`, { variant: 'success' })
                setReloadSizeRef.current()
                setOpenStoreFormRef.current(false);
            })
            .catch((error) => {
                console.log(error);
                setOpenStoreFormRef.current(false);
                snack.current(`Falha ao cadastrar restrição de porte!`, { variant: 'error' })
            });
    }

    return (
        <>
            <DialogContent>
                <TextField
                    fullWidth
                    autoFocus
                    type="text"
                    id="decreto"
                    margin="dense"
                    label="Descrição/Decreto"
                    onChange={(event) => setDecreto(event.target.value)}
                />
                < FieldTime
                    setHora1={setHora1}
                    setHora2={setHora2}
                    setHora3={setHora3}
                    setHora4={setHora4}
                    setHora5={setHora5}
                    setHora6={setHora6}
                    setHora7={setHora7}
                    setHora8={setHora8}
                    setHora9={setHora9}
                    setHora10={setHora10}
                    setHora11={setHora11}
                    setHora12={setHora12}
                    setHora13={setHora13}
                    setHora14={setHora14}
                    setHora15={setHora15}
                    setHora16={setHora16}
                    setHora17={setHora17}
                    setHora18={setHora18}
                />

                <div className="text_field_variables">
                    <div >
                        <TextField
                            autoFocus
                            id="pesomin"
                            type="number"
                            margin="dense"
                            label="Peso Mínimo (kg)"
                            onChange={(event) => setPesomin(event.target.value)}
                        />

                        <TextField
                            autoFocus
                            type="number"
                            id="pesomax"
                            margin="dense"
                            label="Peso Máximo (kg)"
                            onChange={(event) => setPesomax(event.target.value)}
                        />
                    </div>

                    <div>
                        <TextField
                            autoFocus
                            type="number"
                            margin="dense"
                            id="alturamin"
                            label="Altura Mínima (m)"
                            onChange={(event) => setAlturamin(event.target.value)}
                        />

                        <TextField
                            autoFocus
                            type="number"
                            margin="dense"
                            id="alturamax"
                            label="Altura Máxima (m)"
                            onChange={(event) => setAlturamax(event.target.value)}
                        />
                    </div>

                    <div>
                        <TextField
                            autoFocus
                            type="number"
                            margin="dense"
                            id="comprimentomin"
                            label="Comprimento Mínimo (m)"
                            onChange={(event) => setComprimentomin(event.target.value)}
                        />

                        <TextField
                            autoFocus
                            type="number"
                            margin="dense"
                            id="comprimentomax"
                            label="Comprimento Máximo (m)"
                            onChange={(event) => setComprimentomax(event.target.value)}
                        />
                    </div>

                    <div>
                        <TextField
                            autoFocus
                            id="eixomin"
                            type="number"
                            margin="dense"
                            label="Número Mínimo de Eixos"
                            onChange={(event) => setEixomin(event.target.value)}
                        />

                        <TextField
                            autoFocus
                            id="eixomax"
                            type="number"
                            margin="dense"
                            label="Número Máximo de Eixos"
                            onChange={(event) => setEixomax(event.target.value)}
                        />
                    </div>
                </div>

                <Autocomplete
                    fullWidth
                    id="estado"
                    options={Ufs}
                    value={{ title: estado, value: estado }}
                    getOptionLabel={(option) => option.title}
                    onChange={(_, data) => changeEstado(data)}
                    renderInput={(params) => <TextField {...params} label="Estado" margin="dense" />}
                />

                <Autocomplete
                    fullWidth
                    id="cities"
                    options={cities_uf}
                    disabled={disabled}
                    value={{ city: cidade }}
                    getOptionLabel={(option) => option.city}
                    renderInput={(params) => <TextField {...params} label="Cidade" margin="dense" />}
                    onChange={(_, data) => !data ? setCidade("") : setCidade(data.city)}
                />

                <TextField
                    autoFocus
                    fullWidth
                    margin="dense"
                    variant="filled"
                    label="latitudes e longitudes"
                    value={JSON.stringify(area)}
                    InputProps={{ readOnly: true }}
                />

                <TextField
                    autoFocus
                    multiline
                    fullWidth
                    type="text"
                    margin="dense"
                    id="links_decretos"
                    onChange={(event) => setLinksDecreto(event.target.value)}
                    label="Links dos Decretos (para mais de um link separe-o com virgula)"
                />
            </DialogContent >

            <DialogActions>
                <Button disabled={!btn_atualizar_salvar} onClick={() => setSizeRestr()} color="primary">
                    Salvar
                </Button>
                <Button onClick={() => setOpenStoreFormRef.current(false)} color="primary">
                    Cancelar
                </Button>
            </DialogActions>
        </>
    )
}
